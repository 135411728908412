/**
 * ThingsBoard REST API
 * For instructions how to authorize requests please visit <a href=\'http://thingsboard.io/docs/reference/rest-api/\'>REST API documentation page</a>.
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@thingsboard.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EntityTypeFilter { 
    relationType: string;
    entityTypes: Array<EntityTypeFilter.EntityTypesEnum>;
}
export namespace EntityTypeFilter {
    export type EntityTypesEnum = 'TENANT' | 'CUSTOMER' | 'USER' | 'DASHBOARD' | 'ASSET' | 'DEVICE' | 'ALARM' | 'ENTITY_GROUP' | 'CONVERTER' | 'INTEGRATION' | 'RULE_CHAIN' | 'RULE_NODE' | 'SCHEDULER_EVENT' | 'BLOB_ENTITY' | 'ENTITY_VIEW' | 'WIDGETS_BUNDLE' | 'WIDGET_TYPE' | 'ROLE' | 'GROUP_PERMISSION';
    export const EntityTypesEnum = {
        TENANT: 'TENANT' as EntityTypesEnum,
        CUSTOMER: 'CUSTOMER' as EntityTypesEnum,
        USER: 'USER' as EntityTypesEnum,
        DASHBOARD: 'DASHBOARD' as EntityTypesEnum,
        ASSET: 'ASSET' as EntityTypesEnum,
        DEVICE: 'DEVICE' as EntityTypesEnum,
        ALARM: 'ALARM' as EntityTypesEnum,
        ENTITYGROUP: 'ENTITY_GROUP' as EntityTypesEnum,
        CONVERTER: 'CONVERTER' as EntityTypesEnum,
        INTEGRATION: 'INTEGRATION' as EntityTypesEnum,
        RULECHAIN: 'RULE_CHAIN' as EntityTypesEnum,
        RULENODE: 'RULE_NODE' as EntityTypesEnum,
        SCHEDULEREVENT: 'SCHEDULER_EVENT' as EntityTypesEnum,
        BLOBENTITY: 'BLOB_ENTITY' as EntityTypesEnum,
        ENTITYVIEW: 'ENTITY_VIEW' as EntityTypesEnum,
        WIDGETSBUNDLE: 'WIDGETS_BUNDLE' as EntityTypesEnum,
        WIDGETTYPE: 'WIDGET_TYPE' as EntityTypesEnum,
        ROLE: 'ROLE' as EntityTypesEnum,
        GROUPPERMISSION: 'GROUP_PERMISSION' as EntityTypesEnum
    };
}


