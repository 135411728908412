import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AdminControllerService } from './api/adminController.service';
import { AlarmControllerService } from './api/alarmController.service';
import { AssetControllerService } from './api/assetController.service';
import { AuthControllerService } from './api/authController.service';
import { BlobEntityControllerService } from './api/blobEntityController.service';
import { ComponentDescriptorControllerService } from './api/componentDescriptorController.service';
import { ConverterControllerService } from './api/converterController.service';
import { CustomMenuControllerService } from './api/customMenuController.service';
import { CustomTranslationControllerService } from './api/customTranslationController.service';
import { CustomerControllerService } from './api/customerController.service';
import { DashboardControllerService } from './api/dashboardController.service';
import { DeviceApiControllerService } from './api/deviceApiController.service';
import { DeviceControllerService } from './api/deviceController.service';
import { EntityGroupControllerService } from './api/entityGroupController.service';
import { EntityQueryControllerService } from './api/entityQueryController.service';
import { EntityRelationControllerService } from './api/entityRelationController.service';
import { EntityViewControllerService } from './api/entityViewController.service';
import { GroupPermissionControllerService } from './api/groupPermissionController.service';
import { IntegrationControllerService } from './api/integrationController.service';
import { OwnerControllerService } from './api/ownerController.service';
import { QueueControllerService } from './api/queueController.service';
import { ReportControllerService } from './api/reportController.service';
import { RoleControllerService } from './api/roleController.service';
import { RpcControllerService } from './api/rpcController.service';
import { RuleChainControllerService } from './api/ruleChainController.service';
import { RuleEngineControllerService } from './api/ruleEngineController.service';
import { SchedulerEventControllerService } from './api/schedulerEventController.service';
import { SelfRegistrationControllerService } from './api/selfRegistrationController.service';
import { SignUpControllerService } from './api/signUpController.service';
import { TelemetryControllerService } from './api/telemetryController.service';
import { TenantControllerService } from './api/tenantController.service';
import { UserControllerService } from './api/userController.service';
import { UserPermissionsControllerService } from './api/userPermissionsController.service';
import { WhiteLabelingControllerService } from './api/whiteLabelingController.service';
import { WidgetTypeControllerService } from './api/widgetTypeController.service';
import { WidgetsBundleControllerService } from './api/widgetsBundleController.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
