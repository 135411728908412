/**
 * ThingsBoard REST API
 * For instructions how to authorize requests please visit <a href=\'http://thingsboard.io/docs/reference/rest-api/\'>REST API documentation page</a>.
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@thingsboard.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DeviceId } from './deviceId';
import { DeviceCredentialsId } from './deviceCredentialsId';


export interface DeviceCredentials { 
    createdTime?: number;
    credentialsId?: string;
    credentialsType?: DeviceCredentials.CredentialsTypeEnum;
    credentialsValue?: string;
    deviceId?: DeviceId;
    id?: DeviceCredentialsId;
}
export namespace DeviceCredentials {
    export type CredentialsTypeEnum = 'ACCESS_TOKEN' | 'X509_CERTIFICATE';
    export const CredentialsTypeEnum = {
        ACCESSTOKEN: 'ACCESS_TOKEN' as CredentialsTypeEnum,
        X509CERTIFICATE: 'X509_CERTIFICATE' as CredentialsTypeEnum
    };
}


