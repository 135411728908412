/**
 * ThingsBoard REST API
 * For instructions how to authorize requests please visit <a href=\'http://thingsboard.io/docs/reference/rest-api/\'>REST API documentation page</a>.
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@thingsboard.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TenantId } from './tenantId';


export interface EntitySubtype { 
    entityType?: EntitySubtype.EntityTypeEnum;
    tenantId?: TenantId;
    type?: string;
}
export namespace EntitySubtype {
    export type EntityTypeEnum = 'TENANT' | 'CUSTOMER' | 'USER' | 'DASHBOARD' | 'ASSET' | 'DEVICE' | 'ALARM' | 'ENTITY_GROUP' | 'CONVERTER' | 'INTEGRATION' | 'RULE_CHAIN' | 'RULE_NODE' | 'SCHEDULER_EVENT' | 'BLOB_ENTITY' | 'ENTITY_VIEW' | 'WIDGETS_BUNDLE' | 'WIDGET_TYPE' | 'ROLE' | 'GROUP_PERMISSION';
    export const EntityTypeEnum = {
        TENANT: 'TENANT' as EntityTypeEnum,
        CUSTOMER: 'CUSTOMER' as EntityTypeEnum,
        USER: 'USER' as EntityTypeEnum,
        DASHBOARD: 'DASHBOARD' as EntityTypeEnum,
        ASSET: 'ASSET' as EntityTypeEnum,
        DEVICE: 'DEVICE' as EntityTypeEnum,
        ALARM: 'ALARM' as EntityTypeEnum,
        ENTITYGROUP: 'ENTITY_GROUP' as EntityTypeEnum,
        CONVERTER: 'CONVERTER' as EntityTypeEnum,
        INTEGRATION: 'INTEGRATION' as EntityTypeEnum,
        RULECHAIN: 'RULE_CHAIN' as EntityTypeEnum,
        RULENODE: 'RULE_NODE' as EntityTypeEnum,
        SCHEDULEREVENT: 'SCHEDULER_EVENT' as EntityTypeEnum,
        BLOBENTITY: 'BLOB_ENTITY' as EntityTypeEnum,
        ENTITYVIEW: 'ENTITY_VIEW' as EntityTypeEnum,
        WIDGETSBUNDLE: 'WIDGETS_BUNDLE' as EntityTypeEnum,
        WIDGETTYPE: 'WIDGET_TYPE' as EntityTypeEnum,
        ROLE: 'ROLE' as EntityTypeEnum,
        GROUPPERMISSION: 'GROUP_PERMISSION' as EntityTypeEnum
    };
}


