import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-cabecera',
  templateUrl: './cabecera.component.html',
  styleUrls: ['./cabecera.component.scss'],
})
export class CabeceraComponent implements OnInit {
usuario;
  constructor(
      private activeRoute: ActivatedRoute,
      private router: Router,
      public http: HttpClient,
      public translate: TranslateService
  ) {

    }

  ngOnInit() {
    this.getUser();
  }

  async getUser()
  {
     this.usuario = await localStorage.getItem('userlogin');
  }
  routingDeviceAngular(tipo: number){
    if (tipo === 0) {
      this.router.navigate(['/db-tabla'], { replaceUrl: true });
    }
    else if (tipo === 1) {
      localStorage.clear();
      //this.router.navigate(['/'], { replaceUrl: true });
      //mirar si funciona en mobiles.
      location.replace('/');
    }

  }

}
