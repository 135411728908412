import {Component, Input, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {RpcControllerService, DeferredResultResponseEntity} from '../../rest';
import {switchMap, take} from 'rxjs/operators';
import {from, Observable, of, } from 'rxjs';
import {forEach} from '@angular-devkit/schematics';
import {formatDate} from '@angular/common';
import {AlertController} from '@ionic/angular';

@Component({
  selector: 'app-sidebar-dispositivo',
  templateUrl: './sidebar-dispositivo.component.html',
  styleUrls: ['./sidebar-dispositivo.component.scss'],
})
export class SidebarDispositivoComponent  {
  @Input() totales: any;
  @Input() vista: any;
  databody: any;
  constructor(
      public http: HttpClient,
      public translate: TranslateService,
      // tslint:disable-next-line:no-shadowed-variable
      private RpcControllerService: RpcControllerService,
      private alertController: AlertController,
  ) {

  }

    async onClickReset(){
      const alert = await this.alertController.create({
          message: 'Do you want to perform this action?',
          buttons: [
              {
                  text: 'Cancel',
                  role: 'cancel',
                  handler: () => {
                      this.presentAlert('', 'Action canceled');
                  }
              },
              {
                  text: 'Ok',
                  handler: () => {
                      this.databody = '{"method": "setRestartComm2","params": 1}';
                      this.RpcControllerService.handleTwoWayDeviceRPCRequestUsingPOST(this.totales.dataid, this.databody)
                          .pipe(switchMap(res => {
                                  return of(res);
                              })
                          )
                          .subscribe(res => {
                              this.presentAlert('', 'Successful action');
                          }, error => {
                              this.presentAlert('', this.getServerErrorMessage(error));
                          });
                  }
              }
          ]
      });
      await alert.present();
  }


    async onClickResetTCu(){
        const alert = await this.alertController.create({
            message: 'Do you want to perform this action?',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    handler: () => {
                        this.presentAlert('', 'Action canceled');
                    }
                },
                {
                    text: 'Ok',
                    handler: () => {
                        this.databody = '{"method": "setModbusConfig28","params": 1}';
                        this.RpcControllerService.handleTwoWayDeviceRPCRequestUsingPOST(this.totales.dataid, this.databody)
                            .pipe(switchMap(res => {
                                    return of(res);
                                })
                            )
                            .subscribe(res => {
                                this.presentAlert('', 'Successful action');
                            }, error => {
                                this.presentAlert('', this.getServerErrorMessage(error));
                            });
                    }
                }
            ]
        });
        await alert.present();
    }


    async resetNcu() {
        const alert = await this.alertController.create({

            message: 'Do you want to perform this action?',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    handler: () => {
                        this.presentAlert('','Action canceled');
                    }
                },
                {
                    text: 'Ok',
                    handler: () => {
                        const databody = '{"method": "setControl8","params": 1}';
                        this.RpcControllerService.handleTwoWayDeviceRPCRequestUsingPOST(this.totales.dataid, databody)
                            .pipe(
                                switchMap(res => {
                                        return of(res);
                                    }
                                )
                            )
                            .subscribe(res => {
                                this.presentAlert('','Successful action');
                            }, error => {
                                this.presentAlert('', this.getServerErrorMessage(error));
                            });
                    }
                }
            ]
        });
        await alert.present();
    }
    private getServerErrorMessage(error: HttpErrorResponse): string {
        switch (error.status) {
            case 400: {
                return `The value for saving has not been indicated`;
            }
            case 408: {
                return `Failed to connect to the device}`;
            }
            case 409: {
                return `Failed to connect to the device`;
            }
            case 404: {
                return `Not Found`;
            }
            case 403: {
                return `Access Denied`;
            }
            case 500: {
                return `Internal Server Error`;
            }
            default: {
                return `Unknown Server Error`;
            }
        }
    }

    async presentAlert(texto: string,header: string) {
        const alert = await this.alertController.create({
            cssClass: 'my-custom-class',
            header: header,
            message: texto,
            buttons: ['OK']
        });

        await alert.present();
    }
}
