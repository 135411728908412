/**
 * ThingsBoard REST API
 * For instructions how to authorize requests please visit <a href=\'http://thingsboard.io/docs/reference/rest-api/\'>REST API documentation page</a>.
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@thingsboard.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EntityId } from './entityId';
import { TenantId } from './tenantId';
import { CustomerId } from './customerId';
import { RoleId } from './roleId';


export interface Role { 
    additionalInfo?: string;
    createdTime?: number;
    customerId?: CustomerId;
    id?: RoleId;
    name?: string;
    ownerId?: EntityId;
    permissions?: string;
    tenantId?: TenantId;
    type?: Role.TypeEnum;
}
export namespace Role {
    export type TypeEnum = 'GENERIC' | 'GROUP';
    export const TypeEnum = {
        GENERIC: 'GENERIC' as TypeEnum,
        GROUP: 'GROUP' as TypeEnum
    };
}


