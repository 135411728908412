/**
 * ThingsBoard REST API
 * For instructions how to authorize requests please visit
 * <a href=\'http://thingsboard.io/docs/reference/rest-api/\'>REST API documentation page</a>.
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@thingsboard.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec } from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { EntitySubtype } from '../model/models';
import { EntityView } from '../model/models';
import { EntityViewSearchQuery } from '../model/models';
import { PageDataEntityView } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EntityViewControllerService {

    // protected basePath = 'https://iot.stinorland.com:443';
    protected basePath = environment.apiThingsboardUrl;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;
    private typetextSearchsortPropertysortOrderpageSizepage: any;
    private textSearchsortPropertysortOrderpageSizepage: any;

    // tslint:disable-next-line:max-line-length
    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === 'object' && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === 'object') {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error('key may not be null if value is Date');
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error('key may not be null if value is not object or array');
        }
        return httpParams;
    }

    /**
     * deleteEntityView
     * @param entityViewId entityViewId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public deleteEntityViewUsingDELETE(entityViewId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    // tslint:disable-next-line:max-line-length
    public deleteEntityViewUsingDELETE(entityViewId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    // tslint:disable-next-line:max-line-length
    public deleteEntityViewUsingDELETE(entityViewId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    // tslint:disable-next-line:max-line-length
    public deleteEntityViewUsingDELETE(entityViewId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (entityViewId === null || entityViewId === undefined) {
            throw new Error('Required parameter entityViewId was null or undefined when calling deleteEntityViewUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/api/entityView/${encodeURIComponent(String(entityViewId))}`,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * findByQuery
     * @param query query
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findByQueryUsingPOST3(query: EntityViewSearchQuery, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<EntityView>>;
    public findByQueryUsingPOST3(query: EntityViewSearchQuery, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<EntityView>>>;
    public findByQueryUsingPOST3(query: EntityViewSearchQuery, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<EntityView>>>;
    // tslint:disable-next-line:max-line-length
    public findByQueryUsingPOST3(query: EntityViewSearchQuery, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (query === null || query === undefined) {
            throw new Error('Required parameter query was null or undefined when calling findByQueryUsingPOST3.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Array<EntityView>>(`${this.configuration.basePath}/api/entityViews`,
            query,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getCustomerEntityViews
     * @param customerId customerId
     * @param pageSize pageSize
     * @param page page
     * @param type type
     * @param textSearch textSearch
     * @param sortProperty sortProperty
     * @param sortOrder sortOrder
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public getCustomerEntityViewsUsingGET(customerId: string, pageSize: string, page: string, type?: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<PageDataEntityView>;
    // tslint:disable-next-line:max-line-length
    public getCustomerEntityViewsUsingGET(customerId: string, pageSize: string, page: string, type?: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<PageDataEntityView>>;
    // tslint:disable-next-line:max-line-length
    public getCustomerEntityViewsUsingGET(customerId: string, pageSize: string, page: string, type?: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<PageDataEntityView>>;
    // tslint:disable-next-line:max-line-length
    public getCustomerEntityViewsUsingGET(customerId: string, pageSize: string, page: string, type?: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling getCustomerEntityViewsUsingGET.');
        }
        if (pageSize === null || pageSize === undefined) {
            throw new Error('Required parameter pageSize was null or undefined when calling getCustomerEntityViewsUsingGET.');
        }
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling getCustomerEntityViewsUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (type !== undefined && type !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            type as any, 'type');
        }
        if (textSearch !== undefined && textSearch !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            textSearch as any, 'textSearch');
        }
        if (sortProperty !== undefined && sortProperty !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            sortProperty as any, 'sortProperty');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            sortOrder as any, 'sortOrder');
        }
        if (pageSize !== undefined && pageSize !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            pageSize as any, 'pageSize');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            page as any, 'page');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<PageDataEntityView>(`${this.configuration.basePath}/api/customer/${encodeURIComponent(String(customerId))}/entityViews${encodeURIComponent(String(this.typetextSearchsortPropertysortOrderpageSizepage))}`,
            {
                params: queryParameters,
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getEntityViewById
     * @param entityViewId entityViewId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public getEntityViewByIdUsingGET(entityViewId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<EntityView>;
    public getEntityViewByIdUsingGET(entityViewId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<EntityView>>;
    // tslint:disable-next-line:max-line-length
    public getEntityViewByIdUsingGET(entityViewId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<EntityView>>;
    // tslint:disable-next-line:max-line-length
    public getEntityViewByIdUsingGET(entityViewId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (entityViewId === null || entityViewId === undefined) {
            throw new Error('Required parameter entityViewId was null or undefined when calling getEntityViewByIdUsingGET.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<EntityView>(`${this.configuration.basePath}/api/entityView/${encodeURIComponent(String(entityViewId))}`,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getEntityViewTypes
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public getEntityViewTypesUsingGET(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<EntitySubtype>>;
    // tslint:disable-next-line:max-line-length
    public getEntityViewTypesUsingGET(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<EntitySubtype>>>;
    // tslint:disable-next-line:max-line-length
    public getEntityViewTypesUsingGET(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<EntitySubtype>>>;
    // tslint:disable-next-line:max-line-length
    public getEntityViewTypesUsingGET(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<EntitySubtype>>(`${this.configuration.basePath}/api/entityView/types`,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getEntityViewsByEntityGroupId
     * @param entityGroupId entityGroupId
     * @param pageSize Page size
     * @param page Page
     * @param textSearch textSearch
     * @param sortProperty sortProperty
     * @param sortOrder sortOrder
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public getEntityViewsByEntityGroupIdUsingGET(entityGroupId: string, pageSize: string, page: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<PageDataEntityView>;
    // tslint:disable-next-line:max-line-length
    public getEntityViewsByEntityGroupIdUsingGET(entityGroupId: string, pageSize: string, page: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<PageDataEntityView>>;
    // tslint:disable-next-line:max-line-length
    public getEntityViewsByEntityGroupIdUsingGET(entityGroupId: string, pageSize: string, page: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<PageDataEntityView>>;
    // tslint:disable-next-line:max-line-length
    public getEntityViewsByEntityGroupIdUsingGET(entityGroupId: string, pageSize: string, page: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (entityGroupId === null || entityGroupId === undefined) {
            throw new Error('Required parameter entityGroupId was null or undefined when calling getEntityViewsByEntityGroupIdUsingGET.');
        }
        if (pageSize === null || pageSize === undefined) {
            throw new Error('Required parameter pageSize was null or undefined when calling getEntityViewsByEntityGroupIdUsingGET.');
        }
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling getEntityViewsByEntityGroupIdUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (textSearch !== undefined && textSearch !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            textSearch as any, 'textSearch');
        }
        if (sortProperty !== undefined && sortProperty !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            sortProperty as any, 'sortProperty');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            sortOrder as any, 'sortOrder');
        }
        if (pageSize !== undefined && pageSize !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            pageSize as any, 'pageSize');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            page as any, 'page');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<PageDataEntityView>(`${this.configuration.basePath}/api/entityGroup/${encodeURIComponent(String(entityGroupId))}/entityViews${encodeURIComponent(String(this.textSearchsortPropertysortOrderpageSizepage))}`,
            {
                params: queryParameters,
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getEntityViewsByIds
     * @param entityViewIds entityViewIds
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEntityViewsByIdsUsingGET(entityViewIds: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<EntityView>>;
    public getEntityViewsByIdsUsingGET(entityViewIds: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<EntityView>>>;
    public getEntityViewsByIdsUsingGET(entityViewIds: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<EntityView>>>;
    // tslint:disable-next-line:max-line-length
    public getEntityViewsByIdsUsingGET(entityViewIds: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (entityViewIds === null || entityViewIds === undefined) {
            throw new Error('Required parameter entityViewIds was null or undefined when calling getEntityViewsByIdsUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (entityViewIds !== undefined && entityViewIds !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            entityViewIds as any, 'entityViewIds');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<EntityView>>(`${this.configuration.basePath}/api/entityViews${encodeURIComponent(String(entityViewIds))}`,
            {
                params: queryParameters,
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getTenantEntityView
     * @param entityViewName entityViewName
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTenantEntityViewUsingGET(entityViewName: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<EntityView>;
    public getTenantEntityViewUsingGET(entityViewName: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<EntityView>>;
    public getTenantEntityViewUsingGET(entityViewName: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<EntityView>>;
    // tslint:disable-next-line:max-line-length
    public getTenantEntityViewUsingGET(entityViewName: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (entityViewName === null || entityViewName === undefined) {
            throw new Error('Required parameter entityViewName was null or undefined when calling getTenantEntityViewUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (entityViewName !== undefined && entityViewName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            entityViewName as any, 'entityViewName');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<EntityView>(`${this.configuration.basePath}/api/tenant/entityViews${encodeURIComponent(String(entityViewName))}`,
            {
                params: queryParameters,
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getTenantEntityViews
     * @param pageSize pageSize
     * @param page page
     * @param type type
     * @param textSearch textSearch
     * @param sortProperty sortProperty
     * @param sortOrder sortOrder
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public getTenantEntityViewsUsingGET(pageSize: string, page: string, type?: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<PageDataEntityView>;
    // tslint:disable-next-line:max-line-length
    public getTenantEntityViewsUsingGET(pageSize: string, page: string, type?: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<PageDataEntityView>>;
    // tslint:disable-next-line:max-line-length
    public getTenantEntityViewsUsingGET(pageSize: string, page: string, type?: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<PageDataEntityView>>;
    // tslint:disable-next-line:max-line-length
    public getTenantEntityViewsUsingGET(pageSize: string, page: string, type?: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (pageSize === null || pageSize === undefined) {
            throw new Error('Required parameter pageSize was null or undefined when calling getTenantEntityViewsUsingGET.');
        }
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling getTenantEntityViewsUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (type !== undefined && type !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            type as any, 'type');
        }
        if (textSearch !== undefined && textSearch !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            textSearch as any, 'textSearch');
        }
        if (sortProperty !== undefined && sortProperty !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            sortProperty as any, 'sortProperty');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            sortOrder as any, 'sortOrder');
        }
        if (pageSize !== undefined && pageSize !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            pageSize as any, 'pageSize');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            page as any, 'page');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<PageDataEntityView>(`${this.configuration.basePath}/api/tenant/entityViews${encodeURIComponent(String(this.typetextSearchsortPropertysortOrderpageSizepage))}`,
            {
                params: queryParameters,
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getUserEntityViews
     * @param pageSize pageSize
     * @param page page
     * @param type type
     * @param textSearch textSearch
     * @param sortProperty sortProperty
     * @param sortOrder sortOrder
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public getUserEntityViewsUsingGET(pageSize: string, page: string, type?: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<PageDataEntityView>;
    // tslint:disable-next-line:max-line-length
    public getUserEntityViewsUsingGET(pageSize: string, page: string, type?: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<PageDataEntityView>>;
    // tslint:disable-next-line:max-line-length
    public getUserEntityViewsUsingGET(pageSize: string, page: string, type?: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<PageDataEntityView>>;
    // tslint:disable-next-line:max-line-length
    public getUserEntityViewsUsingGET(pageSize: string, page: string, type?: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (pageSize === null || pageSize === undefined) {
            throw new Error('Required parameter pageSize was null or undefined when calling getUserEntityViewsUsingGET.');
        }
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling getUserEntityViewsUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (type !== undefined && type !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            type as any, 'type');
        }
        if (textSearch !== undefined && textSearch !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            textSearch as any, 'textSearch');
        }
        if (sortProperty !== undefined && sortProperty !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            sortProperty as any, 'sortProperty');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            sortOrder as any, 'sortOrder');
        }
        if (pageSize !== undefined && pageSize !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            pageSize as any, 'pageSize');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            page as any, 'page');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<PageDataEntityView>(`${this.configuration.basePath}/api/user/entityViews${encodeURIComponent(String(this.typetextSearchsortPropertysortOrderpageSizepage))}`,
            {
                params: queryParameters,
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * saveEntityView
     * @param entityView entityView
     * @param entityGroupId entityGroupId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public saveEntityViewUsingPOST(entityView: EntityView, entityGroupId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<EntityView>;
    // tslint:disable-next-line:max-line-length
    public saveEntityViewUsingPOST(entityView: EntityView, entityGroupId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<EntityView>>;
    // tslint:disable-next-line:max-line-length
    public saveEntityViewUsingPOST(entityView: EntityView, entityGroupId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<EntityView>>;
    // tslint:disable-next-line:max-line-length
    public saveEntityViewUsingPOST(entityView: EntityView, entityGroupId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (entityView === null || entityView === undefined) {
            throw new Error('Required parameter entityView was null or undefined when calling saveEntityViewUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (entityGroupId !== undefined && entityGroupId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            entityGroupId as any, 'entityGroupId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<EntityView>(`${this.configuration.basePath}/api/entityView${encodeURIComponent(String(entityGroupId))}`,
            entityView,
            {
                params: queryParameters,
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

}
