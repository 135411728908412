/**
 * ThingsBoard REST API
 * For instructions how to authorize requests please visit
 * <a href=\'http://thingsboard.io/docs/reference/rest-api/\'>REST API documentation page</a>.
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@thingsboard.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec } from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { PageDataUser } from '../model/models';
import { User } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserControllerService {

    // protected basePath = 'https://iot.stinorland.com:443';
    protected basePath = environment.apiThingsboardUrl;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;
    private textSearchsortPropertysortOrderpageSizepage: any;
    private sendActivationMailentityGroupId: any;

    // tslint:disable-next-line:max-line-length
    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === 'object' && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === 'object') {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error('key may not be null if value is Date');
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error('key may not be null if value is not object or array');
        }
        return httpParams;
    }

    /**
     * deleteUser
     * @param userId userId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public deleteUserUsingDELETE(userId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    // tslint:disable-next-line:max-line-length
    public deleteUserUsingDELETE(userId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    // tslint:disable-next-line:max-line-length
    public deleteUserUsingDELETE(userId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    // tslint:disable-next-line:max-line-length
    public deleteUserUsingDELETE(userId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling deleteUserUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/api/user/${encodeURIComponent(String(userId))}`,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getActivationLink
     * @param userId userId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public getActivationLinkUsingGET(userId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain'}): Observable<string>;
    public getActivationLinkUsingGET(userId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain'}): Observable<HttpResponse<string>>;
    public getActivationLinkUsingGET(userId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain'}): Observable<HttpEvent<string>>;
    public getActivationLinkUsingGET(userId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain'}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getActivationLinkUsingGET.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<string>(`${this.configuration.basePath}/api/user/${encodeURIComponent(String(userId))}/activationLink`,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getAllCustomerUsers
     * @param pageSize pageSize
     * @param page page
     * @param textSearch textSearch
     * @param sortProperty sortProperty
     * @param sortOrder sortOrder
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public getAllCustomerUsersUsingGET(pageSize: string, page: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<PageDataUser>;
    // tslint:disable-next-line:max-line-length
    public getAllCustomerUsersUsingGET(pageSize: string, page: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<PageDataUser>>;
    // tslint:disable-next-line:max-line-length
    public getAllCustomerUsersUsingGET(pageSize: string, page: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<PageDataUser>>;
    // tslint:disable-next-line:max-line-length
    public getAllCustomerUsersUsingGET(pageSize: string, page: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (pageSize === null || pageSize === undefined) {
            throw new Error('Required parameter pageSize was null or undefined when calling getAllCustomerUsersUsingGET.');
        }
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling getAllCustomerUsersUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (textSearch !== undefined && textSearch !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            textSearch as any, 'textSearch');
        }
        if (sortProperty !== undefined && sortProperty !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            sortProperty as any, 'sortProperty');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            sortOrder as any, 'sortOrder');
        }
        if (pageSize !== undefined && pageSize !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            pageSize as any, 'pageSize');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            page as any, 'page');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<PageDataUser>(`${this.configuration.basePath}/api/customer/users${encodeURIComponent(String(this.textSearchsortPropertysortOrderpageSizepage))}`,
            {
                params: queryParameters,
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getCustomerUsers
     * @param customerId customerId
     * @param pageSize pageSize
     * @param page page
     * @param textSearch textSearch
     * @param sortProperty sortProperty
     * @param sortOrder sortOrder
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public getCustomerUsersUsingGET(customerId: string, pageSize: string, page: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<PageDataUser>;
    // tslint:disable-next-line:max-line-length
    public getCustomerUsersUsingGET(customerId: string, pageSize: string, page: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<PageDataUser>>;
    // tslint:disable-next-line:max-line-length
    public getCustomerUsersUsingGET(customerId: string, pageSize: string, page: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<PageDataUser>>;
    // tslint:disable-next-line:max-line-length
    public getCustomerUsersUsingGET(customerId: string, pageSize: string, page: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling getCustomerUsersUsingGET.');
        }
        if (pageSize === null || pageSize === undefined) {
            throw new Error('Required parameter pageSize was null or undefined when calling getCustomerUsersUsingGET.');
        }
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling getCustomerUsersUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (textSearch !== undefined && textSearch !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            textSearch as any, 'textSearch');
        }
        if (sortProperty !== undefined && sortProperty !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            sortProperty as any, 'sortProperty');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            sortOrder as any, 'sortOrder');
        }
        if (pageSize !== undefined && pageSize !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            pageSize as any, 'pageSize');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            page as any, 'page');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<PageDataUser>(`${this.configuration.basePath}/api/customer/${encodeURIComponent(String(customerId))}/users${encodeURIComponent(String(this.textSearchsortPropertysortOrderpageSizepage))}`,
            {
                params: queryParameters,
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getTenantAdmins
     * @param tenantId tenantId
     * @param pageSize pageSize
     * @param page page
     * @param textSearch textSearch
     * @param sortProperty sortProperty
     * @param sortOrder sortOrder
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public getTenantAdminsUsingGET(tenantId: string, pageSize: string, page: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<PageDataUser>;
    // tslint:disable-next-line:max-line-length
    public getTenantAdminsUsingGET(tenantId: string, pageSize: string, page: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<PageDataUser>>;
    // tslint:disable-next-line:max-line-length
    public getTenantAdminsUsingGET(tenantId: string, pageSize: string, page: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<PageDataUser>>;
    // tslint:disable-next-line:max-line-length
    public getTenantAdminsUsingGET(tenantId: string, pageSize: string, page: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (tenantId === null || tenantId === undefined) {
            throw new Error('Required parameter tenantId was null or undefined when calling getTenantAdminsUsingGET.');
        }
        if (pageSize === null || pageSize === undefined) {
            throw new Error('Required parameter pageSize was null or undefined when calling getTenantAdminsUsingGET.');
        }
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling getTenantAdminsUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (textSearch !== undefined && textSearch !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            textSearch as any, 'textSearch');
        }
        if (sortProperty !== undefined && sortProperty !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            sortProperty as any, 'sortProperty');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            sortOrder as any, 'sortOrder');
        }
        if (pageSize !== undefined && pageSize !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            pageSize as any, 'pageSize');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            page as any, 'page');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<PageDataUser>(`${this.configuration.basePath}/api/tenant/${encodeURIComponent(String(tenantId))}/users${encodeURIComponent(String(this.textSearchsortPropertysortOrderpageSizepage))}`,
            {
                params: queryParameters,
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getUserById
     * @param userId userId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public getUserByIdUsingGET(userId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<User>;
    // tslint:disable-next-line:max-line-length
    public getUserByIdUsingGET(userId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<User>>;
    // tslint:disable-next-line:max-line-length
    public getUserByIdUsingGET(userId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<User>>;
    // tslint:disable-next-line:max-line-length
    public getUserByIdUsingGET(userId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getUserByIdUsingGET.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<User>(`${this.configuration.basePath}/api/user/${encodeURIComponent(String(userId))}`,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getUserToken
     * @param userId userId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public getUserTokenUsingGET(userId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<string>;
    // tslint:disable-next-line:max-line-length
    public getUserTokenUsingGET(userId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<string>>;
    // tslint:disable-next-line:max-line-length
    public getUserTokenUsingGET(userId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<string>>;
    // tslint:disable-next-line:max-line-length
    public getUserTokenUsingGET(userId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getUserTokenUsingGET.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<string>(`${this.configuration.basePath}/api/user/${encodeURIComponent(String(userId))}/token`,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getUserUsers
     * @param pageSize pageSize
     * @param page page
     * @param textSearch textSearch
     * @param sortProperty sortProperty
     * @param sortOrder sortOrder
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserUsersUsingGET(pageSize: string, page: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<PageDataUser>;
    public getUserUsersUsingGET(pageSize: string, page: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<PageDataUser>>;
    public getUserUsersUsingGET(pageSize: string, page: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<PageDataUser>>;
    // tslint:disable-next-line:max-line-length
    public getUserUsersUsingGET(pageSize: string, page: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (pageSize === null || pageSize === undefined) {
            throw new Error('Required parameter pageSize was null or undefined when calling getUserUsersUsingGET.');
        }
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling getUserUsersUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (textSearch !== undefined && textSearch !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            textSearch as any, 'textSearch');
        }
        if (sortProperty !== undefined && sortProperty !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            sortProperty as any, 'sortProperty');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            sortOrder as any, 'sortOrder');
        }
        if (pageSize !== undefined && pageSize !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            pageSize as any, 'pageSize');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            page as any, 'page');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        // return this.httpClient.get<PageDataUser>(`${this.configuration.basePath}/api/user/users${encodeURIComponent(String(this.textSearchsortPropertysortOrderpageSizepage))}`,
        return this.httpClient.get<PageDataUser>(`${this.configuration.basePath}/api/user/users`,
            {
                params: queryParameters,
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getUsersByEntityGroupId
     * @param entityGroupId entityGroupId
     * @param pageSize Page size
     * @param page Page
     * @param textSearch textSearch
     * @param sortProperty sortProperty
     * @param sortOrder sortOrder
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public getUsersByEntityGroupIdUsingGET(entityGroupId: string, pageSize: string, page: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<PageDataUser>;
    // tslint:disable-next-line:max-line-length
    public getUsersByEntityGroupIdUsingGET(entityGroupId: string, pageSize: string, page: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<PageDataUser>>;
    // tslint:disable-next-line:max-line-length
    public getUsersByEntityGroupIdUsingGET(entityGroupId: string, pageSize: string, page: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<PageDataUser>>;
    // tslint:disable-next-line:max-line-length
    public getUsersByEntityGroupIdUsingGET(entityGroupId: string, pageSize: string, page: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (entityGroupId === null || entityGroupId === undefined) {
            throw new Error('Required parameter entityGroupId was null or undefined when calling getUsersByEntityGroupIdUsingGET.');
        }
        if (pageSize === null || pageSize === undefined) {
            throw new Error('Required parameter pageSize was null or undefined when calling getUsersByEntityGroupIdUsingGET.');
        }
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling getUsersByEntityGroupIdUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (textSearch !== undefined && textSearch !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            textSearch as any, 'textSearch');
        }
        if (sortProperty !== undefined && sortProperty !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            sortProperty as any, 'sortProperty');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            sortOrder as any, 'sortOrder');
        }
        if (pageSize !== undefined && pageSize !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            pageSize as any, 'pageSize');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            page as any, 'page');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<PageDataUser>(`${this.configuration.basePath}/api/entityGroup/${encodeURIComponent(String(entityGroupId))}/users${encodeURIComponent(String(this.textSearchsortPropertysortOrderpageSizepage))}`,
            {
                params: queryParameters,
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getUsersByIds
     * @param userIds userIds
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public getUsersByIdsUsingGET(userIds: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<User>>;
    // tslint:disable-next-line:max-line-length
    public getUsersByIdsUsingGET(userIds: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<User>>>;
    // tslint:disable-next-line:max-line-length
    public getUsersByIdsUsingGET(userIds: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<User>>>;
    public getUsersByIdsUsingGET(userIds: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (userIds === null || userIds === undefined) {
            throw new Error('Required parameter userIds was null or undefined when calling getUsersByIdsUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (userIds !== undefined && userIds !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            userIds as any, 'userIds');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<User>>(`${this.configuration.basePath}/api/users${encodeURIComponent(String(userIds))}`,
            {
                params: queryParameters,
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * isUserTokenAccessEnabled
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public isUserTokenAccessEnabledUsingGET(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<boolean>;
    // tslint:disable-next-line:max-line-length
    public isUserTokenAccessEnabledUsingGET(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<boolean>>;
    // tslint:disable-next-line:max-line-length
    public isUserTokenAccessEnabledUsingGET(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<boolean>>;
    // tslint:disable-next-line:max-line-length
    public isUserTokenAccessEnabledUsingGET(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<boolean>(`${this.configuration.basePath}/api/user/tokenAccessEnabled`,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * saveUser
     * @param user user
     * @param sendActivationMail sendActivationMail
     * @param entityGroupId entityGroupId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public saveUserUsingPOST(user: User, sendActivationMail?: boolean, entityGroupId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<User>;
    // tslint:disable-next-line:max-line-length
    public saveUserUsingPOST(user: User, sendActivationMail?: boolean, entityGroupId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<User>>;
    // tslint:disable-next-line:max-line-length
    public saveUserUsingPOST(user: User, sendActivationMail?: boolean, entityGroupId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<User>>;
    // tslint:disable-next-line:max-line-length
    public saveUserUsingPOST(user: User, sendActivationMail?: boolean, entityGroupId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (user === null || user === undefined) {
            throw new Error('Required parameter user was null or undefined when calling saveUserUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (sendActivationMail !== undefined && sendActivationMail !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            sendActivationMail as any, 'sendActivationMail');
        }
        if (entityGroupId !== undefined && entityGroupId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            entityGroupId as any, 'entityGroupId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<User>(`${this.configuration.basePath}/api/user${encodeURIComponent(String(this.sendActivationMailentityGroupId))}`,
            user,
            {
                params: queryParameters,
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * sendActivationEmail
     * @param email email
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public sendActivationEmailUsingPOST(email: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    // tslint:disable-next-line:max-line-length
    public sendActivationEmailUsingPOST(email: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    // tslint:disable-next-line:max-line-length
    public sendActivationEmailUsingPOST(email: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    // tslint:disable-next-line:max-line-length
    public sendActivationEmailUsingPOST(email: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling sendActivationEmailUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (email !== undefined && email !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            email as any, 'email');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/user/sendActivationMail${encodeURIComponent(String(email))}`,
            null,
            {
                params: queryParameters,
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * setUserCredentialsEnabled
     * @param userId userId
     * @param userCredentialsEnabled userCredentialsEnabled
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public setUserCredentialsEnabledUsingPOST(userId: string, userCredentialsEnabled?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    // tslint:disable-next-line:max-line-length
    public setUserCredentialsEnabledUsingPOST(userId: string, userCredentialsEnabled?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    // tslint:disable-next-line:max-line-length
    public setUserCredentialsEnabledUsingPOST(userId: string, userCredentialsEnabled?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    // tslint:disable-next-line:max-line-length
    public setUserCredentialsEnabledUsingPOST(userId: string, userCredentialsEnabled?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling setUserCredentialsEnabledUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (userCredentialsEnabled !== undefined && userCredentialsEnabled !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            userCredentialsEnabled as any, 'userCredentialsEnabled');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/user/${encodeURIComponent(String(userId))}/userCredentialsEnabled${encodeURIComponent(String(userCredentialsEnabled))}`,
            null,
            {
                params: queryParameters,
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

}
