/**
 * ThingsBoard REST API
 * For instructions how to authorize requests please visit <a href=\'http://thingsboard.io/docs/reference/rest-api/\'>REST API documentation page</a>.
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@thingsboard.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TenantId } from './tenantId';
import { IntegrationId } from './integrationId';
import { ConverterId } from './converterId';


export interface Integration { 
    additionalInfo?: string;
    configuration?: string;
    createdTime?: number;
    debugMode?: boolean;
    defaultConverterId?: ConverterId;
    downlinkConverterId?: ConverterId;
    enabled?: boolean;
    id?: IntegrationId;
    name?: string;
    remote?: boolean;
    routingKey?: string;
    secret?: string;
    tenantId?: TenantId;
    type?: Integration.TypeEnum;
}
export namespace Integration {
    export type TypeEnum = 'OCEANCONNECT' | 'SIGFOX' | 'THINGPARK' | 'TPE' | 'TMOBILE_IOT_CDP' | 'HTTP' | 'MQTT' | 'AWS_IOT' | 'AWS_SQS' | 'AWS_KINESIS' | 'IBM_WATSON_IOT' | 'TTN' | 'TTI' | 'AZURE_EVENT_HUB' | 'OPC_UA' | 'CUSTOM' | 'UDP' | 'TCP' | 'KAFKA' | 'AZURE_IOT_HUB' | 'APACHE_PULSAR';
    export const TypeEnum = {
        OCEANCONNECT: 'OCEANCONNECT' as TypeEnum,
        SIGFOX: 'SIGFOX' as TypeEnum,
        THINGPARK: 'THINGPARK' as TypeEnum,
        TPE: 'TPE' as TypeEnum,
        TMOBILEIOTCDP: 'TMOBILE_IOT_CDP' as TypeEnum,
        HTTP: 'HTTP' as TypeEnum,
        MQTT: 'MQTT' as TypeEnum,
        AWSIOT: 'AWS_IOT' as TypeEnum,
        AWSSQS: 'AWS_SQS' as TypeEnum,
        AWSKINESIS: 'AWS_KINESIS' as TypeEnum,
        IBMWATSONIOT: 'IBM_WATSON_IOT' as TypeEnum,
        TTN: 'TTN' as TypeEnum,
        TTI: 'TTI' as TypeEnum,
        AZUREEVENTHUB: 'AZURE_EVENT_HUB' as TypeEnum,
        OPCUA: 'OPC_UA' as TypeEnum,
        CUSTOM: 'CUSTOM' as TypeEnum,
        UDP: 'UDP' as TypeEnum,
        TCP: 'TCP' as TypeEnum,
        KAFKA: 'KAFKA' as TypeEnum,
        AZUREIOTHUB: 'AZURE_IOT_HUB' as TypeEnum,
        APACHEPULSAR: 'APACHE_PULSAR' as TypeEnum
    };
}


