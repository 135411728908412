/**
 * ThingsBoard REST API
 * For instructions how to authorize requests please visit <a href=\'http://thingsboard.io/docs/reference/rest-api/\'>REST API documentation page</a>.
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@thingsboard.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EntityDataSortOrder } from './entityDataSortOrder';


export interface AlarmDataPageLink { 
    dynamic?: boolean;
    page?: number;
    pageSize?: number;
    sortOrder?: EntityDataSortOrder;
    startTs: number;
    textSearch?: string;
    endTs: number;
    timeWindow: number;
    typeList: Array<string>;
    statusList: Array<AlarmDataPageLink.StatusListEnum>;
    severityList: Array<AlarmDataPageLink.SeverityListEnum>;
    searchPropagatedAlarms: boolean;
}
export namespace AlarmDataPageLink {
    export type StatusListEnum = 'ANY' | 'ACTIVE' | 'CLEARED' | 'ACK' | 'UNACK';
    export const StatusListEnum = {
        ANY: 'ANY' as StatusListEnum,
        ACTIVE: 'ACTIVE' as StatusListEnum,
        CLEARED: 'CLEARED' as StatusListEnum,
        ACK: 'ACK' as StatusListEnum,
        UNACK: 'UNACK' as StatusListEnum
    };
    export type SeverityListEnum = 'CRITICAL' | 'MAJOR' | 'MINOR' | 'WARNING' | 'INDETERMINATE';
    export const SeverityListEnum = {
        CRITICAL: 'CRITICAL' as SeverityListEnum,
        MAJOR: 'MAJOR' as SeverityListEnum,
        MINOR: 'MINOR' as SeverityListEnum,
        WARNING: 'WARNING' as SeverityListEnum,
        INDETERMINATE: 'INDETERMINATE' as SeverityListEnum
    };
}


