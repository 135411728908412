import { Component, OnInit, Input  } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-sidebar-dispositivo-modal',
  templateUrl: './sidebar-dispositivo-modal.component.html',
  styleUrls: ['./sidebar-dispositivo-modal.component.scss'],
})
export class SidebarDispositivoModalComponent implements OnInit {

  @Input() totales: any;
  @Input() vista: number;

  constructor(public http: HttpClient, public translate: TranslateService, public modalCtrl: ModalController) { }

  ngOnInit() {

  }

  close() {
    this.modalCtrl.dismiss();
  }

}
