import { Injectable } from '@angular/core';
import {Platform} from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class PopupService {
  isApk:boolean=false;
  constructor( private platform: Platform,) {
    if(document.URL.startsWith('http://localhost') || document.URL.startsWith('ionic://localhost')){
      this.isApk=true;
    }this.isApk=false;
  }

  makePopup(data: any): string {
    let estado;
    const nAlarmas = Object.keys(data.alarmas).map(key => data.alarmas[key]).reduce((acc, curr) => acc + curr);

    if (data.tipo === 'ncu' || data.tipo === 'rsu') {
      estado = data.estado ? 'active' : 'inactive';
    }
    else {
      switch (data.estado.mainstate) {
        case 0:
          estado = 'OFF';
          break;
        case 1:
          estado = 'MANUAL';
          break;
        case 2:
          switch (data.estado.safepositionstate) {
            case 1:
            case 3:
            case 2:
              estado = 'SAFE POSITION';
              break;
            default:
              if (data.estado.bt_active) {
                estado = 'BACKTRACKING';
                break;
              }
              else {
                estado = 'TRACKING';
                break;
              }
          }
      }
    }

    let trackerNameDiv;
    if (data.trackername) {
      trackerNameDiv = `<div>Tracker name: ${ data.trackername } </div>`;
    }
    else {
      trackerNameDiv = '';
    }

    let goto='';
    if(this.isApk)
    {
      goto='<div><a id="myButton" >See details</a></div>';
    }
    return `
      <p><b>${ data.name }</b></p>
      <div>Any alarms: ${ nAlarmas ? 'Yes' : 'No' } </div>
      <div>${ 'Status: ' + estado }</div>
      <div>${ data.grupo ? ('Group: ' + data.grupo) : '' }</div>
    ` + trackerNameDiv
        + goto
        ;
  }

  makePopupPlanta(data: any): string {
    let goto='';
    if(this.isApk)
    {
      goto='<div><a id="myButton" >See plant</a></div>'
    }
    return `
    <div>${ data.displayname }</div>
    `+goto;
  }
}
