import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import * as L from 'leaflet';
import 'leaflet-rotatedmarker';
import { PopupService } from './popup.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HelperFunctionsService } from './helper-functions.service';
import {Platform} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class MarkerService {
  markers: Array<any> = [];
  isApk :boolean =false;
  private icons = {
    planta: {
      path: `assets/icon/punto-global-v2.svg`
    },
    ncu: {
      path: `assets/icon/ncu_v3.svg`
    },
    tcu: {
      path: `assets/icon/tcu.svg`
    },
    rsu: {
      path: `assets/icon/rsu_t1_v3.svg`
    },
    repeater: {
      path: `assets/icon/ico_wifi_v3.svg`
      // path: `assets/icon/tcu.svg`
    }
  }
  private colors = {
    blue: '#4A90E2',
    pink: '#F12C54',
    yellow: '#FACA10',
    orange: '#F1522C',
    red: '#E30432',
    light_blue: '#02CCFB',
    purple: '#990D99',
    green: '#09D9A5',
    dark_blue: '#002857',
    grey: '#92A2B4',
    black: '#333333',
    dark_grey: '#666666',
    light_yellow: '#f9e8a7',
    light_pink: '#f6b8f9',
    light_orange: '#efa153',
  }
  private colorDictionary = {
    "ncu": {
      "ncuyellow": this.colors.red,
      // "ncublue": this.colors.orange,
      "ncumorado": this.colors.purple,
    },
    "tcu": {
      "comm": this.colors.red,
      "batt": this.colors.orange,
      "motor": this.colors.yellow,
      // "axis": this.colors.dark_blue,
      "sw": this.colors.grey,
      "stopbutton": this.colors.green,
    },
    "rsu": {
      "comm": this.colors.red,
      "sensor": this.colors.dark_blue,
      // "meteo": this.colors.orange
    },
    "repeater": {
      "comm": this.colors.red,
      "batt": this.colors.orange,
      "motor": this.colors.yellow,
      "sw": this.colors.grey,
      "stopbutton": this.colors.green,
    }
  }

  constructor(
    private router: Router,
    private popupService: PopupService,
    private http: HttpClient,
    private helperFunctions: HelperFunctionsService,
    private platform: Platform,
  ) {
    if(document.URL.startsWith('http://localhost') || document.URL.startsWith('ionic://localhost')  || document.URL.startsWith('https://localhost')){
      this.isApk=true;
    }
    // Carga de imagenes
    const headers = new HttpHeaders();
    headers.set('Accept', 'image/svg+xml');
    Object.keys(this.icons).forEach( key => {
      this.http.get(this.icons[key]['path'], {headers, responseType: 'text'})
        .subscribe(next => this.icons[key]['svg'] = next)
    })
  }

  determinarColorPlanta(alarmas_resumen, ts, limiteMinutos, state) {
    // Estados de planta
    if (parseInt(state) <= 5){
      // Plantas aun sin activar
      if (parseInt(state) === 1){
        return this.colors.light_yellow;  // 01 Pendiente oferta -- Pending offer
      } else if (parseInt(state) === 2){
        return this.colors.yellow;  // 02 Ofertado -- Offered
      } else if (parseInt(state) === 3){
        return this.colors.light_orange;  // 03 Firmado -- Signed
      } else if (parseInt(state) === 4){
        return this.colors.light_pink;  // En construcción -- In construction
      }
      // 05 En puesta en marcha -- In commissioning
      return this.colors.grey;
    }else if (((Date.now() - ts) / 1000) > (limiteMinutos * 60)) {
        return this.colors.pink;
    }
    return this.colors.blue;
  }

  determinarColorPlantaPorAlarmas(alarmas_resumen, ts, limiteMinutos) {
    if (((Date.now() - ts) / 1000) > (limiteMinutos * 60)) {
      return this.colors.grey;
    }
    const prioridadesDispositivo = ['ncu', 'tcu', 'rsu'];
    const prioridadesTipoAlarma = ['sw', 'comm', 'motor', 'axis', 'batt', 'meteo', 'other'];

    for (const dispositivo of prioridadesDispositivo) {
      for (const tipoAlarma of prioridadesTipoAlarma) {
        if (alarmas_resumen[dispositivo].hasOwnProperty(tipoAlarma)) {
          if (alarmas_resumen[dispositivo][tipoAlarma] > 0) {
            return this.colors.pink;
          }
        }
      }
    }
    return this.colors.blue;
  }

  determinarColorDispositivo(dispositivo, agrupacion) {
    // El Array prioridades determina que alarma se mostrará primero, siendo la primera alarma que aparezca en la lista la
    // de más importancia

    switch (agrupacion) {
      case 'alarmas':
        const alarmas = dispositivo['alarmas'];
        const prioridades = {
          "ncu": [
            // "ncublue",
            "ncumorado",
            "ncuyellow"
          ],
          "tcu": [
            'sw',
            'comm',
            'motor',
            // 'axis',
            'batt',
            'stopbutton'
          ],
          "rsu": [
            "comm",
            "sensor"
            // "meteo"
          ],
          "repeater": [
            'sw',
            'comm',
            'motor',
            'batt',
            'stopbutton'
          ]
        }
        if (dispositivo['tipo'] === 'ncu') {
          // En este caso estado es un booleano
           if (dispositivo['estado']) {this.colors.blue}else{this.colors.grey;}
        }
        else {
          if (!dispositivo['ncu']) {
             this.colors.grey;
          }
        }

        for (const tipoAlarma of prioridades[dispositivo['tipo']]) {
          if (+alarmas[tipoAlarma] > 0) {
            return this.colorDictionary[dispositivo['tipo']][tipoAlarma];
          }
        }
        return this.colors.blue;

      case 'estados':
        const estado = dispositivo['estado'];
        if (dispositivo['tipo'] === 'ncu') {
          // En este caso estado es un booleano
          return estado ? this.colors.blue : this.colors.grey;
        }
        else {
          if (!dispositivo['ncu']) {
            return this.colors.grey;
          }
          switch (estado.mainstate) {
            case 0:
              return this.colors.orange;
            case 1:
              return this.colors.yellow;
            case 2:
              switch (estado.safepositionstate) {
                case 1:
                  return this.colors.red;
                case 3:
                  return this.colors.red;
                case 4:
                  return this.colors.red;
                default:
                  if (estado.bt_active) {
                    return this.colors.purple;
                  }
                  else {
                    return this.colors.blue;
                  }
              }
          }
        }

        return this.colors.blue;
      case 'grupos':
        const grupo = dispositivo['grupo'];
        if (!grupo) {
          return this.colors.blue;
        }
        switch (grupo) {
          case 1:
            return this.colors.pink;
          case 2:
            return this.colors.yellow;
          case 3:
            return this.colors.orange;
          case 4:
            return this.colors.red;
          case 5:
            return this.colors.light_blue;
          case 6:
            return this.colors.purple;
          case 7:
            return this.colors.green;
          case 8:
            return this.colors.dark_blue;
          case 9:
            return this.colors.grey;
          case 10:
            return this.colors.blue;
          default:
            return '#000000';
        }
      case 'binding':
        const binding = dispositivo['binding'];
        if (dispositivo['tipo'] === 'ncu' || dispositivo['tipo'] === 'rsu' || dispositivo['tipo'] === 'repeater') {
          return this.colors.grey;
        }
        else {
          if (dispositivo['tipo'] === 'tcu') {
            if (dispositivo['binding'] === 1) {
              return this.colors.green;
            }else if(dispositivo['binding'] === 0 && dispositivo['zeroset'] === true){
              return this.colors.blue;
            }else{
              return this.colors.red;
            }
          }
        }

        // return this.colors.blue;
        break;
      default:
        break;
    }

  }

  globalEventHandler(id: string) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        dataid: id,
      }
    };
    this.router.navigate(['/planta-base'], navigationExtras);
  }

  plantaEventHandler(plantaid: string, dataid: string, tipo:string) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        dataid: dataid,
        plantaid: plantaid
      }
    };

    if (tipo === 'ncu'){
      this.router.navigate(['/ncu-base'], navigationExtras);
    }else if (tipo === 'tcu'){
      this.router.navigate(['/tcu-base'], navigationExtras);
    }else if (tipo === 'rsu'){
      this.router.navigate(['/rsu-base'], navigationExtras);
    }
  }

  makePlantasMarkers(map: L.Map, data: Array<Object>, holguraZoom: number): void {
    let page = this;
    const lats = data.map(planta => Number(planta['lat']));
    const longs = data.map(planta => Number(planta['long']));
    const deltaLat = Math.max(...lats) - Math.min(...lats);
    const deltaLong = Math.max(...longs) - Math.min(...longs);
    const topLeftCorner = new L.LatLng(Math.min(...lats) - holguraZoom * deltaLat, Math.max(...longs) + holguraZoom * deltaLong);
    const topRightCorner = new L.LatLng(Math.max(...lats) + holguraZoom * deltaLat, Math.min(...longs) - holguraZoom * deltaLong);
    map.fitBounds(L.latLngBounds(topLeftCorner, topRightCorner));

    for (const planta of data) {
      const customMarker = L.marker(
        [planta['lat'], planta['long']],
        {
          icon: L.divIcon({
            className: 'leaflet-data-marker',
            html:
              L.Util.template(
                this.icons.planta['svg'],
                {'fillColor': this.determinarColorPlanta(planta['alarmas_resumen'], planta['alarmas']['ts'], 15, planta['state']),
                  'number': +planta['atributos']['Number_of_Tcu']
                }
              ),
            iconAnchor  : [34, 20],
          })
        }
      )

      customMarker.bindPopup(this.popupService.makePopupPlanta(planta));
      customMarker.on('mouseover', function (e) {
        this.openPopup();
      });
      customMarker.on('mouseout', function (e) {
        this.closePopup();
      });
      this.markers.push(customMarker);
      if(this.isApk) {
        customMarker.addTo(map).on('click', function(e) {
          this.openPopup();
          document.getElementById('myButton').addEventListener('click', function(){
            page.globalEventHandler(planta['id']);
          })
        });
      }else{
        customMarker.addTo(map).on('click', function(e) {
          page.globalEventHandler(planta['id']);
        });
      }
    }
  }

  makeDispositivosMarkers(map: L.Map, data: Array<Object>, holguraZoom: number, modoAgrupacion:string): void {
    let page = this;
    const nulls = data.filter(el => !Boolean(el['lat']) || !Boolean(el['long']))
    if (nulls.length) {
      data = data.filter(el => Boolean(el['lat']));
    }
    const lats = data.map(planta => Number(planta['lat']));
    const longs = data.map(planta => Number(planta['long']));
    const deltaLat = Math.max(...lats) - Math.min(...lats);
    const deltaLong = Math.max(...longs) - Math.min(...longs);
    const topLeftCorner = new L.LatLng(Math.min(...lats) - holguraZoom * deltaLat, Math.max(...longs) + holguraZoom * deltaLong);
    const topRightCorner = new L.LatLng(Math.max(...lats) + holguraZoom * deltaLat, Math.min(...longs) - holguraZoom * deltaLong);
    map.fitBounds(L.latLngBounds(topLeftCorner, topRightCorner));

    for (const elemento of data) {

      const customMarker = L.marker(
        [elemento['lat'], elemento['long']],
        {
          icon:
            L.divIcon({
              className: 'leaflet-data-marker',
              html: L.Util.template(this.icons[elemento['tipo']]['svg'], {
                'fillColor': this.determinarColorDispositivo(elemento, modoAgrupacion),
                'number': elemento['number'],
                // 'number': '',
              }),
            })
        }
      );
      if (elemento['rot']) {
        customMarker.setRotationAngle(180 - elemento['rot']);
      }
      customMarker.bindPopup(this.popupService.makePopup(elemento));
      customMarker.on('mouseover', function (e) {
        this.openPopup();
      });
      customMarker.on('mouseout', function (e) {
        this.closePopup();
      });

      this.markers.push(customMarker);
      if(this.isApk){
        customMarker.addTo(map)
            .on('click', function(e) {
                      this.openPopup();

                      document.getElementById('myButton').addEventListener('click', function(){
                        let tipodevice = elemento['tipo'];
                        if(tipodevice === 'repeater'){
                          tipodevice = 'tcu';
                        }
                        page.plantaEventHandler(elemento['plantaid'], elemento['dataid'], tipodevice);
                      });
            }
            );
      }else{
        customMarker.addTo(map)
            .on('click', function(e) {
                    let tipodevice = elemento['tipo'];
                    if(tipodevice === 'repeater'){
                      tipodevice = 'tcu';
                    }
                    page.plantaEventHandler(elemento['plantaid'], elemento['dataid'], tipodevice);
                }
            );
      }

    }
  }

  removeAllMarkersfromPlanta() {
    this.markers.map(marker => marker.remove());
    this.markers = [];
  }
}
