/**
 * ThingsBoard REST API
 * For instructions how to authorize requests please visit
 * <a href=\'http://thingsboard.io/docs/reference/rest-api/\'>REST API documentation page</a>.
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@thingsboard.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec } from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { LoginWhiteLabelingParams } from '../model/models';
import { PaletteSettings } from '../model/models';
import { WhiteLabelingParams } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

import { environment } from './../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class WhiteLabelingControllerService {

    // protected basePath = 'https://iot.stinorland.com:443';
    protected basePath = environment.apiThingsboardUrl;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;
    private logoImageChecksumfaviconChecksum: any;

    // tslint:disable-next-line:max-line-length
    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === 'object' && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === 'object') {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error('key may not be null if value is Date');
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error('key may not be null if value is not object or array');
        }
        return httpParams;
    }

    /**
     * getAppThemeCss
     * @param paletteSettings paletteSettings
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public getAppThemeCssUsingPOST(paletteSettings: PaletteSettings, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'plain/text'}): Observable<string>;
    // tslint:disable-next-line:max-line-length
    public getAppThemeCssUsingPOST(paletteSettings: PaletteSettings, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'plain/text'}): Observable<HttpResponse<string>>;
    // tslint:disable-next-line:max-line-length
    public getAppThemeCssUsingPOST(paletteSettings: PaletteSettings, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'plain/text'}): Observable<HttpEvent<string>>;
    // tslint:disable-next-line:max-line-length
    public getAppThemeCssUsingPOST(paletteSettings: PaletteSettings, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'plain/text'}): Observable<any> {
        if (paletteSettings === null || paletteSettings === undefined) {
            throw new Error('Required parameter paletteSettings was null or undefined when calling getAppThemeCssUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'plain/text'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<string>(`${this.configuration.basePath}/api/whiteLabel/appThemeCss`,
            paletteSettings,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getCurrentLoginWhiteLabelParams
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCurrentLoginWhiteLabelParamsUsingGET(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<LoginWhiteLabelingParams>;
    public getCurrentLoginWhiteLabelParamsUsingGET(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<LoginWhiteLabelingParams>>;
    public getCurrentLoginWhiteLabelParamsUsingGET(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<LoginWhiteLabelingParams>>;
    public getCurrentLoginWhiteLabelParamsUsingGET(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<LoginWhiteLabelingParams>(`${this.configuration.basePath}/api/whiteLabel/currentLoginWhiteLabelParams`,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getCurrentWhiteLabelParams
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public getCurrentWhiteLabelParamsUsingGET(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<WhiteLabelingParams>;
    public getCurrentWhiteLabelParamsUsingGET(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<WhiteLabelingParams>>;
    // tslint:disable-next-line:max-line-length
    public getCurrentWhiteLabelParamsUsingGET(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<WhiteLabelingParams>>;
    public getCurrentWhiteLabelParamsUsingGET(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<WhiteLabelingParams>(`${this.configuration.basePath}/api/whiteLabel/currentWhiteLabelParams`,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getLoginThemeCss
     * @param paletteSettings paletteSettings
     * @param darkForeground darkForeground
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public getLoginThemeCssUsingPOST(paletteSettings: PaletteSettings, darkForeground?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'plain/text'}): Observable<string>;
    // tslint:disable-next-line:max-line-length
    public getLoginThemeCssUsingPOST(paletteSettings: PaletteSettings, darkForeground?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'plain/text'}): Observable<HttpResponse<string>>;
    // tslint:disable-next-line:max-line-length
    public getLoginThemeCssUsingPOST(paletteSettings: PaletteSettings, darkForeground?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'plain/text'}): Observable<HttpEvent<string>>;
    // tslint:disable-next-line:max-line-length
    public getLoginThemeCssUsingPOST(paletteSettings: PaletteSettings, darkForeground?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'plain/text'}): Observable<any> {
        if (paletteSettings === null || paletteSettings === undefined) {
            throw new Error('Required parameter paletteSettings was null or undefined when calling getLoginThemeCssUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (darkForeground !== undefined && darkForeground !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            darkForeground as any, 'darkForeground');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'plain/text'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<string>(`${this.configuration.basePath}/api/noauth/whiteLabel/loginThemeCss${encodeURIComponent(String(darkForeground))}`,
            paletteSettings,
            {
                params: queryParameters,
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getLoginWhiteLabelParams
     * @param logoImageChecksum logoImageChecksum
     * @param faviconChecksum faviconChecksum
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public getLoginWhiteLabelParamsUsingGET(logoImageChecksum?: string, faviconChecksum?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<LoginWhiteLabelingParams>;
    // tslint:disable-next-line:max-line-length
    public getLoginWhiteLabelParamsUsingGET(logoImageChecksum?: string, faviconChecksum?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<LoginWhiteLabelingParams>>;
    // tslint:disable-next-line:max-line-length
    public getLoginWhiteLabelParamsUsingGET(logoImageChecksum?: string, faviconChecksum?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<LoginWhiteLabelingParams>>;
    // tslint:disable-next-line:max-line-length
    public getLoginWhiteLabelParamsUsingGET(logoImageChecksum?: string, faviconChecksum?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (logoImageChecksum !== undefined && logoImageChecksum !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            logoImageChecksum as any, 'logoImageChecksum');
        }
        if (faviconChecksum !== undefined && faviconChecksum !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            faviconChecksum as any, 'faviconChecksum');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<LoginWhiteLabelingParams>(`${this.configuration.basePath}/api/noauth/whiteLabel/loginWhiteLabelParams${encodeURIComponent(String(this.logoImageChecksumfaviconChecksum))}`,
            {
                params: queryParameters,
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getWhiteLabelParams
     * @param logoImageChecksum logoImageChecksum
     * @param faviconChecksum faviconChecksum
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public getWhiteLabelParamsUsingGET(logoImageChecksum?: string, faviconChecksum?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<WhiteLabelingParams>;
    // tslint:disable-next-line:max-line-length
    public getWhiteLabelParamsUsingGET(logoImageChecksum?: string, faviconChecksum?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<WhiteLabelingParams>>;
    // tslint:disable-next-line:max-line-length
    public getWhiteLabelParamsUsingGET(logoImageChecksum?: string, faviconChecksum?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<WhiteLabelingParams>>;
    // tslint:disable-next-line:max-line-length
    public getWhiteLabelParamsUsingGET(logoImageChecksum?: string, faviconChecksum?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (logoImageChecksum !== undefined && logoImageChecksum !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            logoImageChecksum as any, 'logoImageChecksum');
        }
        if (faviconChecksum !== undefined && faviconChecksum !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            faviconChecksum as any, 'faviconChecksum');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<WhiteLabelingParams>(`${this.configuration.basePath}/api/whiteLabel/whiteLabelParams${encodeURIComponent(String(this.logoImageChecksumfaviconChecksum))}`,
            {
                params: queryParameters,
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * isCustomerWhiteLabelingAllowed
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public isCustomerWhiteLabelingAllowedUsingGET(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<boolean>;
    // tslint:disable-next-line:max-line-length
    public isCustomerWhiteLabelingAllowedUsingGET(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<boolean>>;
    // tslint:disable-next-line:max-line-length
    public isCustomerWhiteLabelingAllowedUsingGET(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<boolean>>;
    public isCustomerWhiteLabelingAllowedUsingGET(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<boolean>(`${this.configuration.basePath}/api/whiteLabel/isCustomerWhiteLabelingAllowed`,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * isWhiteLabelingAllowed
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public isWhiteLabelingAllowedUsingGET(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<boolean>;
    // tslint:disable-next-line:max-line-length
    public isWhiteLabelingAllowedUsingGET(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<boolean>>;
    // tslint:disable-next-line:max-line-length
    public isWhiteLabelingAllowedUsingGET(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<boolean>>;
    // tslint:disable-next-line:max-line-length
    public isWhiteLabelingAllowedUsingGET(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<boolean>(`${this.configuration.basePath}/api/whiteLabel/isWhiteLabelingAllowed`,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * previewWhiteLabelParams
     * @param whiteLabelingParams whiteLabelingParams
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public previewWhiteLabelParamsUsingPOST(whiteLabelingParams: WhiteLabelingParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<WhiteLabelingParams>;
    // tslint:disable-next-line:max-line-length
    public previewWhiteLabelParamsUsingPOST(whiteLabelingParams: WhiteLabelingParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<WhiteLabelingParams>>;
    // tslint:disable-next-line:max-line-length
    public previewWhiteLabelParamsUsingPOST(whiteLabelingParams: WhiteLabelingParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<WhiteLabelingParams>>;
    // tslint:disable-next-line:max-line-length
    public previewWhiteLabelParamsUsingPOST(whiteLabelingParams: WhiteLabelingParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (whiteLabelingParams === null || whiteLabelingParams === undefined) {
            throw new Error('Required parameter whiteLabelingParams was null or undefined when calling previewWhiteLabelParamsUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<WhiteLabelingParams>(`${this.configuration.basePath}/api/whiteLabel/previewWhiteLabelParams`,
            whiteLabelingParams,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * saveLoginWhiteLabelParams
     * @param loginWhiteLabelingParams loginWhiteLabelingParams
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public saveLoginWhiteLabelParamsUsingPOST(loginWhiteLabelingParams: LoginWhiteLabelingParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<LoginWhiteLabelingParams>;
    // tslint:disable-next-line:max-line-length
    public saveLoginWhiteLabelParamsUsingPOST(loginWhiteLabelingParams: LoginWhiteLabelingParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<LoginWhiteLabelingParams>>;
    // tslint:disable-next-line:max-line-length
    public saveLoginWhiteLabelParamsUsingPOST(loginWhiteLabelingParams: LoginWhiteLabelingParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<LoginWhiteLabelingParams>>;
    // tslint:disable-next-line:max-line-length
    public saveLoginWhiteLabelParamsUsingPOST(loginWhiteLabelingParams: LoginWhiteLabelingParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (loginWhiteLabelingParams === null || loginWhiteLabelingParams === undefined) {
            throw new Error('Required parameter loginWhiteLabelingParams was null or undefined when calling saveLoginWhiteLabelParamsUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<LoginWhiteLabelingParams>(`${this.configuration.basePath}/api/whiteLabel/loginWhiteLabelParams`,
            loginWhiteLabelingParams,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * saveWhiteLabelParams
     * @param whiteLabelingParams whiteLabelingParams
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public saveWhiteLabelParamsUsingPOST(whiteLabelingParams: WhiteLabelingParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<WhiteLabelingParams>;
    // tslint:disable-next-line:max-line-length
    public saveWhiteLabelParamsUsingPOST(whiteLabelingParams: WhiteLabelingParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<WhiteLabelingParams>>;
    // tslint:disable-next-line:max-line-length
    public saveWhiteLabelParamsUsingPOST(whiteLabelingParams: WhiteLabelingParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<WhiteLabelingParams>>;
    // tslint:disable-next-line:max-line-length
    public saveWhiteLabelParamsUsingPOST(whiteLabelingParams: WhiteLabelingParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (whiteLabelingParams === null || whiteLabelingParams === undefined) {
            throw new Error('Required parameter whiteLabelingParams was null or undefined when calling saveWhiteLabelParamsUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<WhiteLabelingParams>(`${this.configuration.basePath}/api/whiteLabel/whiteLabelParams`,
            whiteLabelingParams,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

}
