export * from './adminController.service';
import { AdminControllerService } from './adminController.service';
export * from './alarmController.service';
import { AlarmControllerService } from './alarmController.service';
export * from './assetController.service';
import { AssetControllerService } from './assetController.service';
export * from './authController.service';
import { AuthControllerService } from './authController.service';
export * from './blobEntityController.service';
import { BlobEntityControllerService } from './blobEntityController.service';
export * from './componentDescriptorController.service';
import { ComponentDescriptorControllerService } from './componentDescriptorController.service';
export * from './converterController.service';
import { ConverterControllerService } from './converterController.service';
export * from './customMenuController.service';
import { CustomMenuControllerService } from './customMenuController.service';
export * from './customTranslationController.service';
import { CustomTranslationControllerService } from './customTranslationController.service';
export * from './customerController.service';
import { CustomerControllerService } from './customerController.service';
export * from './dashboardController.service';
import { DashboardControllerService } from './dashboardController.service';
export * from './deviceApiController.service';
import { DeviceApiControllerService } from './deviceApiController.service';
export * from './deviceController.service';
import { DeviceControllerService } from './deviceController.service';
export * from './entityGroupController.service';
import { EntityGroupControllerService } from './entityGroupController.service';
export * from './entityQueryController.service';
import { EntityQueryControllerService } from './entityQueryController.service';
export * from './entityRelationController.service';
import { EntityRelationControllerService } from './entityRelationController.service';
export * from './entityViewController.service';
import { EntityViewControllerService } from './entityViewController.service';
export * from './groupPermissionController.service';
import { GroupPermissionControllerService } from './groupPermissionController.service';
export * from './integrationController.service';
import { IntegrationControllerService } from './integrationController.service';
export * from './ownerController.service';
import { OwnerControllerService } from './ownerController.service';
export * from './queueController.service';
import { QueueControllerService } from './queueController.service';
export * from './reportController.service';
import { ReportControllerService } from './reportController.service';
export * from './roleController.service';
import { RoleControllerService } from './roleController.service';
export * from './rpcController.service';
import { RpcControllerService } from './rpcController.service';
export * from './ruleChainController.service';
import { RuleChainControllerService } from './ruleChainController.service';
export * from './ruleEngineController.service';
import { RuleEngineControllerService } from './ruleEngineController.service';
export * from './schedulerEventController.service';
import { SchedulerEventControllerService } from './schedulerEventController.service';
export * from './selfRegistrationController.service';
import { SelfRegistrationControllerService } from './selfRegistrationController.service';
export * from './signUpController.service';
import { SignUpControllerService } from './signUpController.service';
export * from './telemetryController.service';
import { TelemetryControllerService } from './telemetryController.service';
export * from './tenantController.service';
import { TenantControllerService } from './tenantController.service';
export * from './userController.service';
import { UserControllerService } from './userController.service';
export * from './userPermissionsController.service';
import { UserPermissionsControllerService } from './userPermissionsController.service';
export * from './whiteLabelingController.service';
import { WhiteLabelingControllerService } from './whiteLabelingController.service';
export * from './widgetTypeController.service';
import { WidgetTypeControllerService } from './widgetTypeController.service';
export * from './widgetsBundleController.service';
import { WidgetsBundleControllerService } from './widgetsBundleController.service';
// tslint:disable-next-line:max-line-length
export const APIS = [AdminControllerService, AlarmControllerService, AssetControllerService, AuthControllerService, BlobEntityControllerService, ComponentDescriptorControllerService, ConverterControllerService, CustomMenuControllerService, CustomTranslationControllerService, CustomerControllerService, DashboardControllerService, DeviceApiControllerService, DeviceControllerService, EntityGroupControllerService, EntityQueryControllerService, EntityRelationControllerService, EntityViewControllerService, GroupPermissionControllerService, IntegrationControllerService, OwnerControllerService, QueueControllerService, ReportControllerService, RoleControllerService, RpcControllerService, RuleChainControllerService, RuleEngineControllerService, SchedulerEventControllerService, SelfRegistrationControllerService, SignUpControllerService, TelemetryControllerService, TenantControllerService, UserControllerService, UserPermissionsControllerService, WhiteLabelingControllerService, WidgetTypeControllerService, WidgetsBundleControllerService];
