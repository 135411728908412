/**
 * ThingsBoard REST API
 * For instructions how to authorize requests please visit <a href=\'http://thingsboard.io/docs/reference/rest-api/\'>REST API documentation page</a>.
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@thingsboard.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EntityId } from './entityId';
import { EntityGroupId } from './entityGroupId';


export interface EntityGroupInfo { 
    additionalInfo?: string;
    configuration?: string;
    createdTime?: number;
    groupAll?: boolean;
    id?: EntityGroupId;
    name: string;
    ownerId?: EntityId;
    ownerIds?: Array<EntityId>;
    type: EntityGroupInfo.TypeEnum;
}
export namespace EntityGroupInfo {
    export type TypeEnum = 'CUSTOMER' | 'ASSET' | 'DEVICE' | 'USER' | 'ENTITY_VIEW' | 'DASHBOARD';
    export const TypeEnum = {
        CUSTOMER: 'CUSTOMER' as TypeEnum,
        ASSET: 'ASSET' as TypeEnum,
        DEVICE: 'DEVICE' as TypeEnum,
        USER: 'USER' as TypeEnum,
        ENTITYVIEW: 'ENTITY_VIEW' as TypeEnum,
        DASHBOARD: 'DASHBOARD' as TypeEnum
    };
}


