/**
 * ThingsBoard REST API
 * For instructions how to authorize requests please visit <a href=\'http://thingsboard.io/docs/reference/rest-api/\'>REST API documentation page</a>.
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@thingsboard.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EntityId } from './entityId';
import { MergedUserPermissions } from './mergedUserPermissions';


export interface AllowedPermissionsInfo { 
    allowedForGroupOwnerOnlyGroupOperations?: Array<AllowedPermissionsInfo.AllowedForGroupOwnerOnlyGroupOperationsEnum>;
    allowedForGroupOwnerOnlyOperations?: Array<AllowedPermissionsInfo.AllowedForGroupOwnerOnlyOperationsEnum>;
    allowedForGroupRoleOperations?: Array<AllowedPermissionsInfo.AllowedForGroupRoleOperationsEnum>;
    allowedResources?: Array<AllowedPermissionsInfo.AllowedResourcesEnum>;
    operationsByResource?: { [key: string]: Array<string>; };
    userOwnerId?: EntityId;
    userPermissions?: MergedUserPermissions;
}
export namespace AllowedPermissionsInfo {
    export type AllowedForGroupOwnerOnlyGroupOperationsEnum = 'ALL' | 'CREATE' | 'READ' | 'WRITE' | 'DELETE' | 'RPC_CALL' | 'READ_CREDENTIALS' | 'WRITE_CREDENTIALS' | 'READ_ATTRIBUTES' | 'WRITE_ATTRIBUTES' | 'READ_TELEMETRY' | 'WRITE_TELEMETRY' | 'ADD_TO_GROUP' | 'REMOVE_FROM_GROUP' | 'CHANGE_OWNER' | 'IMPERSONATE' | 'CLAIM_DEVICES' | 'SHARE_GROUP' | 'ASSIGN_TO_TENANT';
    export const AllowedForGroupOwnerOnlyGroupOperationsEnum = {
        ALL: 'ALL' as AllowedForGroupOwnerOnlyGroupOperationsEnum,
        CREATE: 'CREATE' as AllowedForGroupOwnerOnlyGroupOperationsEnum,
        READ: 'READ' as AllowedForGroupOwnerOnlyGroupOperationsEnum,
        WRITE: 'WRITE' as AllowedForGroupOwnerOnlyGroupOperationsEnum,
        DELETE: 'DELETE' as AllowedForGroupOwnerOnlyGroupOperationsEnum,
        RPCCALL: 'RPC_CALL' as AllowedForGroupOwnerOnlyGroupOperationsEnum,
        READCREDENTIALS: 'READ_CREDENTIALS' as AllowedForGroupOwnerOnlyGroupOperationsEnum,
        WRITECREDENTIALS: 'WRITE_CREDENTIALS' as AllowedForGroupOwnerOnlyGroupOperationsEnum,
        READATTRIBUTES: 'READ_ATTRIBUTES' as AllowedForGroupOwnerOnlyGroupOperationsEnum,
        WRITEATTRIBUTES: 'WRITE_ATTRIBUTES' as AllowedForGroupOwnerOnlyGroupOperationsEnum,
        READTELEMETRY: 'READ_TELEMETRY' as AllowedForGroupOwnerOnlyGroupOperationsEnum,
        WRITETELEMETRY: 'WRITE_TELEMETRY' as AllowedForGroupOwnerOnlyGroupOperationsEnum,
        ADDTOGROUP: 'ADD_TO_GROUP' as AllowedForGroupOwnerOnlyGroupOperationsEnum,
        REMOVEFROMGROUP: 'REMOVE_FROM_GROUP' as AllowedForGroupOwnerOnlyGroupOperationsEnum,
        CHANGEOWNER: 'CHANGE_OWNER' as AllowedForGroupOwnerOnlyGroupOperationsEnum,
        IMPERSONATE: 'IMPERSONATE' as AllowedForGroupOwnerOnlyGroupOperationsEnum,
        CLAIMDEVICES: 'CLAIM_DEVICES' as AllowedForGroupOwnerOnlyGroupOperationsEnum,
        SHAREGROUP: 'SHARE_GROUP' as AllowedForGroupOwnerOnlyGroupOperationsEnum,
        ASSIGNTOTENANT: 'ASSIGN_TO_TENANT' as AllowedForGroupOwnerOnlyGroupOperationsEnum
    };
    export type AllowedForGroupOwnerOnlyOperationsEnum = 'ALL' | 'CREATE' | 'READ' | 'WRITE' | 'DELETE' | 'RPC_CALL' | 'READ_CREDENTIALS' | 'WRITE_CREDENTIALS' | 'READ_ATTRIBUTES' | 'WRITE_ATTRIBUTES' | 'READ_TELEMETRY' | 'WRITE_TELEMETRY' | 'ADD_TO_GROUP' | 'REMOVE_FROM_GROUP' | 'CHANGE_OWNER' | 'IMPERSONATE' | 'CLAIM_DEVICES' | 'SHARE_GROUP' | 'ASSIGN_TO_TENANT';
    export const AllowedForGroupOwnerOnlyOperationsEnum = {
        ALL: 'ALL' as AllowedForGroupOwnerOnlyOperationsEnum,
        CREATE: 'CREATE' as AllowedForGroupOwnerOnlyOperationsEnum,
        READ: 'READ' as AllowedForGroupOwnerOnlyOperationsEnum,
        WRITE: 'WRITE' as AllowedForGroupOwnerOnlyOperationsEnum,
        DELETE: 'DELETE' as AllowedForGroupOwnerOnlyOperationsEnum,
        RPCCALL: 'RPC_CALL' as AllowedForGroupOwnerOnlyOperationsEnum,
        READCREDENTIALS: 'READ_CREDENTIALS' as AllowedForGroupOwnerOnlyOperationsEnum,
        WRITECREDENTIALS: 'WRITE_CREDENTIALS' as AllowedForGroupOwnerOnlyOperationsEnum,
        READATTRIBUTES: 'READ_ATTRIBUTES' as AllowedForGroupOwnerOnlyOperationsEnum,
        WRITEATTRIBUTES: 'WRITE_ATTRIBUTES' as AllowedForGroupOwnerOnlyOperationsEnum,
        READTELEMETRY: 'READ_TELEMETRY' as AllowedForGroupOwnerOnlyOperationsEnum,
        WRITETELEMETRY: 'WRITE_TELEMETRY' as AllowedForGroupOwnerOnlyOperationsEnum,
        ADDTOGROUP: 'ADD_TO_GROUP' as AllowedForGroupOwnerOnlyOperationsEnum,
        REMOVEFROMGROUP: 'REMOVE_FROM_GROUP' as AllowedForGroupOwnerOnlyOperationsEnum,
        CHANGEOWNER: 'CHANGE_OWNER' as AllowedForGroupOwnerOnlyOperationsEnum,
        IMPERSONATE: 'IMPERSONATE' as AllowedForGroupOwnerOnlyOperationsEnum,
        CLAIMDEVICES: 'CLAIM_DEVICES' as AllowedForGroupOwnerOnlyOperationsEnum,
        SHAREGROUP: 'SHARE_GROUP' as AllowedForGroupOwnerOnlyOperationsEnum,
        ASSIGNTOTENANT: 'ASSIGN_TO_TENANT' as AllowedForGroupOwnerOnlyOperationsEnum
    };
    export type AllowedForGroupRoleOperationsEnum = 'ALL' | 'CREATE' | 'READ' | 'WRITE' | 'DELETE' | 'RPC_CALL' | 'READ_CREDENTIALS' | 'WRITE_CREDENTIALS' | 'READ_ATTRIBUTES' | 'WRITE_ATTRIBUTES' | 'READ_TELEMETRY' | 'WRITE_TELEMETRY' | 'ADD_TO_GROUP' | 'REMOVE_FROM_GROUP' | 'CHANGE_OWNER' | 'IMPERSONATE' | 'CLAIM_DEVICES' | 'SHARE_GROUP' | 'ASSIGN_TO_TENANT';
    export const AllowedForGroupRoleOperationsEnum = {
        ALL: 'ALL' as AllowedForGroupRoleOperationsEnum,
        CREATE: 'CREATE' as AllowedForGroupRoleOperationsEnum,
        READ: 'READ' as AllowedForGroupRoleOperationsEnum,
        WRITE: 'WRITE' as AllowedForGroupRoleOperationsEnum,
        DELETE: 'DELETE' as AllowedForGroupRoleOperationsEnum,
        RPCCALL: 'RPC_CALL' as AllowedForGroupRoleOperationsEnum,
        READCREDENTIALS: 'READ_CREDENTIALS' as AllowedForGroupRoleOperationsEnum,
        WRITECREDENTIALS: 'WRITE_CREDENTIALS' as AllowedForGroupRoleOperationsEnum,
        READATTRIBUTES: 'READ_ATTRIBUTES' as AllowedForGroupRoleOperationsEnum,
        WRITEATTRIBUTES: 'WRITE_ATTRIBUTES' as AllowedForGroupRoleOperationsEnum,
        READTELEMETRY: 'READ_TELEMETRY' as AllowedForGroupRoleOperationsEnum,
        WRITETELEMETRY: 'WRITE_TELEMETRY' as AllowedForGroupRoleOperationsEnum,
        ADDTOGROUP: 'ADD_TO_GROUP' as AllowedForGroupRoleOperationsEnum,
        REMOVEFROMGROUP: 'REMOVE_FROM_GROUP' as AllowedForGroupRoleOperationsEnum,
        CHANGEOWNER: 'CHANGE_OWNER' as AllowedForGroupRoleOperationsEnum,
        IMPERSONATE: 'IMPERSONATE' as AllowedForGroupRoleOperationsEnum,
        CLAIMDEVICES: 'CLAIM_DEVICES' as AllowedForGroupRoleOperationsEnum,
        SHAREGROUP: 'SHARE_GROUP' as AllowedForGroupRoleOperationsEnum,
        ASSIGNTOTENANT: 'ASSIGN_TO_TENANT' as AllowedForGroupRoleOperationsEnum
    };
    export type AllowedResourcesEnum = 'ALL' | 'PROFILE' | 'ADMIN_SETTINGS' | 'ALARM' | 'DEVICE' | 'ASSET' | 'CUSTOMER' | 'DASHBOARD' | 'ENTITY_VIEW' | 'TENANT' | 'RULE_CHAIN' | 'USER' | 'WIDGETS_BUNDLE' | 'WIDGET_TYPE' | 'CONVERTER' | 'INTEGRATION' | 'SCHEDULER_EVENT' | 'BLOB_ENTITY' | 'CUSTOMER_GROUP' | 'DEVICE_GROUP' | 'ASSET_GROUP' | 'USER_GROUP' | 'ENTITY_VIEW_GROUP' | 'DASHBOARD_GROUP' | 'ROLE' | 'GROUP_PERMISSION' | 'WHITE_LABELING' | 'AUDIT_LOG';
    export const AllowedResourcesEnum = {
        ALL: 'ALL' as AllowedResourcesEnum,
        PROFILE: 'PROFILE' as AllowedResourcesEnum,
        ADMINSETTINGS: 'ADMIN_SETTINGS' as AllowedResourcesEnum,
        ALARM: 'ALARM' as AllowedResourcesEnum,
        DEVICE: 'DEVICE' as AllowedResourcesEnum,
        ASSET: 'ASSET' as AllowedResourcesEnum,
        CUSTOMER: 'CUSTOMER' as AllowedResourcesEnum,
        DASHBOARD: 'DASHBOARD' as AllowedResourcesEnum,
        ENTITYVIEW: 'ENTITY_VIEW' as AllowedResourcesEnum,
        TENANT: 'TENANT' as AllowedResourcesEnum,
        RULECHAIN: 'RULE_CHAIN' as AllowedResourcesEnum,
        USER: 'USER' as AllowedResourcesEnum,
        WIDGETSBUNDLE: 'WIDGETS_BUNDLE' as AllowedResourcesEnum,
        WIDGETTYPE: 'WIDGET_TYPE' as AllowedResourcesEnum,
        CONVERTER: 'CONVERTER' as AllowedResourcesEnum,
        INTEGRATION: 'INTEGRATION' as AllowedResourcesEnum,
        SCHEDULEREVENT: 'SCHEDULER_EVENT' as AllowedResourcesEnum,
        BLOBENTITY: 'BLOB_ENTITY' as AllowedResourcesEnum,
        CUSTOMERGROUP: 'CUSTOMER_GROUP' as AllowedResourcesEnum,
        DEVICEGROUP: 'DEVICE_GROUP' as AllowedResourcesEnum,
        ASSETGROUP: 'ASSET_GROUP' as AllowedResourcesEnum,
        USERGROUP: 'USER_GROUP' as AllowedResourcesEnum,
        ENTITYVIEWGROUP: 'ENTITY_VIEW_GROUP' as AllowedResourcesEnum,
        DASHBOARDGROUP: 'DASHBOARD_GROUP' as AllowedResourcesEnum,
        ROLE: 'ROLE' as AllowedResourcesEnum,
        GROUPPERMISSION: 'GROUP_PERMISSION' as AllowedResourcesEnum,
        WHITELABELING: 'WHITE_LABELING' as AllowedResourcesEnum,
        AUDITLOG: 'AUDIT_LOG' as AllowedResourcesEnum
    };
    export type OperationsByResourceEnum = 'ALL' | 'CREATE' | 'READ' | 'WRITE' | 'DELETE' | 'RPC_CALL' | 'READ_CREDENTIALS' | 'WRITE_CREDENTIALS' | 'READ_ATTRIBUTES' | 'WRITE_ATTRIBUTES' | 'READ_TELEMETRY' | 'WRITE_TELEMETRY' | 'ADD_TO_GROUP' | 'REMOVE_FROM_GROUP' | 'CHANGE_OWNER' | 'IMPERSONATE' | 'CLAIM_DEVICES' | 'SHARE_GROUP' | 'ASSIGN_TO_TENANT';
    export const OperationsByResourceEnum = {
        ALL: 'ALL' as OperationsByResourceEnum,
        CREATE: 'CREATE' as OperationsByResourceEnum,
        READ: 'READ' as OperationsByResourceEnum,
        WRITE: 'WRITE' as OperationsByResourceEnum,
        DELETE: 'DELETE' as OperationsByResourceEnum,
        RPCCALL: 'RPC_CALL' as OperationsByResourceEnum,
        READCREDENTIALS: 'READ_CREDENTIALS' as OperationsByResourceEnum,
        WRITECREDENTIALS: 'WRITE_CREDENTIALS' as OperationsByResourceEnum,
        READATTRIBUTES: 'READ_ATTRIBUTES' as OperationsByResourceEnum,
        WRITEATTRIBUTES: 'WRITE_ATTRIBUTES' as OperationsByResourceEnum,
        READTELEMETRY: 'READ_TELEMETRY' as OperationsByResourceEnum,
        WRITETELEMETRY: 'WRITE_TELEMETRY' as OperationsByResourceEnum,
        ADDTOGROUP: 'ADD_TO_GROUP' as OperationsByResourceEnum,
        REMOVEFROMGROUP: 'REMOVE_FROM_GROUP' as OperationsByResourceEnum,
        CHANGEOWNER: 'CHANGE_OWNER' as OperationsByResourceEnum,
        IMPERSONATE: 'IMPERSONATE' as OperationsByResourceEnum,
        CLAIMDEVICES: 'CLAIM_DEVICES' as OperationsByResourceEnum,
        SHAREGROUP: 'SHARE_GROUP' as OperationsByResourceEnum,
        ASSIGNTOTENANT: 'ASSIGN_TO_TENANT' as OperationsByResourceEnum
    };
}


