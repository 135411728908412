/**
 * ThingsBoard REST API
 * For instructions how to authorize requests please visit <a href=\'http://thingsboard.io/docs/reference/rest-api/\'>REST API documentation page</a>.
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@thingsboard.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EntityKey { 
    type: EntityKey.TypeEnum;
    key: string;
}
export namespace EntityKey {
    export type TypeEnum = 'ATTRIBUTE' | 'CLIENT_ATTRIBUTE' | 'SHARED_ATTRIBUTE' | 'SERVER_ATTRIBUTE' | 'TIME_SERIES' | 'ENTITY_FIELD' | 'ALARM_FIELD';
    export const TypeEnum = {
        ATTRIBUTE: 'ATTRIBUTE' as TypeEnum,
        CLIENTATTRIBUTE: 'CLIENT_ATTRIBUTE' as TypeEnum,
        SHAREDATTRIBUTE: 'SHARED_ATTRIBUTE' as TypeEnum,
        SERVERATTRIBUTE: 'SERVER_ATTRIBUTE' as TypeEnum,
        TIMESERIES: 'TIME_SERIES' as TypeEnum,
        ENTITYFIELD: 'ENTITY_FIELD' as TypeEnum,
        ALARMFIELD: 'ALARM_FIELD' as TypeEnum
    };
}


