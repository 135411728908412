import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./paginas/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'db-mapa',
    loadChildren: () => import('./paginas/db-mapa/db-mapa.module').then( m => m.DbMapaPageModule)
  },
  {
    path: 'db-tabla',
    loadChildren: () => import('./paginas/db-tabla/db-tabla.module').then( m => m.DbTablaPageModule)
  },
  {
    path: 'planta-mapa',
    loadChildren: () => import('./paginas/planta-mapa/planta-mapa.module').then( m => m.PlantaMapaPageModule)
  },
  {
    path: 'planta-config',
    loadChildren: () => import('./paginas/planta-config/planta-config.module').then( m => m.PlantaConfigPageModule)
  },
  {
    path: 'planta-analitica',
    loadChildren: () => import('./paginas/planta-analitica/planta-analitica.module').then( m => m.PlantaAnaliticaPageModule)
  },
  {
    path: 'planta-eventos',
    loadChildren: () => import('./paginas/planta-eventos/planta-eventos.module').then( m => m.PlantaEventosPageModule)
  },
  {
    path: 'rsu-base',
    loadChildren: () => import('./paginas/rsu-base/rsu-base.module').then( m => m.RsuBasePageModule)
  },
  {
    path: 'rsu-config',
    loadChildren: () => import('./paginas/rsu-config/rsu-config.module').then( m => m.RsuConfigPageModule)
  },
  {
    path: 'rsu-eventos',
    loadChildren: () => import('./paginas/rsu-eventos/rsu-eventos.module').then( m => m.RsuEventosPageModule)
  },
  {
    path: 'rsu-analitica',
    loadChildren: () => import('./paginas/rsu-analitica/rsu-analitica.module').then( m => m.RsuAnaliticaPageModule)
  },
  {
    path: 'tcu-base',
    loadChildren: () => import('./paginas/tcu-base/tcu-base.module').then( m => m.TcuBasePageModule)
  },
  {
    path: 'tcu-config',
    loadChildren: () => import('./paginas/tcu-config/tcu-config.module').then( m => m.TcuConfigPageModule)
  },
  {
    path: 'tcu-eventos',
    loadChildren: () => import('./paginas/tcu-eventos/tcu-eventos.module').then( m => m.TcuEventosPageModule)
  },
  {
    path: 'tcu-analitica',
    loadChildren: () => import('./paginas/tcu-analitica/tcu-analitica.module').then( m => m.TcuAnaliticaPageModule)
  },
  {
    path: 'ncu-base',
    loadChildren: () => import('./paginas/ncu-base/ncu-base.module').then( m => m.NcuBasePageModule)
  },
  {
    path: 'ncu-config',
    loadChildren: () => import('./paginas/ncu-config/ncu-config.module').then( m => m.NcuConfigPageModule)
  },
  {
    path: 'ncu-analitica',
    loadChildren: () => import('./paginas/ncu-analitica/ncu-analitica.module').then( m => m.NcuAnaliticaPageModule)
  },
  {
    path: 'ncu-eventos',
    loadChildren: () => import('./paginas/ncu-eventos/ncu-eventos.module').then( m => m.NcuEventosPageModule)
  },
  {
    path: 'politica-privacidad',
    loadChildren: () => import('./paginas/politica-privacidad/politica-privacidad.module').then( m => m.PoliticaPrivacidadPageModule)
  },
  {
    path: 'politica-cookies',
    loadChildren: () => import('./paginas/politica-cookies/politica-cookies.module').then( m => m.PoliticaCookiesPageModule)
  },
  {
    path: 'aviso-legal',
    loadChildren: () => import('./paginas/aviso-legal/aviso-legal.module').then( m => m.AvisoLegalPageModule)
  },
  {
    path: 'nueva-planta',
    loadChildren: () => import('./paginas/nueva-planta/nueva-planta.module').then( m => m.NuevaPlantaPageModule)
  },
  {
    path: 'planta-base',
    loadChildren: () => import('./paginas/planta-base/planta-base.module').then( m => m.PlantaBasePageModule)
  },
  {
    path: 'ncu-mapa',
    loadChildren: () => import('./paginas/ncu-mapa/ncu-mapa.module').then( m => m.NcuMapaPageModule)
  },  {
    path: 'db-new-plant',
    loadChildren: () => import('./paginas/db-new-plant/db-new-plant.module').then( m => m.DbNewPlantPageModule)
  },
  {
    path: 'planta-informes',
    loadChildren: () => import('./paginas/planta-informes/planta-informes.module').then( m => m.PlantaInformesPageModule)
  },
  {
    path: 'db-alarmas',
    loadChildren: () => import('./paginas/db-alarmas/db-alarmas.module').then( m => m.DbAlarmasPageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
