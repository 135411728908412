/**
 * ThingsBoard REST API
 * For instructions how to authorize requests please visit <a href=\'http://thingsboard.io/docs/reference/rest-api/\'>REST API documentation page</a>.
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@thingsboard.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EntityId } from './entityId';
import { AuditLogId } from './auditLogId';
import { TenantId } from './tenantId';
import { UserId } from './userId';
import { CustomerId } from './customerId';


export interface AuditLog { 
    actionData?: string;
    actionFailureDetails?: string;
    actionStatus?: AuditLog.ActionStatusEnum;
    actionType?: AuditLog.ActionTypeEnum;
    createdTime?: number;
    customerId?: CustomerId;
    entityId?: EntityId;
    entityName?: string;
    id?: AuditLogId;
    tenantId?: TenantId;
    userId?: UserId;
    userName?: string;
}
export namespace AuditLog {
    export type ActionStatusEnum = 'SUCCESS' | 'FAILURE';
    export const ActionStatusEnum = {
        SUCCESS: 'SUCCESS' as ActionStatusEnum,
        FAILURE: 'FAILURE' as ActionStatusEnum
    };
    export type ActionTypeEnum = 'ADDED' | 'DELETED' | 'UPDATED' | 'ATTRIBUTES_UPDATED' | 'ATTRIBUTES_DELETED' | 'TIMESERIES_DELETED' | 'RPC_CALL' | 'CREDENTIALS_UPDATED' | 'ASSIGNED_TO_CUSTOMER' | 'UNASSIGNED_FROM_CUSTOMER' | 'CHANGE_OWNER' | 'ACTIVATED' | 'SUSPENDED' | 'CREDENTIALS_READ' | 'ATTRIBUTES_READ' | 'RELATION_ADD_OR_UPDATE' | 'RELATION_DELETED' | 'RELATIONS_DELETED' | 'ALARM_ACK' | 'ALARM_CLEAR' | 'ADDED_TO_ENTITY_GROUP' | 'REMOVED_FROM_ENTITY_GROUP' | 'REST_API_RULE_ENGINE_CALL' | 'MADE_PUBLIC' | 'MADE_PRIVATE' | 'LOGIN' | 'LOGOUT' | 'LOCKOUT' | 'ASSIGNED_FROM_TENANT' | 'ASSIGNED_TO_TENANT';
    export const ActionTypeEnum = {
        ADDED: 'ADDED' as ActionTypeEnum,
        DELETED: 'DELETED' as ActionTypeEnum,
        UPDATED: 'UPDATED' as ActionTypeEnum,
        ATTRIBUTESUPDATED: 'ATTRIBUTES_UPDATED' as ActionTypeEnum,
        ATTRIBUTESDELETED: 'ATTRIBUTES_DELETED' as ActionTypeEnum,
        TIMESERIESDELETED: 'TIMESERIES_DELETED' as ActionTypeEnum,
        RPCCALL: 'RPC_CALL' as ActionTypeEnum,
        CREDENTIALSUPDATED: 'CREDENTIALS_UPDATED' as ActionTypeEnum,
        ASSIGNEDTOCUSTOMER: 'ASSIGNED_TO_CUSTOMER' as ActionTypeEnum,
        UNASSIGNEDFROMCUSTOMER: 'UNASSIGNED_FROM_CUSTOMER' as ActionTypeEnum,
        CHANGEOWNER: 'CHANGE_OWNER' as ActionTypeEnum,
        ACTIVATED: 'ACTIVATED' as ActionTypeEnum,
        SUSPENDED: 'SUSPENDED' as ActionTypeEnum,
        CREDENTIALSREAD: 'CREDENTIALS_READ' as ActionTypeEnum,
        ATTRIBUTESREAD: 'ATTRIBUTES_READ' as ActionTypeEnum,
        RELATIONADDORUPDATE: 'RELATION_ADD_OR_UPDATE' as ActionTypeEnum,
        RELATIONDELETED: 'RELATION_DELETED' as ActionTypeEnum,
        RELATIONSDELETED: 'RELATIONS_DELETED' as ActionTypeEnum,
        ALARMACK: 'ALARM_ACK' as ActionTypeEnum,
        ALARMCLEAR: 'ALARM_CLEAR' as ActionTypeEnum,
        ADDEDTOENTITYGROUP: 'ADDED_TO_ENTITY_GROUP' as ActionTypeEnum,
        REMOVEDFROMENTITYGROUP: 'REMOVED_FROM_ENTITY_GROUP' as ActionTypeEnum,
        RESTAPIRULEENGINECALL: 'REST_API_RULE_ENGINE_CALL' as ActionTypeEnum,
        MADEPUBLIC: 'MADE_PUBLIC' as ActionTypeEnum,
        MADEPRIVATE: 'MADE_PRIVATE' as ActionTypeEnum,
        LOGIN: 'LOGIN' as ActionTypeEnum,
        LOGOUT: 'LOGOUT' as ActionTypeEnum,
        LOCKOUT: 'LOCKOUT' as ActionTypeEnum,
        ASSIGNEDFROMTENANT: 'ASSIGNED_FROM_TENANT' as ActionTypeEnum,
        ASSIGNEDTOTENANT: 'ASSIGNED_TO_TENANT' as ActionTypeEnum
    };
}


