/**
 * ThingsBoard REST API
 * For instructions how to authorize requests please visit <a href=\'http://thingsboard.io/docs/reference/rest-api/\'>REST API documentation page</a>.
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@thingsboard.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GroupPermissionId } from './groupPermissionId';
import { TenantId } from './tenantId';
import { EntityGroupId } from './entityGroupId';
import { RoleId } from './roleId';


export interface GroupPermission { 
    createdTime?: number;
    entityGroupId?: EntityGroupId;
    entityGroupType?: GroupPermission.EntityGroupTypeEnum;
    id?: GroupPermissionId;
    name?: string;
    _public?: boolean;
    roleId?: RoleId;
    tenantId?: TenantId;
    userGroupId?: EntityGroupId;
    isPublic: boolean;
}
export namespace GroupPermission {
    export type EntityGroupTypeEnum = 'TENANT' | 'CUSTOMER' | 'USER' | 'DASHBOARD' | 'ASSET' | 'DEVICE' | 'ALARM' | 'ENTITY_GROUP' | 'CONVERTER' | 'INTEGRATION' | 'RULE_CHAIN' | 'RULE_NODE' | 'SCHEDULER_EVENT' | 'BLOB_ENTITY' | 'ENTITY_VIEW' | 'WIDGETS_BUNDLE' | 'WIDGET_TYPE' | 'ROLE' | 'GROUP_PERMISSION';
    export const EntityGroupTypeEnum = {
        TENANT: 'TENANT' as EntityGroupTypeEnum,
        CUSTOMER: 'CUSTOMER' as EntityGroupTypeEnum,
        USER: 'USER' as EntityGroupTypeEnum,
        DASHBOARD: 'DASHBOARD' as EntityGroupTypeEnum,
        ASSET: 'ASSET' as EntityGroupTypeEnum,
        DEVICE: 'DEVICE' as EntityGroupTypeEnum,
        ALARM: 'ALARM' as EntityGroupTypeEnum,
        ENTITYGROUP: 'ENTITY_GROUP' as EntityGroupTypeEnum,
        CONVERTER: 'CONVERTER' as EntityGroupTypeEnum,
        INTEGRATION: 'INTEGRATION' as EntityGroupTypeEnum,
        RULECHAIN: 'RULE_CHAIN' as EntityGroupTypeEnum,
        RULENODE: 'RULE_NODE' as EntityGroupTypeEnum,
        SCHEDULEREVENT: 'SCHEDULER_EVENT' as EntityGroupTypeEnum,
        BLOBENTITY: 'BLOB_ENTITY' as EntityGroupTypeEnum,
        ENTITYVIEW: 'ENTITY_VIEW' as EntityGroupTypeEnum,
        WIDGETSBUNDLE: 'WIDGETS_BUNDLE' as EntityGroupTypeEnum,
        WIDGETTYPE: 'WIDGET_TYPE' as EntityGroupTypeEnum,
        ROLE: 'ROLE' as EntityGroupTypeEnum,
        GROUPPERMISSION: 'GROUP_PERMISSION' as EntityGroupTypeEnum
    };
}


