/**
 * ThingsBoard REST API
 * For instructions how to authorize requests please visit <a href=\'http://thingsboard.io/docs/reference/rest-api/\'>REST API documentation page</a>.
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@thingsboard.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TenantId } from './tenantId';
import { ConverterId } from './converterId';


export interface Converter { 
    additionalInfo?: string;
    configuration?: string;
    createdTime?: number;
    debugMode?: boolean;
    id?: ConverterId;
    name?: string;
    tenantId?: TenantId;
    type?: Converter.TypeEnum;
}
export namespace Converter {
    export type TypeEnum = 'UPLINK' | 'DOWNLINK';
    export const TypeEnum = {
        UPLINK: 'UPLINK' as TypeEnum,
        DOWNLINK: 'DOWNLINK' as TypeEnum
    };
}


