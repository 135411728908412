/**
 * ThingsBoard REST API
 * For instructions how to authorize requests please visit <a href=\'http://thingsboard.io/docs/reference/rest-api/\'>REST API documentation page</a>.
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@thingsboard.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MergedGroupTypePermissionInfo } from './mergedGroupTypePermissionInfo';
import { MergedGroupPermissionInfo } from './mergedGroupPermissionInfo';


export interface MergedUserPermissions { 
    genericPermissions?: { [key: string]: Array<string>; };
    groupPermissions?: { [key: string]: MergedGroupPermissionInfo; };
    readAttrPermissions?: { [key: string]: MergedGroupTypePermissionInfo; };
    readEntityPermissions?: { [key: string]: MergedGroupTypePermissionInfo; };
    readGroupPermissions?: { [key: string]: MergedGroupTypePermissionInfo; };
    readTsPermissions?: { [key: string]: MergedGroupTypePermissionInfo; };
}
export namespace MergedUserPermissions {
    export type GenericPermissionsEnum = 'ALL' | 'CREATE' | 'READ' | 'WRITE' | 'DELETE' | 'RPC_CALL' | 'READ_CREDENTIALS' | 'WRITE_CREDENTIALS' | 'READ_ATTRIBUTES' | 'WRITE_ATTRIBUTES' | 'READ_TELEMETRY' | 'WRITE_TELEMETRY' | 'ADD_TO_GROUP' | 'REMOVE_FROM_GROUP' | 'CHANGE_OWNER' | 'IMPERSONATE' | 'CLAIM_DEVICES' | 'SHARE_GROUP' | 'ASSIGN_TO_TENANT';
    export const GenericPermissionsEnum = {
        ALL: 'ALL' as GenericPermissionsEnum,
        CREATE: 'CREATE' as GenericPermissionsEnum,
        READ: 'READ' as GenericPermissionsEnum,
        WRITE: 'WRITE' as GenericPermissionsEnum,
        DELETE: 'DELETE' as GenericPermissionsEnum,
        RPCCALL: 'RPC_CALL' as GenericPermissionsEnum,
        READCREDENTIALS: 'READ_CREDENTIALS' as GenericPermissionsEnum,
        WRITECREDENTIALS: 'WRITE_CREDENTIALS' as GenericPermissionsEnum,
        READATTRIBUTES: 'READ_ATTRIBUTES' as GenericPermissionsEnum,
        WRITEATTRIBUTES: 'WRITE_ATTRIBUTES' as GenericPermissionsEnum,
        READTELEMETRY: 'READ_TELEMETRY' as GenericPermissionsEnum,
        WRITETELEMETRY: 'WRITE_TELEMETRY' as GenericPermissionsEnum,
        ADDTOGROUP: 'ADD_TO_GROUP' as GenericPermissionsEnum,
        REMOVEFROMGROUP: 'REMOVE_FROM_GROUP' as GenericPermissionsEnum,
        CHANGEOWNER: 'CHANGE_OWNER' as GenericPermissionsEnum,
        IMPERSONATE: 'IMPERSONATE' as GenericPermissionsEnum,
        CLAIMDEVICES: 'CLAIM_DEVICES' as GenericPermissionsEnum,
        SHAREGROUP: 'SHARE_GROUP' as GenericPermissionsEnum,
        ASSIGNTOTENANT: 'ASSIGN_TO_TENANT' as GenericPermissionsEnum
    };
}


