import {Injectable} from '@angular/core';
import * as SunCalc from 'suncalc';
import * as XLSX from 'xlsx';
import {formatDate} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class HelperFunctionsService {
    // tslint:disable-next-line:variable-name
    private alert_comm_rsu: string;
    private alert_commws_rsu: string;

    constructor(public translate: TranslateService, ) {
    }

    getExcelFileName(name: string) {
        const timeSpan = new Date().toISOString();
        const sheetName = name || 'ExportResult';
        const fileName = `${sheetName}-${timeSpan}`;
        return {
            sheetName,
            fileName
        };
    }

    processEstadosRsu(estados) {
        let salida = [];
        const self = this;
        if (estados.length > 0) {
            salida = estados.map(estado => {
                let tipoalarma = '';
                let obj = {};
                switch (estado.tipoalarma) {
                    case 0:
                        tipoalarma = self.translate.instant('TABLAS.BATERIA');
                        break;
                    case 1:
                        tipoalarma = self.translate.instant('TABLAS.MOTOR');
                        break;
                    case 2:
                        tipoalarma = self.translate.instant('TABLAS.COMUNICACION');
                        break;
                    case 3:
                        tipoalarma = self.translate.instant('TABLAS.SETA');
                        break;
                    case 4:
                        tipoalarma = self.translate.instant('TABLAS.METEO');
                        break;
                    case 5:
                        tipoalarma = self.translate.instant('TABLAS.OTRAS');
                        break;
                }
                obj = {
                    alarma: estado.alarma,
                    tipoalarmas: tipoalarma,
                    estado: (estado.estado === 0) ? self.translate.instant('TABLAS.DESACTIVADA') : self.translate.instant('TABLAS.ACTIVADA'),
                    registro: estado.registro,
                    fecha: (estado.fecha && estado.fecha !== '') ? formatDate(estado.fecha, 'y-M-d H:mm', 'en_US', '+0000') : '',
                };
                return obj;
            });
        }
        return salida;
    }

    processEstadosTcu(estados) {
        let salida = [];
        const self = this;
        if (estados.length > 0) {
            salida = estados.map(estado => {
                let tipoalarma = '';
                let obj = {};
                switch (estado.tipoalarma) {
                    case 1:
                        tipoalarma = self.translate.instant('TABLAS.MOTOR');
                        break;
                    case 2:
                        tipoalarma = self.translate.instant('TABLAS.COMUNICACION');
                        break;
                    case 3:
                        tipoalarma = self.translate.instant('TABLAS.SETA');
                        break;
                    case 4:
                        tipoalarma = self.translate.instant('TABLAS.METEO');
                        break;
                    case 5:
                        tipoalarma = self.translate.instant('TABLAS.OTRAS');
                        break;
                }
                obj = {
                    alarma: estado.alarma,
                    tipoalarmas: tipoalarma,
                    estado: (estado.estado === 0) ? self.translate.instant('TABLAS.DESACTIVADA') : self.translate.instant('TABLAS.ACTIVADA'),
                    registro: estado.registro,
                    fecha: (estado.fecha && estado.fecha !== '') ? formatDate(estado.fecha, 'y-M-d H:mm', 'en_US', '+0000') : '',
                };
                return obj;
            });
        }
        return salida;
    }

    processEventosTcu(eventos) {
        let salida = [];
        const self = this;
        if (eventos.length > 0) {
            salida = eventos.map(evento => {
                    let tipoalarma = '';
                    let obj = {};
                    switch (evento.tipoalarma) {
                        case 3:
                            tipoalarma = self.translate.instant('TABLAS.STOPBUTTON');
                            break;
                        case 1:
                            tipoalarma = self.translate.instant('TABLAS.MOTOR');
                            break;
                        case 2:
                            tipoalarma = self.translate.instant('TABLAS.COMUNICACION');
                            break;
                        case 0:
                            tipoalarma = self.translate.instant('TABLAS.BATTERY');
                            break;
                        case 5:
                            tipoalarma = self.translate.instant('TABLAS.SOFTWARE');
                            break;
                    }
                    obj = {
                        alarma: evento.alarma,
                        tipoalarmas: tipoalarma,
                        estado: (evento.estado === 0) ? self.translate.instant('TABLAS.DESACTIVADA') : self.translate.instant('TABLAS.ACTIVADA'),
                        registro: evento.registro,
                        fecha: (evento.fecha && evento.fecha !== '') ? formatDate(evento.fecha, 'y-M-d H:m', 'en_US', '+0000') : '',
                    };
                    return obj;
                }
            );
            return salida;
        }
    }


    exportArrayToExcel(arr: any[], name?: string) {
        const {sheetName, fileName} = this.getExcelFileName(name);
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(arr);
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
        XLSX.writeFile(wb, `${fileName}.xlsx`);
    }

    exportMultipleArrayToExcel(arr: any[], name?: string, sensornieve?: any) {
        const {fileName} = this.getExcelFileName(name);
        let sheetName = [];
        if (sensornieve === 1){
            sheetName = ["Plant availability", "Alarm percentage", "Alarm total", "Devices", "Wind", "Snow"];
        }else{
            sheetName = ["Plant availability", "Alarm percentage", "Alarm total", "Devices", "Wind"];
        }
        const wb = XLSX.utils.book_new();
        for (let i = 0; i < sheetName.length; i++) {
            const ws = XLSX.utils.json_to_sheet(arr[i]);
            XLSX.utils.book_append_sheet(wb, ws, sheetName[i]);
        }
        XLSX.writeFile(wb, `${fileName}.xlsx`);
    }

    average(numArray) {
        return numArray.reduce((a, b) => (a + b)) / numArray.length;
    }

    getSunData(lat, long, localhour = null, horarioinicio = null) {
        let diff = 0;
        let localsunrise = Date.now();
        let localsunset = Date.now();
        let azimuth = 0;
        let altitude = 0;
        let zonah = 0;
        let fechahora = Date.now();
        if (!localhour){
            localhour = Date.now();
        }else{
            if (Date.now() < localhour){
                zonah = 1;
                diff = localhour - Date.now();
            }else{
                zonah = 0;
                diff = Date.now() - localhour;
            }
        }

        if (horarioinicio){
            fechahora = horarioinicio;
        }
        const suncalcTimes = SunCalc.getTimes(fechahora, lat, long);
        const newSunrise = new Date( suncalcTimes.sunrise);
        const newSunset = new Date( suncalcTimes.sunset);
        if (lat && long){
            if (zonah === 1){
                // @ts-ignore
                localsunrise = newSunrise + diff;
                // @ts-ignore
                localsunset = newSunset + diff;
                fechahora = fechahora + diff;
            }else{
                // @ts-ignore
                localsunrise = newSunrise - diff;
                // @ts-ignore
                localsunset = newSunset - diff;
                fechahora = fechahora - diff;
            }
        }else{
            localsunrise = null;
            localsunset = null;
        }

        const suncalcPosition = SunCalc.getPosition(fechahora, lat, long);
        const RAD2DEG = 180 / Math.PI;
        if (localsunrise > fechahora || !lat || !long){
            // Noche
            azimuth = 0;
            altitude = 0;
        }else{
            azimuth = RAD2DEG * suncalcPosition.azimuth + 180;
            altitude = RAD2DEG * suncalcPosition.altitude;
        }
        const sun = {
            sunrise: localsunrise,
            sunset: localsunset,
            azimuth: azimuth,
            altitude: altitude,
            irradiance: null
        };
        return sun;
    }

    buildDataSidebarNcu(planta, planta_assets, atributos, alarmas, currentNcuId) {
        const ncuName = this.getNcuName(planta_assets, currentNcuId);
        let sitematext = 0;
        if (atributos.Supplier.value === 'STI') {
            sitematext = 1;
        }
        let project = '';
        let plant = '';

        planta.forEach(function(obj, index) {
            switch (obj.key) {
                case 'Project': {
                    project = obj.value;
                    break;
                }
                case 'Display_Name': {
                    plant = obj.value;
                    break;
                }
                // case 'Supplier': {
                //     if (obj.value === 'STI') {
                //         sitematext = 1;
                //     }
                //     break;
                // }
            }
        }.bind(this));

        // tslint:disable-next-line:one-variable-per-declaration
        const ids = {tcu: {}, rsu: {}},
            isrepeater = {};
        const regExpLatLong = /(id)_(s|Rsu)(\d+)/;
        Object.keys(atributos)
            .filter(key => regExpLatLong.test(key))
            .forEach(
                key => {
                    const [, latOrLong, type, string] = key.match(regExpLatLong);
                    if (latOrLong === 'id') {
                        ids[type === 's' ? 'tcu' : 'rsu'][string] = atributos[key].value;
                    }
                }
            );
        const regExpRep = /isrepeater_s(\d+)/;
        Object.keys(atributos)
            .filter(key => regExpRep.test(key))
            .forEach(
                key => {
                    const [, number] = key.match(regExpRep);
                    isrepeater[number] = +atributos[key].value;
                }
            );
        // Obtenemos totales de alarmas de NCUs
        let ncualarmcomm = 0;
        let ncualarmother = 0;
        if ((alarmas[`Gw2Timeout`] && alarmas[`Gw2Timeout`][0].value > 0)
            || (alarmas[`Gw1Timeout`] && alarmas[`Gw1Timeout`][0].value > 0)) {
            ncualarmcomm++;
        }
        if ((alarmas[`BadConfiguration`] && alarmas[`BadConfiguration`][0].value > 0)
            || (alarmas[`AlarmTimeNeverSet`] && alarmas[`AlarmTimeNeverSet`][0].value > 0)
            || (alarmas[`Vfault`] && alarmas[`Vfault`][0].value > 0)) {
            ncualarmother++;
        }

        // Obtenemos totales de alarmas de TCUs y Repeaters
        let tcualarmcomm = 0;
        let tcualarmstopbutton = 0;
        let tcualarmbatt = 0;
        let tcualarmmotor = 0;
        let tcualarmsw = 0;
        let repalarmcomm = 0;
        let repalarmbatt = 0;
        let repeatertotales = 0;
        for (let tcuNumber = Math.min(...Object.keys(ids.tcu).map(x => +x)); tcuNumber <= Math.max(...Object.keys(ids.tcu).map(x => +x)); tcuNumber++) {
            if (isrepeater[tcuNumber] === 1){
                repeatertotales++;
                if ((alarmas[`AlarmCom_s${tcuNumber}`] && alarmas[`AlarmCom_s${tcuNumber}`][0].value > 0)) {
                    repalarmcomm++;
                }
                if ((alarmas[`AlarmCriticalBatt_s${tcuNumber}`] && alarmas[`AlarmCriticalBatt_s${tcuNumber}`][0].value > 0)
                    || (alarmas[`AlarmLowBatt_s${tcuNumber}`] && alarmas[`AlarmLowBatt_s${tcuNumber}`][0].value > 0)
                    || (alarmas[`AlarmNotEnoughBatt_s${tcuNumber}`] && alarmas[`AlarmNotEnoughBatt_s${tcuNumber}`][0].value > 0)
                    || (alarmas[`alarmLimitedBatt_s${tcuNumber}`] && alarmas[`alarmLimitedBatt_s${tcuNumber}`][0].value > 0)) {
                    repalarmbatt++;
                }
            }else{
                if ((alarmas[`AlarmCom_s${tcuNumber}`] && alarmas[`AlarmCom_s${tcuNumber}`][0].value > 0)) {
                    tcualarmcomm++;
                }
                if ((alarmas[`AlarmCriticalBatt_s${tcuNumber}`] && alarmas[`AlarmCriticalBatt_s${tcuNumber}`][0].value > 0)
                    || (alarmas[`AlarmLowBatt_s${tcuNumber}`] && alarmas[`AlarmLowBatt_s${tcuNumber}`][0].value > 0)
                    || (alarmas[`AlarmNotEnoughBatt_s${tcuNumber}`] && alarmas[`AlarmNotEnoughBatt_s${tcuNumber}`][0].value > 0)
                    || (alarmas[`alarmLimitedBatt_s${tcuNumber}`] && alarmas[`alarmLimitedBatt_s${tcuNumber}`][0].value > 0)) {
                    tcualarmbatt++;
                }
                if ((alarmas[`AlarmMotor1OverCurrentHw_s${tcuNumber}`] && alarmas[`AlarmMotor1OverCurrentHw_s${tcuNumber}`][0].value > 0)
                    || (alarmas[`AlarmMotor1OverCurrentSw_s${tcuNumber}`] && alarmas[`AlarmMotor1OverCurrentSw_s${tcuNumber}`][0].value > 0)
                    || (alarmas[`AlarmAxis1Blocked_s${tcuNumber}`] && alarmas[`AlarmAxis1Blocked_s${tcuNumber}`][0].value > 0)
                    || (alarmas[`AlarmAxis1LowSpeed_s${tcuNumber}`] && alarmas[`AlarmAxis1LowSpeed_s${tcuNumber}`][0].value > 0)) {
                    tcualarmmotor++;
                }
                if ((alarmas[`AlarmSystemMonitor_s${tcuNumber}`] && alarmas[`AlarmSystemMonitor_s${tcuNumber}`][0].value > 0)
                        || (alarmas[`AlarmTimeNeverSet_s${tcuNumber}`] && alarmas[`AlarmTimeNeverSet_s${tcuNumber}`][0].value > 0)) {
                    tcualarmsw++;
                }
                if (alarmas[`AlarmStopButton_s${tcuNumber}`] && alarmas[`AlarmStopButton_s${tcuNumber}`][0].value > 0) {
                    tcualarmstopbutton++;
                }
            }
        }
        // Obtenemos totales de alarmas de RSUs
        let rsualarmcomm = 0;
        let rsualarmsensor = 0;
        for (let rsuNumber = Math.min(...Object.keys(ids.rsu).map(x => +x)); rsuNumber <= Math.max(...Object.keys(ids.rsu).map(x => +x)); rsuNumber++) {
            if ((alarmas[`AlarmComm_Rsu${rsuNumber}`] && alarmas[`AlarmComm_Rsu${rsuNumber}`][0].value > 0)) {
                rsualarmcomm++;
            }
            if ((alarmas[`AlarmWS_Rsu${rsuNumber}`] && alarmas[`AlarmWS_Rsu${rsuNumber}`][0].value > 0)
                || (alarmas[`AlarmSS_Rsu${rsuNumber}`] && alarmas[`AlarmSS_Rsu${rsuNumber}`][0].value > 0)
                || (alarmas[`AlarmFS_Rsu${rsuNumber}`] && alarmas[`AlarmFS_Rsu${rsuNumber}`][0].value > 0)) {
                rsualarmsensor++;
            }
        }


        return {
            dataid: currentNcuId,
            ncuid: ncuName.match(/\d+/),
            ncuname: this.validate_attr(atributos.device_ShortName),
            procesador: this.validate_attr(atributos.NCUAddress),
            gateway1: this.validate_attr(atributos.Gw1IpAddress),
            gateway2: this.validate_attr(atributos.Gw2IpAddress),
            gw1tcus: this.validate_attr(atributos.Gw1TcuQuantity),
            gw2tcus: this.validate_attr(atributos.Gw2TcuQuantity),
            zm: this.validate_attr(atributos.macAddress_hex),
            sistematext: sitematext,
            plant: plant,
            project: project,
            softwarenumber: this.validate_attr(atributos.Soft_Id),
            hardwarenumber: this.validate_attr(atributos.Hard_Id),
            ncualarm: {
                // comm: +this.validateField(alarmas[`NumCommAlarmNcu`]),
                // meteo: +this.validateField(alarmas[`NumMeteoAlarmNcu`]),
                // other: +this.validateField(alarmas[`NumOtherAlarmNcu`]),
                comm: +ncualarmcomm,
                meteo: 0,
                other: +ncualarmother,
            },
            tcutotal: +(parseInt(atributos.Number_of_TCUs.value) - repeatertotales),
            tcualarm: {
                // comm: +this.validateField(alarmas[`NumCommAlarmTcuTotal`]),
                // batt: +this.validateField(alarmas[`NumBattAlarmTcuTotal`]),
                // axis: +this.validateField(alarmas[`NumAxisAlarmTcuTotal`]),
                // motor: +this.validateField(alarmas[`NumMotorAlarmTcuTotal`]),
                // stopbutton: +this.validateField(alarmas[`NumButtonAlarmTcuTotal`]),
                // sw: +this.validateField(alarmas[`NumSwAlarmTcuTotal`])
                comm: +tcualarmcomm,
                batt: +tcualarmbatt,
                axis: 0,
                motor: +tcualarmmotor,
                stopbutton: +tcualarmstopbutton,
                sw: +tcualarmsw
            },
            rsutotal: +this.validate_attr(atributos.Number_of_RSUs),
            rsualarm: {
                comm: +rsualarmcomm,
                // meteo: +this.validateField(alarmas[`NumMeteoAlarmRsuTotal`]),
                meteo: 0,
                sensor: +rsualarmsensor,
            },
            repeatertotal: repeatertotales,
            repeateralarm: {
                comm: +repalarmcomm,
                batt: +repalarmbatt
            }
        };
    }

    buildDataSidebarNcuTimemachine(planta, planta_assets, atributos, alarmas, currentNcuId) {
        const ncuName = this.getNcuName(planta_assets, currentNcuId);
        let sitematext = 0;
        if (atributos.Supplier.value === 'STI') {
            sitematext = 1;
        }
        let project = '';
        let plant = '';

        planta.forEach(function(obj, index) {
            switch (obj.key) {
                case 'Project': {
                    project = obj.value;
                    break;
                }
                case 'Display_Name': {
                    plant = obj.value;
                    break;
                }
                // case 'Supplier': {
                //     if (obj.value === 'STI') {
                //         sitematext = 1;
                //     }
                //     break;
                // }
            }
        }.bind(this));

        // tslint:disable-next-line:one-variable-per-declaration
        const ids = {tcu: {}, rsu: {}},
            isrepeater = {};
        const regExpLatLong = /(id)_(s|Rsu)(\d+)/;
        Object.keys(atributos)
            .filter(key => regExpLatLong.test(key))
            .forEach(
                key => {
                    const [, latOrLong, type, string] = key.match(regExpLatLong);
                    if (latOrLong === 'id') {
                        ids[type === 's' ? 'tcu' : 'rsu'][string] = atributos[key].value;
                    }
                }
            );
        const regExpRep = /isrepeater_s(\d+)/;
        Object.keys(atributos)
            .filter(key => regExpRep.test(key))
            .forEach(
                key => {
                    const [, number] = key.match(regExpRep);
                    isrepeater[number] = +atributos[key].value;
                }
            );
        // Obtenemos totales de alarmas de NCUs
        let ncualarmcomm = 0;
        let ncualarmother = 0;
        if ((alarmas[`Gw2Timeout`] && alarmas[`Gw2Timeout`].value > 0)
            || (alarmas[`Gw1Timeout`] && alarmas[`Gw1Timeout`].value > 0)) {
            ncualarmcomm++;
        }
        if ((alarmas[`BadConfiguration`] && alarmas[`BadConfiguration`].value > 0)
            || (alarmas[`AlarmTimeNeverSet`] && alarmas[`AlarmTimeNeverSet`].value > 0)
            || (alarmas[`Vfault`] && alarmas[`Vfault`].value > 0)) {
            ncualarmother++;
        }

        // Obtenemos totales de alarmas de TCUs y Repeaters
        let tcualarmcomm = 0;
        let tcualarmstopbutton = 0;
        let tcualarmbatt = 0;
        let tcualarmmotor = 0;
        let tcualarmsw = 0;
        let repalarmcomm = 0;
        let repalarmbatt = 0;
        let repeatertotales = 0;
        for (let tcuNumber = Math.min(...Object.keys(ids.tcu).map(x => +x)); tcuNumber <= Math.max(...Object.keys(ids.tcu).map(x => +x)); tcuNumber++) {
            if (isrepeater[tcuNumber] === 1){
                repeatertotales++;
                if ((alarmas[`AlarmCom_s${tcuNumber}`] && alarmas[`AlarmCom_s${tcuNumber}`].value > 0)) {
                    repalarmcomm++;
                }
                if ((alarmas[`AlarmCriticalBatt_s${tcuNumber}`] && alarmas[`AlarmCriticalBatt_s${tcuNumber}`].value > 0)
                    || (alarmas[`AlarmLowBatt_s${tcuNumber}`] && alarmas[`AlarmLowBatt_s${tcuNumber}`].value > 0)
                    || (alarmas[`AlarmNotEnoughBatt_s${tcuNumber}`] && alarmas[`AlarmNotEnoughBatt_s${tcuNumber}`].value > 0)
                    || (alarmas[`alarmLimitedBatt_s${tcuNumber}`] && alarmas[`alarmLimitedBatt_s${tcuNumber}`].value > 0)) {
                    repalarmbatt++;
                }
            }else{
                if ((alarmas[`AlarmCom_s${tcuNumber}`] && alarmas[`AlarmCom_s${tcuNumber}`].value > 0)) {
                    tcualarmcomm++;
                }
                if ((alarmas[`AlarmCriticalBatt_s${tcuNumber}`] && alarmas[`AlarmCriticalBatt_s${tcuNumber}`].value > 0)
                    || (alarmas[`AlarmLowBatt_s${tcuNumber}`] && alarmas[`AlarmLowBatt_s${tcuNumber}`].value > 0)
                    || (alarmas[`AlarmNotEnoughBatt_s${tcuNumber}`] && alarmas[`AlarmNotEnoughBatt_s${tcuNumber}`].value > 0)
                    || (alarmas[`alarmLimitedBatt_s${tcuNumber}`] && alarmas[`alarmLimitedBatt_s${tcuNumber}`].value > 0)) {
                    tcualarmbatt++;
                }
                if ((alarmas[`AlarmMotor1OverCurrentHw_s${tcuNumber}`] && alarmas[`AlarmMotor1OverCurrentHw_s${tcuNumber}`].value > 0)
                    || (alarmas[`AlarmMotor1OverCurrentSw_s${tcuNumber}`] && alarmas[`AlarmMotor1OverCurrentSw_s${tcuNumber}`].value > 0)
                    || (alarmas[`AlarmAxis1Blocked_s${tcuNumber}`] && alarmas[`AlarmAxis1Blocked_s${tcuNumber}`].value > 0)
                    || (alarmas[`AlarmAxis1LowSpeed_s${tcuNumber}`] && alarmas[`AlarmAxis1LowSpeed_s${tcuNumber}`].value > 0)) {
                    tcualarmmotor++;
                }
                if ((alarmas[`AlarmSystemMonitor_s${tcuNumber}`] && alarmas[`AlarmSystemMonitor_s${tcuNumber}`].value > 0)
                    || (alarmas[`AlarmTimeNeverSet_s${tcuNumber}`] && alarmas[`AlarmTimeNeverSet_s${tcuNumber}`].value > 0)) {
                    tcualarmsw++;
                }
                if (alarmas[`AlarmStopButton_s${tcuNumber}`] && alarmas[`AlarmStopButton_s${tcuNumber}`].value > 0) {
                    tcualarmstopbutton++;
                }
            }
        }
        // Obtenemos totales de alarmas de RSUs
        let rsualarmcomm = 0;
        let rsualarmsensor = 0;
        for (let rsuNumber = Math.min(...Object.keys(ids.rsu).map(x => +x)); rsuNumber <= Math.max(...Object.keys(ids.rsu).map(x => +x)); rsuNumber++) {
            if ((alarmas[`AlarmComm_Rsu${rsuNumber}`] && alarmas[`AlarmComm_Rsu${rsuNumber}`].value > 0)) {
                rsualarmcomm++;
            }
            if ((alarmas[`AlarmWS_Rsu${rsuNumber}`] && alarmas[`AlarmWS_Rsu${rsuNumber}`].value > 0)
                || (alarmas[`AlarmSS_Rsu${rsuNumber}`] && alarmas[`AlarmSS_Rsu${rsuNumber}`].value > 0)
                || (alarmas[`AlarmFS_Rsu${rsuNumber}`] && alarmas[`AlarmFS_Rsu${rsuNumber}`].value > 0)) {
                rsualarmsensor++;
            }
        }


        return {
            dataid: currentNcuId,
            ncuid: ncuName.match(/\d+/),
            ncuname: this.validate_attr(atributos.device_ShortName),
            procesador: this.validate_attr(atributos.NCUAddress),
            gateway1: this.validate_attr(atributos.Gw1IpAddress),
            gateway2: this.validate_attr(atributos.Gw2IpAddress),
            gw1tcus: this.validate_attr(atributos.Gw1TcuQuantity),
            gw2tcus: this.validate_attr(atributos.Gw2TcuQuantity),
            zm: this.validate_attr(atributos.macAddress_hex),
            sistematext: sitematext,
            plant: plant,
            project: project,
            softwarenumber: this.validate_attr(atributos.Soft_Id),
            hardwarenumber: this.validate_attr(atributos.Hard_Id),
            ncualarm: {
                // comm: +this.validateField(alarmas[`NumCommAlarmNcu`]),
                // meteo: +this.validateField(alarmas[`NumMeteoAlarmNcu`]),
                // other: +this.validateField(alarmas[`NumOtherAlarmNcu`]),
                comm: +ncualarmcomm,
                meteo: 0,
                other: +ncualarmother,
            },
            tcutotal: +(parseInt(atributos.Number_of_TCUs.value) - repeatertotales),
            tcualarm: {
                // comm: +this.validateField(alarmas[`NumCommAlarmTcuTotal`]),
                // batt: +this.validateField(alarmas[`NumBattAlarmTcuTotal`]),
                // axis: +this.validateField(alarmas[`NumAxisAlarmTcuTotal`]),
                // motor: +this.validateField(alarmas[`NumMotorAlarmTcuTotal`]),
                // stopbutton: +this.validateField(alarmas[`NumButtonAlarmTcuTotal`]),
                // sw: +this.validateField(alarmas[`NumSwAlarmTcuTotal`])
                comm: +tcualarmcomm,
                batt: +tcualarmbatt,
                axis: 0,
                motor: +tcualarmmotor,
                stopbutton: +tcualarmstopbutton,
                sw: +tcualarmsw
            },
            rsutotal: +this.validate_attr(atributos.Number_of_RSUs),
            rsualarm: {
                comm: +rsualarmcomm,
                // meteo: +this.validateField(alarmas[`NumMeteoAlarmRsuTotal`]),
                meteo: 0,
                sensor: +rsualarmsensor,
            },
            repeatertotal: repeatertotales,
            repeateralarm: {
                comm: +repalarmcomm,
                batt: +repalarmbatt
            }
        };
    }

    validate_attr(field) {
        if (field !== undefined) {
            return field.value;
        }
        return 0;
    }


    buildDataFooterPlantaOld(atributos, alarmas) {

        let NumCommAlarmNcuTotalPlanta = 0;
        if (alarmas.hasOwnProperty('NumCommAlarmNcuTotalPlanta')) {
            NumCommAlarmNcuTotalPlanta = alarmas.NumCommAlarmNcuTotalPlanta[0].value;
        }
        // let NumMeteoAlarmNcuTotalPlanta = 0;
        // if (alarmas.hasOwnProperty('NumMeteoAlarmNcuTotalPlanta')) {
        //     NumMeteoAlarmNcuTotalPlanta = alarmas['NumMeteoAlarmNcuTotalPlanta'][0]['value'];
        // }
        let NumOtherAlarmNcuTotalPlanta = 0;
        if (alarmas.hasOwnProperty('NumOtherAlarmNcuTotalPlanta')) {
            NumOtherAlarmNcuTotalPlanta = alarmas.NumOtherAlarmNcuTotalPlanta[0].value;
        }
        let NumCommAlarmTcuTotalPlanta = 0;
        if (alarmas.hasOwnProperty('NumCommAlarmTcuTotalPlanta')) {
            NumCommAlarmTcuTotalPlanta = alarmas.NumCommAlarmTcuTotalPlanta[0].value;
        }
        let NumBattAlarmTcuTotalPlanta = 0;
        if (alarmas.hasOwnProperty('NumBattAlarmTcuTotalPlanta')) {
            NumBattAlarmTcuTotalPlanta = alarmas.NumBattAlarmTcuTotalPlanta[0].value;
        }
        let NumMotorAlarmTcuTotalPlanta = 0;
        if (alarmas.hasOwnProperty('NumMotorAlarmTcuTotalPlanta')) {
            NumMotorAlarmTcuTotalPlanta = alarmas.NumMotorAlarmTcuTotalPlanta[0].value;
        }
        // let NumAxisAlarmTcuTotalPlanta = 0;
        // if (alarmas.hasOwnProperty('NumAxisAlarmTcuTotalPlanta')) {
        //     NumAxisAlarmTcuTotalPlanta = alarmas['NumAxisAlarmTcuTotalPlanta'][0]['value'];
        // }
        let NumSwAlarmTcuTotalPlanta = 0;
        if (alarmas.hasOwnProperty('NumSwAlarmTcuTotalPlanta')) {
            NumSwAlarmTcuTotalPlanta = alarmas.NumSwAlarmTcuTotalPlanta[0].value;
        }
        let NumButtonAlarmTcuTotalPlanta = 0;
        if (alarmas.hasOwnProperty('NumButtonAlarmTcuTotalPlanta')) {
            NumButtonAlarmTcuTotalPlanta = alarmas.NumButtonAlarmTcuTotalPlanta[0].value;
        }
        let NumCommAlarmRsuTotalPlanta = 0;
        if (alarmas.hasOwnProperty('NumCommAlarmRsuTotalPlanta')) {
            NumCommAlarmRsuTotalPlanta = alarmas.NumCommAlarmRsuTotalPlanta[0].value;
        }
        // let NumMeteoAlarmRsuTotalPlanta = 0;
        // if (alarmas.hasOwnProperty('NumMeteoAlarmRsuTotalPlanta')) {
        //     NumMeteoAlarmRsuTotalPlanta = alarmas['NumMeteoAlarmRsuTotalPlanta'][0]['value'];
        // }
        let NumSensorAlarmRsuTotalPlanta = 0;
        if (alarmas.hasOwnProperty('NumSensorAlarmRsuTotalPlanta')) {
            NumSensorAlarmRsuTotalPlanta = alarmas.NumSensorAlarmRsuTotalPlanta[0].value;
        }

        return {
            ncutotal: +atributos.Number_of_Ncu.value,
            ncualarm: [
                {
                    rojo: +NumCommAlarmNcuTotalPlanta || 0,
                    orange: 0,
                    yellow: 0,
                    green: 0,
                    blue: 0,
                    morado: +NumOtherAlarmNcuTotalPlanta || 0,
                    grey: 0
                }
            ],
            tcutotal: +atributos.Number_of_Tcu.value,
            tcualarm: [
                {
                    rojo: +NumCommAlarmTcuTotalPlanta || 0,
                    orange: +NumBattAlarmTcuTotalPlanta || 0,
                    yellow: +NumMotorAlarmTcuTotalPlanta || 0,
                    green: +NumButtonAlarmTcuTotalPlanta || 0,
                    blue: 0,
                    morado: 0,
                    grey: +NumSwAlarmTcuTotalPlanta || 0
                }
            ],
            rsutotal: +atributos.Number_of_Rsu.value,
            rsualarm: [
                {
                    rojo: +NumCommAlarmRsuTotalPlanta || 0,
                    orange: 0,
                    yellow: 0,
                    green: 0,
                    blue: +NumSensorAlarmRsuTotalPlanta || 0,
                    morado: 0,
                    grey: 0
                }
            ],
            power: +atributos.Power.value
        };
    }

    buildDataFooterPlanta( tableData, datafooter, origen = 0) {
        let NumCommAlarmNcuTotalPlanta = 0;
        let NumOtherAlarmNcuTotalPlanta = 0;
        let NumCommAlarmTcuTotalPlanta = 0;
        let NumBattAlarmTcuTotalPlanta = 0;
        let NumMotorAlarmTcuTotalPlanta = 0;
        let NumSwAlarmTcuTotalPlanta = 0;
        let NumButtonAlarmTcuTotalPlanta = 0;
        let NumCommAlarmRsuTotalPlanta = 0;
        let NumSensorAlarmRsuTotalPlanta = 0;

        for (const i in tableData) {
            if (tableData[i].alarmas) {
                if ((tableData[i].alarmas.Gw2Timeout && tableData[i].alarmas.Gw2Timeout > 0)
                    || (tableData[i].alarmas.Gw1Timeout && tableData[i].alarmas.Gw1Timeout > 0)) {
                    NumCommAlarmNcuTotalPlanta++;
                }
                if ((tableData[i].alarmas.BadConfiguration && tableData[i].alarmas.BadConfiguration > 0)
                    || (tableData[i].alarmas.AlarmTimeNeverSet && tableData[i].alarmas.AlarmTimeNeverSet > 0)
                    || (tableData[i].alarmas.Vfault && tableData[i].alarmas.Vfault > 0)) {
                    NumOtherAlarmNcuTotalPlanta++;
                }
                if ((tableData[i].alarmas.alarmcomm && tableData[i].alarmas.alarmcomm > 0)) {
                    NumCommAlarmTcuTotalPlanta++;
                }
                if ((tableData[i].alarmas.alarmcriticalbat && tableData[i].alarmas.alarmcriticalbat > 0)
                    || (tableData[i].alarmas.alarmlowbat && tableData[i].alarmas.alarmlowbat > 0)
                    || (tableData[i].alarmas.alarmnotbat && tableData[i].alarmas.alarmnotbat > 0)
                    || (tableData[i].alarmas.alarmlimbat && tableData[i].alarmas.alarmlimbat > 0)) {
                    NumBattAlarmTcuTotalPlanta++;
                }
                if (((tableData[i].alarmas.alarmmotorhw && tableData[i].alarmas.alarmmotorhw > 0)
                    || (tableData[i].alarmas.alarmmotorsw && tableData[i].alarmas.alarmmotorsw > 0)
                    || (tableData[i].alarmas.alarmaxisblock && tableData[i].alarmas.alarmaxisblock > 0)
                    || (tableData[i].alarmas.armaxislowspeed && tableData[i].alarmas.armaxislowspeed > 0))
                    && tableData[i].isrepeater !== 1) {
                    NumMotorAlarmTcuTotalPlanta++;
                }
                if (((tableData[i].alarmas.alarmsystemmon && tableData[i].alarmas.alarmsystemmon > 0)
                    || (tableData[i].alarmas.alarmtimeneverset && tableData[i].alarmas.alarmtimeneverset > 0))
                    && tableData[i].isrepeater !== 1) {
                    NumSwAlarmTcuTotalPlanta++;
                }
                if (((tableData[i].alarmas.alarmstopb && tableData[i].alarmas.alarmstopb > 0))
                    && tableData[i].isrepeater !== 1) {
                    NumButtonAlarmTcuTotalPlanta++;
                }
                if ((tableData[i].alarmas.alarmcommrsu && tableData[i].alarmas.alarmcommrsu > 0)) {
                    NumCommAlarmRsuTotalPlanta++;
                }
                if ((tableData[i].alarmas.alarmwsrsu && tableData[i].alarmas.alarmwsrsu > 0)
                    || (tableData[i].alarmas.alarmssrsu && tableData[i].alarmas.alarmssrsu > 0)
                    || (tableData[i].alarmas.alarmfsrsu && tableData[i].alarmas.alarmfsrsu > 0)) {
                    NumSensorAlarmRsuTotalPlanta++;
                }
            }
        }

        return {
            ncutotal: +datafooter.ncutotal || 0,
            ncualarm: [
                {
                    rojo: +NumCommAlarmNcuTotalPlanta || 0,
                    orange: 0,
                    yellow: 0,
                    green: 0,
                    blue: 0,
                    morado: +NumOtherAlarmNcuTotalPlanta || 0,
                    grey: 0
                }
            ],
            tcutotal: +datafooter.tcutotal || 0,
            tcualarm: [
                {
                    rojo: +NumCommAlarmTcuTotalPlanta || 0,
                    orange: +NumBattAlarmTcuTotalPlanta || 0,
                    yellow: +NumMotorAlarmTcuTotalPlanta || 0,
                    green: +NumButtonAlarmTcuTotalPlanta || 0,
                    blue: 0,
                    morado: 0,
                    grey: +NumSwAlarmTcuTotalPlanta || 0
                }
            ],
            rsutotal: +datafooter.rsutotal || 0,
            rsualarm: [
                {
                    rojo: +NumCommAlarmRsuTotalPlanta || 0,
                    orange: 0,
                    yellow: 0,
                    green: 0,
                    blue: +NumSensorAlarmRsuTotalPlanta || 0,
                    morado: 0,
                    grey: 0
                }
            ],
            power: +datafooter.power || 0,
        };
    }

    getDeviceNumbers(atributos) {
        const deviceNumbers = {tcu: [], rsu: []};

        const regExpLatLong = /lat_(s|Rsu)(\d+)/;
        Object.keys(atributos)
            .filter(key => regExpLatLong.test(key))
            .forEach(
                key => {
                    const [, type, number] = key.match(regExpLatLong);
                    deviceNumbers[type === 's' ? 'tcu' : type === 'Rsu' ? 'rsu' : 'errors'].push(+number);
                }
            );

        return deviceNumbers;
    }

    getPosicionesCambioGrupo(atributos, deviceNumbers) {
        const groupTcuQuantities = {};
        const regExpGroup = /Group(\d+)TcuQuantity/;
        Object.keys(atributos)
            .filter(key => regExpGroup.test(key))
            .forEach(
                key => {
                    const [, groupNumber] = key.match(regExpGroup);
                    Object.assign(groupTcuQuantities, {
                        [groupNumber]: +atributos[key].value
                    });
                }
            );

        const posicionesCambioDeGrupo = {};
        const tcuNumberRange = {first: Math.min(...deviceNumbers.tcu), last: Math.max(...deviceNumbers.tcu)};
        for (const groupNumber of Object.keys(groupTcuQuantities)) {
            posicionesCambioDeGrupo[groupNumber] = {
                first: null,
                last: null
            };
            posicionesCambioDeGrupo[groupNumber].first =
                (+groupNumber !== 1) ? posicionesCambioDeGrupo[+groupNumber - 1].last + 1 : tcuNumberRange.first;
            posicionesCambioDeGrupo[groupNumber].last =
                posicionesCambioDeGrupo[groupNumber].first + groupTcuQuantities[groupNumber] - 1;
            if (posicionesCambioDeGrupo[groupNumber].last === tcuNumberRange.last) {
                break;
            }
        }

        return posicionesCambioDeGrupo;
    }

    validateField(field) {
        if (field !== undefined) {
            if (field[0] !== undefined) {
                return field[0].value;
            }else if (field !== undefined) {
                return field.value;
            }
        }
        return 0;
    }

    validateFieldTs(field) {
        if (field !== undefined) {
            if (field[0] !== undefined) {
                return field[0].ts;
            }else if(field !== undefined){
                return field.ts;
            }
        }
        return 0;
    }

    getMarkerData(planta_assets, atributos, alarmas, currentPlantaId, currentNcuId) {
        const markerData = [];
        const ncuName = this.getNcuName(planta_assets, currentNcuId);
        const ncuNumber = +ncuName.match(/\d+/);
        let ncu_yellow = 0;
        let ncu_morado = 0;
        if ((alarmas[`Gw2Timeout`] && alarmas[`Gw2Timeout`][0].value > 0 )
            || (alarmas[`Gw1Timeout`] && alarmas[`Gw1Timeout`][0].value > 0 )){
            ncu_yellow = 1;
        }
        if ((alarmas[`BadConfiguration`] && alarmas[`BadConfiguration`][0].value > 0)
            || (alarmas[`AlarmTimeNeverSet`] && alarmas[`AlarmTimeNeverSet`][0].value > 0)
            || (alarmas[`Vfault`] && alarmas[`Vfault`][0].value > 0)){
            ncu_morado = 1;
        }
        markerData.push({
            name: this.cambiarNombreTcU(ncuName),
            number: ncuNumber,
            tipo: 'ncu',
            lat: +atributos.lat.value,
            long: +atributos.long.value,
            rot: null,
            plantaid: currentPlantaId,
            dataid: currentNcuId,
            alarmas: {
                // 'ncublue': +this.validateField(alarmas[`NumMeteoAlarmNcu`]) || 0,  // meteo
                ncumorado: ncu_morado, // other
                ncuyellow: ncu_yellow, // comm
                Gw2Timeout: +this.validateField(alarmas[`Gw2Timeout`]),
                Gw1Timeout: +this.validateField(alarmas[`Gw1Timeout`]),
                BadConfiguration: +this.validateField(alarmas[`BadConfiguration`]),
                AlarmTimeNeverSet: +this.validateField(alarmas[`AlarmTimeNeverSet`]),
                Vfault: +this.validateField(alarmas[`Vfault`]),
                alarmcomm: 0,
                alarmsystemmon: 0,
                alarmtimeneverset: 0,
                alarmstopb: 0,
                alarmcriticalbat: 0,
                alarmlowbat: 0,
                alarmnotbat: 0,
                alarmlimbat: 0,
                alarmmotorhw: 0,
                alarmmotorsw: 0,
                alarmaxisblock: 0,
                armaxislowspeed: 0,
                alarmcommrsu: 0,
                alarmwsrsu: 0,
                alarmssrsu: 0,
                alarmfsrsu: 0,
            },
            estado: (atributos.active) ? atributos.active.value : 0,
            grupo: null,
            isrepeater: 0,
            trackername: null,
            binding: 0,
        });

        const deviceNumbers = this.getDeviceNumbers(atributos);
        const posicionesCambioDeGrupo = this.getPosicionesCambioGrupo(atributos, deviceNumbers);
        for (let tcuNumber = Math.min(...deviceNumbers.tcu); tcuNumber <= Math.max(...deviceNumbers.tcu); tcuNumber++) {
            let batt_tcu = 0;
            let comm_tcu = 0;
            let motor_tcu = 0;
            let stopbutton_tcu = 0;
            let sw_tcu = 0;
            if ((alarmas[`AlarmSystemMonitor_s`+tcuNumber] && alarmas[`AlarmSystemMonitor_s`+tcuNumber][0].value > 0)
                || (alarmas[`AlarmTimeNeverSet_s`+tcuNumber] && alarmas[`AlarmTimeNeverSet_s`+tcuNumber][0].value > 0)){
                sw_tcu = 1;
            }
            if ((alarmas[`AlarmCom_s`+tcuNumber] && alarmas[`AlarmCom_s`+tcuNumber][0].value > 0)){
                comm_tcu = 1;
            }
            if ((alarmas[`AlarmCriticalBatt_s`+tcuNumber] && alarmas[`AlarmCriticalBatt_s`+tcuNumber][0].value > 0)
                || (alarmas[`AlarmLowBatt_s`+tcuNumber] && alarmas[`AlarmLowBatt_s`+tcuNumber][0].value > 0)
                || (alarmas[`AlarmNotEnoughBatt_s`+tcuNumber] && alarmas[`AlarmNotEnoughBatt_s`+tcuNumber][0].value > 0)
                || (alarmas[`alarmLimitedBatt_s`+tcuNumber] && alarmas[`alarmLimitedBatt_s`+tcuNumber][0].value > 0)){
                batt_tcu = 1;
            }
            if ((alarmas[`AlarmMotor1OverCurrentHw_s`+tcuNumber] && alarmas[`AlarmMotor1OverCurrentHw_s`+tcuNumber][0].value > 0)
                || (alarmas[`AlarmMotor1OverCurrentSw_s`+tcuNumber] && alarmas[`AlarmMotor1OverCurrentSw_s`+tcuNumber][0].value > 0)
                || (alarmas[`AlarmAxis1Blocked_s`+tcuNumber] && alarmas[`AlarmAxis1Blocked_s`+tcuNumber][0].value > 0)
                || (alarmas[`AlarmAxis1LowSpeed_s`+tcuNumber] && alarmas[`AlarmAxis1LowSpeed_s`+tcuNumber][0].value > 0)){
                motor_tcu = 1;
            }
            if ((alarmas[`AlarmStopButton_s`+tcuNumber] && alarmas[`AlarmStopButton_s`+tcuNumber][0].value > 0)){
                stopbutton_tcu = 1;
            }

            let tipotcu = 'tcu';
            let rottcu = this.validateField(alarmas[`Position_a1_deg_s${tcuNumber}`])
            if (atributos[`isrepeater_s${tcuNumber}`] &&  atributos[`isrepeater_s${tcuNumber}`].value === 1){
                tipotcu = 'repeater';
                rottcu = 0;
            }

            markerData.push(
                {
                    name: this.cambiarNombreTcU(atributos[`name_s${tcuNumber}`].value, 'tcu'),
                    number: null,
                    lat: atributos[`lat_s${tcuNumber}`].value,
                    long: atributos[`long_s${tcuNumber}`].value,
                    rot: rottcu,
                    tipo: tipotcu,
                    plantaid: currentPlantaId,
                    dataid: atributos[`id_s${tcuNumber}`].value,
                    alarmas: {
                        // 'axis': +this.validateField(alarmas[`NumAxisAlarm_s${tcuNumber}`]) || 0,
                        batt: batt_tcu,
                        comm: comm_tcu,
                        motor: motor_tcu,
                        stopbutton: stopbutton_tcu,
                        sw: sw_tcu,
                        Gw2Timeout: 0,
                        Gw1Timeout: 0,
                        BadConfiguration: 0,
                        AlarmTimeNeverSet: 0,
                        Vfault: 0,
                        alarmcomm: +this.validateField(alarmas[`AlarmCom_s${tcuNumber}`]),
                        alarmsystemmon: +this.validateField(alarmas[`AlarmSystemMonitor_s${tcuNumber}`]),
                        alarmtimeneverset: +this.validateField(alarmas[`AlarmTimeNeverSet_s${tcuNumber}`]),
                        alarmstopb: +this.validateField(alarmas[`AlarmStopButton_s${tcuNumber}`]) || 0,
                        alarmcriticalbat: +this.validateField(alarmas[`AlarmCriticalBatt_s${tcuNumber}`]),
                        alarmlowbat: +this.validateField(alarmas[`AlarmLowBatt_s${tcuNumber}`]),
                        alarmnotbat: +this.validateField(alarmas[`AlarmNotEnoughBatt_s${tcuNumber}`]),
                        alarmlimbat: +this.validateField(alarmas[`alarmLimitedBatt_s${tcuNumber}`]),
                        alarmmotorhw: +this.validateField(alarmas[`AlarmMotor1OverCurrentHw_s${tcuNumber}`]),
                        alarmmotorsw: +this.validateField(alarmas[`AlarmMotor1OverCurrentSw_s${tcuNumber}`]),
                        alarmaxisblock: +this.validateField(alarmas[`AlarmAxis1Blocked_s${tcuNumber}`]),
                        armaxislowspeed: +this.validateField(alarmas[`AlarmAxis1LowSpeed_s${tcuNumber}`]),
                        alarmcommrsu: 0,
                        alarmwsrsu: 0,
                        alarmssrsu: 0,
                        alarmfsrsu: 0,
                    },
                    estado: {
                        mainstate: +this.validateField(alarmas[`MainState_s${tcuNumber}`]),
                        safepositionstate: +this.validateField(alarmas[`SafePositionState_s${tcuNumber}`]),
                        bt_active: +this.validateField(alarmas[`BT_Active_s${tcuNumber}`]),
                    },
                    grupo: (atributos[`isrepeater_s${tcuNumber}`] && atributos[`isrepeater_s${tcuNumber}`].value === 1) ? null : this.assignTcuGroup(tcuNumber, posicionesCambioDeGrupo),
                    ncu: (atributos.active) ? atributos.active.value : 0,
                    trackername: (atributos[`isrepeater_s${tcuNumber}`] && atributos[`isrepeater_s${tcuNumber}`].value === 1) ? null : atributos[`Tracker_Name_s${tcuNumber}`].value,
                    binding: (atributos[`binding_s${tcuNumber}`]) ? atributos[`binding_s${tcuNumber}`].value : 0,
                    isrepeater: (atributos[`isrepeater_s${tcuNumber}`]) ? atributos[`isrepeater_s${tcuNumber}`].value : 0,
                    zeroset: (atributos[`zeroSet_s${tcuNumber}`]) ? atributos[`zeroSet_s${tcuNumber}`].value : 0,
                }
            );

        }
        for (let rsuNumber = Math.min(...deviceNumbers.rsu); rsuNumber <= Math.max(...deviceNumbers.rsu); rsuNumber++) {
            const rsuName = this.cambiarNombreTcU(atributos[`name_Rsu${rsuNumber}`].value, 'rsu');
            const rsuNameNumber = +rsuName.match(/\d+/);
            let atributosactive_Rsu = 0;
            if (atributos[`active_Rsu${rsuNumber}`] !== undefined && atributos[`active_Rsu${rsuNumber}`].hasOwnProperty('value')) {
                atributosactive_Rsu = atributos[`active_Rsu${rsuNumber}`].value;
            }
            let sensor_rsu = 0;
            let comm_rsu = 0;

            if ((alarmas[`AlarmComm_Rsu`+rsuNumber] && alarmas[`AlarmComm_Rsu`+rsuNumber][0].value > 0)){
                comm_rsu = 1;
            }
            if ((alarmas[`AlarmWS_Rsu`+rsuNumber] && alarmas[`AlarmWS_Rsu`+rsuNumber][0].value > 0)
                || (alarmas[`AlarmSS_Rsu`+rsuNumber] && alarmas[`AlarmSS_Rsu`+rsuNumber][0].value > 0)
                || (alarmas[`AlarmFS_Rsu`+rsuNumber] && alarmas[`AlarmFS_Rsu`+rsuNumber][0].value > 0)){
                sensor_rsu = 1;
            }
            markerData.push(
                {
                    name: this.cambiarNombreTcU(atributos[`name_Rsu${rsuNumber}`].value, 'rsu'),
                    number: rsuNameNumber,
                    lat: atributos[`lat_Rsu${rsuNumber}`].value,
                    long: atributos[`long_Rsu${rsuNumber}`].value,
                    rot: null,
                    tipo: 'rsu',
                    plantaid: currentNcuId,
                    dataid: atributos[`id_Rsu${rsuNumber}`].value,
                    alarmas: {
                        comm: comm_rsu,
                        sensor: sensor_rsu,
                        // 'meteo': +this.validateField(alarmas[`NumMeteoAlarm_Rsu${rsuNumber}`]) || 0,
                        Gw2Timeout: 0,
                        Gw1Timeout: 0,
                        BadConfiguration: 0,
                        AlarmTimeNeverSet: 0,
                        Vfault: 0,
                        alarmcomm: 0,
                        alarmsystemmon: 0,
                        alarmtimeneverset: 0,
                        alarmstopb: 0,
                        alarmcriticalbat: 0,
                        alarmlowbat: 0,
                        alarmnotbat: 0,
                        alarmlimbat: 0,
                        alarmmotorhw: 0,
                        alarmmotorsw: 0,
                        alarmaxisblock: 0,
                        armaxislowspeed: 0,
                        alarmcommrsu: +this.validateField(alarmas[`AlarmComm_Rsu${rsuNumber}`]),
                        alarmwsrsu: +this.validateField(alarmas[`AlarmWS_Rsu${rsuNumber}`]),
                        alarmssrsu: +this.validateField(alarmas[`AlarmSS_Rsu${rsuNumber}`]),
                        alarmfsrsu: +this.validateField(alarmas[`AlarmFS_Rsu${rsuNumber}`]),
                    },
                    estado: atributosactive_Rsu,
                    grupo: null,
                    isrepeater: 0,
                    ncu: (atributos.active) ? atributos.active.value : 0,
                    trackername: null,
                }
            );
        }
        return markerData;
    }

    getMarkerDataTimeMachine(planta_assets, atributos, alarmas, currentPlantaId, currentNcuId) {

        const markerData = [];
        const ncuName = this.getNcuName(planta_assets, currentNcuId);
        const ncuNumber = +ncuName.match(/\d+/);
        let ncu_yellow = 0;
        let ncu_morado = 0;
        if ((alarmas[`Gw2Timeout`] && alarmas[`Gw2Timeout`].value > 0 )
            || (alarmas[`Gw1Timeout`] && alarmas[`Gw1Timeout`].value > 0 )){
            ncu_yellow = 1;
        }
        if ((alarmas[`BadConfiguration`] && alarmas[`BadConfiguration`].value > 0)
            || (alarmas[`AlarmTimeNeverSet`] && alarmas[`AlarmTimeNeverSet`].value > 0)
            || (alarmas[`Vfault`] && alarmas[`Vfault`].value > 0)){
            ncu_morado = 1;
        }
        markerData.push({
            name: this.cambiarNombreTcU(ncuName),
            number: ncuNumber,
            tipo: 'ncu',
            lat: +atributos.lat.value,
            long: +atributos.long.value,
            rot: null,
            plantaid: currentPlantaId,
            dataid: currentNcuId,
            alarmas: {
                // 'ncublue': +this.validateField(alarmas[`NumMeteoAlarmNcu`]) || 0,  // meteo
                ncumorado: ncu_morado, // other
                ncuyellow: ncu_yellow, // comm
                Gw2Timeout: +this.validateField(alarmas[`Gw2Timeout`]),
                Gw1Timeout: +this.validateField(alarmas[`Gw1Timeout`]),
                BadConfiguration: +this.validateField(alarmas[`BadConfiguration`]),
                AlarmTimeNeverSet: +this.validateField(alarmas[`AlarmTimeNeverSet`]),
                Vfault: +this.validateField(alarmas[`Vfault`]),
                alarmcomm: 0,
                alarmsystemmon: 0,
                alarmtimeneverset: 0,
                alarmstopb: 0,
                alarmcriticalbat: 0,
                alarmlowbat: 0,
                alarmnotbat: 0,
                alarmlimbat: 0,
                alarmmotorhw: 0,
                alarmmotorsw: 0,
                alarmaxisblock: 0,
                armaxislowspeed: 0,
                alarmcommrsu: 0,
                alarmwsrsu: 0,
                alarmssrsu: 0,
                alarmfsrsu: 0,
            },
            estado: (atributos.active) ? atributos.active.value : 0,
            grupo: null,
            isrepeater: 0,
            trackername: null,
            binding: 0,
        });

        const deviceNumbers = this.getDeviceNumbers(atributos);
        const posicionesCambioDeGrupo = this.getPosicionesCambioGrupo(atributos, deviceNumbers);
        for (let tcuNumber = Math.min(...deviceNumbers.tcu); tcuNumber <= Math.max(...deviceNumbers.tcu); tcuNumber++) {
            let batt_tcu = 0;
            let comm_tcu = 0;
            let motor_tcu = 0;
            let stopbutton_tcu = 0;
            let sw_tcu = 0;
            if ((alarmas[`AlarmSystemMonitor_s`+tcuNumber] && alarmas[`AlarmSystemMonitor_s`+tcuNumber].value > 0)
                || (alarmas[`AlarmTimeNeverSet_s`+tcuNumber] && alarmas[`AlarmTimeNeverSet_s`+tcuNumber].value > 0)){
                sw_tcu = 1;
            }
            if ((alarmas[`AlarmCom_s`+tcuNumber] && alarmas[`AlarmCom_s`+tcuNumber].value > 0)){
                comm_tcu = 1;
            }
            if ((alarmas[`AlarmCriticalBatt_s`+tcuNumber] && alarmas[`AlarmCriticalBatt_s`+tcuNumber].value > 0)
                || (alarmas[`AlarmLowBatt_s`+tcuNumber] && alarmas[`AlarmLowBatt_s`+tcuNumber].value > 0)
                || (alarmas[`AlarmNotEnoughBatt_s`+tcuNumber] && alarmas[`AlarmNotEnoughBatt_s`+tcuNumber].value > 0)
                || (alarmas[`alarmLimitedBatt_s`+tcuNumber] && alarmas[`alarmLimitedBatt_s`+tcuNumber].value > 0)){
                batt_tcu = 1;
            }
            if ((alarmas[`AlarmMotor1OverCurrentHw_s`+tcuNumber] && alarmas[`AlarmMotor1OverCurrentHw_s`+tcuNumber].value > 0)
                || (alarmas[`AlarmMotor1OverCurrentSw_s`+tcuNumber] && alarmas[`AlarmMotor1OverCurrentSw_s`+tcuNumber].value > 0)
                || (alarmas[`AlarmAxis1Blocked_s`+tcuNumber] && alarmas[`AlarmAxis1Blocked_s`+tcuNumber].value > 0)
                || (alarmas[`AlarmAxis1LowSpeed_s`+tcuNumber] && alarmas[`AlarmAxis1LowSpeed_s`+tcuNumber].value > 0)){
                motor_tcu = 1;
            }
            if ((alarmas[`AlarmStopButton_s`+tcuNumber] && alarmas[`AlarmStopButton_s`+tcuNumber].value > 0)){
                stopbutton_tcu = 1;
            }

            let tipotcu = 'tcu';
            let rottcu = this.validateField(alarmas[`Position_a1_deg_s${tcuNumber}`]);
            if (atributos[`isrepeater_s${tcuNumber}`] &&  atributos[`isrepeater_s${tcuNumber}`].value === 1){
                tipotcu = 'repeater';
                rottcu = 0;
            }

            markerData.push(
                {
                    name: this.cambiarNombreTcU(atributos[`name_s${tcuNumber}`].value, 'tcu'),
                    number: null,
                    lat: atributos[`lat_s${tcuNumber}`].value,
                    long: atributos[`long_s${tcuNumber}`].value,
                    rot: rottcu,
                    tipo: tipotcu,
                    plantaid: currentPlantaId,
                    dataid: atributos[`id_s${tcuNumber}`].value,
                    alarmas: {
                        // 'axis': +this.validateField(alarmas[`NumAxisAlarm_s${tcuNumber}`]) || 0,
                        batt: batt_tcu,
                        comm: comm_tcu,
                        motor: motor_tcu,
                        stopbutton: stopbutton_tcu,
                        sw: sw_tcu,
                        Gw2Timeout: 0,
                        Gw1Timeout: 0,
                        BadConfiguration: 0,
                        AlarmTimeNeverSet: 0,
                        Vfault: 0,
                        alarmcomm: +this.validateField(alarmas[`AlarmCom_s${tcuNumber}`]),
                        alarmsystemmon: +this.validateField(alarmas[`AlarmSystemMonitor_s${tcuNumber}`]),
                        alarmtimeneverset: +this.validateField(alarmas[`AlarmTimeNeverSet_s${tcuNumber}`]),
                        alarmstopb: +this.validateField(alarmas[`AlarmStopButton_s${tcuNumber}`]) || 0,
                        alarmcriticalbat: +this.validateField(alarmas[`AlarmCriticalBatt_s${tcuNumber}`]),
                        alarmlowbat: +this.validateField(alarmas[`AlarmLowBatt_s${tcuNumber}`]),
                        alarmnotbat: +this.validateField(alarmas[`AlarmNotEnoughBatt_s${tcuNumber}`]),
                        alarmlimbat: +this.validateField(alarmas[`alarmLimitedBatt_s${tcuNumber}`]),
                        alarmmotorhw: +this.validateField(alarmas[`AlarmMotor1OverCurrentHw_s${tcuNumber}`]),
                        alarmmotorsw: +this.validateField(alarmas[`AlarmMotor1OverCurrentSw_s${tcuNumber}`]),
                        alarmaxisblock: +this.validateField(alarmas[`AlarmAxis1Blocked_s${tcuNumber}`]),
                        armaxislowspeed: +this.validateField(alarmas[`AlarmAxis1LowSpeed_s${tcuNumber}`]),
                        alarmcommrsu: 0,
                        alarmwsrsu: 0,
                        alarmssrsu: 0,
                        alarmfsrsu: 0,
                    },
                    estado: {
                        mainstate: +this.validateField(alarmas[`MainState_s${tcuNumber}`]),
                        safepositionstate: +this.validateField(alarmas[`SafePositionState_s${tcuNumber}`]),
                        bt_active: +this.validateField(alarmas[`BT_Active_s${tcuNumber}`]),
                    },
                    grupo: (atributos[`isrepeater_s${tcuNumber}`] && atributos[`isrepeater_s${tcuNumber}`].value === 1) ? null : this.assignTcuGroup(tcuNumber, posicionesCambioDeGrupo),
                    ncu: (atributos.active) ? atributos.active.value : 0,
                    trackername: (atributos[`isrepeater_s${tcuNumber}`] && atributos[`isrepeater_s${tcuNumber}`].value === 1) ? null : atributos[`Tracker_Name_s${tcuNumber}`].value,
                    binding: (atributos[`binding_s${tcuNumber}`]) ? atributos[`binding_s${tcuNumber}`].value : 0,
                    isrepeater: (atributos[`isrepeater_s${tcuNumber}`]) ? atributos[`isrepeater_s${tcuNumber}`].value : 0,
                    zeroset: (atributos[`zeroSet_s${tcuNumber}`]) ? atributos[`zeroSet_s${tcuNumber}`].value : 0,
                }
            );

        }
        for (let rsuNumber = Math.min(...deviceNumbers.rsu); rsuNumber <= Math.max(...deviceNumbers.rsu); rsuNumber++) {
            const rsuName = this.cambiarNombreTcU(atributos[`name_Rsu${rsuNumber}`].value, 'rsu');
            const rsuNameNumber = +rsuName.match(/\d+/);
            let atributosactive_Rsu = 0;
            if (atributos[`active_Rsu${rsuNumber}`] !== undefined && atributos[`active_Rsu${rsuNumber}`].hasOwnProperty('value')) {
                atributosactive_Rsu = atributos[`active_Rsu${rsuNumber}`].value;
            }
            let sensor_rsu = 0;
            let comm_rsu = 0;

            if ((alarmas[`AlarmComm_Rsu`+rsuNumber] && alarmas[`AlarmComm_Rsu`+rsuNumber].value > 0)){
                comm_rsu = 1;
            }
            if ((alarmas[`AlarmWS_Rsu`+rsuNumber] && alarmas[`AlarmWS_Rsu`+rsuNumber].value > 0)
                || (alarmas[`AlarmSS_Rsu`+rsuNumber] && alarmas[`AlarmSS_Rsu`+rsuNumber].value > 0)
                || (alarmas[`AlarmFS_Rsu`+rsuNumber] && alarmas[`AlarmFS_Rsu`+rsuNumber].value > 0)){
                sensor_rsu = 1;
            }
            markerData.push(
                {
                    name: this.cambiarNombreTcU(atributos[`name_Rsu${rsuNumber}`].value, 'rsu'),
                    number: rsuNameNumber,
                    lat: atributos[`lat_Rsu${rsuNumber}`].value,
                    long: atributos[`long_Rsu${rsuNumber}`].value,
                    rot: null,
                    tipo: 'rsu',
                    plantaid: currentNcuId,
                    dataid: atributos[`id_Rsu${rsuNumber}`].value,
                    alarmas: {
                        comm: comm_rsu,
                        sensor: sensor_rsu,
                        // 'meteo': +this.validateField(alarmas[`NumMeteoAlarm_Rsu${rsuNumber}`]) || 0,
                        Gw2Timeout: 0,
                        Gw1Timeout: 0,
                        BadConfiguration: 0,
                        AlarmTimeNeverSet: 0,
                        Vfault: 0,
                        alarmcomm: 0,
                        alarmsystemmon: 0,
                        alarmtimeneverset: 0,
                        alarmstopb: 0,
                        alarmcriticalbat: 0,
                        alarmlowbat: 0,
                        alarmnotbat: 0,
                        alarmlimbat: 0,
                        alarmmotorhw: 0,
                        alarmmotorsw: 0,
                        alarmaxisblock: 0,
                        armaxislowspeed: 0,
                        alarmcommrsu: +this.validateField(alarmas[`AlarmComm_Rsu${rsuNumber}`]),
                        alarmwsrsu: +this.validateField(alarmas[`AlarmWS_Rsu${rsuNumber}`]),
                        alarmssrsu: +this.validateField(alarmas[`AlarmSS_Rsu${rsuNumber}`]),
                        alarmfsrsu: +this.validateField(alarmas[`AlarmFS_Rsu${rsuNumber}`]),
                    },
                    estado: atributosactive_Rsu,
                    grupo: null,
                    isrepeater: 0,
                    ncu: (atributos.active) ? atributos.active.value : 0,
                    trackername: null,
                }
            );
        }
        return markerData;
    }

    getTableData(planta_assets, atributos, alarmas, currentPlantaId, currentNcuId) {
        const tableData = [];
        const ncuName = this.getNcuName(planta_assets, currentNcuId);
        // console.log(alarmas);
        let fecha = '0';
        if (alarmas.hasOwnProperty('Last_Communication')) {
            fecha = alarmas[`Last_Communication`][0].ts;
        }

        const ids = {tcu: {}, rsu: {}},
            lats = {tcu: {}, rsu: {}},
            longs = {tcu: {}, rsu: {}},
            active = {tcu: {}, rsu: {}},
            bindings = {},
            zerosets = {},
            isrepeater = {},
            rots = {};

        const regExpLatLong = /(lat|long|active|id)_(s|Rsu)(\d+)/;
        Object.keys(atributos)
            .filter(key => regExpLatLong.test(key))
            .forEach(
                key => {
                    const [, latOrLong, type, string] = key.match(regExpLatLong);
                    if (latOrLong === 'lat') {
                        lats[type === 's' ? 'tcu' : 'rsu'][string] = +atributos[key].value;
                    } else if (latOrLong === 'long') {
                        longs[type === 's' ? 'tcu' : 'rsu'][string] = +atributos[key].value;
                    } else if (latOrLong === 'active') {
                        active[type === 's' ? 'tcu' : 'rsu'][string] = +atributos[key].value;
                    } else if (latOrLong === 'id') {
                        ids[type === 's' ? 'tcu' : 'rsu'][string] = atributos[key].value;
                    }
                }
            );
        const regExpRot = /Position_a1_deg_s(\d+)/;
        Object.keys(atributos)
            .filter(key => regExpRot.test(key))
            .forEach(
                key => {
                    const [, number] = key.match(regExpRot);
                    rots[number] = +atributos[key].value;
                }
            );

        const regExpBin = /binding_s(\d+)/;
        Object.keys(atributos)
            .filter(key => regExpBin.test(key))
            .forEach(
                key => {
                    const [, number] = key.match(regExpBin);
                    bindings[number] = +atributos[key].value;
                }
            );

        const regExpZero = /zeroSet_s(\d+)/;
        Object.keys(atributos)
            .filter(key => regExpZero.test(key))
            .forEach(
                key => {
                    const [, number] = key.match(regExpZero);
                    zerosets[number] = +atributos[key].value;
                }
            );

        const regExpRep = /isrepeater_s(\d+)/;
        Object.keys(atributos)
            .filter(key => regExpRep.test(key))
            .forEach(
                key => {
                    const [, number] = key.match(regExpRep);
                    isrepeater[number] = +atributos[key].value;
                }
            );

        const groupTcuQuantities = {};
        const regExpGroup = /Group(\d+)TcuQuantity/;
        Object.keys(atributos)
            .filter(key => regExpGroup.test(key))
            .forEach(
                key => {
                    const [, groupNumber] = key.match(regExpGroup);
                    Object.assign(groupTcuQuantities, {
                        [groupNumber]: +atributos[key].value
                    });
                }
            );

        const posicionesCambioDeGrupo = {};
        const tcuNumberRange = {
            first: Math.min(...Object.keys(lats.tcu).map(x => +x)),
            last: Math.max(...Object.keys(lats.tcu).map(x => +x))
        };
        for (const groupNumber of Object.keys(groupTcuQuantities)) {
            posicionesCambioDeGrupo[groupNumber] = {
                first: null,
                last: null
            };
            posicionesCambioDeGrupo[groupNumber].first =
                (+groupNumber !== 1) ? posicionesCambioDeGrupo[+groupNumber - 1].last + 1 : tcuNumberRange.first;
            posicionesCambioDeGrupo[groupNumber].last =
                posicionesCambioDeGrupo[groupNumber].first + groupTcuQuantities[groupNumber] - 1;
            if (posicionesCambioDeGrupo[groupNumber].last === tcuNumberRange.last) {
                break;
            }
        }

        // Comprobamos si la NCU tiene RSU
        if (Object.keys(lats.rsu).length > 0) {
            this.alert_comm_rsu = this.validateField(alarmas[`AlarmAnyRsuComm`]);
            this.alert_commws_rsu = this.validateField(alarmas[`AlarmAnyWS`]);
        } else {
            this.alert_comm_rsu = '0';
            this.alert_commws_rsu = '0';
        }
        const ncu_slave = this.limpiarNombreNcU(ncuName);
        let tcusystemnotok = 0;
        let totaltcu = 0;
        // tslint:disable-next-line:max-line-length
        for (let tcuNumber = Math.min(...Object.keys(lats.tcu).map(x => +x)); tcuNumber <= Math.max(...Object.keys(lats.tcu).map(x => +x)); tcuNumber++) {
            totaltcu++;
            if (alarmas.hasOwnProperty(`FlagSystemOk_s${tcuNumber}`)) {
                if (alarmas[`FlagSystemOk_s${tcuNumber}`][0].value < 1) {
                    tcusystemnotok++;
                }
            }
            let diff;
            if (alarmas.hasOwnProperty(`TargetAngle_a1_deg_s${tcuNumber}`) && alarmas.hasOwnProperty(`Position_a1_deg_s${tcuNumber}`)) {
                if (alarmas[`TargetAngle_a1_deg_s${tcuNumber}`][0] && alarmas[`Position_a1_deg_s${tcuNumber}`][0]) {
                    diff = alarmas[`TargetAngle_a1_deg_s${tcuNumber}`][0].value - alarmas[`Position_a1_deg_s${tcuNumber}`][0].value;
                }else{
                    diff = alarmas[`TargetAngle_a1_deg_s${tcuNumber}`].value - alarmas[`Position_a1_deg_s${tcuNumber}`].value;
                }
            }
            let StateOfCharge = 'N/A';
            if (alarmas.hasOwnProperty(`StateOfCharge_s${tcuNumber}`) && alarmas[`StateOfCharge_s${tcuNumber}`]) {
                if (alarmas[`StateOfCharge_s${tcuNumber}`][0]){
                    StateOfCharge = alarmas[`StateOfCharge_s${tcuNumber}`][0].value;
                }else{
                    StateOfCharge = alarmas[`StateOfCharge_s${tcuNumber}`].value;
                }
               // StateOfCharge = alarmas[`StateOfCharge_s${tcuNumber}`][0].value;
            }
            // if(tcuNumber === 21 && atributos[`name_s${tcuNumber}`]['value'] == 'TCU_NCU_2_Medina_21'){
            //     console.log(this.cambiarNombreTcU(atributos[`name_s${tcuNumber}`]['value'], 'ncu') + ' - NumMotorAlarm_s21 ' +alarmas[`NumMotorAlarm_s${tcuNumber}`][0]['value']);
            // }
            let fechatcu = 0;
            let fechalastcomtcu = '';
            if (alarmas.hasOwnProperty(`TS_Year_s${tcuNumber}`) && alarmas.hasOwnProperty(`TS_Month_s${tcuNumber}`)
                && alarmas.hasOwnProperty(`TS_Day_s${tcuNumber}`) && alarmas.hasOwnProperty(`TS_Hour_s${tcuNumber}`)
                && alarmas.hasOwnProperty(`TS_Minute_s${tcuNumber}`) && alarmas.hasOwnProperty(`TS_Second_s${tcuNumber}`)) {
                // var valorfecha = this.validateField(alarmas[`TS_Year_s${tcuNumber}`]) + '/' + this.validateField(alarmas[`TS_Month_s${tcuNumber}`]) + '/' + this.validateField(alarmas[`TS_Day_s${tcuNumber}`]) + ' ' + this.validateField(alarmas[`TS_Hour_s${tcuNumber}`]) + ':' + this.validateField(alarmas[`TS_Minute_s${tcuNumber}`]) + ':' + this.validateField(alarmas[`TS_Second_s${tcuNumber}`]);
                // const myDate = new Date(valorfecha).getTime();
                // fechatcu = myDate + (2 * 3600000);
                // fechatcu = myDate;
                // tslint:disable-next-line:max-line-length
                fechalastcomtcu = this.validateField(alarmas[`TS_Day_s${tcuNumber}`]).padStart(2, 0) + '/' + this.validateField(alarmas[`TS_Month_s${tcuNumber}`]).padStart(2, 0) + '/' + this.validateField(alarmas[`TS_Year_s${tcuNumber}`]) + ' ' + this.validateField(alarmas[`TS_Hour_s${tcuNumber}`]).padStart(2, 0) + ':' + this.validateField(alarmas[`TS_Minute_s${tcuNumber}`]).padStart(2, 0);
            }else{
                if ((parseInt(this.validateFieldTs(alarmas[`TargetAngle_a1_deg_s${tcuNumber}`])) >= parseInt(this.validateFieldTs(alarmas[`Position_a1_deg_s${tcuNumber}`])))
                    && (parseInt(this.validateFieldTs(alarmas[`TargetAngle_a1_deg_s${tcuNumber}`])) >= parseInt(this.validateFieldTs(alarmas[`MainState_s${tcuNumber}`])))){
                    fechatcu = this.validateFieldTs(alarmas[`TargetAngle_a1_deg_s${tcuNumber}`]);
                }else if ((parseInt(this.validateFieldTs(alarmas[`Position_a1_deg_s${tcuNumber}`])) >= parseInt(this.validateFieldTs(alarmas[`TargetAngle_a1_deg_s${tcuNumber}`])))
                    && (parseInt(this.validateFieldTs(alarmas[`Position_a1_deg_s${tcuNumber}`])) >= parseInt(this.validateFieldTs(alarmas[`MainState_s${tcuNumber}`])))){
                    fechatcu = this.validateFieldTs(alarmas[`Position_a1_deg_s${tcuNumber}`]);
                }else{
                    fechatcu = this.validateFieldTs(alarmas[`MainState_s${tcuNumber}`]);
                }
            }

            let repeater = 0;
            if (isrepeater[tcuNumber] === 1){
                repeater = 1;
            }

            // Disponibilidad ficticia hasta tener calculo real de disponibilidad
            let tcudisp_false = 100;
            if (this.validateField(alarmas[`NumAxisAlarm_s${tcuNumber}`]) > 0 || this.validateField(alarmas[`NumBattAlarm_s${tcuNumber}`]) > 0
            || this.validateField(alarmas[`NumCommAlarm_s${tcuNumber}`]) > 0 || this.validateField(alarmas[`NumMotorAlarm_s${tcuNumber}`]) > 0
            || this.validateField(alarmas[`NumSwAlarm_s${tcuNumber}`]) > 0){
                const min = Math.ceil(80);
                const max = Math.floor(95);
                tcudisp_false = Math.floor(Math.random() * (max - min) + min);
            }

            tableData.push(
                {
                    name: this.cambiarNombreTcU(atributos[`name_s${tcuNumber}`].value, 'ncu', repeater),
                    parentNcuId: currentNcuId,
                    lat: lats.tcu[tcuNumber],
                    long: longs.tcu[tcuNumber],
                    rot: rots[tcuNumber],
                    tipo: 'tcu',
                    plantaid: currentPlantaId,
                    dataid: ids.tcu[tcuNumber],
                    tcuNumber: tcuNumber,
                    ncu_slave: ncu_slave,
                    posicion: (ncu_slave * 1000) + tcuNumber,
                    // Plant_Disponibility: +this.validateField(alarmas[`Plant_Disponibility_s${tcuNumber}`]) || +this.validateField(alarmas[`Plant_Disponibility`]), // Si no de TCU de NCU
                    Plant_Disponibility: +this.validateField(alarmas[`Plant_Disponibility_s${tcuNumber}`]) || tcudisp_false, // Si no de TCU valor aleatorio
                    FlagSystemOk: +this.validateField(alarmas[`FlagSystemOk_s${tcuNumber}`]),
                    alarmas: {
                        axis: +this.validateField(alarmas[`NumAxisAlarm_s${tcuNumber}`]),
                        batt: +this.validateField(alarmas[`NumBattAlarm_s${tcuNumber}`]),
                        comm: +this.validateField(alarmas[`NumCommAlarm_s${tcuNumber}`]),
                        motor: +this.validateField(alarmas[`NumMotorAlarm_s${tcuNumber}`]),
                        sw: +this.validateField(alarmas[`NumSwAlarm_s${tcuNumber}`]),
                        meteo: 0,
                        sensor: 0,
                        stopbutton: +this.validateField(alarmas[`AlarmStopButton_s${tcuNumber}`]) || 0,
                        alarmtimeneverset: +this.validateField(alarmas[`AlarmTimeNeverSet_s${tcuNumber}`]),
                        alarmzigbee: +this.validateField(alarmas[`AlarmZigbee_s${tcuNumber}`]),
                        alarmcomm: +this.validateField(alarmas[`AlarmCom_s${tcuNumber}`]),
                        alarmsystemmon: +this.validateField(alarmas[`AlarmSystemMonitor_s${tcuNumber}`]),
                        alarmtestbuild: +this.validateField(alarmas[`AlarmTestBuild_s${tcuNumber}`]),
                        alarmstopb: +this.validateField(alarmas[`AlarmStopButton_s${tcuNumber}`]),
                        alarmlshwtop: +this.validateField(alarmas[`AlarmLsHwTop1_s${tcuNumber}`]),
                        alarmlshwbot: +this.validateField(alarmas[`AlarmLsHwBot1_s${tcuNumber}`]),
                        alarmaxisblock: +this.validateField(alarmas[`AlarmAxis1Blocked_s${tcuNumber}`]),
                        armaxislowspeed: +this.validateField(alarmas[`AlarmAxis1LowSpeed_s${tcuNumber}`]),
                        alarmcriticalbat: +this.validateField(alarmas[`AlarmCriticalBatt_s${tcuNumber}`]),
                        alarmlowbat: +this.validateField(alarmas[`AlarmLowBatt_s${tcuNumber}`]),
                        alarmnotbat: +this.validateField(alarmas[`AlarmNotEnoughBatt_s${tcuNumber}`]),
                        alarmlimbat: +this.validateField(alarmas[`alarmLimitedBatt_s${tcuNumber}`]),
                        alarmmotorhw: +this.validateField(alarmas[`AlarmMotor1OverCurrentHw_s${tcuNumber}`]),
                        alarmmotorsw: +this.validateField(alarmas[`AlarmMotor1OverCurrentSw_s${tcuNumber}`]),

                        AlarmAnyFS: 0,
                        AlarmAnySS: 0,
                        AlarmAnyWS: 0,
                        AlarmAnyRsuComm: 0,
                        AlarmAnemoComm: 0,
                        AlarmUps: 0,
                        Gw2Timeout: 0,
                        Gw1Timeout: 0,
                        AlarmAnyFlood: 0,
                        AlarmAnyGustWind: 0,
                        AlarmAnySnow: 0,
                        AlarmAnyWind: 0,
                        AlarmWind: 0,
                        BadConfiguration: 0,
                        AlarmNoFloodInfo: 0,
                        AlarmNoSnowInfo: 0,
                        AlarmNoWindInfo: 0,
                        AlarmTimeNeverSet: 0,
                        Vfault: 0,
                        alarmcommrsu: 0,
                        alarmwsrsu: 0,
                        alarmwindrsu: 0,
                        alarmgustrsu: 0,
                        alarmsnowrsu: 0,
                        alarmfloodrsu: 0,
                        alarmssrsu: 0,
                        alarmfsrsu: 0,
                    },
                    fecha: +fechatcu,
                    fechalastcomtcu: fechalastcomtcu,
                    estado: {
                        mainstate: +this.validateField(alarmas[`MainState_s${tcuNumber}`]),
                        safepositionstate: +this.validateField(alarmas[`SafePositionState_s${tcuNumber}`]),
                        bt_active: +this.validateField(alarmas[`BT_Active_s${tcuNumber}`]),
                    },
                    binding: bindings[tcuNumber],
                    zeroset: zerosets[tcuNumber],
                    isrepeater: (isrepeater[tcuNumber]) ? isrepeater[tcuNumber] : 0,
                    bateria: +StateOfCharge,
                    posreal: +this.validateField(alarmas[`Position_a1_deg_s${tcuNumber}`]),
                    postarget: +this.validateField(alarmas[`TargetAngle_a1_deg_s${tcuNumber}`]),
                    diferencia: +diff,
                    pasafiltro: 1,
                    ncustate: (atributos.active) ? atributos.active.value : 0,
                    deviceZM: (atributos.DeviceZM) ? atributos.DeviceZM.value : '',
                }
            );
        }

        for (let rsuNumber = Math.min(...Object.keys(lats.rsu).map(x => +x)); rsuNumber <= Math.max(...Object.keys(lats.rsu).map(x => +x)); rsuNumber++) {

            const posicion = 50 + parseInt(this.limpiarNombreNcU(atributos[`name_Rsu${rsuNumber}`].value, 'rsu')) ;
            // Disponibilidad ficticia hasta tener calculo real de disponibilidad
            let rsudisp_false = 90;
            if (this.validateField(alarmas[`NumCommAlarm_Rsu${rsuNumber}`]) > 0 || this.validateField(alarmas[`NumSensorAlarm_Rsu${rsuNumber}`]) > 0){
                const min = Math.ceil(80);
                const max = Math.floor(95);
                rsudisp_false = Math.floor(Math.random() * (max - min) + min);
            }
            tableData.push(
                {
                    name: this.cambiarNombreTcU(atributos[`name_Rsu${rsuNumber}`].value, 'rsu'),
                    parentNcuId: currentNcuId,
                    lat: lats.tcu[rsuNumber],
                    long: longs.tcu[rsuNumber],
                    rot: null,
                    tipo: 'rsu',
                    plantaid: currentPlantaId,
                    dataid: ids.rsu[rsuNumber],
                    ncu_slave: ncu_slave,
                    rsuNumber: {rsuNumber},
                    posicion: posicion,
                    // Plant_Disponibility: +this.validateField(alarmas[`Plant_Disponibility_Rsu${rsuNumber}`]) || +this.validateField(alarmas[`Plant_Disponibility`]), // Si no de RSU de NCU
                    Plant_Disponibility: +this.validateField(alarmas[`Plant_Disponibility_Rsu${rsuNumber}`]) || rsudisp_false, // Si no de RSU valor falso
                    FlagSystemOk: 1,
                    alarmas: {
                        axis: 0,
                        batt: 0,
                        motor: 0,
                        sw: 0,
                        comm: +this.validateField(alarmas[`NumCommAlarm_Rsu${rsuNumber}`]),
                        meteo: 0,
                        sensor: +this.validateField(alarmas[`NumSensorAlarm_Rsu${rsuNumber}`]) || 0,
                        stopbutton: 0,
                        alarmcommrsu: +this.validateField(alarmas[`AlarmComm_Rsu${rsuNumber}`]),
                        alarmwsrsu: +this.validateField(alarmas[`AlarmWS_Rsu${rsuNumber}`]),
                        alarmwindrsu: +this.validateField(alarmas[`AlarmWind_Rsu${rsuNumber}`]),
                        alarmgustrsu: +this.validateField(alarmas[`AlarmGustyWind_Rsu${rsuNumber}`]),
                        alarmsnowrsu: +this.validateField(alarmas[`AlarmSnow_Rsu${rsuNumber}`]),
                        alarmfloodrsu: +this.validateField(alarmas[`AlarmFlood_Rsu${rsuNumber}`]),
                        alarmssrsu: +this.validateField(alarmas[`AlarmSS_Rsu${rsuNumber}`]),
                        alarmfsrsu: +this.validateField(alarmas[`AlarmFS_Rsu${rsuNumber}`]),

                        AlarmAnyFlood: 0,
                        AlarmAnyGustWind: 0,
                        AlarmAnySnow: 0,
                        AlarmAnyWind: 0,
                        AlarmWind: 0,
                        BadConfiguration: 0,
                        AlarmNoFloodInfo: 0,
                        AlarmNoSnowInfo: 0,
                        AlarmNoWindInfo: 0,
                        AlarmTimeNeverSet: 0,
                        Vfault: 0,
                        alarmtimeneverset: 0,
                        alarmzigbee: 0,
                        alarmcomm: 0,
                        alarmsystemmon: 0,
                        alarmtestbuild: 0,
                        alarmstopb: 0,
                        alarmlshwtop: 0,
                        alarmlshwbot: 0,
                        alarmaxisblock: 0,
                        armaxislowspeed: 0,
                        alarmcriticalbat: 0,
                        alarmlowbat: 0,
                        alarmnotbat: 0,
                        alarmlimbat: 0,
                        alarmmotorhw: 0,
                        alarmmotorsw: 0,
                    },
                    estado: {
                        active: active.rsu[rsuNumber],
                    },
                    bateria: null,
                    posreal: null,
                    postarget: null,
                    diferencia: null,
                    fecha: +this.validateFieldTs(alarmas.Last_Communication),
                    fechalastcomtcu: '',
                    pasafiltro: 1,
                    isrepeater: 0,
                    ncustate: (atributos.active) ? atributos.active.value : 0,
                    deviceZM: (atributos.DeviceZM) ? atributos.DeviceZM.value : '',
                }
            );
        }

        // Generamos listado de NCUs
        const ncuFlagSystemOk = Math.round(100 - ((tcusystemnotok * 100) / totaltcu));
        tableData.push({
            name: this.cambiarNombreTcU(ncuName),
            tipo: 'ncu',
            lat: +atributos.lat.value,
            long: +atributos.long.value,
            rot: null,
            plantaid: currentPlantaId,
            dataid: currentNcuId,
            binding_percent: +atributos.binding_percent.value,
            // Plant_Disponibility: +this.validateField(alarmas[`Plant_Disponibility`]),
            Plant_Disponibility: ncuFlagSystemOk,
            FlagSystemOk: (ncuFlagSystemOk > 98.5 ) ? 1 : 0,
            ncu_slave: ncu_slave,
            posicion: ncu_slave,
            alarmas: {
                axis: 0,
                batt: 0,
                comm: +this.validateField(alarmas[`NumCommAlarmNcu`]), // comm
                motor: 0,
                sw: 0,
                sensor: 0,
                stopbutton: 0,
                meteo: 0,  // meteo
                ncublue: +this.validateField(alarmas[`NumMeteoAlarmNcu`]),  // meteo
                ncumorado: +this.validateField(alarmas[`NumOtherAlarmNcu`]), // other
                ncuyellow: +this.validateField(alarmas[`NumCommAlarmNcu`]), // comm
                AlarmAnyFS: +this.validateField(alarmas[`AlarmAnyFS`]),
                AlarmAnySS: +this.validateField(alarmas[`AlarmAnySS`]),
                AlarmAnyWS: +this.alert_commws_rsu,
                AlarmAnyRsuComm: +this.alert_comm_rsu,
                AlarmAnemoComm: +this.validateField(alarmas[`AlarmAnemoComm`]),
                AlarmUps: +this.validateField(alarmas[`AlarmUps`]),
                Gw2Timeout: +this.validateField(alarmas[`Gw2Timeout`]),
                Gw1Timeout: +this.validateField(alarmas[`Gw1Timeout`]),
                AlarmAnyFlood: +this.validateField(alarmas[`AlarmAnyFlood`]),
                AlarmAnyGustWind: +this.validateField(alarmas[`AlarmAnyGustWind`]),
                AlarmAnySnow: +this.validateField(alarmas[`AlarmAnySnow`]),
                AlarmAnyWind: +this.validateField(alarmas[`AlarmAnyWind`]),
                AlarmWind: +this.validateField(alarmas[`AlarmWind`]),
                BadConfiguration: +this.validateField(alarmas[`BadConfiguration`]),
                AlarmNoFloodInfo: +this.validateField(alarmas[`AlarmNoFloodInfo`]),
                AlarmNoSnowInfo: +this.validateField(alarmas[`AlarmNoSnowInfo`]),
                AlarmNoWindInfo: +this.validateField(alarmas[`AlarmNoWindInfo`]),
                AlarmTimeNeverSet: +this.validateField(alarmas[`AlarmTimeNeverSet`]),
                Vfault: +this.validateField(alarmas[`Vfault`]),

                alarmtimeneverset: 0,
                alarmzigbee: 0,
                alarmcomm: 0,
                alarmsystemmon: 0,
                alarmtestbuild: 0,
                alarmstopb: 0,
                alarmlshwtop: 0,
                alarmlshwbot: 0,
                alarmaxisblock: 0,
                armaxislowspeed: 0,
                alarmcriticalbat: 0,
                alarmlowbat: 0,
                alarmnotbat: 0,
                alarmlimbat: 0,
                alarmmotorhw: 0,
                alarmmotorsw: 0,
                alarmcommrsu: 0,
                alarmwsrsu: 0,
                alarmwindrsu: 0,
                alarmgustrsu: 0,
                alarmsnowrsu: 0,
                alarmfloodrsu: 0,
                alarmssrsu: 0,
                alarmfsrsu: 0,
            },
            estado: {
                active: (atributos.active) ? atributos.active.value : 0
            },
            grupo: null,
            fecha: +fecha,
            fechalastcomtcu: '',
            pasafiltro: 1,
            isrepeater: 0,
            deviceZM: (atributos.DeviceZM) ? atributos.DeviceZM.value : '',
        });

        return tableData;
    }

    getDeviceAlarmas( atributos, alarmas, datasidebar) {
        const tableData = [];
        const ids = {tcu: {}, rsu: {}},
            lats = {tcu: {}, rsu: {}},
            longs = {tcu: {}, rsu: {}},
            active = {tcu: {}, rsu: {}},
            bindings = {},
            zerosets = {},
            rots = {};

        // Generamos listado de NCUs
        let ncu_yellow = 0;
        let ncu_morado = 0;
        if ((alarmas[`Gw2Timeout`] && alarmas[`Gw2Timeout`][0].value > 0) || (alarmas[`Gw1Timeout`] && alarmas[`Gw1Timeout`][0].value > 0)){
            ncu_yellow = 1;
            datasidebar.ncualarm[0].rojo++;
        }
        if ((alarmas[`BadConfiguration`] && alarmas[`BadConfiguration`][0].value > 0)
            || (alarmas[`AlarmTimeNeverSet`] && alarmas[`AlarmTimeNeverSet`][0].value > 0)
            || (alarmas[`Vfault`] && alarmas[`Vfault`][0].value > 0)){
            ncu_morado = 1;
            datasidebar.ncualarm[0].morado++;
        }

        const deviceNumberstcu = atributos.deviceid.length;
        for (let tcuNumber = 1; tcuNumber < deviceNumberstcu; tcuNumber++) {
            if ((alarmas[`AlarmSystemMonitor_s`+tcuNumber] && alarmas[`AlarmSystemMonitor_s`+tcuNumber][0].value > 0)
                || (alarmas[`AlarmTimeNeverSet_s`+tcuNumber] && alarmas[`AlarmTimeNeverSet_s`+tcuNumber][0].value > 0)){
                datasidebar.tcualarm[0].grey++;
            }
            if ((alarmas[`AlarmCom_s`+tcuNumber] && alarmas[`AlarmCom_s`+tcuNumber][0].value > 0)){
                datasidebar.tcualarm[0].rojo++;
            }
            if ((alarmas[`AlarmCriticalBatt_s`+tcuNumber] && alarmas[`AlarmCriticalBatt_s`+tcuNumber][0].value > 0)
                || (alarmas[`AlarmLowBatt_s`+tcuNumber] && alarmas[`AlarmLowBatt_s`+tcuNumber][0].value > 0)
                || (alarmas[`AlarmNotEnoughBatt_s`+tcuNumber] && alarmas[`AlarmNotEnoughBatt_s`+tcuNumber][0].value > 0)
                || (alarmas[`alarmLimitedBatt_s`+tcuNumber] && alarmas[`alarmLimitedBatt_s`+tcuNumber][0].value > 0)){
                datasidebar.tcualarm[0].orange++;
            }
            if ((alarmas[`AlarmMotor1OverCurrentHw_s`+tcuNumber] && alarmas[`AlarmMotor1OverCurrentHw_s`+tcuNumber][0].value > 0)
                || (alarmas[`AlarmMotor1OverCurrentSw_s`+tcuNumber] && alarmas[`AlarmMotor1OverCurrentSw_s`+tcuNumber][0].value > 0)
                || (alarmas[`AlarmAxis1Blocked_s`+tcuNumber] && alarmas[`AlarmAxis1Blocked_s`+tcuNumber][0].value > 0)
                || (alarmas[`AlarmAxis1LowSpeed_s`+tcuNumber] && alarmas[`AlarmAxis1LowSpeed_s`+tcuNumber][0].value > 0)){
                datasidebar.tcualarm[0].yellow++;
            }
            if ((alarmas[`AlarmStopButton_s`+tcuNumber] && alarmas[`AlarmStopButton_s`+tcuNumber][0].value > 0)){
                datasidebar.tcualarm[0].green++;
            }
        }
        const deviceNumbersRSU = atributos.deviceidrsu.length;
        for (let rsuNumber = 1; rsuNumber < deviceNumbersRSU; rsuNumber++) {
            if ((alarmas[`AlarmComm_Rsu`+rsuNumber] && alarmas[`AlarmComm_Rsu`+rsuNumber][0].value > 0)){
                datasidebar.rsualarm[0].rojo++;
            }
            if ((alarmas[`AlarmWS_Rsu`+rsuNumber] && alarmas[`AlarmWS_Rsu`+rsuNumber][0].value > 0)
                || (alarmas[`AlarmSS_Rsu`+rsuNumber] && alarmas[`AlarmSS_Rsu`+rsuNumber][0].value > 0)
                || (alarmas[`AlarmFS_Rsu`+rsuNumber] && alarmas[`AlarmFS_Rsu`+rsuNumber][0].value > 0)){
                datasidebar.rsualarm[0].blue++;
            }
        }

        return datasidebar;
    }

    cambiarNombreTcU(datos, tipo = 'rsu', repeater = 0) {
        const t = datos.split('_');
        if (tipo === 'rsu') {
            if (t.length === 3) {
                t.splice(1, 1);
            }else{
                const size = t.length - 1;
                for (let i = 1; i < size; i++){
                    t.splice(1, 1); // Al ir restando siempre quitamos la posicion 1
                }
            }
            return t.join('_');
        }

        if (t.length === 5){
            if (repeater === 1){
                t.splice(0, 1, 'Repeater');
            }
            t.splice(3, 1);
            t.splice(1, 1);
        }else{
            if (repeater === 1){
                t.splice(0, 1, 'Repeater');
            }

            const size = t.length - 1;
            for (let i = 3; i < size; i++){
                t.splice(3, 1); // Al ir restando siempre quitamos la posicion 3
            }
            // t.splice(3, 1);
            t.splice(1, 1);
        }

        return t.join('_');
    }

    limpiarNombreNcU(datos, tipo = 'rsu') {
        const t = datos.split('_');
        const pos = t.length;
        return t[pos - 1];
    }


    getTableDataEventos(planta_assets, atributos, currentPlantaId, currentNcuId) {
        const tableData = [];
        const ncuName = this.getNcuName(planta_assets, currentNcuId);

        tableData.push({
            name: this.cambiarNombreTcU(ncuName),
            tipo: 'ncu',
            plantaid: currentPlantaId,
            dataid: currentNcuId,
            parentNcuId: currentNcuId,
            pasafiltro: 1,
        });

        const ids = {tcu: {}, rsu: {}},
            lats = {tcu: {}, rsu: {}},
            longs = {tcu: {}, rsu: {}},
            active = {tcu: {}, rsu: {}},
            rots = {};

        const regExpLatLong = /(lat|long|active|id)_(s|Rsu)(\d+)/;
        Object.keys(atributos)
            .filter(key => regExpLatLong.test(key))
            .forEach(
                key => {
                    const [, latOrLong, type, string] = key.match(regExpLatLong);
                    if (latOrLong === 'lat') {
                        lats[type === 's' ? 'tcu' : 'rsu'][string] = +atributos[key].value;
                    } else if (latOrLong === 'long') {
                        longs[type === 's' ? 'tcu' : 'rsu'][string] = +atributos[key].value;
                    } else if (latOrLong === 'active') {
                        active[type === 's' ? 'tcu' : 'rsu'][string] = +atributos[key].value;
                    } else if (latOrLong === 'id') {
                        ids[type === 's' ? 'tcu' : 'rsu'][string] = atributos[key].value;
                    }
                }
            );
        const regExpRot = /Position_a1_deg_s(\d+)/;
        Object.keys(atributos)
            .filter(key => regExpRot.test(key))
            .forEach(
                key => {
                    const [, number] = key.match(regExpRot);
                    rots[number] = +atributos[key].value;
                }
            );

        const groupTcuQuantities = {};
        const regExpGroup = /Group(\d+)TcuQuantity/;
        Object.keys(atributos)
            .filter(key => regExpGroup.test(key))
            .forEach(
                key => {
                    const [, groupNumber] = key.match(regExpGroup);
                    Object.assign(groupTcuQuantities, {
                        [groupNumber]: +atributos[key].value
                    });
                }
            );

        const posicionesCambioDeGrupo = {};
        const tcuNumberRange = {
            first: Math.min(...Object.keys(lats.tcu).map(x => +x)),
            last: Math.max(...Object.keys(lats.tcu).map(x => +x))
        };
        for (const groupNumber of Object.keys(groupTcuQuantities)) {
            posicionesCambioDeGrupo[groupNumber] = {
                first: null,
                last: null
            };
            posicionesCambioDeGrupo[groupNumber].first =
                (+groupNumber !== 1) ? posicionesCambioDeGrupo[+groupNumber - 1].last + 1 : tcuNumberRange.first;
            posicionesCambioDeGrupo[groupNumber].last =
                posicionesCambioDeGrupo[groupNumber].first + groupTcuQuantities[groupNumber] - 1;
            if (posicionesCambioDeGrupo[groupNumber].last === tcuNumberRange.last) {
                break;
            }
        }

        //
        for (let tcuNumber = Math.min(...Object.keys(lats.tcu).map(x => +x)); tcuNumber <= Math.max(...Object.keys(lats.tcu).map(x => +x)); tcuNumber++) {

            tableData.push(
                {
                    name: this.cambiarNombreTcU(atributos[`name_s${tcuNumber}`].value, 'tcu'),
                    parentNcuId: currentNcuId,
                    tipo: 'tcu',
                    plantaid: currentPlantaId,
                    dataid: ids.tcu[tcuNumber],
                    pasafiltro: 1,
                }
            );
        }

        for (let rsuNumber = Math.min(...Object.keys(lats.rsu).map(x => +x)); rsuNumber <= Math.max(...Object.keys(lats.rsu).map(x => +x)); rsuNumber++) {
            tableData.push(
                {
                    name: this.cambiarNombreTcU(atributos[`name_Rsu${rsuNumber}`].value, 'rsu'),
                    parentNcuId: currentNcuId,
                    tipo: 'rsu',
                    plantaid: currentPlantaId,
                    dataid: ids.rsu[rsuNumber],
                    pasafiltro: 1,
                }
            );
        }
        return tableData;
    }

    getTcuDatasidevar(datos, idTcu, selfPowered) {
        const alarmas = {
            rojo: 0,
            yellow: 0,
            blue: 0,
            grey: 0,
            orange: 0,
            green: 0,
        };
        // Comm
        if (datos.hasOwnProperty('AlarmCom_' + idTcu) && datos['AlarmCom_' + idTcu][0].value === '1') {
            alarmas.rojo++;
        }
        // Stop button
        if (datos.hasOwnProperty('AlarmStopButton_' + idTcu) && datos['AlarmStopButton_' + idTcu][0].value === '1') {
            alarmas.green++;
        }
        // Motor
        if (datos.hasOwnProperty('AlarmMotor1OverCurrentHw_' + idTcu) && datos['AlarmMotor1OverCurrentHw_' + idTcu][0].value === '1') {
            alarmas.yellow++;
        }

        if (datos.hasOwnProperty('AlarmMotor1OverCurrentSw_' + idTcu) && datos['AlarmMotor1OverCurrentSw_' + idTcu].value === '1') {
            alarmas.yellow++;
        }
        if (datos.hasOwnProperty('AlarmAxis1LowSpeed_' + idTcu) && datos['AlarmAxis1LowSpeed_' + idTcu][0].value === '1') {
            alarmas.yellow++;
        }
        if (datos.hasOwnProperty('AlarmAxis1Blocked_' + idTcu) && datos['AlarmAxis1Blocked_' + idTcu][0].value === '1') {
            alarmas.yellow++;
        }
        // SOFTWARE
        if (datos.hasOwnProperty('AlarmSystemMonitor_' + idTcu) && datos['AlarmSystemMonitor_' + idTcu][0].value === '1') {
            alarmas.grey++;
        }
        if (datos.hasOwnProperty('AlarmTimeNeverSet_' + idTcu) && datos['AlarmTimeNeverSet_' + idTcu][0].value === '1') {
            alarmas.grey++;
        }
        // battery
        if (selfPowered != 'AC') {
            if (datos.hasOwnProperty('AlarmCriticalBatt_' + idTcu) && datos['AlarmCriticalBatt_' + idTcu][0].value === '1') {
                alarmas.orange = alarmas.orange + 1;
            }
            if (datos.hasOwnProperty('AlarmLowBatt_' + idTcu) && datos['AlarmLowBatt_' + idTcu][0].value === '1') {
                alarmas.orange = alarmas.orange + 1;
            }
            if (datos.hasOwnProperty('alarmLimitedBatt_' + idTcu) && datos['alarmLimitedBatt_' + idTcu][0].value === '1') {
                alarmas.orange = alarmas.orange + 1;
            }
            if (datos.hasOwnProperty('AlarmNotEnoughBatt_' + idTcu) && datos['AlarmNotEnoughBatt_' + idTcu][0].value === '1') {
                alarmas.orange = alarmas.orange + 1;
            }
        }
        return alarmas;
    }

    getEventosTcu(idTcu, idDisp, data, isrepeater = 0) {
        const eventos = [];
        if(isrepeater !== 1) {
            if (data.hasOwnProperty('AlarmAxis1Blocked_' + idDisp)) {
                eventos.push({
                    fecha: data['AlarmAxis1Blocked_' + idDisp][0].ts,
                    dispositivo: idTcu,
                    registro: 'Alarm',
                    tipoalarma: 1,
                    alarma: 'Alarm Axis 1 Blocked',
                    estado: data['AlarmAxis1Blocked_' + idDisp][0].value,
                    pasafiltro: 1

                });
            }
            if (data.hasOwnProperty('AlarmAxis1LowSpeed_' + idDisp)) {
                eventos.push({
                    fecha: data['AlarmAxis1LowSpeed_' + idDisp][0].ts,
                    dispositivo: idTcu,
                    registro: 'Alarm',
                    tipoalarma: 1,
                    alarma: 'Alarm Axis 1 Low Speed',
                    estado: data['AlarmAxis1LowSpeed_' + idDisp][0].value,
                    pasafiltro: 1
                });
            }
            if (data.hasOwnProperty('AlarmMotor1OverCurrentHw_' + idDisp)) {
                eventos.push({
                    fecha: data['AlarmMotor1OverCurrentHw_' + idDisp][0].ts,
                    dispositivo: idTcu,
                    registro: 'Alarm',
                    tipoalarma: 1,
                    alarma: 'Alarm Motor 1OverCurrentHw',
                    estado: data['AlarmMotor1OverCurrentHw_' + idDisp][0].value,
                    pasafiltro: 1
                });
            }
            if (data.hasOwnProperty('AlarmMotor1OverCurrentSw_' + idDisp)) {
                eventos.push({
                    fecha: data['AlarmMotor1OverCurrentSw_' + idDisp][0].ts,
                    dispositivo: idTcu,
                    registro: 'Alarm',
                    tipoalarma: 1,
                    alarma: 'Alarm Motor Motor1OverCurrentSw',
                    estado: data['AlarmMotor1OverCurrentSw_' + idDisp][0].value,
                    pasafiltro: 1
                });
            }
            if (data.hasOwnProperty('AlarmSystemMonitor_' + idDisp))
            {
                eventos.push({
                    fecha: data['AlarmSystemMonitor_' + idDisp][0].ts,
                    dispositivo: idTcu,
                    registro: 'Alarm',
                    tipoalarma: 5,
                    alarma: 'Alarm SW SystemMonitor',
                    estado: data['AlarmSystemMonitor_' + idDisp][0].value,
                    pasafiltro: 1
                });
            }
            if (data.hasOwnProperty('AlarmTimeNeverSet_' + idDisp))
            {
                eventos.push({
                    fecha: data['AlarmTimeNeverSet_' + idDisp][0].ts,
                    dispositivo: idTcu,
                    registro: 'Alarm',
                    tipoalarma: 5,
                    alarma: 'Alarm SW TimeNeverSet',
                    estado: data['AlarmTimeNeverSet_' + idDisp][0].value,
                    pasafiltro: 1
                });
            }
            if (data.hasOwnProperty('AlarmStopButton_' + idDisp))
            {
                eventos.push({
                    fecha: data['AlarmStopButton_' + idDisp][0].ts,
                    dispositivo: idTcu,
                    registro: 'Alarm',
                    tipoalarma: 3,
                    alarma: 'Alarm Stop Button',
                    estado: data['AlarmStopButton_' + idDisp][0].value,
                    pasafiltro: 1
                });
            }
        }
        if (data.hasOwnProperty('AlarmCom_' + idDisp))
        {
            eventos.push({
                fecha: data['AlarmCom_' + idDisp][0].ts,
                dispositivo: idTcu,
                registro: 'Alarm',
                tipoalarma: 2,
                alarma: 'Alarm CommLost',
                estado: data['AlarmCom_' + idDisp][0].value,
                pasafiltro: 1
            });
       }

        if (data.hasOwnProperty('AlarmCriticalBatt_' + idDisp))
        {
            eventos.push({
                fecha: data['AlarmCriticalBatt_' + idDisp][0].ts,
                dispositivo: idTcu,
                registro: 'Alarm',
                tipoalarma: 0,
                alarma: 'Alarm Critical Battery',
                estado: data['AlarmCriticalBatt_' + idDisp][0].value,
                pasafiltro: 1
            });
        }
        if (data.hasOwnProperty('AlarmLowBatt_' + idDisp))
        {
            eventos.push({
                fecha: data['AlarmLowBatt_' + idDisp][0].ts,
                dispositivo: idTcu,
                registro: 'Alarm',
                tipoalarma: 0,
                alarma: 'Alarm Low Battery',
                estado: data['AlarmLowBatt_' + idDisp][0].value,
                pasafiltro: 1
            });
        }
        if (data.hasOwnProperty('AlarmNotEnoughBatt_' + idDisp))
        {
            eventos.push({
                fecha: data['AlarmNotEnoughBatt_' + idDisp][0].ts,
                dispositivo: idTcu,
                registro: 'Alarm',
                tipoalarma: 0,
                alarma: 'Alarm Not Enough Battery',
                estado: data['AlarmNotEnoughBatt_' + idDisp][0].value,
                pasafiltro: 1
            });
        }
        if (data.hasOwnProperty('alarmLimitedBatt_' + idDisp))
        {
            eventos.push({
                fecha: data['alarmLimitedBatt_' + idDisp][0].ts,
                dispositivo: idTcu,
                registro: 'Alarm',
                tipoalarma: 0,
                alarma: 'Alarm Limited Battery',
                estado: data['alarmLimitedBatt_' + idDisp][0].value,
                pasafiltro: 1
            });
        }
        return eventos;
    }

    getEstadosTcu(idTcu, idDisp, data) {
        const estados = [];
        if (data.hasOwnProperty('SleepState_' + idDisp)) {
            estados.push({
                fecha: data['SleepState_' + idDisp][0].ts,
                dispositivo: idTcu,
                registro: 'State',
                tipoalarma: 9,
                alarma: 'Sleep State',
                estado: data['SleepState_' + idDisp][0].value,
                pasafiltro: 1
            });

        }
        if (data.hasOwnProperty('SafePositionState_' + idDisp)) {
            estados.push({
                fecha: data['SafePositionState_' + idDisp][0].ts,
                dispositivo: idTcu,
                registro: 'State',
                tipoalarma: 9,
                alarma: 'Safe Position State',
                estado: data['SafePositionState_' + idDisp][0].value,
                pasafiltro: 1
            });
        }
        if (data.hasOwnProperty('MainState_' + idDisp)) {
            estados.push({
                fecha: data['MainState_' + idDisp][0].ts,
                dispositivo: idTcu,
                registro: 'State',
                tipoalarma: 9,
                alarma: 'Main State',
                estado: data['MainState_' + idDisp][0].value,
                pasafiltro: 1
            });
        }
        if (data.hasOwnProperty('FlagHold1_' + idDisp)) {
            estados.push({
                fecha: data['FlagHold1_' + idDisp][0].ts,
                dispositivo: idTcu,
                registro: 'State',
                tipoalarma: 9,
                alarma: 'Flag Hold 1',
                estado: data['FlagHold1_' + idDisp][0].value,
                pasafiltro: 1
            });
        }
        if (data.hasOwnProperty('FlagSystemOk_' + idDisp)) {
            estados.push({
                fecha: data['FlagSystemOk_' + idDisp][0].ts,
                dispositivo: idTcu,
                registro: 'State',
                tipoalarma: 9,
                alarma: 'Flag System Ok',
                estado: data['FlagSystemOk_' + idDisp][0].value,
                pasafiltro: 1
            });
        }
        if (data.hasOwnProperty('CommisionState_' + idDisp)) {
            estados.push({
                fecha: data['CommisionState_' + idDisp][0].ts,
                dispositivo: idTcu,
                registro: 'State',
                tipoalarma: 9,
                alarma: 'Commission State',
                estado: data['CommisionState_' + idDisp][0].value,
                pasafiltro: 1
            });
        }
        if (data.hasOwnProperty('BT_Active_' + idDisp)) {
            estados.push({
                fecha: data['BT_Active_' + idDisp][0].ts,
                dispositivo: idTcu,
                registro: 'State',
                tipoalarma: 9,
                alarma: 'BT Active_a1',
                estado: data['BT_Active_' + idDisp][0].value
            });
        }
        return estados;
    }

    aplanarArrayDeObjetos(arrayDeObjetos) {
        const objetoModificado = arrayDeObjetos.map(elemento => {
            return {
                [elemento.key]: {
                    value: elemento.value,
                    ts: elemento.lastUpdateTs
                }
            };
        });
        return Object.assign({}, ...objetoModificado);
    }

    getNcuName(assetsData, currentDataId) {
        for (const ncu of assetsData) {
            if (ncu.to.id === currentDataId) {
                return ncu.toName;
            }
        }
    }

    assignTcuGroup(tcuNumber, tcuGroupRanges) {
        for (const groupNumber of Object.keys(tcuGroupRanges)) {
            if (tcuGroupRanges[groupNumber].first <= tcuNumber && tcuNumber <= tcuGroupRanges[groupNumber].last) {
                return +groupNumber;
            }
        }
    }

    padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    formatDatenew(date) {
        return (
            [
                date.getFullYear(),
                this.padTo2Digits(date.getMonth() + 1),
                this.padTo2Digits(date.getDate()),
            ].join('-') +
            ' ' +
            [
                this.padTo2Digits(date.getHours()),
                this.padTo2Digits(date.getMinutes()),
                this.padTo2Digits(date.getSeconds()),
            ].join(':')
        );
    }

    imageExists(imageurl){

        const http = new XMLHttpRequest();

        http.open('HEAD', imageurl, false);
        http.send();

        return http.status !== 404;

    }
}
