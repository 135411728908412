/**
 * ThingsBoard REST API
 * For instructions how to authorize requests please visit <a href=\'http://thingsboard.io/docs/reference/rest-api/\'>REST API documentation page</a>.
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@thingsboard.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EntityKey } from './entityKey';


export interface EntityDataSortOrder { 
    direction?: EntityDataSortOrder.DirectionEnum;
    key?: EntityKey;
}
export namespace EntityDataSortOrder {
    export type DirectionEnum = 'ASC' | 'DESC';
    export const DirectionEnum = {
        ASC: 'ASC' as DirectionEnum,
        DESC: 'DESC' as DirectionEnum
    };
}


