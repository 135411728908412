/**
 * ThingsBoard REST API
 * For instructions how to authorize requests please visit
 * <a href=\'https://thingsboard.io/docs/reference/rest-api/\'>REST API documentation page</a>.
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@thingsboard.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec } from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { EntityRelation } from '../model/models';
import { EntityRelationInfo } from '../model/models';
import { EntityRelationsQuery } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EntityRelationControllerService {

    // protected basePath = 'https://iot.stinorland.com:443';
    protected basePath = environment.apiThingsboardUrl;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;
    private relationTypeGroupfromIdfromTyperelationTypetoIdtoType: any;
    private entityIdentityTypeidtype: any;
    private relationTypeGroupfromIdfromTyperelationType: any;
    private relationTypeGroupfromIdfromType: any;
    private relationTypeGrouptoIdtoType: any;
    private relationTypeGrouptoIdtoTyperelationType: any;

    // tslint:disable-next-line:max-line-length
    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === 'object' && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === 'object') {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error('key may not be null if value is Date');
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error('key may not be null if value is not object or array');
        }
        return httpParams;
    }

    /**
     * deleteRelation
     * @param fromId fromId
     * @param fromType fromType
     * @param relationType relationType
     * @param toId toId
     * @param toType toType
     * @param relationTypeGroup relationTypeGroup
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public deleteRelationUsingDELETE(fromId: string, fromType: string, relationType: string, toId: string, toType: string, relationTypeGroup?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    // tslint:disable-next-line:max-line-length
    public deleteRelationUsingDELETE(fromId: string, fromType: string, relationType: string, toId: string, toType: string, relationTypeGroup?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    // tslint:disable-next-line:max-line-length
    public deleteRelationUsingDELETE(fromId: string, fromType: string, relationType: string, toId: string, toType: string, relationTypeGroup?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    // tslint:disable-next-line:max-line-length
    public deleteRelationUsingDELETE(fromId: string, fromType: string, relationType: string, toId: string, toType: string, relationTypeGroup?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (fromId === null || fromId === undefined) {
            throw new Error('Required parameter fromId was null or undefined when calling deleteRelationUsingDELETE.');
        }
        if (fromType === null || fromType === undefined) {
            throw new Error('Required parameter fromType was null or undefined when calling deleteRelationUsingDELETE.');
        }
        if (relationType === null || relationType === undefined) {
            throw new Error('Required parameter relationType was null or undefined when calling deleteRelationUsingDELETE.');
        }
        if (toId === null || toId === undefined) {
            throw new Error('Required parameter toId was null or undefined when calling deleteRelationUsingDELETE.');
        }
        if (toType === null || toType === undefined) {
            throw new Error('Required parameter toType was null or undefined when calling deleteRelationUsingDELETE.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (relationTypeGroup !== undefined && relationTypeGroup !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            relationTypeGroup as any, 'relationTypeGroup');
        }
        if (fromId !== undefined && fromId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            fromId as any, 'fromId');
        }
        if (fromType !== undefined && fromType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            fromType as any, 'fromType');
        }
        if (relationType !== undefined && relationType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            relationType as any, 'relationType');
        }
        if (toId !== undefined && toId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            toId as any, 'toId');
        }
        if (toType !== undefined && toType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            toType as any, 'toType');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/api/relation${encodeURIComponent(String(this.relationTypeGroupfromIdfromTyperelationTypetoIdtoType))}`,
            {
                params: queryParameters,
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * deleteRelations
     * @param entityId entityId
     * @param entityType entityType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public deleteRelationsUsingDELETE(entityId: string, entityType: string, id: string, type: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    // tslint:disable-next-line:max-line-length
    public deleteRelationsUsingDELETE(entityId: string, entityType: string, id: string, type: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    // tslint:disable-next-line:max-line-length
    public deleteRelationsUsingDELETE(entityId: string, entityType: string, id: string, type: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    // tslint:disable-next-line:max-line-length
    public deleteRelationsUsingDELETE(entityId: string, entityType: string, id: string, type: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (entityId === null || entityId === undefined) {
            throw new Error('Required parameter entityId was null or undefined when calling deleteRelationsUsingDELETE.');
        }
        if (entityType === null || entityType === undefined) {
            throw new Error('Required parameter entityType was null or undefined when calling deleteRelationsUsingDELETE.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteRelationsUsingDELETE.');
        }
        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling deleteRelationsUsingDELETE.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (entityId !== undefined && entityId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            entityId as any, 'entityId');
        }
        if (entityType !== undefined && entityType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            entityType as any, 'entityType');
        }
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            id as any, 'id');
        }
        if (type !== undefined && type !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            type as any, 'type');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/api/relations${encodeURIComponent(String(this.entityIdentityTypeidtype))}`,
            {
                params: queryParameters,
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * findByFrom
     * @param fromId fromId
     * @param fromType fromType
     * @param relationType relationType
     * @param relationTypeGroup relationTypeGroup
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public findByFromUsingGET(fromId: string, fromType: string, relationType: string, relationTypeGroup?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<EntityRelation>>;
    // tslint:disable-next-line:max-line-length
    public findByFromUsingGET(fromId: string, fromType: string, relationType: string, relationTypeGroup?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<EntityRelation>>>;
    // tslint:disable-next-line:max-line-length
    public findByFromUsingGET(fromId: string, fromType: string, relationType: string, relationTypeGroup?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<EntityRelation>>>;
    // tslint:disable-next-line:max-line-length
    public findByFromUsingGET(fromId: string, fromType: string, relationType: string, relationTypeGroup?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (fromId === null || fromId === undefined) {
            throw new Error('Required parameter fromId was null or undefined when calling findByFromUsingGET.');
        }
        if (fromType === null || fromType === undefined) {
            throw new Error('Required parameter fromType was null or undefined when calling findByFromUsingGET.');
        }
        if (relationType === null || relationType === undefined) {
            throw new Error('Required parameter relationType was null or undefined when calling findByFromUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (relationTypeGroup !== undefined && relationTypeGroup !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            relationTypeGroup as any, 'relationTypeGroup');
        }
        if (fromId !== undefined && fromId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            fromId as any, 'fromId');
        }
        if (fromType !== undefined && fromType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            fromType as any, 'fromType');
        }
        if (relationType !== undefined && relationType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            relationType as any, 'relationType');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<EntityRelation>>(`${this.configuration.basePath}/api/relations${encodeURIComponent(String(this.relationTypeGroupfromIdfromTyperelationType))}`,
            {
                params: queryParameters,
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * findByFrom
     * @param fromId fromId
     * @param fromType fromType
     * @param relationTypeGroup relationTypeGroup
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public findByFromUsingGET1(fromId: string, fromType: string, relationTypeGroup?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<EntityRelation>>;
    // tslint:disable-next-line:max-line-length
    public findByFromUsingGET1(fromId: string, fromType: string, relationTypeGroup?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<EntityRelation>>>;
    // tslint:disable-next-line:max-line-length
    public findByFromUsingGET1(fromId: string, fromType: string, relationTypeGroup?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<EntityRelation>>>;
    // tslint:disable-next-line:max-line-length
    public findByFromUsingGET1(fromId: string, fromType: string, relationTypeGroup?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (fromId === null || fromId === undefined) {
            throw new Error('Required parameter fromId was null or undefined when calling findByFromUsingGET1.');
        }
        if (fromType === null || fromType === undefined) {
            throw new Error('Required parameter fromType was null or undefined when calling findByFromUsingGET1.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (relationTypeGroup !== undefined && relationTypeGroup !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            relationTypeGroup as any, 'relationTypeGroup');
        }
        if (fromId !== undefined && fromId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            fromId as any, 'fromId');
        }
        if (fromType !== undefined && fromType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            fromType as any, 'fromType');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<EntityRelation>>(`${this.configuration.basePath}/api/relations${encodeURIComponent(String(this.relationTypeGroupfromIdfromType))}`,
            {
                params: queryParameters,
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * findByQuery
     * @param query query
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findByQueryUsingPOST2(query: EntityRelationsQuery, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<EntityRelation>>;
    public findByQueryUsingPOST2(query: EntityRelationsQuery, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<EntityRelation>>>;
    public findByQueryUsingPOST2(query: EntityRelationsQuery, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<EntityRelation>>>;
    // tslint:disable-next-line:max-line-length
    public findByQueryUsingPOST2(query: EntityRelationsQuery, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (query === null || query === undefined) {
            throw new Error('Required parameter query was null or undefined when calling findByQueryUsingPOST2.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Array<EntityRelation>>(`${this.configuration.basePath}/api/relations`,
            query,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * findByTo
     * @param toId toId
     * @param toType toType
     * @param relationTypeGroup relationTypeGroup
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public findByToUsingGET(toId: string, toType: string, relationTypeGroup?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<EntityRelation>>;
    // tslint:disable-next-line:max-line-length
    public findByToUsingGET(toId: string, toType: string, relationTypeGroup?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<EntityRelation>>>;
    // tslint:disable-next-line:max-line-length
    public findByToUsingGET(toId: string, toType: string, relationTypeGroup?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<EntityRelation>>>;
    // tslint:disable-next-line:max-line-length
    public findByToUsingGET(toId: string, toType: string, relationTypeGroup?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (toId === null || toId === undefined) {
            throw new Error('Required parameter toId was null or undefined when calling findByToUsingGET.');
        }
        if (toType === null || toType === undefined) {
            throw new Error('Required parameter toType was null or undefined when calling findByToUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (relationTypeGroup !== undefined && relationTypeGroup !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            relationTypeGroup as any, 'relationTypeGroup');
        }
        if (toId !== undefined && toId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            toId as any, 'toId');
        }
        if (toType !== undefined && toType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            toType as any, 'toType');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<EntityRelation>>(`${this.configuration.basePath}/api/relations${encodeURIComponent(String(this.relationTypeGrouptoIdtoType))}`,
            {
                params: queryParameters,
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * findByTo
     * @param toId toId
     * @param toType toType
     * @param relationType relationType
     * @param relationTypeGroup relationTypeGroup
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public findByToUsingGET1(toId: string, toType: string, relationType: string, relationTypeGroup?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<EntityRelation>>;
    // tslint:disable-next-line:max-line-length
    public findByToUsingGET1(toId: string, toType: string, relationType: string, relationTypeGroup?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<EntityRelation>>>;
    // tslint:disable-next-line:max-line-length
    public findByToUsingGET1(toId: string, toType: string, relationType: string, relationTypeGroup?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<EntityRelation>>>;
    // tslint:disable-next-line:max-line-length
    public findByToUsingGET1(toId: string, toType: string, relationType: string, relationTypeGroup?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (toId === null || toId === undefined) {
            throw new Error('Required parameter toId was null or undefined when calling findByToUsingGET1.');
        }
        if (toType === null || toType === undefined) {
            throw new Error('Required parameter toType was null or undefined when calling findByToUsingGET1.');
        }
        if (relationType === null || relationType === undefined) {
            throw new Error('Required parameter relationType was null or undefined when calling findByToUsingGET1.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (relationTypeGroup !== undefined && relationTypeGroup !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            relationTypeGroup as any, 'relationTypeGroup');
        }
        if (toId !== undefined && toId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            toId as any, 'toId');
        }
        if (toType !== undefined && toType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            toType as any, 'toType');
        }
        if (relationType !== undefined && relationType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            relationType as any, 'relationType');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<EntityRelation>>(`${this.configuration.basePath}/api/relations${encodeURIComponent(String(this.relationTypeGrouptoIdtoTyperelationType))}`,
            {
                params: queryParameters,
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * findInfoByFrom
     * @param fromId fromId
     * @param fromType fromType
     * @param relationTypeGroup relationTypeGroup
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public findInfoByFromUsingGET(fromId: string, fromType: string, relationTypeGroup?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<EntityRelationInfo>>;
    // tslint:disable-next-line:max-line-length
    public findInfoByFromUsingGET(fromId: string, fromType: string, relationTypeGroup?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<EntityRelationInfo>>>;
    // tslint:disable-next-line:max-line-length
    public findInfoByFromUsingGET(fromId: string, fromType: string, relationTypeGroup?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<EntityRelationInfo>>>;
    // tslint:disable-next-line:max-line-length
    public findInfoByFromUsingGET(fromId: string, fromType: string, relationTypeGroup?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (fromId === null || fromId === undefined) {
            throw new Error('Required parameter fromId was null or undefined when calling findInfoByFromUsingGET.');
        }
        if (fromType === null || fromType === undefined) {
            throw new Error('Required parameter fromType was null or undefined when calling findInfoByFromUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (relationTypeGroup !== undefined && relationTypeGroup !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            relationTypeGroup as any, 'relationTypeGroup');
        }
        if (fromId !== undefined && fromId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            fromId as any, 'fromId');
        }
        if (fromType !== undefined && fromType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            fromType as any, 'fromType');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        // tslint:disable-next-line:max-line-length
        // return this.httpClient.get<Array<EntityRelationInfo>>(`${this.configuration.basePath}/api/relations/info${encodeURIComponent(String(this.relationTypeGroupfromIdfromType))}`,
        return this.httpClient.get<Array<EntityRelationInfo>>(`${this.configuration.basePath}/api/relations/info`,
            {
                params: queryParameters,
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * findInfoByQuery
     * @param query query
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findInfoByQueryUsingPOST(query: EntityRelationsQuery, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<EntityRelationInfo>>;
    // tslint:disable-next-line:max-line-length
    public findInfoByQueryUsingPOST(query: EntityRelationsQuery, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<EntityRelationInfo>>>;
    public findInfoByQueryUsingPOST(query: EntityRelationsQuery, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<EntityRelationInfo>>>;
    // tslint:disable-next-line:max-line-length
    public findInfoByQueryUsingPOST(query: EntityRelationsQuery, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (query === null || query === undefined) {
            throw new Error('Required parameter query was null or undefined when calling findInfoByQueryUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Array<EntityRelationInfo>>(`${this.configuration.basePath}/api/relations/info`,
            query,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * findInfoByTo
     * @param toId toId
     * @param toType toType
     * @param relationTypeGroup relationTypeGroup
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public findInfoByToUsingGET(toId: string, toType: string, relationTypeGroup?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<EntityRelationInfo>>;
    // tslint:disable-next-line:max-line-length
    public findInfoByToUsingGET(toId: string, toType: string, relationTypeGroup?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<EntityRelationInfo>>>;
    // tslint:disable-next-line:max-line-length
    public findInfoByToUsingGET(toId: string, toType: string, relationTypeGroup?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<EntityRelationInfo>>>;
    // tslint:disable-next-line:max-line-length
    public findInfoByToUsingGET(toId: string, toType: string, relationTypeGroup?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (toId === null || toId === undefined) {
            throw new Error('Required parameter toId was null or undefined when calling findInfoByToUsingGET.');
        }
        if (toType === null || toType === undefined) {
            throw new Error('Required parameter toType was null or undefined when calling findInfoByToUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (relationTypeGroup !== undefined && relationTypeGroup !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            relationTypeGroup as any, 'relationTypeGroup');
        }
        if (toId !== undefined && toId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            toId as any, 'toId');
        }
        if (toType !== undefined && toType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            toType as any, 'toType');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<EntityRelationInfo>>(`${this.configuration.basePath}/api/relations/info${encodeURIComponent(String(this.relationTypeGrouptoIdtoType))}`,
            {
                params: queryParameters,
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getRelation
     * @param fromId fromId
     * @param fromType fromType
     * @param relationType relationType
     * @param toId toId
     * @param toType toType
     * @param relationTypeGroup relationTypeGroup
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public getRelationUsingGET(fromId: string, fromType: string, relationType: string, toId: string, toType: string, relationTypeGroup?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<EntityRelation>;
    // tslint:disable-next-line:max-line-length
    public getRelationUsingGET(fromId: string, fromType: string, relationType: string, toId: string, toType: string, relationTypeGroup?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<EntityRelation>>;
    // tslint:disable-next-line:max-line-length
    public getRelationUsingGET(fromId: string, fromType: string, relationType: string, toId: string, toType: string, relationTypeGroup?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<EntityRelation>>;
    // tslint:disable-next-line:max-line-length
    public getRelationUsingGET(fromId: string, fromType: string, relationType: string, toId: string, toType: string, relationTypeGroup?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (fromId === null || fromId === undefined) {
            throw new Error('Required parameter fromId was null or undefined when calling getRelationUsingGET.');
        }
        if (fromType === null || fromType === undefined) {
            throw new Error('Required parameter fromType was null or undefined when calling getRelationUsingGET.');
        }
        if (relationType === null || relationType === undefined) {
            throw new Error('Required parameter relationType was null or undefined when calling getRelationUsingGET.');
        }
        if (toId === null || toId === undefined) {
            throw new Error('Required parameter toId was null or undefined when calling getRelationUsingGET.');
        }
        if (toType === null || toType === undefined) {
            throw new Error('Required parameter toType was null or undefined when calling getRelationUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (relationTypeGroup !== undefined && relationTypeGroup !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            relationTypeGroup as any, 'relationTypeGroup');
        }
        if (fromId !== undefined && fromId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            fromId as any, 'fromId');
        }
        if (fromType !== undefined && fromType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            fromType as any, 'fromType');
        }
        if (relationType !== undefined && relationType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            relationType as any, 'relationType');
        }
        if (toId !== undefined && toId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            toId as any, 'toId');
        }
        if (toType !== undefined && toType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            toType as any, 'toType');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<EntityRelation>(`${this.configuration.basePath}/api/relation${encodeURIComponent(String(this.relationTypeGroupfromIdfromTyperelationTypetoIdtoType))}`,
            {
                params: queryParameters,
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * saveRelation
     * @param relation relation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public saveRelationUsingPOST(relation: EntityRelation, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    // tslint:disable-next-line:max-line-length
    public saveRelationUsingPOST(relation: EntityRelation, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    // tslint:disable-next-line:max-line-length
    public saveRelationUsingPOST(relation: EntityRelation, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    // tslint:disable-next-line:max-line-length
    public saveRelationUsingPOST(relation: EntityRelation, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (relation === null || relation === undefined) {
            throw new Error('Required parameter relation was null or undefined when calling saveRelationUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/relation`,
            relation,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

}
