/**
 * ThingsBoard REST API
 * For instructions how to authorize requests please visit <a href=\'http://thingsboard.io/docs/reference/rest-api/\'>REST API documentation page</a>.
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@thingsboard.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ReportConfig { 
    baseUrl?: string;
    dashboardId?: string;
    namePattern?: string;
    state?: string;
    timewindow?: string;
    timezone?: string;
    type?: string;
    useCurrentUserCredentials?: boolean;
    useDashboardTimewindow?: boolean;
    userId?: string;
}

