/**
 * ThingsBoard REST API
 * For instructions how to authorize requests please visit <a href=\'http://thingsboard.io/docs/reference/rest-api/\'>REST API documentation page</a>.
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@thingsboard.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DeferredResultResponseEntity {
    SnowDirection: any;
    WindDirection: any;
    BackTrackingMode: any;
    timestamp: any;
    Last_Connection: any;
    NumCommAlarmNcu: any;
    NumMeteoAlarmNcu: any;
    NumOtherAlarmNcu: any;
    NumCommAlarmTcuTotal: any;
    NumBattAlarmTcuTotal: any;
    NumAxisAlarmTcuTotal: any;
    NumMotorAlarmTcuTotal: any;
    NumSwAlarmTcuTotal: any;
    NumCommAlarmRsuTotal: any;
    NumMeteoAlarmRsuTotal: any;
    NumButtonAlarmTcuTotal: any;
    NumSensorAlarmRsuTotal: any;
    NumCommAlarmNcuTotalPlanta: any;
    NumMeteoAlarmNcuTotalPlanta: any;
    NumOtherAlarmNcuTotalPlanta: any;
    NumSwAlarmTcuTotalPlanta: any;
    NumCommAlarmTcuTotalPlanta: any;
    NumMotorAlarmTcuTotalPlanta: any;
    NumAxisAlarmTcuTotalPlanta: any;
    NumBattAlarmTcuTotalPlanta: any;
    NumCommAlarmRsuTotalPlanta: any;
    NumMeteoAlarmRsuTotalPlanta: any;
    NumButtonAlarmTcuTotalPlanta: any;
    NumSensorAlarmRsuTotalPlanta: any;
    MotorCurrent_a1_mA: any;
    MotorCurrentPeak_a1_mA: any;
    State_Of_Charge: any;
    Battery_UPS: any;
    NumCommAlarmTcuTotal_Ncu1: any;
    NumCommAlarmTcuTotal_Ncu2: any;
    NumCommAlarmTcuTotal_Ncu3: any;
    NumCommAlarmTcuTotal_Ncu4: any;
    NumCommAlarmTcuTotal_Ncu5: any;
    NumCommAlarmTcuTotal_Ncu6: any;
    NumCommAlarmTcuTotal_Ncu7: any;
    NumCommAlarmTcuTotal_Ncu8: any;
    NumCommAlarmTcuTotal_Ncu9: any;
    NumCommAlarmTcuTotal_Ncu10: any;
    NumSwAlarmTcuTotal_Ncu1: any;
    NumSwAlarmTcuTotal_Ncu2: any;
    NumSwAlarmTcuTotal_Ncu3: any;
    NumSwAlarmTcuTotal_Ncu4: any;
    NumSwAlarmTcuTotal_Ncu5: any;
    NumSwAlarmTcuTotal_Ncu6: any;
    NumSwAlarmTcuTotal_Ncu7: any;
    NumSwAlarmTcuTotal_Ncu8: any;
    NumSwAlarmTcuTotal_Ncu9: any;
    NumSwAlarmTcuTotal_Ncu10: any;
    NumBattAlarmTcuTotal_Ncu1: any;
    NumBattAlarmTcuTotal_Ncu2: any;
    NumBattAlarmTcuTotal_Ncu3: any;
    NumBattAlarmTcuTotal_Ncu4: any;
    NumBattAlarmTcuTotal_Ncu5: any;
    NumBattAlarmTcuTotal_Ncu6: any;
    NumBattAlarmTcuTotal_Ncu7: any;
    NumBattAlarmTcuTotal_Ncu8: any;
    NumBattAlarmTcuTotal_Ncu9: any;
    NumBattAlarmTcuTotal_Ncu10: any;
    NumAxisAlarmTcuTotal_Ncu1: any;
    NumAxisAlarmTcuTotal_Ncu2: any;
    NumAxisAlarmTcuTotal_Ncu3: any;
    NumAxisAlarmTcuTotal_Ncu4: any;
    NumAxisAlarmTcuTotal_Ncu5: any;
    NumAxisAlarmTcuTotal_Ncu6: any;
    NumAxisAlarmTcuTotal_Ncu7: any;
    NumAxisAlarmTcuTotal_Ncu8: any;
    NumAxisAlarmTcuTotal_Ncu9: any;
    NumAxisAlarmTcuTotal_Ncu10: any;
    NumMotorAlarmTcuTotal_Ncu1: any;
    NumMotorAlarmTcuTotal_Ncu2: any;
    NumMotorAlarmTcuTotal_Ncu3: any;
    NumMotorAlarmTcuTotal_Ncu4: any;
    NumMotorAlarmTcuTotal_Ncu5: any;
    NumMotorAlarmTcuTotal_Ncu6: any;
    NumMotorAlarmTcuTotal_Ncu7: any;
    NumMotorAlarmTcuTotal_Ncu8: any;
    NumMotorAlarmTcuTotal_Ncu9: any;
    NumMotorAlarmTcuTotal_Ncu10: any;
    NumCommAlarmRsuTotal_Ncu1: any;
    NumCommAlarmRsuTotal_Ncu2: any;
    NumCommAlarmRsuTotal_Ncu3: any;
    NumCommAlarmRsuTotal_Ncu4: any;
    NumCommAlarmRsuTotal_Ncu5: any;
    NumCommAlarmRsuTotal_Ncu6: any;
    NumCommAlarmRsuTotal_Ncu7: any;
    NumCommAlarmRsuTotal_Ncu8: any;
    NumCommAlarmRsuTotal_Ncu9: any;
    NumCommAlarmRsuTotal_Ncu10: any;
    NumMeteoAlarmRsuTotal_Ncu1: any;
    NumMeteoAlarmRsuTotal_Ncu2: any;
    NumMeteoAlarmRsuTotal_Ncu3: any;
    NumMeteoAlarmRsuTotal_Ncu4: any;
    NumMeteoAlarmRsuTotal_Ncu5: any;
    NumMeteoAlarmRsuTotal_Ncu6: any;
    NumMeteoAlarmRsuTotal_Ncu7: any;
    NumMeteoAlarmRsuTotal_Ncu8: any;
    NumMeteoAlarmRsuTotal_Ncu9: any;
    NumMeteoAlarmRsuTotal_Ncu10: any;
    NumCommAlarmTotal: any;
    NumSwAlarmTotal: any;
    NumAxisAlarmTotal: any;
    NumMotorAlarmTotal: any;
    NumBattAlarmTotal: any;
    NumMeteoAlarm_Rsu1: any;
    NumCommAlarm_Rsu1: any;
    NumCommAlarm_Rsu2: any;
    NumMeteoAlarm_Rsu2: any;
    NumCommAlarm_Rsu3: any;
    NumMeteoAlarm_Rsu3: any;
    NumCommAlarm_Rsu4: any;
    NumMeteoAlarm_Rsu4: any;
    NumCommAlarm_Rsu5: any;
    NumMeteoAlarm_Rsu5: any;
    NumCommAlarm_Rsu6: any;
    NumMeteoAlarm_Rsu6: any;
    NumCommAlarm_Rsu7: any;
    NumMeteoAlarm_Rsu7: any;
    NumCommAlarm_Rsu8: any;
    NumMeteoAlarm_Rsu8: any;
    NumCommAlarm_Rsu9: any;
    NumMeteoAlarm_Rsu9: any;
    NumCommAlarm_Rsu10: any;
    NumMeteoAlarm_Rsu10: any;
    AlarmAnySS_Ncu10: any;
    AlarmAnyFS_Ncu10: any;
    AlarmAnySS_Ncu9: any;
    AlarmAnyFS_Ncu9: any;
    AlarmAnySS_Ncu8: any;
    AlarmAnyFS_Ncu8: any;
    AlarmAnySS_Ncu7: any;
    AlarmAnyFS_Ncu7: any;
    AlarmAnySS_Ncu6: any;
    AlarmAnyFS_Ncu6: any;
    AlarmAnySS_Ncu5: any;
    AlarmAnyFS_Ncu5: any;
    AlarmAnySS_Ncu4: any;
    AlarmAnyFS_Ncu4: any;
    AlarmAnySS_Ncu3: any;
    AlarmAnyFS_Ncu3: any;
    AlarmAnySS_Ncu2: any;
    AlarmAnyFS_Ncu2: any;
    AlarmAnySS_Ncu1: any;
    AlarmAnyFS_Ncu1: any;
    AlarmAnyFS: any;
    AlarmAnySS: any;
    AlarmAnyWS: any;
    AlarmAnyRsuComm: any;
    AlarmAnemoComm: any;
    AlarmUps: any;
    Gw2Timeout: any;
    Gw1Timeout: any;
    AlarmAnyFlood: any;
    AlarmAnyGustWind: any;
    AlarmAnySnow: any;
    AlarmAnyWind: any;
    AlarmWind: any;
    Vfault: any;
    BadConfiguration: any;
    AlarmNoFloodInfo: any;
    AlarmNoSnowInfo: any;
    AlarmNoWindInfo: any;
    FlagLsSwTop1: any;
    Remaining_Capacity_mAh: any;
    Voltage_mV: any;
    Current_mA: any;
    Average_Current_mA: any;
    Temp_Bat_C: any;
    TempBat_C: any;
    Temp_Pcb_C: any;
    DifferenceRel: any;
    Temp_Measure_C: any;
    V24Meas_mV: any;
    VBusMeas_mV: any;
    AlarmSmartLimit: any;
    SafePos1_a1_deg: any;
    SafePos2_a1_deg: any;
    SafePos3_a1_deg: any;
    SafePos4_a1_deg: any;
    SafePos5_a1_deg: any;
    SafePos6_a1_deg: any;
    SafePos7_a1_deg: any;
    RemSafePositionRequest: any;
    TmaxNoComm_min: any;
    SafePositionRequest: any;
    FlagWaitBeforeSleep: any;
    PcManualControlRequest: any;
    MotorCurrentLongPeak_a1_mA: any;
    Motor_Current_Peak_a1_mA: any;
    Motor_Current_a1_mA: any;
    FlagLsSwBot1: any;
    FlagLsTop1: any;
    CraneManualControl: any;
    MainStateRequest: any;
    SlopeFallingAzOnGround_deg: any;
    PanelCurrent_mA: any;
    PanelVoltage_mV: any;
    Full_Capacity_mAh: any;
    Charge: any;
    SelfPowered: any;
    DifferenceAbs: any;
    Target_Angle_a1_deg: any;
    Position_a1_deg: any;
    BTActive_a1: any;
    SleepState: any;
    CommissionState: any;
    MainState: any;
    HwAlarmsSummary: any;
    FlagHold1: any;
    AlarmTimeNeverSet: any;
    AlarmMotor1OverCurrentSw: any;
    AlarmMotor1OverCurrentHw: any;
    AlarmLowTemp: any;
    AlarmAxis1LowSpeed: any;
    AlarmAxis1Blocked: any;
    AlarmOutOfRange: any;
    AlarmLsHwBot1: any;
    AlarmLsHwTop1: any;
    AlarmCommLost: any;
    AlarmStopButton: any;
    SnowLevel_m: any;
    NumberOfActivatedAlarms: any;
    NumberOfActivatedStates: any;
    SafePositionState: any;
    Plant_Disponibility: any;
    AccessGranted: any;
    PowerSupplyLow: any;
    PowerSupplyHigh: any;
    VccLow: any;
    VccHigh: any;
    TempLow: any;
    TempHigh: any;
    ControlOutputMonitorSummary: any;
    PowerOutputMonitorSummary: any;
    FlagNewSwAvailable: any;
    FlagNVMDataSaved: any;
    FlagPassWordUpdated: any;
    FlagSystemOk: any;
    RtcEnterMode: any;
    RtcUpdate: any;
    CalibrateVane: any;
    CalibrateSnowSensor: any;
    ZBNetworkReset: any;
    InstallNewSw: any;
    SaveDataToNVM: any;
    WindLevel: any;
    AlarmWindSensor: any;
    AlarmSnowSensor: any;
    AlarmSystemMonitor: any;
    AlarmSnow: any;
    AlarmRain: any;
    AlarmWindLow: any;
    AlarmZigbee: any;
    AlarmTestBuild: any;
    AlarmWindMid: any;
    AlarmWindHigh: any;
    WindDirectionState: any;
    Irradiance_Wm2x100: any;
    AlarmSnowSnowLevel_m: any;
    WindDir_deg: any;
    WindSpeed_kmh: any;
    Last_Communication: any;
    result?: object;
    setOrExpired?: boolean;

    WindDir_Rsu1: any;
    Rsu1WindSpeed: any;
    Rsu1WindDirection: any;
    AlarmGustyWind_Rsu1: any;
    Rsu1SnowLevel: any;
    Rsu1BaseLine: any;
    Rsu1Irradiance: any;

    WindDir_Rsu2: any;
    Rsu2WindSpeed: any;
    Rsu2WindDirection: any;
    AlarmGustyWind_Rsu2: any;
    Rsu2SnowLevel: any;
    Rsu2BaseLine: any;
    Rsu2Irradiance: any;

    WindDir_Rsu3: any;
    Rsu3WindSpeed: any;
    Rsu3WindDirection: any;
    AlarmGustyWind_Rsu3: any;
    Rsu3SnowLevel: any;
    Rsu3BaseLine: any;
    Rsu3Irradiance: any;

    WindDir_Rsu4: any;
    Rsu4WindSpeed: any;
    Rsu4WindDirection: any;
    AlarmGustyWind_Rsu4: any;
    Rsu4SnowLevel: any;
    Rsu4BaseLine: any;
    Rsu4Irradiance: any;

    WindDir_Rsu5: any;
    Rsu5WindSpeed: any;
    Rsu5WindDirection: any;
    AlarmGustyWind_Rsu5: any;
    Rsu5SnowLevel: any;
    Rsu5BaseLine: any;
    Rsu5Irradiance: any;

    WindDir_Rsu6: any;
    Rsu6WindSpeed: any;
    Rsu6WindDirection: any;
    AlarmGustyWind_Rsu6: any;
    Rsu6SnowLevel: any;
    Rsu6BaseLine: any;
    Rsu6Irradiance: any;

    WindDir_Rsu7: any;
    Rsu7WindSpeed: any;
    Rsu7WindDirection: any;
    AlarmGustyWind_Rsu7: any;
    Rsu7SnowLevel: any;
    Rsu7BaseLine: any;
    Rsu7Irradiance: any;

    WindDir_Rsu8: any;
    Rsu8WindSpeed: any;
    Rsu8WindDirection: any;
    AlarmGustyWind_Rsu8: any;
    Rsu8SnowLevel: any;
    Rsu8BaseLine: any;
    Rsu8Irradiance: any;

    WindDir_Rsu9: any;
    Rsu9WindSpeed: any;
    Rsu9WindDirection: any;
    AlarmGustyWind_Rsu9: any;
    Rsu9SnowLevel: any;
    Rsu9BaseLine: any;
    Rsu9Irradiance: any;

    WindDir_Rsu10: any;
    Rsu10WindSpeed: any;
    Rsu10WindDirection: any;
    AlarmGustyWind_Rsu10: any;
    Rsu10SnowLevel: any;
    Rsu10BaseLine: any;
    Rsu10Irradiance: any;

    AlarmCriticalBatt: any;
    AlarmCom: any;
    AlarmLowBatt: any;
    AlarmNotEnoughBatt: any;
    AlarmLimitedBatt: any;

    UpsGroup: any;
    CmdStatus: any;
    SnowEvent: any;
    LinkDown: any;
    DataSaved: any;
    newActualization: any;
    WindDir: any;
    WindHighestLevel: any;
    DisableSafePosCtrl: any;
    PauseScan: any;
    ResetLinkDown: any;
    ResetMACAddress: any;
    ResetIpAddress: any;
    Reset: any;
    ResetSnowEvents: any;
    ForzeDataUpdate: any;

    Supplier: any;
    CommunicationProtocol: any;

    TargetAngle_a1_deg: any;

    WindLevel_Rsu1: any;
    AlarmWind_Rsu1: any;
    AlarmSnow_Rsu1: any;
    AlarmFlood_Rsu1: any;
    AlarmWS_Rsu1: any;
    AlarmComm_Rsu1: any;

    WindLevel_Rsu2: any;
    AlarmWind_Rsu2: any;
    AlarmSnow_Rsu2: any;
    AlarmFlood_Rsu2: any;
    AlarmWS_Rsu2: any;
    AlarmComm_Rsu2: any;

    WindLevel_Rsu3: any;
    AlarmWind_Rsu3: any;
    AlarmSnow_Rsu3: any;
    AlarmFlood_Rsu3: any;
    AlarmWS_Rsu3: any;
    AlarmComm_Rsu3: any;

    WindLevel_Rsu4: any;
    AlarmWind_Rsu4: any;
    AlarmSnow_Rsu4: any;
    AlarmFlood_Rsu4: any;
    AlarmWS_Rsu4: any;
    AlarmComm_Rsu4: any;

    WindLevel_Rsu5: any;
    AlarmWind_Rsu5: any;
    AlarmSnow_Rsu5: any;
    AlarmFlood_Rsu5: any;
    AlarmWS_Rsu5: any;
    AlarmComm_Rsu5: any;

    WindLevel_Rsu6: any;
    AlarmWind_Rsu6: any;
    AlarmSnow_Rsu6: any;
    AlarmFlood_Rsu6: any;
    AlarmWS_Rsu6: any;
    AlarmComm_Rsu6: any;

    WindLevel_Rsu7: any;
    AlarmWind_Rsu7: any;
    AlarmSnow_Rsu7: any;
    AlarmFlood_Rsu7: any;
    AlarmWS_Rsu7: any;
    AlarmComm_Rsu7: any;

    WindLevel_Rsu8: any;
    AlarmWind_Rsu8: any;
    AlarmSnow_Rsu8: any;
    AlarmFlood_Rsu8: any;
    AlarmWS_Rsu8: any;
    AlarmComm_Rsu8: any;

    WindLevel_Rsu9: any;
    AlarmWind_Rsu9: any;
    AlarmSnow_Rsu9: any;
    AlarmFlood_Rsu9: any;
    AlarmWS_Rsu9: any;
    AlarmComm_Rsu9: any;

    WindLevel_Rsu10: any;
    AlarmWind_Rsu10: any;
    AlarmSnow_Rsu10: any;
    AlarmFlood_Rsu10: any;
    AlarmWS_Rsu10: any;
    AlarmComm_Rsu10: any;

    AlarmSS_Rsu1: any;
    AlarmFS_Rsu1: any;
    AlarmSS_Rsu2: any;
    AlarmFS_Rsu2: any;
    AlarmSS_Rsu3: any;
    AlarmFS_Rsu3: any;
    AlarmSS_Rsu4: any;
    AlarmFS_Rsu4: any;
    AlarmSS_Rsu5: any;
    AlarmFS_Rsu5: any;
    AlarmSS_Rsu6: any;
    AlarmFS_Rsu6: any;
    AlarmSS_Rsu7: any;
    AlarmFS_Rsu7: any;
    AlarmSS_Rsu8: any;
    AlarmFS_Rsu8: any;
    AlarmSS_Rsu9: any;
    AlarmFS_Rsu9: any;
    AlarmSS_Rsu10: any;
    AlarmFS_Rsu10: any;



    AlarmAnyWind_Ncu1: any;
    AlarmAnySnow_Ncu1: any;
    AlarmAnyFlood_Ncu1: any;
    AlarmAnyWS_Ncu1: any;
    AlarmAnyRsuComm_Ncu1: any;
    WindLevel_Ncu1: any;
    AlarmAnemoComm_Ncu1: any;

    AlarmAnyWind_Ncu2: any;
    AlarmAnySnow_Ncu2: any;
    AlarmAnyFlood_Ncu2: any;
    AlarmAnyWS_Ncu2: any;
    AlarmAnyRsuComm_Ncu2: any;
    WindLevel_Ncu2: any;
    AlarmAnemoComm_Ncu2: any;

    AlarmAnyWind_Ncu3: any;
    AlarmAnySnow_Ncu3: any;
    AlarmAnyFlood_Ncu3: any;
    AlarmAnyWS_Ncu3: any;
    AlarmAnyRsuComm_Ncu3: any;
    WindLevel_Ncu3: any;
    AlarmAnemoComm_Ncu3: any;

    AlarmAnyWind_Ncu4: any;
    AlarmAnySnow_Ncu4: any;
    AlarmAnyFlood_Ncu4: any;
    AlarmAnyWS_Ncu4: any;
    AlarmAnyRsuComm_Ncu4: any;
    WindLevel_Ncu4: any;
    AlarmAnemoComm_Ncu4: any;

    AlarmAnyWind_Ncu5: any;
    AlarmAnySnow_Ncu5: any;
    AlarmAnyFlood_Ncu5: any;
    AlarmAnyWS_Ncu5: any;
    AlarmAnyRsuComm_Ncu5: any;
    WindLevel_Ncu5: any;
    AlarmAnemoComm_Ncu5: any;

    AlarmAnyWind_Ncu6: any;
    AlarmAnySnow_Ncu6: any;
    AlarmAnyFlood_Ncu6: any;
    AlarmAnyWS_Ncu6: any;
    AlarmAnyRsuComm_Ncu6: any;
    WindLevel_Ncu6: any;
    AlarmAnemoComm_Ncu6: any;

    AlarmAnyWind_Ncu7: any;
    AlarmAnySnow_Ncu7: any;
    AlarmAnyFlood_Ncu7: any;
    AlarmAnyWS_Ncu7: any;
    AlarmAnyRsuComm_Ncu7: any;
    WindLevel_Ncu7: any;
    AlarmAnemoComm_Ncu7: any;

    AlarmAnyWind_Ncu8: any;
    AlarmAnySnow_Ncu8: any;
    AlarmAnyFlood_Ncu8: any;
    AlarmAnyWS_Ncu8: any;
    AlarmAnyRsuComm_Ncu8: any;
    WindLevel_Ncu8: any;
    AlarmAnemoComm_Ncu8: any;
    Position_a1_deg_s1: any;
    Position_a1_deg_s2: any;
    Position_a1_deg_s3: any;
    Position_a1_deg_s4: any;
    Position_a1_deg_s5: any;
    Position_a1_deg_s6: any;
    Position_a1_deg_s7: any;
    Position_a1_deg_s8: any;
    Position_a1_deg_s9: any;
    Position_a1_deg_s10: any;
    Position_a1_deg_s11: any;
    Position_a1_deg_s12: any;
    Position_a1_deg_s13: any;
    Position_a1_deg_s14: any;
    Position_a1_deg_s15: any;
    Position_a1_deg_s16: any;
    Position_a1_deg_s17: any;
    Position_a1_deg_s18: any;
    Position_a1_deg_s19: any;
    Position_a1_deg_s20: any;
    Position_a1_deg_s21: any;
    Position_a1_deg_s22: any;
    Position_a1_deg_s23: any;
    Position_a1_deg_s24: any;
    Position_a1_deg_s25: any;
    Position_a1_deg_s26: any;
    Position_a1_deg_s27: any;
    Position_a1_deg_s28: any;
    Position_a1_deg_s29: any;
    Position_a1_deg_s30: any;
    Position_a1_deg_s31: any;
    Position_a1_deg_s32: any;
    Position_a1_deg_s33: any;
    Position_a1_deg_s34: any;
    Position_a1_deg_s35: any;
    Position_a1_deg_s36: any;
    Position_a1_deg_s37: any;
    Position_a1_deg_s38: any;
    Position_a1_deg_s39: any;
    Position_a1_deg_s40: any;
    Position_a1_deg_s41: any;
    Position_a1_deg_s42: any;
    Position_a1_deg_s43: any;
    Position_a1_deg_s44: any;
    Position_a1_deg_s45: any;
    Position_a1_deg_s46: any;
    Position_a1_deg_s47: any;
    Position_a1_deg_s48: any;
    Position_a1_deg_s49: any;
    Position_a1_deg_s50: any;
    Position_a1_deg_s51: any;
    Position_a1_deg_s52: any;
    Position_a1_deg_s53: any;
    Position_a1_deg_s54: any;
    Position_a1_deg_s55: any;
    Position_a1_deg_s56: any;
    Position_a1_deg_s57: any;
    Position_a1_deg_s58: any;
    Position_a1_deg_s59: any;
    Position_a1_deg_s60: any;
    Position_a1_deg_s61: any;
    Position_a1_deg_s62: any;
    Position_a1_deg_s63: any;
    Position_a1_deg_s64: any;
    Position_a1_deg_s65: any;
    Position_a1_deg_s66: any;
    Position_a1_deg_s67: any;
    Position_a1_deg_s68: any;
    Position_a1_deg_s69: any;
    Position_a1_deg_s70: any;
    Position_a1_deg_s71: any;
    Position_a1_deg_s72: any;
    Position_a1_deg_s73: any;
    Position_a1_deg_s74: any;
    Position_a1_deg_s75: any;
    Position_a1_deg_s76: any;
    Position_a1_deg_s77: any;
    Position_a1_deg_s78: any;
    Position_a1_deg_s79: any;
    Position_a1_deg_s80: any;
    Position_a1_deg_s81: any;
    Position_a1_deg_s82: any;
    Position_a1_deg_s83: any;
    Position_a1_deg_s84: any;
    Position_a1_deg_s85: any;
    Position_a1_deg_s86: any;
    Position_a1_deg_s87: any;
    Position_a1_deg_s88: any;
    Position_a1_deg_s89: any;
    Position_a1_deg_s90: any;
    Position_a1_deg_s91: any;
    Position_a1_deg_s92: any;
    Position_a1_deg_s93: any;
    Position_a1_deg_s94: any;
    Position_a1_deg_s95: any;
    Position_a1_deg_s96: any;
    Position_a1_deg_s97: any;
    Position_a1_deg_s98: any;
    Position_a1_deg_s99: any;
    Position_a1_deg_s100: any;
    Position_a1_deg_s101: any;
    Position_a1_deg_s102: any;
    Position_a1_deg_s103: any;
    Position_a1_deg_s104: any;
    Position_a1_deg_s105: any;
    Position_a1_deg_s106: any;
    Position_a1_deg_s107: any;
    Position_a1_deg_s108: any;
    Position_a1_deg_s109: any;
    Position_a1_deg_s110: any;
    Position_a1_deg_s111: any;
    Position_a1_deg_s112: any;
    Position_a1_deg_s113: any;
    Position_a1_deg_s114: any;
    Position_a1_deg_s115: any;
    Position_a1_deg_s116: any;
    Position_a1_deg_s117: any;
    Position_a1_deg_s118: any;
    Position_a1_deg_s119: any;
    Position_a1_deg_s120: any;
    Position_a1_deg_s121: any;
    Position_a1_deg_s122: any;
    Position_a1_deg_s123: any;
    Position_a1_deg_s124: any;
    Position_a1_deg_s125: any;
    Position_a1_deg_s126: any;
    Position_a1_deg_s127: any;
    Position_a1_deg_s128: any;
    Position_a1_deg_s129: any;
    Position_a1_deg_s130: any;
    Position_a1_deg_s131: any;
    Position_a1_deg_s132: any;
    Position_a1_deg_s133: any;
    Position_a1_deg_s134: any;
    Position_a1_deg_s135: any;
    Position_a1_deg_s136: any;
    Position_a1_deg_s137: any;
    Position_a1_deg_s138: any;
    Position_a1_deg_s139: any;
    Position_a1_deg_s140: any;
    Position_a1_deg_s141: any;
    Position_a1_deg_s142: any;
    Position_a1_deg_s143: any;
    Position_a1_deg_s144: any;
    Position_a1_deg_s145: any;
    Position_a1_deg_s146: any;
    Position_a1_deg_s147: any;
    Position_a1_deg_s148: any;
    Position_a1_deg_s149: any;
    Position_a1_deg_s150: any;
    Position_a1_deg_s151: any;
    Position_a1_deg_s152: any;
    Position_a1_deg_s153: any;
    Position_a1_deg_s154: any;
    Position_a1_deg_s155: any;
    Position_a1_deg_s156: any;
    Position_a1_deg_s157: any;
    Position_a1_deg_s158: any;
    Position_a1_deg_s159: any;
    Position_a1_deg_s160: any;
    Position_a1_deg_s161: any;
    Position_a1_deg_s162: any;
    Position_a1_deg_s163: any;
    Position_a1_deg_s164: any;
    Position_a1_deg_s165: any;
    Position_a1_deg_s166: any;
    Position_a1_deg_s167: any;
    Position_a1_deg_s168: any;
    Position_a1_deg_s169: any;
    Position_a1_deg_s170: any;
    Position_a1_deg_s171: any;
    Position_a1_deg_s172: any;
    Position_a1_deg_s173: any;
    Position_a1_deg_s174: any;
    Position_a1_deg_s175: any;
    Position_a1_deg_s176: any;
    Position_a1_deg_s177: any;
    Position_a1_deg_s178: any;
    Position_a1_deg_s179: any;
    Position_a1_deg_s180: any;
    Position_a1_deg_s181: any;
    Position_a1_deg_s182: any;
    Position_a1_deg_s183: any;
    Position_a1_deg_s184: any;
    Position_a1_deg_s185: any;
    Position_a1_deg_s186: any;
    Position_a1_deg_s187: any;
    Position_a1_deg_s188: any;
    Position_a1_deg_s189: any;
    Position_a1_deg_s190: any;
    Position_a1_deg_s191: any;
    Position_a1_deg_s192: any;
    Position_a1_deg_s193: any;
    Position_a1_deg_s194: any;
    Position_a1_deg_s195: any;
    Position_a1_deg_s196: any;
    Position_a1_deg_s197: any;
    Position_a1_deg_s198: any;
    Position_a1_deg_s199: any;
    Position_a1_deg_s200: any;
    Position_a1_deg_s201: any;
    Position_a1_deg_s202: any;
    Position_a1_deg_s203: any;
    Position_a1_deg_s204: any;
    Position_a1_deg_s205: any;
    Position_a1_deg_s206: any;
    Position_a1_deg_s207: any;
    Position_a1_deg_s208: any;
    Position_a1_deg_s209: any;
    Position_a1_deg_s210: any;
    Position_a1_deg_s211: any;
    Position_a1_deg_s212: any;
    Position_a1_deg_s213: any;
    Position_a1_deg_s214: any;
    Position_a1_deg_s215: any;
    Position_a1_deg_s216: any;
    Position_a1_deg_s217: any;
    Position_a1_deg_s218: any;
    Position_a1_deg_s219: any;
    Position_a1_deg_s220: any;
    Position_a1_deg_s221: any;
    Position_a1_deg_s222: any;
    Position_a1_deg_s223: any;
    Position_a1_deg_s224: any;
    Position_a1_deg_s225: any;
    Position_a1_deg_s226: any;
    Position_a1_deg_s227: any;
    Position_a1_deg_s228: any;
    Position_a1_deg_s229: any;
    Position_a1_deg_s230: any;
    Position_a1_deg_s231: any;
    Position_a1_deg_s232: any;
    Position_a1_deg_s233: any;
    Position_a1_deg_s234: any;
    Position_a1_deg_s235: any;
    Position_a1_deg_s236: any;
    Position_a1_deg_s237: any;
    Position_a1_deg_s238: any;
    Position_a1_deg_s239: any;
    Position_a1_deg_s240: any;
    Position_a1_deg_s241: any;
    Position_a1_deg_s242: any;
    Position_a1_deg_s243: any;
    Position_a1_deg_s244: any;
    Position_a1_deg_s245: any;
    Position_a1_deg_s246: any;
    Position_a1_deg_s247: any;
    Position_a1_deg_s248: any;
    Position_a1_deg_s249: any;
    Position_a1_deg_s250: any;
    Position_a1_deg_s251: any;
    Position_a1_deg_s252: any;
    Position_a1_deg_s253: any;
    Position_a1_deg_s254: any;
    Position_a1_deg_s255: any;
    Position_a1_deg_s256: any;
    StateOfCharge_s1: any;
    StateOfCharge_s2: any;
    StateOfCharge_s3: any;
    StateOfCharge_s4: any;
    StateOfCharge_s5: any;
    StateOfCharge_s6: any;
    StateOfCharge_s7: any;
    StateOfCharge_s8: any;
    StateOfCharge_s9: any;
    StateOfCharge_s10: any;
    StateOfCharge_s11: any;
    StateOfCharge_s12: any;
    StateOfCharge_s13: any;
    StateOfCharge_s14: any;
    StateOfCharge_s15: any;
    StateOfCharge_s16: any;
    StateOfCharge_s17: any;
    StateOfCharge_s18: any;
    StateOfCharge_s19: any;
    StateOfCharge_s20: any;
    StateOfCharge_s21: any;
    StateOfCharge_s22: any;
    StateOfCharge_s23: any;
    StateOfCharge_s24: any;
    StateOfCharge_s25: any;
    StateOfCharge_s26: any;
    StateOfCharge_s27: any;
    StateOfCharge_s28: any;
    StateOfCharge_s29: any;
    StateOfCharge_s30: any;
    StateOfCharge_s31: any;
    StateOfCharge_s32: any;
    StateOfCharge_s33: any;
    StateOfCharge_s34: any;
    StateOfCharge_s35: any;
    StateOfCharge_s36: any;
    StateOfCharge_s37: any;
    StateOfCharge_s38: any;
    StateOfCharge_s39: any;
    StateOfCharge_s40: any;
    StateOfCharge_s41: any;
    StateOfCharge_s42: any;
    StateOfCharge_s43: any;
    StateOfCharge_s44: any;
    StateOfCharge_s45: any;
    StateOfCharge_s46: any;
    StateOfCharge_s47: any;
    StateOfCharge_s48: any;
    StateOfCharge_s49: any;
    StateOfCharge_s50: any;
    StateOfCharge_s51: any;
    StateOfCharge_s52: any;
    StateOfCharge_s53: any;
    StateOfCharge_s54: any;
    StateOfCharge_s55: any;
    StateOfCharge_s56: any;
    StateOfCharge_s57: any;
    StateOfCharge_s58: any;
    StateOfCharge_s59: any;
    StateOfCharge_s60: any;
    StateOfCharge_s61: any;
    StateOfCharge_s62: any;
    StateOfCharge_s63: any;
    StateOfCharge_s64: any;
    StateOfCharge_s65: any;
    StateOfCharge_s66: any;
    StateOfCharge_s67: any;
    StateOfCharge_s68: any;
    StateOfCharge_s69: any;
    StateOfCharge_s70: any;
    StateOfCharge_s71: any;
    StateOfCharge_s72: any;
    StateOfCharge_s73: any;
    StateOfCharge_s74: any;
    StateOfCharge_s75: any;
    StateOfCharge_s76: any;
    StateOfCharge_s77: any;
    StateOfCharge_s78: any;
    StateOfCharge_s79: any;
    StateOfCharge_s80: any;
    StateOfCharge_s81: any;
    StateOfCharge_s82: any;
    StateOfCharge_s83: any;
    StateOfCharge_s84: any;
    StateOfCharge_s85: any;
    StateOfCharge_s86: any;
    StateOfCharge_s87: any;
    StateOfCharge_s88: any;
    StateOfCharge_s89: any;
    StateOfCharge_s90: any;
    StateOfCharge_s91: any;
    StateOfCharge_s92: any;
    StateOfCharge_s93: any;
    StateOfCharge_s94: any;
    StateOfCharge_s95: any;
    StateOfCharge_s96: any;
    StateOfCharge_s97: any;
    StateOfCharge_s98: any;
    StateOfCharge_s99: any;
    StateOfCharge_s100: any;
    StateOfCharge_s101: any;
    StateOfCharge_s102: any;
    StateOfCharge_s103: any;
    StateOfCharge_s104: any;
    StateOfCharge_s105: any;
    StateOfCharge_s106: any;
    StateOfCharge_s107: any;
    StateOfCharge_s108: any;
    StateOfCharge_s109: any;
    StateOfCharge_s110: any;
    StateOfCharge_s111: any;
    StateOfCharge_s112: any;
    StateOfCharge_s113: any;
    StateOfCharge_s114: any;
    StateOfCharge_s115: any;
    StateOfCharge_s116: any;
    StateOfCharge_s117: any;
    StateOfCharge_s118: any;
    StateOfCharge_s119: any;
    StateOfCharge_s120: any;
    StateOfCharge_s121: any;
    StateOfCharge_s122: any;
    StateOfCharge_s123: any;
    StateOfCharge_s124: any;
    StateOfCharge_s125: any;
    StateOfCharge_s126: any;
    StateOfCharge_s127: any;
    StateOfCharge_s128: any;
    StateOfCharge_s129: any;
    StateOfCharge_s130: any;
    StateOfCharge_s131: any;
    StateOfCharge_s132: any;
    StateOfCharge_s133: any;
    StateOfCharge_s134: any;
    StateOfCharge_s135: any;
    StateOfCharge_s136: any;
    StateOfCharge_s137: any;
    StateOfCharge_s138: any;
    StateOfCharge_s139: any;
    StateOfCharge_s140: any;
    StateOfCharge_s141: any;
    StateOfCharge_s142: any;
    StateOfCharge_s143: any;
    StateOfCharge_s144: any;
    StateOfCharge_s145: any;
    StateOfCharge_s146: any;
    StateOfCharge_s147: any;
    StateOfCharge_s148: any;
    StateOfCharge_s149: any;
    StateOfCharge_s150: any;
    StateOfCharge_s151: any;
    StateOfCharge_s152: any;
    StateOfCharge_s153: any;
    StateOfCharge_s154: any;
    StateOfCharge_s155: any;
    StateOfCharge_s156: any;
    StateOfCharge_s157: any;
    StateOfCharge_s158: any;
    StateOfCharge_s159: any;
    StateOfCharge_s160: any;
    StateOfCharge_s161: any;
    StateOfCharge_s162: any;
    StateOfCharge_s163: any;
    StateOfCharge_s164: any;
    StateOfCharge_s165: any;
    StateOfCharge_s166: any;
    StateOfCharge_s167: any;
    StateOfCharge_s168: any;
    StateOfCharge_s169: any;
    StateOfCharge_s170: any;
    StateOfCharge_s171: any;
    StateOfCharge_s172: any;
    StateOfCharge_s173: any;
    StateOfCharge_s174: any;
    StateOfCharge_s175: any;
    StateOfCharge_s176: any;
    StateOfCharge_s177: any;
    StateOfCharge_s178: any;
    StateOfCharge_s179: any;
    StateOfCharge_s180: any;
    StateOfCharge_s181: any;
    StateOfCharge_s182: any;
    StateOfCharge_s183: any;
    StateOfCharge_s184: any;
    StateOfCharge_s185: any;
    StateOfCharge_s186: any;
    StateOfCharge_s187: any;
    StateOfCharge_s188: any;
    StateOfCharge_s189: any;
    StateOfCharge_s190: any;
    StateOfCharge_s191: any;
    StateOfCharge_s192: any;
    StateOfCharge_s193: any;
    StateOfCharge_s194: any;
    StateOfCharge_s195: any;
    StateOfCharge_s196: any;
    StateOfCharge_s197: any;
    StateOfCharge_s198: any;
    StateOfCharge_s199: any;
    StateOfCharge_s200: any;
    StateOfCharge_s201: any;
    StateOfCharge_s202: any;
    StateOfCharge_s203: any;
    StateOfCharge_s204: any;
    StateOfCharge_s205: any;
    StateOfCharge_s206: any;
    StateOfCharge_s207: any;
    StateOfCharge_s208: any;
    StateOfCharge_s209: any;
    StateOfCharge_s210: any;
    StateOfCharge_s211: any;
    StateOfCharge_s212: any;
    StateOfCharge_s213: any;
    StateOfCharge_s214: any;
    StateOfCharge_s215: any;
    StateOfCharge_s216: any;
    StateOfCharge_s217: any;
    StateOfCharge_s218: any;
    StateOfCharge_s219: any;
    StateOfCharge_s220: any;
    StateOfCharge_s221: any;
    StateOfCharge_s222: any;
    StateOfCharge_s223: any;
    StateOfCharge_s224: any;
    StateOfCharge_s225: any;
    StateOfCharge_s226: any;
    StateOfCharge_s227: any;
    StateOfCharge_s228: any;
    StateOfCharge_s229: any;
    StateOfCharge_s230: any;
    StateOfCharge_s231: any;
    StateOfCharge_s232: any;
    StateOfCharge_s233: any;
    StateOfCharge_s234: any;
    StateOfCharge_s235: any;
    StateOfCharge_s236: any;
    StateOfCharge_s237: any;
    StateOfCharge_s238: any;
    StateOfCharge_s239: any;
    StateOfCharge_s240: any;
    StateOfCharge_s241: any;
    StateOfCharge_s242: any;
    StateOfCharge_s243: any;
    StateOfCharge_s244: any;
    StateOfCharge_s245: any;
    StateOfCharge_s246: any;
    StateOfCharge_s247: any;
    StateOfCharge_s248: any;
    StateOfCharge_s249: any;
    StateOfCharge_s250: any;
    StateOfCharge_s251: any;
    StateOfCharge_s252: any;
    StateOfCharge_s253: any;
    StateOfCharge_s254: any;
    StateOfCharge_s255: any;
    StateOfCharge_s256: any;
    TargetAngle_a1_deg_s1: any;
    TargetAngle_a1_deg_s2: any;
    TargetAngle_a1_deg_s3: any;
    TargetAngle_a1_deg_s4: any;
    TargetAngle_a1_deg_s5: any;
    TargetAngle_a1_deg_s6: any;
    TargetAngle_a1_deg_s7: any;
    TargetAngle_a1_deg_s8: any;
    TargetAngle_a1_deg_s9: any;
    TargetAngle_a1_deg_s10: any;
    TargetAngle_a1_deg_s11: any;
    TargetAngle_a1_deg_s12: any;
    TargetAngle_a1_deg_s13: any;
    TargetAngle_a1_deg_s14: any;
    TargetAngle_a1_deg_s15: any;
    TargetAngle_a1_deg_s16: any;
    TargetAngle_a1_deg_s17: any;
    TargetAngle_a1_deg_s18: any;
    TargetAngle_a1_deg_s19: any;
    TargetAngle_a1_deg_s20: any;
    TargetAngle_a1_deg_s21: any;
    TargetAngle_a1_deg_s22: any;
    TargetAngle_a1_deg_s23: any;
    TargetAngle_a1_deg_s24: any;
    TargetAngle_a1_deg_s25: any;
    TargetAngle_a1_deg_s26: any;
    TargetAngle_a1_deg_s27: any;
    TargetAngle_a1_deg_s28: any;
    TargetAngle_a1_deg_s29: any;
    TargetAngle_a1_deg_s30: any;
    TargetAngle_a1_deg_s31: any;
    TargetAngle_a1_deg_s32: any;
    TargetAngle_a1_deg_s33: any;
    TargetAngle_a1_deg_s34: any;
    TargetAngle_a1_deg_s35: any;
    TargetAngle_a1_deg_s36: any;
    TargetAngle_a1_deg_s37: any;
    TargetAngle_a1_deg_s38: any;
    TargetAngle_a1_deg_s39: any;
    TargetAngle_a1_deg_s40: any;
    TargetAngle_a1_deg_s41: any;
    TargetAngle_a1_deg_s42: any;
    TargetAngle_a1_deg_s43: any;
    TargetAngle_a1_deg_s44: any;
    TargetAngle_a1_deg_s45: any;
    TargetAngle_a1_deg_s46: any;
    TargetAngle_a1_deg_s47: any;
    TargetAngle_a1_deg_s48: any;
    TargetAngle_a1_deg_s49: any;
    TargetAngle_a1_deg_s50: any;
    TargetAngle_a1_deg_s51: any;
    TargetAngle_a1_deg_s52: any;
    TargetAngle_a1_deg_s53: any;
    TargetAngle_a1_deg_s54: any;
    TargetAngle_a1_deg_s55: any;
    TargetAngle_a1_deg_s56: any;
    TargetAngle_a1_deg_s57: any;
    TargetAngle_a1_deg_s58: any;
    TargetAngle_a1_deg_s59: any;
    TargetAngle_a1_deg_s60: any;
    TargetAngle_a1_deg_s61: any;
    TargetAngle_a1_deg_s62: any;
    TargetAngle_a1_deg_s63: any;
    TargetAngle_a1_deg_s64: any;
    TargetAngle_a1_deg_s65: any;
    TargetAngle_a1_deg_s66: any;
    TargetAngle_a1_deg_s67: any;
    TargetAngle_a1_deg_s68: any;
    TargetAngle_a1_deg_s69: any;
    TargetAngle_a1_deg_s70: any;
    TargetAngle_a1_deg_s71: any;
    TargetAngle_a1_deg_s72: any;
    TargetAngle_a1_deg_s73: any;
    TargetAngle_a1_deg_s74: any;
    TargetAngle_a1_deg_s75: any;
    TargetAngle_a1_deg_s76: any;
    TargetAngle_a1_deg_s77: any;
    TargetAngle_a1_deg_s78: any;
    TargetAngle_a1_deg_s79: any;
    TargetAngle_a1_deg_s80: any;
    TargetAngle_a1_deg_s81: any;
    TargetAngle_a1_deg_s82: any;
    TargetAngle_a1_deg_s83: any;
    TargetAngle_a1_deg_s84: any;
    TargetAngle_a1_deg_s85: any;
    TargetAngle_a1_deg_s86: any;
    TargetAngle_a1_deg_s87: any;
    TargetAngle_a1_deg_s88: any;
    TargetAngle_a1_deg_s89: any;
    TargetAngle_a1_deg_s90: any;
    TargetAngle_a1_deg_s91: any;
    TargetAngle_a1_deg_s92: any;
    TargetAngle_a1_deg_s93: any;
    TargetAngle_a1_deg_s94: any;
    TargetAngle_a1_deg_s95: any;
    TargetAngle_a1_deg_s96: any;
    TargetAngle_a1_deg_s97: any;
    TargetAngle_a1_deg_s98: any;
    TargetAngle_a1_deg_s99: any;
    TargetAngle_a1_deg_s100: any;
    TargetAngle_a1_deg_s101: any;
    TargetAngle_a1_deg_s102: any;
    TargetAngle_a1_deg_s103: any;
    TargetAngle_a1_deg_s104: any;
    TargetAngle_a1_deg_s105: any;
    TargetAngle_a1_deg_s106: any;
    TargetAngle_a1_deg_s107: any;
    TargetAngle_a1_deg_s108: any;
    TargetAngle_a1_deg_s109: any;
    TargetAngle_a1_deg_s110: any;
    TargetAngle_a1_deg_s111: any;
    TargetAngle_a1_deg_s112: any;
    TargetAngle_a1_deg_s113: any;
    TargetAngle_a1_deg_s114: any;
    TargetAngle_a1_deg_s115: any;
    TargetAngle_a1_deg_s116: any;
    TargetAngle_a1_deg_s117: any;
    TargetAngle_a1_deg_s118: any;
    TargetAngle_a1_deg_s119: any;
    TargetAngle_a1_deg_s120: any;
    TargetAngle_a1_deg_s121: any;
    TargetAngle_a1_deg_s122: any;
    TargetAngle_a1_deg_s123: any;
    TargetAngle_a1_deg_s124: any;
    TargetAngle_a1_deg_s125: any;
    TargetAngle_a1_deg_s126: any;
    TargetAngle_a1_deg_s127: any;
    TargetAngle_a1_deg_s128: any;
    TargetAngle_a1_deg_s129: any;
    TargetAngle_a1_deg_s130: any;
    TargetAngle_a1_deg_s131: any;
    TargetAngle_a1_deg_s132: any;
    TargetAngle_a1_deg_s133: any;
    TargetAngle_a1_deg_s134: any;
    TargetAngle_a1_deg_s135: any;
    TargetAngle_a1_deg_s136: any;
    TargetAngle_a1_deg_s137: any;
    TargetAngle_a1_deg_s138: any;
    TargetAngle_a1_deg_s139: any;
    TargetAngle_a1_deg_s140: any;
    TargetAngle_a1_deg_s141: any;
    TargetAngle_a1_deg_s142: any;
    TargetAngle_a1_deg_s143: any;
    TargetAngle_a1_deg_s144: any;
    TargetAngle_a1_deg_s145: any;
    TargetAngle_a1_deg_s146: any;
    TargetAngle_a1_deg_s147: any;
    TargetAngle_a1_deg_s148: any;
    TargetAngle_a1_deg_s149: any;
    TargetAngle_a1_deg_s150: any;
    TargetAngle_a1_deg_s151: any;
    TargetAngle_a1_deg_s152: any;
    TargetAngle_a1_deg_s153: any;
    TargetAngle_a1_deg_s154: any;
    TargetAngle_a1_deg_s155: any;
    TargetAngle_a1_deg_s156: any;
    TargetAngle_a1_deg_s157: any;
    TargetAngle_a1_deg_s158: any;
    TargetAngle_a1_deg_s159: any;
    TargetAngle_a1_deg_s160: any;
    TargetAngle_a1_deg_s161: any;
    TargetAngle_a1_deg_s162: any;
    TargetAngle_a1_deg_s163: any;
    TargetAngle_a1_deg_s164: any;
    TargetAngle_a1_deg_s165: any;
    TargetAngle_a1_deg_s166: any;
    TargetAngle_a1_deg_s167: any;
    TargetAngle_a1_deg_s168: any;
    TargetAngle_a1_deg_s169: any;
    TargetAngle_a1_deg_s170: any;
    TargetAngle_a1_deg_s171: any;
    TargetAngle_a1_deg_s172: any;
    TargetAngle_a1_deg_s173: any;
    TargetAngle_a1_deg_s174: any;
    TargetAngle_a1_deg_s175: any;
    TargetAngle_a1_deg_s176: any;
    TargetAngle_a1_deg_s177: any;
    TargetAngle_a1_deg_s178: any;
    TargetAngle_a1_deg_s179: any;
    TargetAngle_a1_deg_s180: any;
    TargetAngle_a1_deg_s181: any;
    TargetAngle_a1_deg_s182: any;
    TargetAngle_a1_deg_s183: any;
    TargetAngle_a1_deg_s184: any;
    TargetAngle_a1_deg_s185: any;
    TargetAngle_a1_deg_s186: any;
    TargetAngle_a1_deg_s187: any;
    TargetAngle_a1_deg_s188: any;
    TargetAngle_a1_deg_s189: any;
    TargetAngle_a1_deg_s190: any;
    TargetAngle_a1_deg_s191: any;
    TargetAngle_a1_deg_s192: any;
    TargetAngle_a1_deg_s193: any;
    TargetAngle_a1_deg_s194: any;
    TargetAngle_a1_deg_s195: any;
    TargetAngle_a1_deg_s196: any;
    TargetAngle_a1_deg_s197: any;
    TargetAngle_a1_deg_s198: any;
    TargetAngle_a1_deg_s199: any;
    TargetAngle_a1_deg_s200: any;
    TargetAngle_a1_deg_s201: any;
    TargetAngle_a1_deg_s202: any;
    TargetAngle_a1_deg_s203: any;
    TargetAngle_a1_deg_s204: any;
    TargetAngle_a1_deg_s205: any;
    TargetAngle_a1_deg_s206: any;
    TargetAngle_a1_deg_s207: any;
    TargetAngle_a1_deg_s208: any;
    TargetAngle_a1_deg_s209: any;
    TargetAngle_a1_deg_s210: any;
    TargetAngle_a1_deg_s211: any;
    TargetAngle_a1_deg_s212: any;
    TargetAngle_a1_deg_s213: any;
    TargetAngle_a1_deg_s214: any;
    TargetAngle_a1_deg_s215: any;
    TargetAngle_a1_deg_s216: any;
    TargetAngle_a1_deg_s217: any;
    TargetAngle_a1_deg_s218: any;
    TargetAngle_a1_deg_s219: any;
    TargetAngle_a1_deg_s220: any;
    TargetAngle_a1_deg_s221: any;
    TargetAngle_a1_deg_s222: any;
    TargetAngle_a1_deg_s223: any;
    TargetAngle_a1_deg_s224: any;
    TargetAngle_a1_deg_s225: any;
    TargetAngle_a1_deg_s226: any;
    TargetAngle_a1_deg_s227: any;
    TargetAngle_a1_deg_s228: any;
    TargetAngle_a1_deg_s229: any;
    TargetAngle_a1_deg_s230: any;
    TargetAngle_a1_deg_s231: any;
    TargetAngle_a1_deg_s232: any;
    TargetAngle_a1_deg_s233: any;
    TargetAngle_a1_deg_s234: any;
    TargetAngle_a1_deg_s235: any;
    TargetAngle_a1_deg_s236: any;
    TargetAngle_a1_deg_s237: any;
    TargetAngle_a1_deg_s238: any;
    TargetAngle_a1_deg_s239: any;
    TargetAngle_a1_deg_s240: any;
    TargetAngle_a1_deg_s241: any;
    TargetAngle_a1_deg_s242: any;
    TargetAngle_a1_deg_s243: any;
    TargetAngle_a1_deg_s244: any;
    TargetAngle_a1_deg_s245: any;
    TargetAngle_a1_deg_s246: any;
    TargetAngle_a1_deg_s247: any;
    TargetAngle_a1_deg_s248: any;
    TargetAngle_a1_deg_s249: any;
    TargetAngle_a1_deg_s250: any;
    TargetAngle_a1_deg_s251: any;
    TargetAngle_a1_deg_s252: any;
    TargetAngle_a1_deg_s253: any;
    TargetAngle_a1_deg_s254: any;
    TargetAngle_a1_deg_s255: any;
    TargetAngle_a1_deg_s256: any;
    DifferenceAbs_s1: any;
    DifferenceAbs_s2: any;
    DifferenceAbs_s3: any;
    DifferenceAbs_s4: any;
    DifferenceAbs_s5: any;
    DifferenceAbs_s6: any;
    DifferenceAbs_s7: any;
    DifferenceAbs_s8: any;
    DifferenceAbs_s9: any;
    DifferenceAbs_s10: any;
    DifferenceAbs_s11: any;
    DifferenceAbs_s12: any;
    DifferenceAbs_s13: any;
    DifferenceAbs_s14: any;
    DifferenceAbs_s15: any;
    DifferenceAbs_s16: any;
    DifferenceAbs_s17: any;
    DifferenceAbs_s18: any;
    DifferenceAbs_s19: any;
    DifferenceAbs_s20: any;
    DifferenceAbs_s21: any;
    DifferenceAbs_s22: any;
    DifferenceAbs_s23: any;
    DifferenceAbs_s24: any;
    DifferenceAbs_s25: any;
    DifferenceAbs_s26: any;
    DifferenceAbs_s27: any;
    DifferenceAbs_s28: any;
    DifferenceAbs_s29: any;
    DifferenceAbs_s30: any;
    DifferenceAbs_s31: any;
    DifferenceAbs_s32: any;
    DifferenceAbs_s33: any;
    DifferenceAbs_s34: any;
    DifferenceAbs_s35: any;
    DifferenceAbs_s36: any;
    DifferenceAbs_s37: any;
    DifferenceAbs_s38: any;
    DifferenceAbs_s39: any;
    DifferenceAbs_s40: any;
    DifferenceAbs_s41: any;
    DifferenceAbs_s42: any;
    DifferenceAbs_s43: any;
    DifferenceAbs_s44: any;
    DifferenceAbs_s45: any;
    DifferenceAbs_s46: any;
    DifferenceAbs_s47: any;
    DifferenceAbs_s48: any;
    DifferenceAbs_s49: any;
    DifferenceAbs_s50: any;
    DifferenceAbs_s51: any;
    DifferenceAbs_s52: any;
    DifferenceAbs_s53: any;
    DifferenceAbs_s54: any;
    DifferenceAbs_s55: any;
    DifferenceAbs_s56: any;
    DifferenceAbs_s57: any;
    DifferenceAbs_s58: any;
    DifferenceAbs_s59: any;
    DifferenceAbs_s60: any;
    DifferenceAbs_s61: any;
    DifferenceAbs_s62: any;
    DifferenceAbs_s63: any;
    DifferenceAbs_s64: any;
    DifferenceAbs_s65: any;
    DifferenceAbs_s66: any;
    DifferenceAbs_s67: any;
    DifferenceAbs_s68: any;
    DifferenceAbs_s69: any;
    DifferenceAbs_s70: any;
    DifferenceAbs_s71: any;
    DifferenceAbs_s72: any;
    DifferenceAbs_s73: any;
    DifferenceAbs_s74: any;
    DifferenceAbs_s75: any;
    DifferenceAbs_s76: any;
    DifferenceAbs_s77: any;
    DifferenceAbs_s78: any;
    DifferenceAbs_s79: any;
    DifferenceAbs_s80: any;
    DifferenceAbs_s81: any;
    DifferenceAbs_s82: any;
    DifferenceAbs_s83: any;
    DifferenceAbs_s84: any;
    DifferenceAbs_s85: any;
    DifferenceAbs_s86: any;
    DifferenceAbs_s87: any;
    DifferenceAbs_s88: any;
    DifferenceAbs_s89: any;
    DifferenceAbs_s90: any;
    DifferenceAbs_s91: any;
    DifferenceAbs_s92: any;
    DifferenceAbs_s93: any;
    DifferenceAbs_s94: any;
    DifferenceAbs_s95: any;
    DifferenceAbs_s96: any;
    DifferenceAbs_s97: any;
    DifferenceAbs_s98: any;
    DifferenceAbs_s99: any;
    DifferenceAbs_s100: any;
    DifferenceAbs_s101: any;
    DifferenceAbs_s102: any;
    DifferenceAbs_s103: any;
    DifferenceAbs_s104: any;
    DifferenceAbs_s105: any;
    DifferenceAbs_s106: any;
    DifferenceAbs_s107: any;
    DifferenceAbs_s108: any;
    DifferenceAbs_s109: any;
    DifferenceAbs_s110: any;
    DifferenceAbs_s111: any;
    DifferenceAbs_s112: any;
    DifferenceAbs_s113: any;
    DifferenceAbs_s114: any;
    DifferenceAbs_s115: any;
    DifferenceAbs_s116: any;
    DifferenceAbs_s117: any;
    DifferenceAbs_s118: any;
    DifferenceAbs_s119: any;
    DifferenceAbs_s120: any;
    DifferenceAbs_s121: any;
    DifferenceAbs_s122: any;
    DifferenceAbs_s123: any;
    DifferenceAbs_s124: any;
    DifferenceAbs_s125: any;
    DifferenceAbs_s126: any;
    DifferenceAbs_s127: any;
    DifferenceAbs_s128: any;
    DifferenceAbs_s129: any;
    DifferenceAbs_s130: any;
    DifferenceAbs_s131: any;
    DifferenceAbs_s132: any;
    DifferenceAbs_s133: any;
    DifferenceAbs_s134: any;
    DifferenceAbs_s135: any;
    DifferenceAbs_s136: any;
    DifferenceAbs_s137: any;
    DifferenceAbs_s138: any;
    DifferenceAbs_s139: any;
    DifferenceAbs_s140: any;
    DifferenceAbs_s141: any;
    DifferenceAbs_s142: any;
    DifferenceAbs_s143: any;
    DifferenceAbs_s144: any;
    DifferenceAbs_s145: any;
    DifferenceAbs_s146: any;
    DifferenceAbs_s147: any;
    DifferenceAbs_s148: any;
    DifferenceAbs_s149: any;
    DifferenceAbs_s150: any;
    DifferenceAbs_s151: any;
    DifferenceAbs_s152: any;
    DifferenceAbs_s153: any;
    DifferenceAbs_s154: any;
    DifferenceAbs_s155: any;
    DifferenceAbs_s156: any;
    DifferenceAbs_s157: any;
    DifferenceAbs_s158: any;
    DifferenceAbs_s159: any;
    DifferenceAbs_s160: any;
    DifferenceAbs_s161: any;
    DifferenceAbs_s162: any;
    DifferenceAbs_s163: any;
    DifferenceAbs_s164: any;
    DifferenceAbs_s165: any;
    DifferenceAbs_s166: any;
    DifferenceAbs_s167: any;
    DifferenceAbs_s168: any;
    DifferenceAbs_s169: any;
    DifferenceAbs_s170: any;
    DifferenceAbs_s171: any;
    DifferenceAbs_s172: any;
    DifferenceAbs_s173: any;
    DifferenceAbs_s174: any;
    DifferenceAbs_s175: any;
    DifferenceAbs_s176: any;
    DifferenceAbs_s177: any;
    DifferenceAbs_s178: any;
    DifferenceAbs_s179: any;
    DifferenceAbs_s180: any;
    DifferenceAbs_s181: any;
    DifferenceAbs_s182: any;
    DifferenceAbs_s183: any;
    DifferenceAbs_s184: any;
    DifferenceAbs_s185: any;
    DifferenceAbs_s186: any;
    DifferenceAbs_s187: any;
    DifferenceAbs_s188: any;
    DifferenceAbs_s189: any;
    DifferenceAbs_s190: any;
    DifferenceAbs_s191: any;
    DifferenceAbs_s192: any;
    DifferenceAbs_s193: any;
    DifferenceAbs_s194: any;
    DifferenceAbs_s195: any;
    DifferenceAbs_s196: any;
    DifferenceAbs_s197: any;
    DifferenceAbs_s198: any;
    DifferenceAbs_s199: any;
    DifferenceAbs_s200: any;
    DifferenceAbs_s201: any;
    DifferenceAbs_s202: any;
    DifferenceAbs_s203: any;
    DifferenceAbs_s204: any;
    DifferenceAbs_s205: any;
    DifferenceAbs_s206: any;
    DifferenceAbs_s207: any;
    DifferenceAbs_s208: any;
    DifferenceAbs_s209: any;
    DifferenceAbs_s210: any;
    DifferenceAbs_s211: any;
    DifferenceAbs_s212: any;
    DifferenceAbs_s213: any;
    DifferenceAbs_s214: any;
    DifferenceAbs_s215: any;
    DifferenceAbs_s216: any;
    DifferenceAbs_s217: any;
    DifferenceAbs_s218: any;
    DifferenceAbs_s219: any;
    DifferenceAbs_s220: any;
    DifferenceAbs_s221: any;
    DifferenceAbs_s222: any;
    DifferenceAbs_s223: any;
    DifferenceAbs_s224: any;
    DifferenceAbs_s225: any;
    DifferenceAbs_s226: any;
    DifferenceAbs_s227: any;
    DifferenceAbs_s228: any;
    DifferenceAbs_s229: any;
    DifferenceAbs_s230: any;
    DifferenceAbs_s231: any;
    DifferenceAbs_s232: any;
    DifferenceAbs_s233: any;
    DifferenceAbs_s234: any;
    DifferenceAbs_s235: any;
    DifferenceAbs_s236: any;
    DifferenceAbs_s237: any;
    DifferenceAbs_s238: any;
    DifferenceAbs_s239: any;
    DifferenceAbs_s240: any;
    DifferenceAbs_s241: any;
    DifferenceAbs_s242: any;
    DifferenceAbs_s243: any;
    DifferenceAbs_s244: any;
    DifferenceAbs_s245: any;
    DifferenceAbs_s246: any;
    DifferenceAbs_s247: any;
    DifferenceAbs_s248: any;
    DifferenceAbs_s249: any;
    DifferenceAbs_s250: any;
    DifferenceAbs_s251: any;
    DifferenceAbs_s252: any;
    DifferenceAbs_s253: any;
    DifferenceAbs_s254: any;
    DifferenceAbs_s255: any;
    DifferenceAbs_s256: any;
    MainState_s1: any;
    MainState_s2: any;
    MainState_s3: any;
    MainState_s4: any;
    MainState_s5: any;
    MainState_s6: any;
    MainState_s7: any;
    MainState_s8: any;
    MainState_s9: any;
    MainState_s10: any;
    MainState_s11: any;
    MainState_s12: any;
    MainState_s13: any;
    MainState_s14: any;
    MainState_s15: any;
    MainState_s16: any;
    MainState_s17: any;
    MainState_s18: any;
    MainState_s19: any;
    MainState_s20: any;
    MainState_s21: any;
    MainState_s22: any;
    MainState_s23: any;
    MainState_s24: any;
    MainState_s25: any;
    MainState_s26: any;
    MainState_s27: any;
    MainState_s28: any;
    MainState_s29: any;
    MainState_s30: any;
    MainState_s31: any;
    MainState_s32: any;
    MainState_s33: any;
    MainState_s34: any;
    MainState_s35: any;
    MainState_s36: any;
    MainState_s37: any;
    MainState_s38: any;
    MainState_s39: any;
    MainState_s40: any;
    MainState_s41: any;
    MainState_s42: any;
    MainState_s43: any;
    MainState_s44: any;
    MainState_s45: any;
    MainState_s46: any;
    MainState_s47: any;
    MainState_s48: any;
    MainState_s49: any;
    MainState_s50: any;
    MainState_s51: any;
    MainState_s52: any;
    MainState_s53: any;
    MainState_s54: any;
    MainState_s55: any;
    MainState_s56: any;
    MainState_s57: any;
    MainState_s58: any;
    MainState_s59: any;
    MainState_s60: any;
    MainState_s61: any;
    MainState_s62: any;
    MainState_s63: any;
    MainState_s64: any;
    MainState_s65: any;
    MainState_s66: any;
    MainState_s67: any;
    MainState_s68: any;
    MainState_s69: any;
    MainState_s70: any;
    MainState_s71: any;
    MainState_s72: any;
    MainState_s73: any;
    MainState_s74: any;
    MainState_s75: any;
    MainState_s76: any;
    MainState_s77: any;
    MainState_s78: any;
    MainState_s79: any;
    MainState_s80: any;
    MainState_s81: any;
    MainState_s82: any;
    MainState_s83: any;
    MainState_s84: any;
    MainState_s85: any;
    MainState_s86: any;
    MainState_s87: any;
    MainState_s88: any;
    MainState_s89: any;
    MainState_s90: any;
    MainState_s91: any;
    MainState_s92: any;
    MainState_s93: any;
    MainState_s94: any;
    MainState_s95: any;
    MainState_s96: any;
    MainState_s97: any;
    MainState_s98: any;
    MainState_s99: any;
    MainState_s100: any;
    MainState_s101: any;
    MainState_s102: any;
    MainState_s103: any;
    MainState_s104: any;
    MainState_s105: any;
    MainState_s106: any;
    MainState_s107: any;
    MainState_s108: any;
    MainState_s109: any;
    MainState_s110: any;
    MainState_s111: any;
    MainState_s112: any;
    MainState_s113: any;
    MainState_s114: any;
    MainState_s115: any;
    MainState_s116: any;
    MainState_s117: any;
    MainState_s118: any;
    MainState_s119: any;
    MainState_s120: any;
    MainState_s121: any;
    MainState_s122: any;
    MainState_s123: any;
    MainState_s124: any;
    MainState_s125: any;
    MainState_s126: any;
    MainState_s127: any;
    MainState_s128: any;
    MainState_s129: any;
    MainState_s130: any;
    MainState_s131: any;
    MainState_s132: any;
    MainState_s133: any;
    MainState_s134: any;
    MainState_s135: any;
    MainState_s136: any;
    MainState_s137: any;
    MainState_s138: any;
    MainState_s139: any;
    MainState_s140: any;
    MainState_s141: any;
    MainState_s142: any;
    MainState_s143: any;
    MainState_s144: any;
    MainState_s145: any;
    MainState_s146: any;
    MainState_s147: any;
    MainState_s148: any;
    MainState_s149: any;
    MainState_s150: any;
    MainState_s151: any;
    MainState_s152: any;
    MainState_s153: any;
    MainState_s154: any;
    MainState_s155: any;
    MainState_s156: any;
    MainState_s157: any;
    MainState_s158: any;
    MainState_s159: any;
    MainState_s160: any;
    MainState_s161: any;
    MainState_s162: any;
    MainState_s163: any;
    MainState_s164: any;
    MainState_s165: any;
    MainState_s166: any;
    MainState_s167: any;
    MainState_s168: any;
    MainState_s169: any;
    MainState_s170: any;
    MainState_s171: any;
    MainState_s172: any;
    MainState_s173: any;
    MainState_s174: any;
    MainState_s175: any;
    MainState_s176: any;
    MainState_s177: any;
    MainState_s178: any;
    MainState_s179: any;
    MainState_s180: any;
    MainState_s181: any;
    MainState_s182: any;
    MainState_s183: any;
    MainState_s184: any;
    MainState_s185: any;
    MainState_s186: any;
    MainState_s187: any;
    MainState_s188: any;
    MainState_s189: any;
    MainState_s190: any;
    MainState_s191: any;
    MainState_s192: any;
    MainState_s193: any;
    MainState_s194: any;
    MainState_s195: any;
    MainState_s196: any;
    MainState_s197: any;
    MainState_s198: any;
    MainState_s199: any;
    MainState_s200: any;
    MainState_s201: any;
    MainState_s202: any;
    MainState_s203: any;
    MainState_s204: any;
    MainState_s205: any;
    MainState_s206: any;
    MainState_s207: any;
    MainState_s208: any;
    MainState_s209: any;
    MainState_s210: any;
    MainState_s211: any;
    MainState_s212: any;
    MainState_s213: any;
    MainState_s214: any;
    MainState_s215: any;
    MainState_s216: any;
    MainState_s217: any;
    MainState_s218: any;
    MainState_s219: any;
    MainState_s220: any;
    MainState_s221: any;
    MainState_s222: any;
    MainState_s223: any;
    MainState_s224: any;
    MainState_s225: any;
    MainState_s226: any;
    MainState_s227: any;
    MainState_s228: any;
    MainState_s229: any;
    MainState_s230: any;
    MainState_s231: any;
    MainState_s232: any;
    MainState_s233: any;
    MainState_s234: any;
    MainState_s235: any;
    MainState_s236: any;
    MainState_s237: any;
    MainState_s238: any;
    MainState_s239: any;
    MainState_s240: any;
    MainState_s241: any;
    MainState_s242: any;
    MainState_s243: any;
    MainState_s244: any;
    MainState_s245: any;
    MainState_s246: any;
    MainState_s247: any;
    MainState_s248: any;
    MainState_s249: any;
    MainState_s250: any;
    MainState_s251: any;
    MainState_s252: any;
    MainState_s253: any;
    MainState_s254: any;
    MainState_s255: any;
    MainState_s256: any;
    AlarmStopButton_s1: any;
    AlarmStopButton_s2: any;
    AlarmStopButton_s3: any;
    AlarmStopButton_s4: any;
    AlarmStopButton_s5: any;
    AlarmStopButton_s6: any;
    AlarmStopButton_s7: any;
    AlarmStopButton_s8: any;
    AlarmStopButton_s9: any;
    AlarmStopButton_s10: any;
    AlarmStopButton_s11: any;
    AlarmStopButton_s12: any;
    AlarmStopButton_s13: any;
    AlarmStopButton_s14: any;
    AlarmStopButton_s15: any;
    AlarmStopButton_s16: any;
    AlarmStopButton_s17: any;
    AlarmStopButton_s18: any;
    AlarmStopButton_s19: any;
    AlarmStopButton_s20: any;
    AlarmStopButton_s21: any;
    AlarmStopButton_s22: any;
    AlarmStopButton_s23: any;
    AlarmStopButton_s24: any;
    AlarmStopButton_s25: any;
    AlarmStopButton_s26: any;
    AlarmStopButton_s27: any;
    AlarmStopButton_s28: any;
    AlarmStopButton_s29: any;
    AlarmStopButton_s30: any;
    AlarmStopButton_s31: any;
    AlarmStopButton_s32: any;
    AlarmStopButton_s33: any;
    AlarmStopButton_s34: any;
    AlarmStopButton_s35: any;
    AlarmStopButton_s36: any;
    AlarmStopButton_s37: any;
    AlarmStopButton_s38: any;
    AlarmStopButton_s39: any;
    AlarmStopButton_s40: any;
    AlarmStopButton_s41: any;
    AlarmStopButton_s42: any;
    AlarmStopButton_s43: any;
    AlarmStopButton_s44: any;
    AlarmStopButton_s45: any;
    AlarmStopButton_s46: any;
    AlarmStopButton_s47: any;
    AlarmStopButton_s48: any;
    AlarmStopButton_s49: any;
    AlarmStopButton_s50: any;
    AlarmStopButton_s51: any;
    AlarmStopButton_s52: any;
    AlarmStopButton_s53: any;
    AlarmStopButton_s54: any;
    AlarmStopButton_s55: any;
    AlarmStopButton_s56: any;
    AlarmStopButton_s57: any;
    AlarmStopButton_s58: any;
    AlarmStopButton_s59: any;
    AlarmStopButton_s60: any;
    AlarmStopButton_s61: any;
    AlarmStopButton_s62: any;
    AlarmStopButton_s63: any;
    AlarmStopButton_s64: any;
    AlarmStopButton_s65: any;
    AlarmStopButton_s66: any;
    AlarmStopButton_s67: any;
    AlarmStopButton_s68: any;
    AlarmStopButton_s69: any;
    AlarmStopButton_s70: any;
    AlarmStopButton_s71: any;
    AlarmStopButton_s72: any;
    AlarmStopButton_s73: any;
    AlarmStopButton_s74: any;
    AlarmStopButton_s75: any;
    AlarmStopButton_s76: any;
    AlarmStopButton_s77: any;
    AlarmStopButton_s78: any;
    AlarmStopButton_s79: any;
    AlarmStopButton_s80: any;
    AlarmStopButton_s81: any;
    AlarmStopButton_s82: any;
    AlarmStopButton_s83: any;
    AlarmStopButton_s84: any;
    AlarmStopButton_s85: any;
    AlarmStopButton_s86: any;
    AlarmStopButton_s87: any;
    AlarmStopButton_s88: any;
    AlarmStopButton_s89: any;
    AlarmStopButton_s90: any;
    AlarmStopButton_s91: any;
    AlarmStopButton_s92: any;
    AlarmStopButton_s93: any;
    AlarmStopButton_s94: any;
    AlarmStopButton_s95: any;
    AlarmStopButton_s96: any;
    AlarmStopButton_s97: any;
    AlarmStopButton_s98: any;
    AlarmStopButton_s99: any;
    AlarmStopButton_s100: any;
    AlarmStopButton_s101: any;
    AlarmStopButton_s102: any;
    AlarmStopButton_s103: any;
    AlarmStopButton_s104: any;
    AlarmStopButton_s105: any;
    AlarmStopButton_s106: any;
    AlarmStopButton_s107: any;
    AlarmStopButton_s108: any;
    AlarmStopButton_s109: any;
    AlarmStopButton_s110: any;
    AlarmStopButton_s111: any;
    AlarmStopButton_s112: any;
    AlarmStopButton_s113: any;
    AlarmStopButton_s114: any;
    AlarmStopButton_s115: any;
    AlarmStopButton_s116: any;
    AlarmStopButton_s117: any;
    AlarmStopButton_s118: any;
    AlarmStopButton_s119: any;
    AlarmStopButton_s120: any;
    AlarmStopButton_s121: any;
    AlarmStopButton_s122: any;
    AlarmStopButton_s123: any;
    AlarmStopButton_s124: any;
    AlarmStopButton_s125: any;
    AlarmStopButton_s126: any;
    AlarmStopButton_s127: any;
    AlarmStopButton_s128: any;
    AlarmStopButton_s129: any;
    AlarmStopButton_s130: any;
    AlarmStopButton_s131: any;
    AlarmStopButton_s132: any;
    AlarmStopButton_s133: any;
    AlarmStopButton_s134: any;
    AlarmStopButton_s135: any;
    AlarmStopButton_s136: any;
    AlarmStopButton_s137: any;
    AlarmStopButton_s138: any;
    AlarmStopButton_s139: any;
    AlarmStopButton_s140: any;
    AlarmStopButton_s141: any;
    AlarmStopButton_s142: any;
    AlarmStopButton_s143: any;
    AlarmStopButton_s144: any;
    AlarmStopButton_s145: any;
    AlarmStopButton_s146: any;
    AlarmStopButton_s147: any;
    AlarmStopButton_s148: any;
    AlarmStopButton_s149: any;
    AlarmStopButton_s150: any;
    AlarmStopButton_s151: any;
    AlarmStopButton_s152: any;
    AlarmStopButton_s153: any;
    AlarmStopButton_s154: any;
    AlarmStopButton_s155: any;
    AlarmStopButton_s156: any;
    AlarmStopButton_s157: any;
    AlarmStopButton_s158: any;
    AlarmStopButton_s159: any;
    AlarmStopButton_s160: any;
    AlarmStopButton_s161: any;
    AlarmStopButton_s162: any;
    AlarmStopButton_s163: any;
    AlarmStopButton_s164: any;
    AlarmStopButton_s165: any;
    AlarmStopButton_s166: any;
    AlarmStopButton_s167: any;
    AlarmStopButton_s168: any;
    AlarmStopButton_s169: any;
    AlarmStopButton_s170: any;
    AlarmStopButton_s171: any;
    AlarmStopButton_s172: any;
    AlarmStopButton_s173: any;
    AlarmStopButton_s174: any;
    AlarmStopButton_s175: any;
    AlarmStopButton_s176: any;
    AlarmStopButton_s177: any;
    AlarmStopButton_s178: any;
    AlarmStopButton_s179: any;
    AlarmStopButton_s180: any;
    AlarmStopButton_s181: any;
    AlarmStopButton_s182: any;
    AlarmStopButton_s183: any;
    AlarmStopButton_s184: any;
    AlarmStopButton_s185: any;
    AlarmStopButton_s186: any;
    AlarmStopButton_s187: any;
    AlarmStopButton_s188: any;
    AlarmStopButton_s189: any;
    AlarmStopButton_s190: any;
    AlarmStopButton_s191: any;
    AlarmStopButton_s192: any;
    AlarmStopButton_s193: any;
    AlarmStopButton_s194: any;
    AlarmStopButton_s195: any;
    AlarmStopButton_s196: any;
    AlarmStopButton_s197: any;
    AlarmStopButton_s198: any;
    AlarmStopButton_s199: any;
    AlarmStopButton_s200: any;
    AlarmStopButton_s201: any;
    AlarmStopButton_s202: any;
    AlarmStopButton_s203: any;
    AlarmStopButton_s204: any;
    AlarmStopButton_s205: any;
    AlarmStopButton_s206: any;
    AlarmStopButton_s207: any;
    AlarmStopButton_s208: any;
    AlarmStopButton_s209: any;
    AlarmStopButton_s210: any;
    AlarmStopButton_s211: any;
    AlarmStopButton_s212: any;
    AlarmStopButton_s213: any;
    AlarmStopButton_s214: any;
    AlarmStopButton_s215: any;
    AlarmStopButton_s216: any;
    AlarmStopButton_s217: any;
    AlarmStopButton_s218: any;
    AlarmStopButton_s219: any;
    AlarmStopButton_s220: any;
    AlarmStopButton_s221: any;
    AlarmStopButton_s222: any;
    AlarmStopButton_s223: any;
    AlarmStopButton_s224: any;
    AlarmStopButton_s225: any;
    AlarmStopButton_s226: any;
    AlarmStopButton_s227: any;
    AlarmStopButton_s228: any;
    AlarmStopButton_s229: any;
    AlarmStopButton_s230: any;
    AlarmStopButton_s231: any;
    AlarmStopButton_s232: any;
    AlarmStopButton_s233: any;
    AlarmStopButton_s234: any;
    AlarmStopButton_s235: any;
    AlarmStopButton_s236: any;
    AlarmStopButton_s237: any;
    AlarmStopButton_s238: any;
    AlarmStopButton_s239: any;
    AlarmStopButton_s240: any;
    AlarmStopButton_s241: any;
    AlarmStopButton_s242: any;
    AlarmStopButton_s243: any;
    AlarmStopButton_s244: any;
    AlarmStopButton_s245: any;
    AlarmStopButton_s246: any;
    AlarmStopButton_s247: any;
    AlarmStopButton_s248: any;
    AlarmStopButton_s249: any;
    AlarmStopButton_s250: any;
    AlarmStopButton_s251: any;
    AlarmStopButton_s252: any;
    AlarmStopButton_s253: any;
    AlarmStopButton_s254: any;
    AlarmStopButton_s255: any;
    AlarmStopButton_s256: any;
    AlarmZigbee_s1: any;
    AlarmZigbee_s2: any;
    AlarmZigbee_s3: any;
    AlarmZigbee_s4: any;
    AlarmZigbee_s5: any;
    AlarmZigbee_s6: any;
    AlarmZigbee_s7: any;
    AlarmZigbee_s8: any;
    AlarmZigbee_s9: any;
    AlarmZigbee_s10: any;
    AlarmZigbee_s11: any;
    AlarmZigbee_s12: any;
    AlarmZigbee_s13: any;
    AlarmZigbee_s14: any;
    AlarmZigbee_s15: any;
    AlarmZigbee_s16: any;
    AlarmZigbee_s17: any;
    AlarmZigbee_s18: any;
    AlarmZigbee_s19: any;
    AlarmZigbee_s20: any;
    AlarmZigbee_s21: any;
    AlarmZigbee_s22: any;
    AlarmZigbee_s23: any;
    AlarmZigbee_s24: any;
    AlarmZigbee_s25: any;
    AlarmZigbee_s26: any;
    AlarmZigbee_s27: any;
    AlarmZigbee_s28: any;
    AlarmZigbee_s29: any;
    AlarmZigbee_s30: any;
    AlarmZigbee_s31: any;
    AlarmZigbee_s32: any;
    AlarmZigbee_s33: any;
    AlarmZigbee_s34: any;
    AlarmZigbee_s35: any;
    AlarmZigbee_s36: any;
    AlarmZigbee_s37: any;
    AlarmZigbee_s38: any;
    AlarmZigbee_s39: any;
    AlarmZigbee_s40: any;
    AlarmZigbee_s41: any;
    AlarmZigbee_s42: any;
    AlarmZigbee_s43: any;
    AlarmZigbee_s44: any;
    AlarmZigbee_s45: any;
    AlarmZigbee_s46: any;
    AlarmZigbee_s47: any;
    AlarmZigbee_s48: any;
    AlarmZigbee_s49: any;
    AlarmZigbee_s50: any;
    AlarmZigbee_s51: any;
    AlarmZigbee_s52: any;
    AlarmZigbee_s53: any;
    AlarmZigbee_s54: any;
    AlarmZigbee_s55: any;
    AlarmZigbee_s56: any;
    AlarmZigbee_s57: any;
    AlarmZigbee_s58: any;
    AlarmZigbee_s59: any;
    AlarmZigbee_s60: any;
    AlarmZigbee_s61: any;
    AlarmZigbee_s62: any;
    AlarmZigbee_s63: any;
    AlarmZigbee_s64: any;
    AlarmZigbee_s65: any;
    AlarmZigbee_s66: any;
    AlarmZigbee_s67: any;
    AlarmZigbee_s68: any;
    AlarmZigbee_s69: any;
    AlarmZigbee_s70: any;
    AlarmZigbee_s71: any;
    AlarmZigbee_s72: any;
    AlarmZigbee_s73: any;
    AlarmZigbee_s74: any;
    AlarmZigbee_s75: any;
    AlarmZigbee_s76: any;
    AlarmZigbee_s77: any;
    AlarmZigbee_s78: any;
    AlarmZigbee_s79: any;
    AlarmZigbee_s80: any;
    AlarmZigbee_s81: any;
    AlarmZigbee_s82: any;
    AlarmZigbee_s83: any;
    AlarmZigbee_s84: any;
    AlarmZigbee_s85: any;
    AlarmZigbee_s86: any;
    AlarmZigbee_s87: any;
    AlarmZigbee_s88: any;
    AlarmZigbee_s89: any;
    AlarmZigbee_s90: any;
    AlarmZigbee_s91: any;
    AlarmZigbee_s92: any;
    AlarmZigbee_s93: any;
    AlarmZigbee_s94: any;
    AlarmZigbee_s95: any;
    AlarmZigbee_s96: any;
    AlarmZigbee_s97: any;
    AlarmZigbee_s98: any;
    AlarmZigbee_s99: any;
    AlarmZigbee_s100: any;
    AlarmZigbee_s101: any;
    AlarmZigbee_s102: any;
    AlarmZigbee_s103: any;
    AlarmZigbee_s104: any;
    AlarmZigbee_s105: any;
    AlarmZigbee_s106: any;
    AlarmZigbee_s107: any;
    AlarmZigbee_s108: any;
    AlarmZigbee_s109: any;
    AlarmZigbee_s110: any;
    AlarmZigbee_s111: any;
    AlarmZigbee_s112: any;
    AlarmZigbee_s113: any;
    AlarmZigbee_s114: any;
    AlarmZigbee_s115: any;
    AlarmZigbee_s116: any;
    AlarmZigbee_s117: any;
    AlarmZigbee_s118: any;
    AlarmZigbee_s119: any;
    AlarmZigbee_s120: any;
    AlarmZigbee_s121: any;
    AlarmZigbee_s122: any;
    AlarmZigbee_s123: any;
    AlarmZigbee_s124: any;
    AlarmZigbee_s125: any;
    AlarmZigbee_s126: any;
    AlarmZigbee_s127: any;
    AlarmZigbee_s128: any;
    AlarmZigbee_s129: any;
    AlarmZigbee_s130: any;
    AlarmZigbee_s131: any;
    AlarmZigbee_s132: any;
    AlarmZigbee_s133: any;
    AlarmZigbee_s134: any;
    AlarmZigbee_s135: any;
    AlarmZigbee_s136: any;
    AlarmZigbee_s137: any;
    AlarmZigbee_s138: any;
    AlarmZigbee_s139: any;
    AlarmZigbee_s140: any;
    AlarmZigbee_s141: any;
    AlarmZigbee_s142: any;
    AlarmZigbee_s143: any;
    AlarmZigbee_s144: any;
    AlarmZigbee_s145: any;
    AlarmZigbee_s146: any;
    AlarmZigbee_s147: any;
    AlarmZigbee_s148: any;
    AlarmZigbee_s149: any;
    AlarmZigbee_s150: any;
    AlarmZigbee_s151: any;
    AlarmZigbee_s152: any;
    AlarmZigbee_s153: any;
    AlarmZigbee_s154: any;
    AlarmZigbee_s155: any;
    AlarmZigbee_s156: any;
    AlarmZigbee_s157: any;
    AlarmZigbee_s158: any;
    AlarmZigbee_s159: any;
    AlarmZigbee_s160: any;
    AlarmZigbee_s161: any;
    AlarmZigbee_s162: any;
    AlarmZigbee_s163: any;
    AlarmZigbee_s164: any;
    AlarmZigbee_s165: any;
    AlarmZigbee_s166: any;
    AlarmZigbee_s167: any;
    AlarmZigbee_s168: any;
    AlarmZigbee_s169: any;
    AlarmZigbee_s170: any;
    AlarmZigbee_s171: any;
    AlarmZigbee_s172: any;
    AlarmZigbee_s173: any;
    AlarmZigbee_s174: any;
    AlarmZigbee_s175: any;
    AlarmZigbee_s176: any;
    AlarmZigbee_s177: any;
    AlarmZigbee_s178: any;
    AlarmZigbee_s179: any;
    AlarmZigbee_s180: any;
    AlarmZigbee_s181: any;
    AlarmZigbee_s182: any;
    AlarmZigbee_s183: any;
    AlarmZigbee_s184: any;
    AlarmZigbee_s185: any;
    AlarmZigbee_s186: any;
    AlarmZigbee_s187: any;
    AlarmZigbee_s188: any;
    AlarmZigbee_s189: any;
    AlarmZigbee_s190: any;
    AlarmZigbee_s191: any;
    AlarmZigbee_s192: any;
    AlarmZigbee_s193: any;
    AlarmZigbee_s194: any;
    AlarmZigbee_s195: any;
    AlarmZigbee_s196: any;
    AlarmZigbee_s197: any;
    AlarmZigbee_s198: any;
    AlarmZigbee_s199: any;
    AlarmZigbee_s200: any;
    AlarmZigbee_s201: any;
    AlarmZigbee_s202: any;
    AlarmZigbee_s203: any;
    AlarmZigbee_s204: any;
    AlarmZigbee_s205: any;
    AlarmZigbee_s206: any;
    AlarmZigbee_s207: any;
    AlarmZigbee_s208: any;
    AlarmZigbee_s209: any;
    AlarmZigbee_s210: any;
    AlarmZigbee_s211: any;
    AlarmZigbee_s212: any;
    AlarmZigbee_s213: any;
    AlarmZigbee_s214: any;
    AlarmZigbee_s215: any;
    AlarmZigbee_s216: any;
    AlarmZigbee_s217: any;
    AlarmZigbee_s218: any;
    AlarmZigbee_s219: any;
    AlarmZigbee_s220: any;
    AlarmZigbee_s221: any;
    AlarmZigbee_s222: any;
    AlarmZigbee_s223: any;
    AlarmZigbee_s224: any;
    AlarmZigbee_s225: any;
    AlarmZigbee_s226: any;
    AlarmZigbee_s227: any;
    AlarmZigbee_s228: any;
    AlarmZigbee_s229: any;
    AlarmZigbee_s230: any;
    AlarmZigbee_s231: any;
    AlarmZigbee_s232: any;
    AlarmZigbee_s233: any;
    AlarmZigbee_s234: any;
    AlarmZigbee_s235: any;
    AlarmZigbee_s236: any;
    AlarmZigbee_s237: any;
    AlarmZigbee_s238: any;
    AlarmZigbee_s239: any;
    AlarmZigbee_s240: any;
    AlarmZigbee_s241: any;
    AlarmZigbee_s242: any;
    AlarmZigbee_s243: any;
    AlarmZigbee_s244: any;
    AlarmZigbee_s245: any;
    AlarmZigbee_s246: any;
    AlarmZigbee_s247: any;
    AlarmZigbee_s248: any;
    AlarmZigbee_s249: any;
    AlarmZigbee_s250: any;
    AlarmZigbee_s251: any;
    AlarmZigbee_s252: any;
    AlarmZigbee_s253: any;
    AlarmZigbee_s254: any;
    AlarmZigbee_s255: any;
    AlarmZigbee_s256: any;
    AlarmCom_s1: any;
    AlarmCom_s2: any;
    AlarmCom_s3: any;
    AlarmCom_s4: any;
    AlarmCom_s5: any;
    AlarmCom_s6: any;
    AlarmCom_s7: any;
    AlarmCom_s8: any;
    AlarmCom_s9: any;
    AlarmCom_s10: any;
    AlarmCom_s11: any;
    AlarmCom_s12: any;
    AlarmCom_s13: any;
    AlarmCom_s14: any;
    AlarmCom_s15: any;
    AlarmCom_s16: any;
    AlarmCom_s17: any;
    AlarmCom_s18: any;
    AlarmCom_s19: any;
    AlarmCom_s20: any;
    AlarmCom_s21: any;
    AlarmCom_s22: any;
    AlarmCom_s23: any;
    AlarmCom_s24: any;
    AlarmCom_s25: any;
    AlarmCom_s26: any;
    AlarmCom_s27: any;
    AlarmCom_s28: any;
    AlarmCom_s29: any;
    AlarmCom_s30: any;
    AlarmCom_s31: any;
    AlarmCom_s32: any;
    AlarmCom_s33: any;
    AlarmCom_s34: any;
    AlarmCom_s35: any;
    AlarmCom_s36: any;
    AlarmCom_s37: any;
    AlarmCom_s38: any;
    AlarmCom_s39: any;
    AlarmCom_s40: any;
    AlarmCom_s41: any;
    AlarmCom_s42: any;
    AlarmCom_s43: any;
    AlarmCom_s44: any;
    AlarmCom_s45: any;
    AlarmCom_s46: any;
    AlarmCom_s47: any;
    AlarmCom_s48: any;
    AlarmCom_s49: any;
    AlarmCom_s50: any;
    AlarmCom_s51: any;
    AlarmCom_s52: any;
    AlarmCom_s53: any;
    AlarmCom_s54: any;
    AlarmCom_s55: any;
    AlarmCom_s56: any;
    AlarmCom_s57: any;
    AlarmCom_s58: any;
    AlarmCom_s59: any;
    AlarmCom_s60: any;
    AlarmCom_s61: any;
    AlarmCom_s62: any;
    AlarmCom_s63: any;
    AlarmCom_s64: any;
    AlarmCom_s65: any;
    AlarmCom_s66: any;
    AlarmCom_s67: any;
    AlarmCom_s68: any;
    AlarmCom_s69: any;
    AlarmCom_s70: any;
    AlarmCom_s71: any;
    AlarmCom_s72: any;
    AlarmCom_s73: any;
    AlarmCom_s74: any;
    AlarmCom_s75: any;
    AlarmCom_s76: any;
    AlarmCom_s77: any;
    AlarmCom_s78: any;
    AlarmCom_s79: any;
    AlarmCom_s80: any;
    AlarmCom_s81: any;
    AlarmCom_s82: any;
    AlarmCom_s83: any;
    AlarmCom_s84: any;
    AlarmCom_s85: any;
    AlarmCom_s86: any;
    AlarmCom_s87: any;
    AlarmCom_s88: any;
    AlarmCom_s89: any;
    AlarmCom_s90: any;
    AlarmCom_s91: any;
    AlarmCom_s92: any;
    AlarmCom_s93: any;
    AlarmCom_s94: any;
    AlarmCom_s95: any;
    AlarmCom_s96: any;
    AlarmCom_s97: any;
    AlarmCom_s98: any;
    AlarmCom_s99: any;
    AlarmCom_s100: any;
    AlarmCom_s101: any;
    AlarmCom_s102: any;
    AlarmCom_s103: any;
    AlarmCom_s104: any;
    AlarmCom_s105: any;
    AlarmCom_s106: any;
    AlarmCom_s107: any;
    AlarmCom_s108: any;
    AlarmCom_s109: any;
    AlarmCom_s110: any;
    AlarmCom_s111: any;
    AlarmCom_s112: any;
    AlarmCom_s113: any;
    AlarmCom_s114: any;
    AlarmCom_s115: any;
    AlarmCom_s116: any;
    AlarmCom_s117: any;
    AlarmCom_s118: any;
    AlarmCom_s119: any;
    AlarmCom_s120: any;
    AlarmCom_s121: any;
    AlarmCom_s122: any;
    AlarmCom_s123: any;
    AlarmCom_s124: any;
    AlarmCom_s125: any;
    AlarmCom_s126: any;
    AlarmCom_s127: any;
    AlarmCom_s128: any;
    AlarmCom_s129: any;
    AlarmCom_s130: any;
    AlarmCom_s131: any;
    AlarmCom_s132: any;
    AlarmCom_s133: any;
    AlarmCom_s134: any;
    AlarmCom_s135: any;
    AlarmCom_s136: any;
    AlarmCom_s137: any;
    AlarmCom_s138: any;
    AlarmCom_s139: any;
    AlarmCom_s140: any;
    AlarmCom_s141: any;
    AlarmCom_s142: any;
    AlarmCom_s143: any;
    AlarmCom_s144: any;
    AlarmCom_s145: any;
    AlarmCom_s146: any;
    AlarmCom_s147: any;
    AlarmCom_s148: any;
    AlarmCom_s149: any;
    AlarmCom_s150: any;
    AlarmCom_s151: any;
    AlarmCom_s152: any;
    AlarmCom_s153: any;
    AlarmCom_s154: any;
    AlarmCom_s155: any;
    AlarmCom_s156: any;
    AlarmCom_s157: any;
    AlarmCom_s158: any;
    AlarmCom_s159: any;
    AlarmCom_s160: any;
    AlarmCom_s161: any;
    AlarmCom_s162: any;
    AlarmCom_s163: any;
    AlarmCom_s164: any;
    AlarmCom_s165: any;
    AlarmCom_s166: any;
    AlarmCom_s167: any;
    AlarmCom_s168: any;
    AlarmCom_s169: any;
    AlarmCom_s170: any;
    AlarmCom_s171: any;
    AlarmCom_s172: any;
    AlarmCom_s173: any;
    AlarmCom_s174: any;
    AlarmCom_s175: any;
    AlarmCom_s176: any;
    AlarmCom_s177: any;
    AlarmCom_s178: any;
    AlarmCom_s179: any;
    AlarmCom_s180: any;
    AlarmCom_s181: any;
    AlarmCom_s182: any;
    AlarmCom_s183: any;
    AlarmCom_s184: any;
    AlarmCom_s185: any;
    AlarmCom_s186: any;
    AlarmCom_s187: any;
    AlarmCom_s188: any;
    AlarmCom_s189: any;
    AlarmCom_s190: any;
    AlarmCom_s191: any;
    AlarmCom_s192: any;
    AlarmCom_s193: any;
    AlarmCom_s194: any;
    AlarmCom_s195: any;
    AlarmCom_s196: any;
    AlarmCom_s197: any;
    AlarmCom_s198: any;
    AlarmCom_s199: any;
    AlarmCom_s200: any;
    AlarmCom_s201: any;
    AlarmCom_s202: any;
    AlarmCom_s203: any;
    AlarmCom_s204: any;
    AlarmCom_s205: any;
    AlarmCom_s206: any;
    AlarmCom_s207: any;
    AlarmCom_s208: any;
    AlarmCom_s209: any;
    AlarmCom_s210: any;
    AlarmCom_s211: any;
    AlarmCom_s212: any;
    AlarmCom_s213: any;
    AlarmCom_s214: any;
    AlarmCom_s215: any;
    AlarmCom_s216: any;
    AlarmCom_s217: any;
    AlarmCom_s218: any;
    AlarmCom_s219: any;
    AlarmCom_s220: any;
    AlarmCom_s221: any;
    AlarmCom_s222: any;
    AlarmCom_s223: any;
    AlarmCom_s224: any;
    AlarmCom_s225: any;
    AlarmCom_s226: any;
    AlarmCom_s227: any;
    AlarmCom_s228: any;
    AlarmCom_s229: any;
    AlarmCom_s230: any;
    AlarmCom_s231: any;
    AlarmCom_s232: any;
    AlarmCom_s233: any;
    AlarmCom_s234: any;
    AlarmCom_s235: any;
    AlarmCom_s236: any;
    AlarmCom_s237: any;
    AlarmCom_s238: any;
    AlarmCom_s239: any;
    AlarmCom_s240: any;
    AlarmCom_s241: any;
    AlarmCom_s242: any;
    AlarmCom_s243: any;
    AlarmCom_s244: any;
    AlarmCom_s245: any;
    AlarmCom_s246: any;
    AlarmCom_s247: any;
    AlarmCom_s248: any;
    AlarmCom_s249: any;
    AlarmCom_s250: any;
    AlarmCom_s251: any;
    AlarmCom_s252: any;
    AlarmCom_s253: any;
    AlarmCom_s254: any;
    AlarmCom_s255: any;
    AlarmCom_s256: any;
    AlarmLsHwTop1_s1: any;
    AlarmLsHwTop1_s2: any;
    AlarmLsHwTop1_s3: any;
    AlarmLsHwTop1_s4: any;
    AlarmLsHwTop1_s5: any;
    AlarmLsHwTop1_s6: any;
    AlarmLsHwTop1_s7: any;
    AlarmLsHwTop1_s8: any;
    AlarmLsHwTop1_s9: any;
    AlarmLsHwTop1_s10: any;
    AlarmLsHwTop1_s11: any;
    AlarmLsHwTop1_s12: any;
    AlarmLsHwTop1_s13: any;
    AlarmLsHwTop1_s14: any;
    AlarmLsHwTop1_s15: any;
    AlarmLsHwTop1_s16: any;
    AlarmLsHwTop1_s17: any;
    AlarmLsHwTop1_s18: any;
    AlarmLsHwTop1_s19: any;
    AlarmLsHwTop1_s20: any;
    AlarmLsHwTop1_s21: any;
    AlarmLsHwTop1_s22: any;
    AlarmLsHwTop1_s23: any;
    AlarmLsHwTop1_s24: any;
    AlarmLsHwTop1_s25: any;
    AlarmLsHwTop1_s26: any;
    AlarmLsHwTop1_s27: any;
    AlarmLsHwTop1_s28: any;
    AlarmLsHwTop1_s29: any;
    AlarmLsHwTop1_s30: any;
    AlarmLsHwTop1_s31: any;
    AlarmLsHwTop1_s32: any;
    AlarmLsHwTop1_s33: any;
    AlarmLsHwTop1_s34: any;
    AlarmLsHwTop1_s35: any;
    AlarmLsHwTop1_s36: any;
    AlarmLsHwTop1_s37: any;
    AlarmLsHwTop1_s38: any;
    AlarmLsHwTop1_s39: any;
    AlarmLsHwTop1_s40: any;
    AlarmLsHwTop1_s41: any;
    AlarmLsHwTop1_s42: any;
    AlarmLsHwTop1_s43: any;
    AlarmLsHwTop1_s44: any;
    AlarmLsHwTop1_s45: any;
    AlarmLsHwTop1_s46: any;
    AlarmLsHwTop1_s47: any;
    AlarmLsHwTop1_s48: any;
    AlarmLsHwTop1_s49: any;
    AlarmLsHwTop1_s50: any;
    AlarmLsHwTop1_s51: any;
    AlarmLsHwTop1_s52: any;
    AlarmLsHwTop1_s53: any;
    AlarmLsHwTop1_s54: any;
    AlarmLsHwTop1_s55: any;
    AlarmLsHwTop1_s56: any;
    AlarmLsHwTop1_s57: any;
    AlarmLsHwTop1_s58: any;
    AlarmLsHwTop1_s59: any;
    AlarmLsHwTop1_s60: any;
    AlarmLsHwTop1_s61: any;
    AlarmLsHwTop1_s62: any;
    AlarmLsHwTop1_s63: any;
    AlarmLsHwTop1_s64: any;
    AlarmLsHwTop1_s65: any;
    AlarmLsHwTop1_s66: any;
    AlarmLsHwTop1_s67: any;
    AlarmLsHwTop1_s68: any;
    AlarmLsHwTop1_s69: any;
    AlarmLsHwTop1_s70: any;
    AlarmLsHwTop1_s71: any;
    AlarmLsHwTop1_s72: any;
    AlarmLsHwTop1_s73: any;
    AlarmLsHwTop1_s74: any;
    AlarmLsHwTop1_s75: any;
    AlarmLsHwTop1_s76: any;
    AlarmLsHwTop1_s77: any;
    AlarmLsHwTop1_s78: any;
    AlarmLsHwTop1_s79: any;
    AlarmLsHwTop1_s80: any;
    AlarmLsHwTop1_s81: any;
    AlarmLsHwTop1_s82: any;
    AlarmLsHwTop1_s83: any;
    AlarmLsHwTop1_s84: any;
    AlarmLsHwTop1_s85: any;
    AlarmLsHwTop1_s86: any;
    AlarmLsHwTop1_s87: any;
    AlarmLsHwTop1_s88: any;
    AlarmLsHwTop1_s89: any;
    AlarmLsHwTop1_s90: any;
    AlarmLsHwTop1_s91: any;
    AlarmLsHwTop1_s92: any;
    AlarmLsHwTop1_s93: any;
    AlarmLsHwTop1_s94: any;
    AlarmLsHwTop1_s95: any;
    AlarmLsHwTop1_s96: any;
    AlarmLsHwTop1_s97: any;
    AlarmLsHwTop1_s98: any;
    AlarmLsHwTop1_s99: any;
    AlarmLsHwTop1_s100: any;
    AlarmLsHwTop1_s101: any;
    AlarmLsHwTop1_s102: any;
    AlarmLsHwTop1_s103: any;
    AlarmLsHwTop1_s104: any;
    AlarmLsHwTop1_s105: any;
    AlarmLsHwTop1_s106: any;
    AlarmLsHwTop1_s107: any;
    AlarmLsHwTop1_s108: any;
    AlarmLsHwTop1_s109: any;
    AlarmLsHwTop1_s110: any;
    AlarmLsHwTop1_s111: any;
    AlarmLsHwTop1_s112: any;
    AlarmLsHwTop1_s113: any;
    AlarmLsHwTop1_s114: any;
    AlarmLsHwTop1_s115: any;
    AlarmLsHwTop1_s116: any;
    AlarmLsHwTop1_s117: any;
    AlarmLsHwTop1_s118: any;
    AlarmLsHwTop1_s119: any;
    AlarmLsHwTop1_s120: any;
    AlarmLsHwTop1_s121: any;
    AlarmLsHwTop1_s122: any;
    AlarmLsHwTop1_s123: any;
    AlarmLsHwTop1_s124: any;
    AlarmLsHwTop1_s125: any;
    AlarmLsHwTop1_s126: any;
    AlarmLsHwTop1_s127: any;
    AlarmLsHwTop1_s128: any;
    AlarmLsHwTop1_s129: any;
    AlarmLsHwTop1_s130: any;
    AlarmLsHwTop1_s131: any;
    AlarmLsHwTop1_s132: any;
    AlarmLsHwTop1_s133: any;
    AlarmLsHwTop1_s134: any;
    AlarmLsHwTop1_s135: any;
    AlarmLsHwTop1_s136: any;
    AlarmLsHwTop1_s137: any;
    AlarmLsHwTop1_s138: any;
    AlarmLsHwTop1_s139: any;
    AlarmLsHwTop1_s140: any;
    AlarmLsHwTop1_s141: any;
    AlarmLsHwTop1_s142: any;
    AlarmLsHwTop1_s143: any;
    AlarmLsHwTop1_s144: any;
    AlarmLsHwTop1_s145: any;
    AlarmLsHwTop1_s146: any;
    AlarmLsHwTop1_s147: any;
    AlarmLsHwTop1_s148: any;
    AlarmLsHwTop1_s149: any;
    AlarmLsHwTop1_s150: any;
    AlarmLsHwTop1_s151: any;
    AlarmLsHwTop1_s152: any;
    AlarmLsHwTop1_s153: any;
    AlarmLsHwTop1_s154: any;
    AlarmLsHwTop1_s155: any;
    AlarmLsHwTop1_s156: any;
    AlarmLsHwTop1_s157: any;
    AlarmLsHwTop1_s158: any;
    AlarmLsHwTop1_s159: any;
    AlarmLsHwTop1_s160: any;
    AlarmLsHwTop1_s161: any;
    AlarmLsHwTop1_s162: any;
    AlarmLsHwTop1_s163: any;
    AlarmLsHwTop1_s164: any;
    AlarmLsHwTop1_s165: any;
    AlarmLsHwTop1_s166: any;
    AlarmLsHwTop1_s167: any;
    AlarmLsHwTop1_s168: any;
    AlarmLsHwTop1_s169: any;
    AlarmLsHwTop1_s170: any;
    AlarmLsHwTop1_s171: any;
    AlarmLsHwTop1_s172: any;
    AlarmLsHwTop1_s173: any;
    AlarmLsHwTop1_s174: any;
    AlarmLsHwTop1_s175: any;
    AlarmLsHwTop1_s176: any;
    AlarmLsHwTop1_s177: any;
    AlarmLsHwTop1_s178: any;
    AlarmLsHwTop1_s179: any;
    AlarmLsHwTop1_s180: any;
    AlarmLsHwTop1_s181: any;
    AlarmLsHwTop1_s182: any;
    AlarmLsHwTop1_s183: any;
    AlarmLsHwTop1_s184: any;
    AlarmLsHwTop1_s185: any;
    AlarmLsHwTop1_s186: any;
    AlarmLsHwTop1_s187: any;
    AlarmLsHwTop1_s188: any;
    AlarmLsHwTop1_s189: any;
    AlarmLsHwTop1_s190: any;
    AlarmLsHwTop1_s191: any;
    AlarmLsHwTop1_s192: any;
    AlarmLsHwTop1_s193: any;
    AlarmLsHwTop1_s194: any;
    AlarmLsHwTop1_s195: any;
    AlarmLsHwTop1_s196: any;
    AlarmLsHwTop1_s197: any;
    AlarmLsHwTop1_s198: any;
    AlarmLsHwTop1_s199: any;
    AlarmLsHwTop1_s200: any;
    AlarmLsHwTop1_s201: any;
    AlarmLsHwTop1_s202: any;
    AlarmLsHwTop1_s203: any;
    AlarmLsHwTop1_s204: any;
    AlarmLsHwTop1_s205: any;
    AlarmLsHwTop1_s206: any;
    AlarmLsHwTop1_s207: any;
    AlarmLsHwTop1_s208: any;
    AlarmLsHwTop1_s209: any;
    AlarmLsHwTop1_s210: any;
    AlarmLsHwTop1_s211: any;
    AlarmLsHwTop1_s212: any;
    AlarmLsHwTop1_s213: any;
    AlarmLsHwTop1_s214: any;
    AlarmLsHwTop1_s215: any;
    AlarmLsHwTop1_s216: any;
    AlarmLsHwTop1_s217: any;
    AlarmLsHwTop1_s218: any;
    AlarmLsHwTop1_s219: any;
    AlarmLsHwTop1_s220: any;
    AlarmLsHwTop1_s221: any;
    AlarmLsHwTop1_s222: any;
    AlarmLsHwTop1_s223: any;
    AlarmLsHwTop1_s224: any;
    AlarmLsHwTop1_s225: any;
    AlarmLsHwTop1_s226: any;
    AlarmLsHwTop1_s227: any;
    AlarmLsHwTop1_s228: any;
    AlarmLsHwTop1_s229: any;
    AlarmLsHwTop1_s230: any;
    AlarmLsHwTop1_s231: any;
    AlarmLsHwTop1_s232: any;
    AlarmLsHwTop1_s233: any;
    AlarmLsHwTop1_s234: any;
    AlarmLsHwTop1_s235: any;
    AlarmLsHwTop1_s236: any;
    AlarmLsHwTop1_s237: any;
    AlarmLsHwTop1_s238: any;
    AlarmLsHwTop1_s239: any;
    AlarmLsHwTop1_s240: any;
    AlarmLsHwTop1_s241: any;
    AlarmLsHwTop1_s242: any;
    AlarmLsHwTop1_s243: any;
    AlarmLsHwTop1_s244: any;
    AlarmLsHwTop1_s245: any;
    AlarmLsHwTop1_s246: any;
    AlarmLsHwTop1_s247: any;
    AlarmLsHwTop1_s248: any;
    AlarmLsHwTop1_s249: any;
    AlarmLsHwTop1_s250: any;
    AlarmLsHwTop1_s251: any;
    AlarmLsHwTop1_s252: any;
    AlarmLsHwTop1_s253: any;
    AlarmLsHwTop1_s254: any;
    AlarmLsHwTop1_s255: any;
    AlarmLsHwTop1_s256: any;
    AlarmLsHwBot1_s1: any;
    AlarmLsHwBot1_s2: any;
    AlarmLsHwBot1_s3: any;
    AlarmLsHwBot1_s4: any;
    AlarmLsHwBot1_s5: any;
    AlarmLsHwBot1_s6: any;
    AlarmLsHwBot1_s7: any;
    AlarmLsHwBot1_s8: any;
    AlarmLsHwBot1_s9: any;
    AlarmLsHwBot1_s10: any;
    AlarmLsHwBot1_s11: any;
    AlarmLsHwBot1_s12: any;
    AlarmLsHwBot1_s13: any;
    AlarmLsHwBot1_s14: any;
    AlarmLsHwBot1_s15: any;
    AlarmLsHwBot1_s16: any;
    AlarmLsHwBot1_s17: any;
    AlarmLsHwBot1_s18: any;
    AlarmLsHwBot1_s19: any;
    AlarmLsHwBot1_s20: any;
    AlarmLsHwBot1_s21: any;
    AlarmLsHwBot1_s22: any;
    AlarmLsHwBot1_s23: any;
    AlarmLsHwBot1_s24: any;
    AlarmLsHwBot1_s25: any;
    AlarmLsHwBot1_s26: any;
    AlarmLsHwBot1_s27: any;
    AlarmLsHwBot1_s28: any;
    AlarmLsHwBot1_s29: any;
    AlarmLsHwBot1_s30: any;
    AlarmLsHwBot1_s31: any;
    AlarmLsHwBot1_s32: any;
    AlarmLsHwBot1_s33: any;
    AlarmLsHwBot1_s34: any;
    AlarmLsHwBot1_s35: any;
    AlarmLsHwBot1_s36: any;
    AlarmLsHwBot1_s37: any;
    AlarmLsHwBot1_s38: any;
    AlarmLsHwBot1_s39: any;
    AlarmLsHwBot1_s40: any;
    AlarmLsHwBot1_s41: any;
    AlarmLsHwBot1_s42: any;
    AlarmLsHwBot1_s43: any;
    AlarmLsHwBot1_s44: any;
    AlarmLsHwBot1_s45: any;
    AlarmLsHwBot1_s46: any;
    AlarmLsHwBot1_s47: any;
    AlarmLsHwBot1_s48: any;
    AlarmLsHwBot1_s49: any;
    AlarmLsHwBot1_s50: any;
    AlarmLsHwBot1_s51: any;
    AlarmLsHwBot1_s52: any;
    AlarmLsHwBot1_s53: any;
    AlarmLsHwBot1_s54: any;
    AlarmLsHwBot1_s55: any;
    AlarmLsHwBot1_s56: any;
    AlarmLsHwBot1_s57: any;
    AlarmLsHwBot1_s58: any;
    AlarmLsHwBot1_s59: any;
    AlarmLsHwBot1_s60: any;
    AlarmLsHwBot1_s61: any;
    AlarmLsHwBot1_s62: any;
    AlarmLsHwBot1_s63: any;
    AlarmLsHwBot1_s64: any;
    AlarmLsHwBot1_s65: any;
    AlarmLsHwBot1_s66: any;
    AlarmLsHwBot1_s67: any;
    AlarmLsHwBot1_s68: any;
    AlarmLsHwBot1_s69: any;
    AlarmLsHwBot1_s70: any;
    AlarmLsHwBot1_s71: any;
    AlarmLsHwBot1_s72: any;
    AlarmLsHwBot1_s73: any;
    AlarmLsHwBot1_s74: any;
    AlarmLsHwBot1_s75: any;
    AlarmLsHwBot1_s76: any;
    AlarmLsHwBot1_s77: any;
    AlarmLsHwBot1_s78: any;
    AlarmLsHwBot1_s79: any;
    AlarmLsHwBot1_s80: any;
    AlarmLsHwBot1_s81: any;
    AlarmLsHwBot1_s82: any;
    AlarmLsHwBot1_s83: any;
    AlarmLsHwBot1_s84: any;
    AlarmLsHwBot1_s85: any;
    AlarmLsHwBot1_s86: any;
    AlarmLsHwBot1_s87: any;
    AlarmLsHwBot1_s88: any;
    AlarmLsHwBot1_s89: any;
    AlarmLsHwBot1_s90: any;
    AlarmLsHwBot1_s91: any;
    AlarmLsHwBot1_s92: any;
    AlarmLsHwBot1_s93: any;
    AlarmLsHwBot1_s94: any;
    AlarmLsHwBot1_s95: any;
    AlarmLsHwBot1_s96: any;
    AlarmLsHwBot1_s97: any;
    AlarmLsHwBot1_s98: any;
    AlarmLsHwBot1_s99: any;
    AlarmLsHwBot1_s100: any;
    AlarmLsHwBot1_s101: any;
    AlarmLsHwBot1_s102: any;
    AlarmLsHwBot1_s103: any;
    AlarmLsHwBot1_s104: any;
    AlarmLsHwBot1_s105: any;
    AlarmLsHwBot1_s106: any;
    AlarmLsHwBot1_s107: any;
    AlarmLsHwBot1_s108: any;
    AlarmLsHwBot1_s109: any;
    AlarmLsHwBot1_s110: any;
    AlarmLsHwBot1_s111: any;
    AlarmLsHwBot1_s112: any;
    AlarmLsHwBot1_s113: any;
    AlarmLsHwBot1_s114: any;
    AlarmLsHwBot1_s115: any;
    AlarmLsHwBot1_s116: any;
    AlarmLsHwBot1_s117: any;
    AlarmLsHwBot1_s118: any;
    AlarmLsHwBot1_s119: any;
    AlarmLsHwBot1_s120: any;
    AlarmLsHwBot1_s121: any;
    AlarmLsHwBot1_s122: any;
    AlarmLsHwBot1_s123: any;
    AlarmLsHwBot1_s124: any;
    AlarmLsHwBot1_s125: any;
    AlarmLsHwBot1_s126: any;
    AlarmLsHwBot1_s127: any;
    AlarmLsHwBot1_s128: any;
    AlarmLsHwBot1_s129: any;
    AlarmLsHwBot1_s130: any;
    AlarmLsHwBot1_s131: any;
    AlarmLsHwBot1_s132: any;
    AlarmLsHwBot1_s133: any;
    AlarmLsHwBot1_s134: any;
    AlarmLsHwBot1_s135: any;
    AlarmLsHwBot1_s136: any;
    AlarmLsHwBot1_s137: any;
    AlarmLsHwBot1_s138: any;
    AlarmLsHwBot1_s139: any;
    AlarmLsHwBot1_s140: any;
    AlarmLsHwBot1_s141: any;
    AlarmLsHwBot1_s142: any;
    AlarmLsHwBot1_s143: any;
    AlarmLsHwBot1_s144: any;
    AlarmLsHwBot1_s145: any;
    AlarmLsHwBot1_s146: any;
    AlarmLsHwBot1_s147: any;
    AlarmLsHwBot1_s148: any;
    AlarmLsHwBot1_s149: any;
    AlarmLsHwBot1_s150: any;
    AlarmLsHwBot1_s151: any;
    AlarmLsHwBot1_s152: any;
    AlarmLsHwBot1_s153: any;
    AlarmLsHwBot1_s154: any;
    AlarmLsHwBot1_s155: any;
    AlarmLsHwBot1_s156: any;
    AlarmLsHwBot1_s157: any;
    AlarmLsHwBot1_s158: any;
    AlarmLsHwBot1_s159: any;
    AlarmLsHwBot1_s160: any;
    AlarmLsHwBot1_s161: any;
    AlarmLsHwBot1_s162: any;
    AlarmLsHwBot1_s163: any;
    AlarmLsHwBot1_s164: any;
    AlarmLsHwBot1_s165: any;
    AlarmLsHwBot1_s166: any;
    AlarmLsHwBot1_s167: any;
    AlarmLsHwBot1_s168: any;
    AlarmLsHwBot1_s169: any;
    AlarmLsHwBot1_s170: any;
    AlarmLsHwBot1_s171: any;
    AlarmLsHwBot1_s172: any;
    AlarmLsHwBot1_s173: any;
    AlarmLsHwBot1_s174: any;
    AlarmLsHwBot1_s175: any;
    AlarmLsHwBot1_s176: any;
    AlarmLsHwBot1_s177: any;
    AlarmLsHwBot1_s178: any;
    AlarmLsHwBot1_s179: any;
    AlarmLsHwBot1_s180: any;
    AlarmLsHwBot1_s181: any;
    AlarmLsHwBot1_s182: any;
    AlarmLsHwBot1_s183: any;
    AlarmLsHwBot1_s184: any;
    AlarmLsHwBot1_s185: any;
    AlarmLsHwBot1_s186: any;
    AlarmLsHwBot1_s187: any;
    AlarmLsHwBot1_s188: any;
    AlarmLsHwBot1_s189: any;
    AlarmLsHwBot1_s190: any;
    AlarmLsHwBot1_s191: any;
    AlarmLsHwBot1_s192: any;
    AlarmLsHwBot1_s193: any;
    AlarmLsHwBot1_s194: any;
    AlarmLsHwBot1_s195: any;
    AlarmLsHwBot1_s196: any;
    AlarmLsHwBot1_s197: any;
    AlarmLsHwBot1_s198: any;
    AlarmLsHwBot1_s199: any;
    AlarmLsHwBot1_s200: any;
    AlarmLsHwBot1_s201: any;
    AlarmLsHwBot1_s202: any;
    AlarmLsHwBot1_s203: any;
    AlarmLsHwBot1_s204: any;
    AlarmLsHwBot1_s205: any;
    AlarmLsHwBot1_s206: any;
    AlarmLsHwBot1_s207: any;
    AlarmLsHwBot1_s208: any;
    AlarmLsHwBot1_s209: any;
    AlarmLsHwBot1_s210: any;
    AlarmLsHwBot1_s211: any;
    AlarmLsHwBot1_s212: any;
    AlarmLsHwBot1_s213: any;
    AlarmLsHwBot1_s214: any;
    AlarmLsHwBot1_s215: any;
    AlarmLsHwBot1_s216: any;
    AlarmLsHwBot1_s217: any;
    AlarmLsHwBot1_s218: any;
    AlarmLsHwBot1_s219: any;
    AlarmLsHwBot1_s220: any;
    AlarmLsHwBot1_s221: any;
    AlarmLsHwBot1_s222: any;
    AlarmLsHwBot1_s223: any;
    AlarmLsHwBot1_s224: any;
    AlarmLsHwBot1_s225: any;
    AlarmLsHwBot1_s226: any;
    AlarmLsHwBot1_s227: any;
    AlarmLsHwBot1_s228: any;
    AlarmLsHwBot1_s229: any;
    AlarmLsHwBot1_s230: any;
    AlarmLsHwBot1_s231: any;
    AlarmLsHwBot1_s232: any;
    AlarmLsHwBot1_s233: any;
    AlarmLsHwBot1_s234: any;
    AlarmLsHwBot1_s235: any;
    AlarmLsHwBot1_s236: any;
    AlarmLsHwBot1_s237: any;
    AlarmLsHwBot1_s238: any;
    AlarmLsHwBot1_s239: any;
    AlarmLsHwBot1_s240: any;
    AlarmLsHwBot1_s241: any;
    AlarmLsHwBot1_s242: any;
    AlarmLsHwBot1_s243: any;
    AlarmLsHwBot1_s244: any;
    AlarmLsHwBot1_s245: any;
    AlarmLsHwBot1_s246: any;
    AlarmLsHwBot1_s247: any;
    AlarmLsHwBot1_s248: any;
    AlarmLsHwBot1_s249: any;
    AlarmLsHwBot1_s250: any;
    AlarmLsHwBot1_s251: any;
    AlarmLsHwBot1_s252: any;
    AlarmLsHwBot1_s253: any;
    AlarmLsHwBot1_s254: any;
    AlarmLsHwBot1_s255: any;
    AlarmLsHwBot1_s256: any;
    AlarmAxis1LowSpeed_s1: any;
    AlarmAxis1LowSpeed_s2: any;
    AlarmAxis1LowSpeed_s3: any;
    AlarmAxis1LowSpeed_s4: any;
    AlarmAxis1LowSpeed_s5: any;
    AlarmAxis1LowSpeed_s6: any;
    AlarmAxis1LowSpeed_s7: any;
    AlarmAxis1LowSpeed_s8: any;
    AlarmAxis1LowSpeed_s9: any;
    AlarmAxis1LowSpeed_s10: any;
    AlarmAxis1LowSpeed_s11: any;
    AlarmAxis1LowSpeed_s12: any;
    AlarmAxis1LowSpeed_s13: any;
    AlarmAxis1LowSpeed_s14: any;
    AlarmAxis1LowSpeed_s15: any;
    AlarmAxis1LowSpeed_s16: any;
    AlarmAxis1LowSpeed_s17: any;
    AlarmAxis1LowSpeed_s18: any;
    AlarmAxis1LowSpeed_s19: any;
    AlarmAxis1LowSpeed_s20: any;
    AlarmAxis1LowSpeed_s21: any;
    AlarmAxis1LowSpeed_s22: any;
    AlarmAxis1LowSpeed_s23: any;
    AlarmAxis1LowSpeed_s24: any;
    AlarmAxis1LowSpeed_s25: any;
    AlarmAxis1LowSpeed_s26: any;
    AlarmAxis1LowSpeed_s27: any;
    AlarmAxis1LowSpeed_s28: any;
    AlarmAxis1LowSpeed_s29: any;
    AlarmAxis1LowSpeed_s30: any;
    AlarmAxis1LowSpeed_s31: any;
    AlarmAxis1LowSpeed_s32: any;
    AlarmAxis1LowSpeed_s33: any;
    AlarmAxis1LowSpeed_s34: any;
    AlarmAxis1LowSpeed_s35: any;
    AlarmAxis1LowSpeed_s36: any;
    AlarmAxis1LowSpeed_s37: any;
    AlarmAxis1LowSpeed_s38: any;
    AlarmAxis1LowSpeed_s39: any;
    AlarmAxis1LowSpeed_s40: any;
    AlarmAxis1LowSpeed_s41: any;
    AlarmAxis1LowSpeed_s42: any;
    AlarmAxis1LowSpeed_s43: any;
    AlarmAxis1LowSpeed_s44: any;
    AlarmAxis1LowSpeed_s45: any;
    AlarmAxis1LowSpeed_s46: any;
    AlarmAxis1LowSpeed_s47: any;
    AlarmAxis1LowSpeed_s48: any;
    AlarmAxis1LowSpeed_s49: any;
    AlarmAxis1LowSpeed_s50: any;
    AlarmAxis1LowSpeed_s51: any;
    AlarmAxis1LowSpeed_s52: any;
    AlarmAxis1LowSpeed_s53: any;
    AlarmAxis1LowSpeed_s54: any;
    AlarmAxis1LowSpeed_s55: any;
    AlarmAxis1LowSpeed_s56: any;
    AlarmAxis1LowSpeed_s57: any;
    AlarmAxis1LowSpeed_s58: any;
    AlarmAxis1LowSpeed_s59: any;
    AlarmAxis1LowSpeed_s60: any;
    AlarmAxis1LowSpeed_s61: any;
    AlarmAxis1LowSpeed_s62: any;
    AlarmAxis1LowSpeed_s63: any;
    AlarmAxis1LowSpeed_s64: any;
    AlarmAxis1LowSpeed_s65: any;
    AlarmAxis1LowSpeed_s66: any;
    AlarmAxis1LowSpeed_s67: any;
    AlarmAxis1LowSpeed_s68: any;
    AlarmAxis1LowSpeed_s69: any;
    AlarmAxis1LowSpeed_s70: any;
    AlarmAxis1LowSpeed_s71: any;
    AlarmAxis1LowSpeed_s72: any;
    AlarmAxis1LowSpeed_s73: any;
    AlarmAxis1LowSpeed_s74: any;
    AlarmAxis1LowSpeed_s75: any;
    AlarmAxis1LowSpeed_s76: any;
    AlarmAxis1LowSpeed_s77: any;
    AlarmAxis1LowSpeed_s78: any;
    AlarmAxis1LowSpeed_s79: any;
    AlarmAxis1LowSpeed_s80: any;
    AlarmAxis1LowSpeed_s81: any;
    AlarmAxis1LowSpeed_s82: any;
    AlarmAxis1LowSpeed_s83: any;
    AlarmAxis1LowSpeed_s84: any;
    AlarmAxis1LowSpeed_s85: any;
    AlarmAxis1LowSpeed_s86: any;
    AlarmAxis1LowSpeed_s87: any;
    AlarmAxis1LowSpeed_s88: any;
    AlarmAxis1LowSpeed_s89: any;
    AlarmAxis1LowSpeed_s90: any;
    AlarmAxis1LowSpeed_s91: any;
    AlarmAxis1LowSpeed_s92: any;
    AlarmAxis1LowSpeed_s93: any;
    AlarmAxis1LowSpeed_s94: any;
    AlarmAxis1LowSpeed_s95: any;
    AlarmAxis1LowSpeed_s96: any;
    AlarmAxis1LowSpeed_s97: any;
    AlarmAxis1LowSpeed_s98: any;
    AlarmAxis1LowSpeed_s99: any;
    AlarmAxis1LowSpeed_s100: any;
    AlarmAxis1LowSpeed_s101: any;
    AlarmAxis1LowSpeed_s102: any;
    AlarmAxis1LowSpeed_s103: any;
    AlarmAxis1LowSpeed_s104: any;
    AlarmAxis1LowSpeed_s105: any;
    AlarmAxis1LowSpeed_s106: any;
    AlarmAxis1LowSpeed_s107: any;
    AlarmAxis1LowSpeed_s108: any;
    AlarmAxis1LowSpeed_s109: any;
    AlarmAxis1LowSpeed_s110: any;
    AlarmAxis1LowSpeed_s111: any;
    AlarmAxis1LowSpeed_s112: any;
    AlarmAxis1LowSpeed_s113: any;
    AlarmAxis1LowSpeed_s114: any;
    AlarmAxis1LowSpeed_s115: any;
    AlarmAxis1LowSpeed_s116: any;
    AlarmAxis1LowSpeed_s117: any;
    AlarmAxis1LowSpeed_s118: any;
    AlarmAxis1LowSpeed_s119: any;
    AlarmAxis1LowSpeed_s120: any;
    AlarmAxis1LowSpeed_s121: any;
    AlarmAxis1LowSpeed_s122: any;
    AlarmAxis1LowSpeed_s123: any;
    AlarmAxis1LowSpeed_s124: any;
    AlarmAxis1LowSpeed_s125: any;
    AlarmAxis1LowSpeed_s126: any;
    AlarmAxis1LowSpeed_s127: any;
    AlarmAxis1LowSpeed_s128: any;
    AlarmAxis1LowSpeed_s129: any;
    AlarmAxis1LowSpeed_s130: any;
    AlarmAxis1LowSpeed_s131: any;
    AlarmAxis1LowSpeed_s132: any;
    AlarmAxis1LowSpeed_s133: any;
    AlarmAxis1LowSpeed_s134: any;
    AlarmAxis1LowSpeed_s135: any;
    AlarmAxis1LowSpeed_s136: any;
    AlarmAxis1LowSpeed_s137: any;
    AlarmAxis1LowSpeed_s138: any;
    AlarmAxis1LowSpeed_s139: any;
    AlarmAxis1LowSpeed_s140: any;
    AlarmAxis1LowSpeed_s141: any;
    AlarmAxis1LowSpeed_s142: any;
    AlarmAxis1LowSpeed_s143: any;
    AlarmAxis1LowSpeed_s144: any;
    AlarmAxis1LowSpeed_s145: any;
    AlarmAxis1LowSpeed_s146: any;
    AlarmAxis1LowSpeed_s147: any;
    AlarmAxis1LowSpeed_s148: any;
    AlarmAxis1LowSpeed_s149: any;
    AlarmAxis1LowSpeed_s150: any;
    AlarmAxis1LowSpeed_s151: any;
    AlarmAxis1LowSpeed_s152: any;
    AlarmAxis1LowSpeed_s153: any;
    AlarmAxis1LowSpeed_s154: any;
    AlarmAxis1LowSpeed_s155: any;
    AlarmAxis1LowSpeed_s156: any;
    AlarmAxis1LowSpeed_s157: any;
    AlarmAxis1LowSpeed_s158: any;
    AlarmAxis1LowSpeed_s159: any;
    AlarmAxis1LowSpeed_s160: any;
    AlarmAxis1LowSpeed_s161: any;
    AlarmAxis1LowSpeed_s162: any;
    AlarmAxis1LowSpeed_s163: any;
    AlarmAxis1LowSpeed_s164: any;
    AlarmAxis1LowSpeed_s165: any;
    AlarmAxis1LowSpeed_s166: any;
    AlarmAxis1LowSpeed_s167: any;
    AlarmAxis1LowSpeed_s168: any;
    AlarmAxis1LowSpeed_s169: any;
    AlarmAxis1LowSpeed_s170: any;
    AlarmAxis1LowSpeed_s171: any;
    AlarmAxis1LowSpeed_s172: any;
    AlarmAxis1LowSpeed_s173: any;
    AlarmAxis1LowSpeed_s174: any;
    AlarmAxis1LowSpeed_s175: any;
    AlarmAxis1LowSpeed_s176: any;
    AlarmAxis1LowSpeed_s177: any;
    AlarmAxis1LowSpeed_s178: any;
    AlarmAxis1LowSpeed_s179: any;
    AlarmAxis1LowSpeed_s180: any;
    AlarmAxis1LowSpeed_s181: any;
    AlarmAxis1LowSpeed_s182: any;
    AlarmAxis1LowSpeed_s183: any;
    AlarmAxis1LowSpeed_s184: any;
    AlarmAxis1LowSpeed_s185: any;
    AlarmAxis1LowSpeed_s186: any;
    AlarmAxis1LowSpeed_s187: any;
    AlarmAxis1LowSpeed_s188: any;
    AlarmAxis1LowSpeed_s189: any;
    AlarmAxis1LowSpeed_s190: any;
    AlarmAxis1LowSpeed_s191: any;
    AlarmAxis1LowSpeed_s192: any;
    AlarmAxis1LowSpeed_s193: any;
    AlarmAxis1LowSpeed_s194: any;
    AlarmAxis1LowSpeed_s195: any;
    AlarmAxis1LowSpeed_s196: any;
    AlarmAxis1LowSpeed_s197: any;
    AlarmAxis1LowSpeed_s198: any;
    AlarmAxis1LowSpeed_s199: any;
    AlarmAxis1LowSpeed_s200: any;
    AlarmAxis1LowSpeed_s201: any;
    AlarmAxis1LowSpeed_s202: any;
    AlarmAxis1LowSpeed_s203: any;
    AlarmAxis1LowSpeed_s204: any;
    AlarmAxis1LowSpeed_s205: any;
    AlarmAxis1LowSpeed_s206: any;
    AlarmAxis1LowSpeed_s207: any;
    AlarmAxis1LowSpeed_s208: any;
    AlarmAxis1LowSpeed_s209: any;
    AlarmAxis1LowSpeed_s210: any;
    AlarmAxis1LowSpeed_s211: any;
    AlarmAxis1LowSpeed_s212: any;
    AlarmAxis1LowSpeed_s213: any;
    AlarmAxis1LowSpeed_s214: any;
    AlarmAxis1LowSpeed_s215: any;
    AlarmAxis1LowSpeed_s216: any;
    AlarmAxis1LowSpeed_s217: any;
    AlarmAxis1LowSpeed_s218: any;
    AlarmAxis1LowSpeed_s219: any;
    AlarmAxis1LowSpeed_s220: any;
    AlarmAxis1LowSpeed_s221: any;
    AlarmAxis1LowSpeed_s222: any;
    AlarmAxis1LowSpeed_s223: any;
    AlarmAxis1LowSpeed_s224: any;
    AlarmAxis1LowSpeed_s225: any;
    AlarmAxis1LowSpeed_s226: any;
    AlarmAxis1LowSpeed_s227: any;
    AlarmAxis1LowSpeed_s228: any;
    AlarmAxis1LowSpeed_s229: any;
    AlarmAxis1LowSpeed_s230: any;
    AlarmAxis1LowSpeed_s231: any;
    AlarmAxis1LowSpeed_s232: any;
    AlarmAxis1LowSpeed_s233: any;
    AlarmAxis1LowSpeed_s234: any;
    AlarmAxis1LowSpeed_s235: any;
    AlarmAxis1LowSpeed_s236: any;
    AlarmAxis1LowSpeed_s237: any;
    AlarmAxis1LowSpeed_s238: any;
    AlarmAxis1LowSpeed_s239: any;
    AlarmAxis1LowSpeed_s240: any;
    AlarmAxis1LowSpeed_s241: any;
    AlarmAxis1LowSpeed_s242: any;
    AlarmAxis1LowSpeed_s243: any;
    AlarmAxis1LowSpeed_s244: any;
    AlarmAxis1LowSpeed_s245: any;
    AlarmAxis1LowSpeed_s246: any;
    AlarmAxis1LowSpeed_s247: any;
    AlarmAxis1LowSpeed_s248: any;
    AlarmAxis1LowSpeed_s249: any;
    AlarmAxis1LowSpeed_s250: any;
    AlarmAxis1LowSpeed_s251: any;
    AlarmAxis1LowSpeed_s252: any;
    AlarmAxis1LowSpeed_s253: any;
    AlarmAxis1LowSpeed_s254: any;
    AlarmAxis1LowSpeed_s255: any;
    AlarmAxis1LowSpeed_s256: any;
    AlarmTimeNeverSet_s1: any;
    AlarmTimeNeverSet_s2: any;
    AlarmTimeNeverSet_s3: any;
    AlarmTimeNeverSet_s4: any;
    AlarmTimeNeverSet_s5: any;
    AlarmTimeNeverSet_s6: any;
    AlarmTimeNeverSet_s7: any;
    AlarmTimeNeverSet_s8: any;
    AlarmTimeNeverSet_s9: any;
    AlarmTimeNeverSet_s10: any;
    AlarmTimeNeverSet_s11: any;
    AlarmTimeNeverSet_s12: any;
    AlarmTimeNeverSet_s13: any;
    AlarmTimeNeverSet_s14: any;
    AlarmTimeNeverSet_s15: any;
    AlarmTimeNeverSet_s16: any;
    AlarmTimeNeverSet_s17: any;
    AlarmTimeNeverSet_s18: any;
    AlarmTimeNeverSet_s19: any;
    AlarmTimeNeverSet_s20: any;
    AlarmTimeNeverSet_s21: any;
    AlarmTimeNeverSet_s22: any;
    AlarmTimeNeverSet_s23: any;
    AlarmTimeNeverSet_s24: any;
    AlarmTimeNeverSet_s25: any;
    AlarmTimeNeverSet_s26: any;
    AlarmTimeNeverSet_s27: any;
    AlarmTimeNeverSet_s28: any;
    AlarmTimeNeverSet_s29: any;
    AlarmTimeNeverSet_s30: any;
    AlarmTimeNeverSet_s31: any;
    AlarmTimeNeverSet_s32: any;
    AlarmTimeNeverSet_s33: any;
    AlarmTimeNeverSet_s34: any;
    AlarmTimeNeverSet_s35: any;
    AlarmTimeNeverSet_s36: any;
    AlarmTimeNeverSet_s37: any;
    AlarmTimeNeverSet_s38: any;
    AlarmTimeNeverSet_s39: any;
    AlarmTimeNeverSet_s40: any;
    AlarmTimeNeverSet_s41: any;
    AlarmTimeNeverSet_s42: any;
    AlarmTimeNeverSet_s43: any;
    AlarmTimeNeverSet_s44: any;
    AlarmTimeNeverSet_s45: any;
    AlarmTimeNeverSet_s46: any;
    AlarmTimeNeverSet_s47: any;
    AlarmTimeNeverSet_s48: any;
    AlarmTimeNeverSet_s49: any;
    AlarmTimeNeverSet_s50: any;
    AlarmTimeNeverSet_s51: any;
    AlarmTimeNeverSet_s52: any;
    AlarmTimeNeverSet_s53: any;
    AlarmTimeNeverSet_s54: any;
    AlarmTimeNeverSet_s55: any;
    AlarmTimeNeverSet_s56: any;
    AlarmTimeNeverSet_s57: any;
    AlarmTimeNeverSet_s58: any;
    AlarmTimeNeverSet_s59: any;
    AlarmTimeNeverSet_s60: any;
    AlarmTimeNeverSet_s61: any;
    AlarmTimeNeverSet_s62: any;
    AlarmTimeNeverSet_s63: any;
    AlarmTimeNeverSet_s64: any;
    AlarmTimeNeverSet_s65: any;
    AlarmTimeNeverSet_s66: any;
    AlarmTimeNeverSet_s67: any;
    AlarmTimeNeverSet_s68: any;
    AlarmTimeNeverSet_s69: any;
    AlarmTimeNeverSet_s70: any;
    AlarmTimeNeverSet_s71: any;
    AlarmTimeNeverSet_s72: any;
    AlarmTimeNeverSet_s73: any;
    AlarmTimeNeverSet_s74: any;
    AlarmTimeNeverSet_s75: any;
    AlarmTimeNeverSet_s76: any;
    AlarmTimeNeverSet_s77: any;
    AlarmTimeNeverSet_s78: any;
    AlarmTimeNeverSet_s79: any;
    AlarmTimeNeverSet_s80: any;
    AlarmTimeNeverSet_s81: any;
    AlarmTimeNeverSet_s82: any;
    AlarmTimeNeverSet_s83: any;
    AlarmTimeNeverSet_s84: any;
    AlarmTimeNeverSet_s85: any;
    AlarmTimeNeverSet_s86: any;
    AlarmTimeNeverSet_s87: any;
    AlarmTimeNeverSet_s88: any;
    AlarmTimeNeverSet_s89: any;
    AlarmTimeNeverSet_s90: any;
    AlarmTimeNeverSet_s91: any;
    AlarmTimeNeverSet_s92: any;
    AlarmTimeNeverSet_s93: any;
    AlarmTimeNeverSet_s94: any;
    AlarmTimeNeverSet_s95: any;
    AlarmTimeNeverSet_s96: any;
    AlarmTimeNeverSet_s97: any;
    AlarmTimeNeverSet_s98: any;
    AlarmTimeNeverSet_s99: any;
    AlarmTimeNeverSet_s100: any;
    AlarmTimeNeverSet_s101: any;
    AlarmTimeNeverSet_s102: any;
    AlarmTimeNeverSet_s103: any;
    AlarmTimeNeverSet_s104: any;
    AlarmTimeNeverSet_s105: any;
    AlarmTimeNeverSet_s106: any;
    AlarmTimeNeverSet_s107: any;
    AlarmTimeNeverSet_s108: any;
    AlarmTimeNeverSet_s109: any;
    AlarmTimeNeverSet_s110: any;
    AlarmTimeNeverSet_s111: any;
    AlarmTimeNeverSet_s112: any;
    AlarmTimeNeverSet_s113: any;
    AlarmTimeNeverSet_s114: any;
    AlarmTimeNeverSet_s115: any;
    AlarmTimeNeverSet_s116: any;
    AlarmTimeNeverSet_s117: any;
    AlarmTimeNeverSet_s118: any;
    AlarmTimeNeverSet_s119: any;
    AlarmTimeNeverSet_s120: any;
    AlarmTimeNeverSet_s121: any;
    AlarmTimeNeverSet_s122: any;
    AlarmTimeNeverSet_s123: any;
    AlarmTimeNeverSet_s124: any;
    AlarmTimeNeverSet_s125: any;
    AlarmTimeNeverSet_s126: any;
    AlarmTimeNeverSet_s127: any;
    AlarmTimeNeverSet_s128: any;
    AlarmTimeNeverSet_s129: any;
    AlarmTimeNeverSet_s130: any;
    AlarmTimeNeverSet_s131: any;
    AlarmTimeNeverSet_s132: any;
    AlarmTimeNeverSet_s133: any;
    AlarmTimeNeverSet_s134: any;
    AlarmTimeNeverSet_s135: any;
    AlarmTimeNeverSet_s136: any;
    AlarmTimeNeverSet_s137: any;
    AlarmTimeNeverSet_s138: any;
    AlarmTimeNeverSet_s139: any;
    AlarmTimeNeverSet_s140: any;
    AlarmTimeNeverSet_s141: any;
    AlarmTimeNeverSet_s142: any;
    AlarmTimeNeverSet_s143: any;
    AlarmTimeNeverSet_s144: any;
    AlarmTimeNeverSet_s145: any;
    AlarmTimeNeverSet_s146: any;
    AlarmTimeNeverSet_s147: any;
    AlarmTimeNeverSet_s148: any;
    AlarmTimeNeverSet_s149: any;
    AlarmTimeNeverSet_s150: any;
    AlarmTimeNeverSet_s151: any;
    AlarmTimeNeverSet_s152: any;
    AlarmTimeNeverSet_s153: any;
    AlarmTimeNeverSet_s154: any;
    AlarmTimeNeverSet_s155: any;
    AlarmTimeNeverSet_s156: any;
    AlarmTimeNeverSet_s157: any;
    AlarmTimeNeverSet_s158: any;
    AlarmTimeNeverSet_s159: any;
    AlarmTimeNeverSet_s160: any;
    AlarmTimeNeverSet_s161: any;
    AlarmTimeNeverSet_s162: any;
    AlarmTimeNeverSet_s163: any;
    AlarmTimeNeverSet_s164: any;
    AlarmTimeNeverSet_s165: any;
    AlarmTimeNeverSet_s166: any;
    AlarmTimeNeverSet_s167: any;
    AlarmTimeNeverSet_s168: any;
    AlarmTimeNeverSet_s169: any;
    AlarmTimeNeverSet_s170: any;
    AlarmTimeNeverSet_s171: any;
    AlarmTimeNeverSet_s172: any;
    AlarmTimeNeverSet_s173: any;
    AlarmTimeNeverSet_s174: any;
    AlarmTimeNeverSet_s175: any;
    AlarmTimeNeverSet_s176: any;
    AlarmTimeNeverSet_s177: any;
    AlarmTimeNeverSet_s178: any;
    AlarmTimeNeverSet_s179: any;
    AlarmTimeNeverSet_s180: any;
    AlarmTimeNeverSet_s181: any;
    AlarmTimeNeverSet_s182: any;
    AlarmTimeNeverSet_s183: any;
    AlarmTimeNeverSet_s184: any;
    AlarmTimeNeverSet_s185: any;
    AlarmTimeNeverSet_s186: any;
    AlarmTimeNeverSet_s187: any;
    AlarmTimeNeverSet_s188: any;
    AlarmTimeNeverSet_s189: any;
    AlarmTimeNeverSet_s190: any;
    AlarmTimeNeverSet_s191: any;
    AlarmTimeNeverSet_s192: any;
    AlarmTimeNeverSet_s193: any;
    AlarmTimeNeverSet_s194: any;
    AlarmTimeNeverSet_s195: any;
    AlarmTimeNeverSet_s196: any;
    AlarmTimeNeverSet_s197: any;
    AlarmTimeNeverSet_s198: any;
    AlarmTimeNeverSet_s199: any;
    AlarmTimeNeverSet_s200: any;
    AlarmTimeNeverSet_s201: any;
    AlarmTimeNeverSet_s202: any;
    AlarmTimeNeverSet_s203: any;
    AlarmTimeNeverSet_s204: any;
    AlarmTimeNeverSet_s205: any;
    AlarmTimeNeverSet_s206: any;
    AlarmTimeNeverSet_s207: any;
    AlarmTimeNeverSet_s208: any;
    AlarmTimeNeverSet_s209: any;
    AlarmTimeNeverSet_s210: any;
    AlarmTimeNeverSet_s211: any;
    AlarmTimeNeverSet_s212: any;
    AlarmTimeNeverSet_s213: any;
    AlarmTimeNeverSet_s214: any;
    AlarmTimeNeverSet_s215: any;
    AlarmTimeNeverSet_s216: any;
    AlarmTimeNeverSet_s217: any;
    AlarmTimeNeverSet_s218: any;
    AlarmTimeNeverSet_s219: any;
    AlarmTimeNeverSet_s220: any;
    AlarmTimeNeverSet_s221: any;
    AlarmTimeNeverSet_s222: any;
    AlarmTimeNeverSet_s223: any;
    AlarmTimeNeverSet_s224: any;
    AlarmTimeNeverSet_s225: any;
    AlarmTimeNeverSet_s226: any;
    AlarmTimeNeverSet_s227: any;
    AlarmTimeNeverSet_s228: any;
    AlarmTimeNeverSet_s229: any;
    AlarmTimeNeverSet_s230: any;
    AlarmTimeNeverSet_s231: any;
    AlarmTimeNeverSet_s232: any;
    AlarmTimeNeverSet_s233: any;
    AlarmTimeNeverSet_s234: any;
    AlarmTimeNeverSet_s235: any;
    AlarmTimeNeverSet_s236: any;
    AlarmTimeNeverSet_s237: any;
    AlarmTimeNeverSet_s238: any;
    AlarmTimeNeverSet_s239: any;
    AlarmTimeNeverSet_s240: any;
    AlarmTimeNeverSet_s241: any;
    AlarmTimeNeverSet_s242: any;
    AlarmTimeNeverSet_s243: any;
    AlarmTimeNeverSet_s244: any;
    AlarmTimeNeverSet_s245: any;
    AlarmTimeNeverSet_s246: any;
    AlarmTimeNeverSet_s247: any;
    AlarmTimeNeverSet_s248: any;
    AlarmTimeNeverSet_s249: any;
    AlarmTimeNeverSet_s250: any;
    AlarmTimeNeverSet_s251: any;
    AlarmTimeNeverSet_s252: any;
    AlarmTimeNeverSet_s253: any;
    AlarmTimeNeverSet_s254: any;
    AlarmTimeNeverSet_s255: any;
    AlarmTimeNeverSet_s256: any;
    AlarmTestBuild_s1: any;
    AlarmTestBuild_s2: any;
    AlarmTestBuild_s3: any;
    AlarmTestBuild_s4: any;
    AlarmTestBuild_s5: any;
    AlarmTestBuild_s6: any;
    AlarmTestBuild_s7: any;
    AlarmTestBuild_s8: any;
    AlarmTestBuild_s9: any;
    AlarmTestBuild_s10: any;
    AlarmTestBuild_s11: any;
    AlarmTestBuild_s12: any;
    AlarmTestBuild_s13: any;
    AlarmTestBuild_s14: any;
    AlarmTestBuild_s15: any;
    AlarmTestBuild_s16: any;
    AlarmTestBuild_s17: any;
    AlarmTestBuild_s18: any;
    AlarmTestBuild_s19: any;
    AlarmTestBuild_s20: any;
    AlarmTestBuild_s21: any;
    AlarmTestBuild_s22: any;
    AlarmTestBuild_s23: any;
    AlarmTestBuild_s24: any;
    AlarmTestBuild_s25: any;
    AlarmTestBuild_s26: any;
    AlarmTestBuild_s27: any;
    AlarmTestBuild_s28: any;
    AlarmTestBuild_s29: any;
    AlarmTestBuild_s30: any;
    AlarmTestBuild_s31: any;
    AlarmTestBuild_s32: any;
    AlarmTestBuild_s33: any;
    AlarmTestBuild_s34: any;
    AlarmTestBuild_s35: any;
    AlarmTestBuild_s36: any;
    AlarmTestBuild_s37: any;
    AlarmTestBuild_s38: any;
    AlarmTestBuild_s39: any;
    AlarmTestBuild_s40: any;
    AlarmTestBuild_s41: any;
    AlarmTestBuild_s42: any;
    AlarmTestBuild_s43: any;
    AlarmTestBuild_s44: any;
    AlarmTestBuild_s45: any;
    AlarmTestBuild_s46: any;
    AlarmTestBuild_s47: any;
    AlarmTestBuild_s48: any;
    AlarmTestBuild_s49: any;
    AlarmTestBuild_s50: any;
    AlarmTestBuild_s51: any;
    AlarmTestBuild_s52: any;
    AlarmTestBuild_s53: any;
    AlarmTestBuild_s54: any;
    AlarmTestBuild_s55: any;
    AlarmTestBuild_s56: any;
    AlarmTestBuild_s57: any;
    AlarmTestBuild_s58: any;
    AlarmTestBuild_s59: any;
    AlarmTestBuild_s60: any;
    AlarmTestBuild_s61: any;
    AlarmTestBuild_s62: any;
    AlarmTestBuild_s63: any;
    AlarmTestBuild_s64: any;
    AlarmTestBuild_s65: any;
    AlarmTestBuild_s66: any;
    AlarmTestBuild_s67: any;
    AlarmTestBuild_s68: any;
    AlarmTestBuild_s69: any;
    AlarmTestBuild_s70: any;
    AlarmTestBuild_s71: any;
    AlarmTestBuild_s72: any;
    AlarmTestBuild_s73: any;
    AlarmTestBuild_s74: any;
    AlarmTestBuild_s75: any;
    AlarmTestBuild_s76: any;
    AlarmTestBuild_s77: any;
    AlarmTestBuild_s78: any;
    AlarmTestBuild_s79: any;
    AlarmTestBuild_s80: any;
    AlarmTestBuild_s81: any;
    AlarmTestBuild_s82: any;
    AlarmTestBuild_s83: any;
    AlarmTestBuild_s84: any;
    AlarmTestBuild_s85: any;
    AlarmTestBuild_s86: any;
    AlarmTestBuild_s87: any;
    AlarmTestBuild_s88: any;
    AlarmTestBuild_s89: any;
    AlarmTestBuild_s90: any;
    AlarmTestBuild_s91: any;
    AlarmTestBuild_s92: any;
    AlarmTestBuild_s93: any;
    AlarmTestBuild_s94: any;
    AlarmTestBuild_s95: any;
    AlarmTestBuild_s96: any;
    AlarmTestBuild_s97: any;
    AlarmTestBuild_s98: any;
    AlarmTestBuild_s99: any;
    AlarmTestBuild_s100: any;
    AlarmTestBuild_s101: any;
    AlarmTestBuild_s102: any;
    AlarmTestBuild_s103: any;
    AlarmTestBuild_s104: any;
    AlarmTestBuild_s105: any;
    AlarmTestBuild_s106: any;
    AlarmTestBuild_s107: any;
    AlarmTestBuild_s108: any;
    AlarmTestBuild_s109: any;
    AlarmTestBuild_s110: any;
    AlarmTestBuild_s111: any;
    AlarmTestBuild_s112: any;
    AlarmTestBuild_s113: any;
    AlarmTestBuild_s114: any;
    AlarmTestBuild_s115: any;
    AlarmTestBuild_s116: any;
    AlarmTestBuild_s117: any;
    AlarmTestBuild_s118: any;
    AlarmTestBuild_s119: any;
    AlarmTestBuild_s120: any;
    AlarmTestBuild_s121: any;
    AlarmTestBuild_s122: any;
    AlarmTestBuild_s123: any;
    AlarmTestBuild_s124: any;
    AlarmTestBuild_s125: any;
    AlarmTestBuild_s126: any;
    AlarmTestBuild_s127: any;
    AlarmTestBuild_s128: any;
    AlarmTestBuild_s129: any;
    AlarmTestBuild_s130: any;
    AlarmTestBuild_s131: any;
    AlarmTestBuild_s132: any;
    AlarmTestBuild_s133: any;
    AlarmTestBuild_s134: any;
    AlarmTestBuild_s135: any;
    AlarmTestBuild_s136: any;
    AlarmTestBuild_s137: any;
    AlarmTestBuild_s138: any;
    AlarmTestBuild_s139: any;
    AlarmTestBuild_s140: any;
    AlarmTestBuild_s141: any;
    AlarmTestBuild_s142: any;
    AlarmTestBuild_s143: any;
    AlarmTestBuild_s144: any;
    AlarmTestBuild_s145: any;
    AlarmTestBuild_s146: any;
    AlarmTestBuild_s147: any;
    AlarmTestBuild_s148: any;
    AlarmTestBuild_s149: any;
    AlarmTestBuild_s150: any;
    AlarmTestBuild_s151: any;
    AlarmTestBuild_s152: any;
    AlarmTestBuild_s153: any;
    AlarmTestBuild_s154: any;
    AlarmTestBuild_s155: any;
    AlarmTestBuild_s156: any;
    AlarmTestBuild_s157: any;
    AlarmTestBuild_s158: any;
    AlarmTestBuild_s159: any;
    AlarmTestBuild_s160: any;
    AlarmTestBuild_s161: any;
    AlarmTestBuild_s162: any;
    AlarmTestBuild_s163: any;
    AlarmTestBuild_s164: any;
    AlarmTestBuild_s165: any;
    AlarmTestBuild_s166: any;
    AlarmTestBuild_s167: any;
    AlarmTestBuild_s168: any;
    AlarmTestBuild_s169: any;
    AlarmTestBuild_s170: any;
    AlarmTestBuild_s171: any;
    AlarmTestBuild_s172: any;
    AlarmTestBuild_s173: any;
    AlarmTestBuild_s174: any;
    AlarmTestBuild_s175: any;
    AlarmTestBuild_s176: any;
    AlarmTestBuild_s177: any;
    AlarmTestBuild_s178: any;
    AlarmTestBuild_s179: any;
    AlarmTestBuild_s180: any;
    AlarmTestBuild_s181: any;
    AlarmTestBuild_s182: any;
    AlarmTestBuild_s183: any;
    AlarmTestBuild_s184: any;
    AlarmTestBuild_s185: any;
    AlarmTestBuild_s186: any;
    AlarmTestBuild_s187: any;
    AlarmTestBuild_s188: any;
    AlarmTestBuild_s189: any;
    AlarmTestBuild_s190: any;
    AlarmTestBuild_s191: any;
    AlarmTestBuild_s192: any;
    AlarmTestBuild_s193: any;
    AlarmTestBuild_s194: any;
    AlarmTestBuild_s195: any;
    AlarmTestBuild_s196: any;
    AlarmTestBuild_s197: any;
    AlarmTestBuild_s198: any;
    AlarmTestBuild_s199: any;
    AlarmTestBuild_s200: any;
    AlarmTestBuild_s201: any;
    AlarmTestBuild_s202: any;
    AlarmTestBuild_s203: any;
    AlarmTestBuild_s204: any;
    AlarmTestBuild_s205: any;
    AlarmTestBuild_s206: any;
    AlarmTestBuild_s207: any;
    AlarmTestBuild_s208: any;
    AlarmTestBuild_s209: any;
    AlarmTestBuild_s210: any;
    AlarmTestBuild_s211: any;
    AlarmTestBuild_s212: any;
    AlarmTestBuild_s213: any;
    AlarmTestBuild_s214: any;
    AlarmTestBuild_s215: any;
    AlarmTestBuild_s216: any;
    AlarmTestBuild_s217: any;
    AlarmTestBuild_s218: any;
    AlarmTestBuild_s219: any;
    AlarmTestBuild_s220: any;
    AlarmTestBuild_s221: any;
    AlarmTestBuild_s222: any;
    AlarmTestBuild_s223: any;
    AlarmTestBuild_s224: any;
    AlarmTestBuild_s225: any;
    AlarmTestBuild_s226: any;
    AlarmTestBuild_s227: any;
    AlarmTestBuild_s228: any;
    AlarmTestBuild_s229: any;
    AlarmTestBuild_s230: any;
    AlarmTestBuild_s231: any;
    AlarmTestBuild_s232: any;
    AlarmTestBuild_s233: any;
    AlarmTestBuild_s234: any;
    AlarmTestBuild_s235: any;
    AlarmTestBuild_s236: any;
    AlarmTestBuild_s237: any;
    AlarmTestBuild_s238: any;
    AlarmTestBuild_s239: any;
    AlarmTestBuild_s240: any;
    AlarmTestBuild_s241: any;
    AlarmTestBuild_s242: any;
    AlarmTestBuild_s243: any;
    AlarmTestBuild_s244: any;
    AlarmTestBuild_s245: any;
    AlarmTestBuild_s246: any;
    AlarmTestBuild_s247: any;
    AlarmTestBuild_s248: any;
    AlarmTestBuild_s249: any;
    AlarmTestBuild_s250: any;
    AlarmTestBuild_s251: any;
    AlarmTestBuild_s252: any;
    AlarmTestBuild_s253: any;
    AlarmTestBuild_s254: any;
    AlarmTestBuild_s255: any;
    AlarmTestBuild_s256: any;
    AlarmSystemMonitor_s1: any;
    AlarmSystemMonitor_s2: any;
    AlarmSystemMonitor_s3: any;
    AlarmSystemMonitor_s4: any;
    AlarmSystemMonitor_s5: any;
    AlarmSystemMonitor_s6: any;
    AlarmSystemMonitor_s7: any;
    AlarmSystemMonitor_s8: any;
    AlarmSystemMonitor_s9: any;
    AlarmSystemMonitor_s10: any;
    AlarmSystemMonitor_s11: any;
    AlarmSystemMonitor_s12: any;
    AlarmSystemMonitor_s13: any;
    AlarmSystemMonitor_s14: any;
    AlarmSystemMonitor_s15: any;
    AlarmSystemMonitor_s16: any;
    AlarmSystemMonitor_s17: any;
    AlarmSystemMonitor_s18: any;
    AlarmSystemMonitor_s19: any;
    AlarmSystemMonitor_s20: any;
    AlarmSystemMonitor_s21: any;
    AlarmSystemMonitor_s22: any;
    AlarmSystemMonitor_s23: any;
    AlarmSystemMonitor_s24: any;
    AlarmSystemMonitor_s25: any;
    AlarmSystemMonitor_s26: any;
    AlarmSystemMonitor_s27: any;
    AlarmSystemMonitor_s28: any;
    AlarmSystemMonitor_s29: any;
    AlarmSystemMonitor_s30: any;
    AlarmSystemMonitor_s31: any;
    AlarmSystemMonitor_s32: any;
    AlarmSystemMonitor_s33: any;
    AlarmSystemMonitor_s34: any;
    AlarmSystemMonitor_s35: any;
    AlarmSystemMonitor_s36: any;
    AlarmSystemMonitor_s37: any;
    AlarmSystemMonitor_s38: any;
    AlarmSystemMonitor_s39: any;
    AlarmSystemMonitor_s40: any;
    AlarmSystemMonitor_s41: any;
    AlarmSystemMonitor_s42: any;
    AlarmSystemMonitor_s43: any;
    AlarmSystemMonitor_s44: any;
    AlarmSystemMonitor_s45: any;
    AlarmSystemMonitor_s46: any;
    AlarmSystemMonitor_s47: any;
    AlarmSystemMonitor_s48: any;
    AlarmSystemMonitor_s49: any;
    AlarmSystemMonitor_s50: any;
    AlarmSystemMonitor_s51: any;
    AlarmSystemMonitor_s52: any;
    AlarmSystemMonitor_s53: any;
    AlarmSystemMonitor_s54: any;
    AlarmSystemMonitor_s55: any;
    AlarmSystemMonitor_s56: any;
    AlarmSystemMonitor_s57: any;
    AlarmSystemMonitor_s58: any;
    AlarmSystemMonitor_s59: any;
    AlarmSystemMonitor_s60: any;
    AlarmSystemMonitor_s61: any;
    AlarmSystemMonitor_s62: any;
    AlarmSystemMonitor_s63: any;
    AlarmSystemMonitor_s64: any;
    AlarmSystemMonitor_s65: any;
    AlarmSystemMonitor_s66: any;
    AlarmSystemMonitor_s67: any;
    AlarmSystemMonitor_s68: any;
    AlarmSystemMonitor_s69: any;
    AlarmSystemMonitor_s70: any;
    AlarmSystemMonitor_s71: any;
    AlarmSystemMonitor_s72: any;
    AlarmSystemMonitor_s73: any;
    AlarmSystemMonitor_s74: any;
    AlarmSystemMonitor_s75: any;
    AlarmSystemMonitor_s76: any;
    AlarmSystemMonitor_s77: any;
    AlarmSystemMonitor_s78: any;
    AlarmSystemMonitor_s79: any;
    AlarmSystemMonitor_s80: any;
    AlarmSystemMonitor_s81: any;
    AlarmSystemMonitor_s82: any;
    AlarmSystemMonitor_s83: any;
    AlarmSystemMonitor_s84: any;
    AlarmSystemMonitor_s85: any;
    AlarmSystemMonitor_s86: any;
    AlarmSystemMonitor_s87: any;
    AlarmSystemMonitor_s88: any;
    AlarmSystemMonitor_s89: any;
    AlarmSystemMonitor_s90: any;
    AlarmSystemMonitor_s91: any;
    AlarmSystemMonitor_s92: any;
    AlarmSystemMonitor_s93: any;
    AlarmSystemMonitor_s94: any;
    AlarmSystemMonitor_s95: any;
    AlarmSystemMonitor_s96: any;
    AlarmSystemMonitor_s97: any;
    AlarmSystemMonitor_s98: any;
    AlarmSystemMonitor_s99: any;
    AlarmSystemMonitor_s100: any;
    AlarmSystemMonitor_s101: any;
    AlarmSystemMonitor_s102: any;
    AlarmSystemMonitor_s103: any;
    AlarmSystemMonitor_s104: any;
    AlarmSystemMonitor_s105: any;
    AlarmSystemMonitor_s106: any;
    AlarmSystemMonitor_s107: any;
    AlarmSystemMonitor_s108: any;
    AlarmSystemMonitor_s109: any;
    AlarmSystemMonitor_s110: any;
    AlarmSystemMonitor_s111: any;
    AlarmSystemMonitor_s112: any;
    AlarmSystemMonitor_s113: any;
    AlarmSystemMonitor_s114: any;
    AlarmSystemMonitor_s115: any;
    AlarmSystemMonitor_s116: any;
    AlarmSystemMonitor_s117: any;
    AlarmSystemMonitor_s118: any;
    AlarmSystemMonitor_s119: any;
    AlarmSystemMonitor_s120: any;
    AlarmSystemMonitor_s121: any;
    AlarmSystemMonitor_s122: any;
    AlarmSystemMonitor_s123: any;
    AlarmSystemMonitor_s124: any;
    AlarmSystemMonitor_s125: any;
    AlarmSystemMonitor_s126: any;
    AlarmSystemMonitor_s127: any;
    AlarmSystemMonitor_s128: any;
    AlarmSystemMonitor_s129: any;
    AlarmSystemMonitor_s130: any;
    AlarmSystemMonitor_s131: any;
    AlarmSystemMonitor_s132: any;
    AlarmSystemMonitor_s133: any;
    AlarmSystemMonitor_s134: any;
    AlarmSystemMonitor_s135: any;
    AlarmSystemMonitor_s136: any;
    AlarmSystemMonitor_s137: any;
    AlarmSystemMonitor_s138: any;
    AlarmSystemMonitor_s139: any;
    AlarmSystemMonitor_s140: any;
    AlarmSystemMonitor_s141: any;
    AlarmSystemMonitor_s142: any;
    AlarmSystemMonitor_s143: any;
    AlarmSystemMonitor_s144: any;
    AlarmSystemMonitor_s145: any;
    AlarmSystemMonitor_s146: any;
    AlarmSystemMonitor_s147: any;
    AlarmSystemMonitor_s148: any;
    AlarmSystemMonitor_s149: any;
    AlarmSystemMonitor_s150: any;
    AlarmSystemMonitor_s151: any;
    AlarmSystemMonitor_s152: any;
    AlarmSystemMonitor_s153: any;
    AlarmSystemMonitor_s154: any;
    AlarmSystemMonitor_s155: any;
    AlarmSystemMonitor_s156: any;
    AlarmSystemMonitor_s157: any;
    AlarmSystemMonitor_s158: any;
    AlarmSystemMonitor_s159: any;
    AlarmSystemMonitor_s160: any;
    AlarmSystemMonitor_s161: any;
    AlarmSystemMonitor_s162: any;
    AlarmSystemMonitor_s163: any;
    AlarmSystemMonitor_s164: any;
    AlarmSystemMonitor_s165: any;
    AlarmSystemMonitor_s166: any;
    AlarmSystemMonitor_s167: any;
    AlarmSystemMonitor_s168: any;
    AlarmSystemMonitor_s169: any;
    AlarmSystemMonitor_s170: any;
    AlarmSystemMonitor_s171: any;
    AlarmSystemMonitor_s172: any;
    AlarmSystemMonitor_s173: any;
    AlarmSystemMonitor_s174: any;
    AlarmSystemMonitor_s175: any;
    AlarmSystemMonitor_s176: any;
    AlarmSystemMonitor_s177: any;
    AlarmSystemMonitor_s178: any;
    AlarmSystemMonitor_s179: any;
    AlarmSystemMonitor_s180: any;
    AlarmSystemMonitor_s181: any;
    AlarmSystemMonitor_s182: any;
    AlarmSystemMonitor_s183: any;
    AlarmSystemMonitor_s184: any;
    AlarmSystemMonitor_s185: any;
    AlarmSystemMonitor_s186: any;
    AlarmSystemMonitor_s187: any;
    AlarmSystemMonitor_s188: any;
    AlarmSystemMonitor_s189: any;
    AlarmSystemMonitor_s190: any;
    AlarmSystemMonitor_s191: any;
    AlarmSystemMonitor_s192: any;
    AlarmSystemMonitor_s193: any;
    AlarmSystemMonitor_s194: any;
    AlarmSystemMonitor_s195: any;
    AlarmSystemMonitor_s196: any;
    AlarmSystemMonitor_s197: any;
    AlarmSystemMonitor_s198: any;
    AlarmSystemMonitor_s199: any;
    AlarmSystemMonitor_s200: any;
    AlarmSystemMonitor_s201: any;
    AlarmSystemMonitor_s202: any;
    AlarmSystemMonitor_s203: any;
    AlarmSystemMonitor_s204: any;
    AlarmSystemMonitor_s205: any;
    AlarmSystemMonitor_s206: any;
    AlarmSystemMonitor_s207: any;
    AlarmSystemMonitor_s208: any;
    AlarmSystemMonitor_s209: any;
    AlarmSystemMonitor_s210: any;
    AlarmSystemMonitor_s211: any;
    AlarmSystemMonitor_s212: any;
    AlarmSystemMonitor_s213: any;
    AlarmSystemMonitor_s214: any;
    AlarmSystemMonitor_s215: any;
    AlarmSystemMonitor_s216: any;
    AlarmSystemMonitor_s217: any;
    AlarmSystemMonitor_s218: any;
    AlarmSystemMonitor_s219: any;
    AlarmSystemMonitor_s220: any;
    AlarmSystemMonitor_s221: any;
    AlarmSystemMonitor_s222: any;
    AlarmSystemMonitor_s223: any;
    AlarmSystemMonitor_s224: any;
    AlarmSystemMonitor_s225: any;
    AlarmSystemMonitor_s226: any;
    AlarmSystemMonitor_s227: any;
    AlarmSystemMonitor_s228: any;
    AlarmSystemMonitor_s229: any;
    AlarmSystemMonitor_s230: any;
    AlarmSystemMonitor_s231: any;
    AlarmSystemMonitor_s232: any;
    AlarmSystemMonitor_s233: any;
    AlarmSystemMonitor_s234: any;
    AlarmSystemMonitor_s235: any;
    AlarmSystemMonitor_s236: any;
    AlarmSystemMonitor_s237: any;
    AlarmSystemMonitor_s238: any;
    AlarmSystemMonitor_s239: any;
    AlarmSystemMonitor_s240: any;
    AlarmSystemMonitor_s241: any;
    AlarmSystemMonitor_s242: any;
    AlarmSystemMonitor_s243: any;
    AlarmSystemMonitor_s244: any;
    AlarmSystemMonitor_s245: any;
    AlarmSystemMonitor_s246: any;
    AlarmSystemMonitor_s247: any;
    AlarmSystemMonitor_s248: any;
    AlarmSystemMonitor_s249: any;
    AlarmSystemMonitor_s250: any;
    AlarmSystemMonitor_s251: any;
    AlarmSystemMonitor_s252: any;
    AlarmSystemMonitor_s253: any;
    AlarmSystemMonitor_s254: any;
    AlarmSystemMonitor_s255: any;
    AlarmSystemMonitor_s256: any;
    AlarmMotor1OverCurrentSw_s1: any;
    AlarmMotor1OverCurrentSw_s2: any;
    AlarmMotor1OverCurrentSw_s3: any;
    AlarmMotor1OverCurrentSw_s4: any;
    AlarmMotor1OverCurrentSw_s5: any;
    AlarmMotor1OverCurrentSw_s6: any;
    AlarmMotor1OverCurrentSw_s7: any;
    AlarmMotor1OverCurrentSw_s8: any;
    AlarmMotor1OverCurrentSw_s9: any;
    AlarmMotor1OverCurrentSw_s10: any;
    AlarmMotor1OverCurrentSw_s11: any;
    AlarmMotor1OverCurrentSw_s12: any;
    AlarmMotor1OverCurrentSw_s13: any;
    AlarmMotor1OverCurrentSw_s14: any;
    AlarmMotor1OverCurrentSw_s15: any;
    AlarmMotor1OverCurrentSw_s16: any;
    AlarmMotor1OverCurrentSw_s17: any;
    AlarmMotor1OverCurrentSw_s18: any;
    AlarmMotor1OverCurrentSw_s19: any;
    AlarmMotor1OverCurrentSw_s20: any;
    AlarmMotor1OverCurrentSw_s21: any;
    AlarmMotor1OverCurrentSw_s22: any;
    AlarmMotor1OverCurrentSw_s23: any;
    AlarmMotor1OverCurrentSw_s24: any;
    AlarmMotor1OverCurrentSw_s25: any;
    AlarmMotor1OverCurrentSw_s26: any;
    AlarmMotor1OverCurrentSw_s27: any;
    AlarmMotor1OverCurrentSw_s28: any;
    AlarmMotor1OverCurrentSw_s29: any;
    AlarmMotor1OverCurrentSw_s30: any;
    AlarmMotor1OverCurrentSw_s31: any;
    AlarmMotor1OverCurrentSw_s32: any;
    AlarmMotor1OverCurrentSw_s33: any;
    AlarmMotor1OverCurrentSw_s34: any;
    AlarmMotor1OverCurrentSw_s35: any;
    AlarmMotor1OverCurrentSw_s36: any;
    AlarmMotor1OverCurrentSw_s37: any;
    AlarmMotor1OverCurrentSw_s38: any;
    AlarmMotor1OverCurrentSw_s39: any;
    AlarmMotor1OverCurrentSw_s40: any;
    AlarmMotor1OverCurrentSw_s41: any;
    AlarmMotor1OverCurrentSw_s42: any;
    AlarmMotor1OverCurrentSw_s43: any;
    AlarmMotor1OverCurrentSw_s44: any;
    AlarmMotor1OverCurrentSw_s45: any;
    AlarmMotor1OverCurrentSw_s46: any;
    AlarmMotor1OverCurrentSw_s47: any;
    AlarmMotor1OverCurrentSw_s48: any;
    AlarmMotor1OverCurrentSw_s49: any;
    AlarmMotor1OverCurrentSw_s50: any;
    AlarmMotor1OverCurrentSw_s51: any;
    AlarmMotor1OverCurrentSw_s52: any;
    AlarmMotor1OverCurrentSw_s53: any;
    AlarmMotor1OverCurrentSw_s54: any;
    AlarmMotor1OverCurrentSw_s55: any;
    AlarmMotor1OverCurrentSw_s56: any;
    AlarmMotor1OverCurrentSw_s57: any;
    AlarmMotor1OverCurrentSw_s58: any;
    AlarmMotor1OverCurrentSw_s59: any;
    AlarmMotor1OverCurrentSw_s60: any;
    AlarmMotor1OverCurrentSw_s61: any;
    AlarmMotor1OverCurrentSw_s62: any;
    AlarmMotor1OverCurrentSw_s63: any;
    AlarmMotor1OverCurrentSw_s64: any;
    AlarmMotor1OverCurrentSw_s65: any;
    AlarmMotor1OverCurrentSw_s66: any;
    AlarmMotor1OverCurrentSw_s67: any;
    AlarmMotor1OverCurrentSw_s68: any;
    AlarmMotor1OverCurrentSw_s69: any;
    AlarmMotor1OverCurrentSw_s70: any;
    AlarmMotor1OverCurrentSw_s71: any;
    AlarmMotor1OverCurrentSw_s72: any;
    AlarmMotor1OverCurrentSw_s73: any;
    AlarmMotor1OverCurrentSw_s74: any;
    AlarmMotor1OverCurrentSw_s75: any;
    AlarmMotor1OverCurrentSw_s76: any;
    AlarmMotor1OverCurrentSw_s77: any;
    AlarmMotor1OverCurrentSw_s78: any;
    AlarmMotor1OverCurrentSw_s79: any;
    AlarmMotor1OverCurrentSw_s80: any;
    AlarmMotor1OverCurrentSw_s81: any;
    AlarmMotor1OverCurrentSw_s82: any;
    AlarmMotor1OverCurrentSw_s83: any;
    AlarmMotor1OverCurrentSw_s84: any;
    AlarmMotor1OverCurrentSw_s85: any;
    AlarmMotor1OverCurrentSw_s86: any;
    AlarmMotor1OverCurrentSw_s87: any;
    AlarmMotor1OverCurrentSw_s88: any;
    AlarmMotor1OverCurrentSw_s89: any;
    AlarmMotor1OverCurrentSw_s90: any;
    AlarmMotor1OverCurrentSw_s91: any;
    AlarmMotor1OverCurrentSw_s92: any;
    AlarmMotor1OverCurrentSw_s93: any;
    AlarmMotor1OverCurrentSw_s94: any;
    AlarmMotor1OverCurrentSw_s95: any;
    AlarmMotor1OverCurrentSw_s96: any;
    AlarmMotor1OverCurrentSw_s97: any;
    AlarmMotor1OverCurrentSw_s98: any;
    AlarmMotor1OverCurrentSw_s99: any;
    AlarmMotor1OverCurrentSw_s100: any;
    AlarmMotor1OverCurrentSw_s101: any;
    AlarmMotor1OverCurrentSw_s102: any;
    AlarmMotor1OverCurrentSw_s103: any;
    AlarmMotor1OverCurrentSw_s104: any;
    AlarmMotor1OverCurrentSw_s105: any;
    AlarmMotor1OverCurrentSw_s106: any;
    AlarmMotor1OverCurrentSw_s107: any;
    AlarmMotor1OverCurrentSw_s108: any;
    AlarmMotor1OverCurrentSw_s109: any;
    AlarmMotor1OverCurrentSw_s110: any;
    AlarmMotor1OverCurrentSw_s111: any;
    AlarmMotor1OverCurrentSw_s112: any;
    AlarmMotor1OverCurrentSw_s113: any;
    AlarmMotor1OverCurrentSw_s114: any;
    AlarmMotor1OverCurrentSw_s115: any;
    AlarmMotor1OverCurrentSw_s116: any;
    AlarmMotor1OverCurrentSw_s117: any;
    AlarmMotor1OverCurrentSw_s118: any;
    AlarmMotor1OverCurrentSw_s119: any;
    AlarmMotor1OverCurrentSw_s120: any;
    AlarmMotor1OverCurrentSw_s121: any;
    AlarmMotor1OverCurrentSw_s122: any;
    AlarmMotor1OverCurrentSw_s123: any;
    AlarmMotor1OverCurrentSw_s124: any;
    AlarmMotor1OverCurrentSw_s125: any;
    AlarmMotor1OverCurrentSw_s126: any;
    AlarmMotor1OverCurrentSw_s127: any;
    AlarmMotor1OverCurrentSw_s128: any;
    AlarmMotor1OverCurrentSw_s129: any;
    AlarmMotor1OverCurrentSw_s130: any;
    AlarmMotor1OverCurrentSw_s131: any;
    AlarmMotor1OverCurrentSw_s132: any;
    AlarmMotor1OverCurrentSw_s133: any;
    AlarmMotor1OverCurrentSw_s134: any;
    AlarmMotor1OverCurrentSw_s135: any;
    AlarmMotor1OverCurrentSw_s136: any;
    AlarmMotor1OverCurrentSw_s137: any;
    AlarmMotor1OverCurrentSw_s138: any;
    AlarmMotor1OverCurrentSw_s139: any;
    AlarmMotor1OverCurrentSw_s140: any;
    AlarmMotor1OverCurrentSw_s141: any;
    AlarmMotor1OverCurrentSw_s142: any;
    AlarmMotor1OverCurrentSw_s143: any;
    AlarmMotor1OverCurrentSw_s144: any;
    AlarmMotor1OverCurrentSw_s145: any;
    AlarmMotor1OverCurrentSw_s146: any;
    AlarmMotor1OverCurrentSw_s147: any;
    AlarmMotor1OverCurrentSw_s148: any;
    AlarmMotor1OverCurrentSw_s149: any;
    AlarmMotor1OverCurrentSw_s150: any;
    AlarmMotor1OverCurrentSw_s151: any;
    AlarmMotor1OverCurrentSw_s152: any;
    AlarmMotor1OverCurrentSw_s153: any;
    AlarmMotor1OverCurrentSw_s154: any;
    AlarmMotor1OverCurrentSw_s155: any;
    AlarmMotor1OverCurrentSw_s156: any;
    AlarmMotor1OverCurrentSw_s157: any;
    AlarmMotor1OverCurrentSw_s158: any;
    AlarmMotor1OverCurrentSw_s159: any;
    AlarmMotor1OverCurrentSw_s160: any;
    AlarmMotor1OverCurrentSw_s161: any;
    AlarmMotor1OverCurrentSw_s162: any;
    AlarmMotor1OverCurrentSw_s163: any;
    AlarmMotor1OverCurrentSw_s164: any;
    AlarmMotor1OverCurrentSw_s165: any;
    AlarmMotor1OverCurrentSw_s166: any;
    AlarmMotor1OverCurrentSw_s167: any;
    AlarmMotor1OverCurrentSw_s168: any;
    AlarmMotor1OverCurrentSw_s169: any;
    AlarmMotor1OverCurrentSw_s170: any;
    AlarmMotor1OverCurrentSw_s171: any;
    AlarmMotor1OverCurrentSw_s172: any;
    AlarmMotor1OverCurrentSw_s173: any;
    AlarmMotor1OverCurrentSw_s174: any;
    AlarmMotor1OverCurrentSw_s175: any;
    AlarmMotor1OverCurrentSw_s176: any;
    AlarmMotor1OverCurrentSw_s177: any;
    AlarmMotor1OverCurrentSw_s178: any;
    AlarmMotor1OverCurrentSw_s179: any;
    AlarmMotor1OverCurrentSw_s180: any;
    AlarmMotor1OverCurrentSw_s181: any;
    AlarmMotor1OverCurrentSw_s182: any;
    AlarmMotor1OverCurrentSw_s183: any;
    AlarmMotor1OverCurrentSw_s184: any;
    AlarmMotor1OverCurrentSw_s185: any;
    AlarmMotor1OverCurrentSw_s186: any;
    AlarmMotor1OverCurrentSw_s187: any;
    AlarmMotor1OverCurrentSw_s188: any;
    AlarmMotor1OverCurrentSw_s189: any;
    AlarmMotor1OverCurrentSw_s190: any;
    AlarmMotor1OverCurrentSw_s191: any;
    AlarmMotor1OverCurrentSw_s192: any;
    AlarmMotor1OverCurrentSw_s193: any;
    AlarmMotor1OverCurrentSw_s194: any;
    AlarmMotor1OverCurrentSw_s195: any;
    AlarmMotor1OverCurrentSw_s196: any;
    AlarmMotor1OverCurrentSw_s197: any;
    AlarmMotor1OverCurrentSw_s198: any;
    AlarmMotor1OverCurrentSw_s199: any;
    AlarmMotor1OverCurrentSw_s200: any;
    AlarmMotor1OverCurrentSw_s201: any;
    AlarmMotor1OverCurrentSw_s202: any;
    AlarmMotor1OverCurrentSw_s203: any;
    AlarmMotor1OverCurrentSw_s204: any;
    AlarmMotor1OverCurrentSw_s205: any;
    AlarmMotor1OverCurrentSw_s206: any;
    AlarmMotor1OverCurrentSw_s207: any;
    AlarmMotor1OverCurrentSw_s208: any;
    AlarmMotor1OverCurrentSw_s209: any;
    AlarmMotor1OverCurrentSw_s210: any;
    AlarmMotor1OverCurrentSw_s211: any;
    AlarmMotor1OverCurrentSw_s212: any;
    AlarmMotor1OverCurrentSw_s213: any;
    AlarmMotor1OverCurrentSw_s214: any;
    AlarmMotor1OverCurrentSw_s215: any;
    AlarmMotor1OverCurrentSw_s216: any;
    AlarmMotor1OverCurrentSw_s217: any;
    AlarmMotor1OverCurrentSw_s218: any;
    AlarmMotor1OverCurrentSw_s219: any;
    AlarmMotor1OverCurrentSw_s220: any;
    AlarmMotor1OverCurrentSw_s221: any;
    AlarmMotor1OverCurrentSw_s222: any;
    AlarmMotor1OverCurrentSw_s223: any;
    AlarmMotor1OverCurrentSw_s224: any;
    AlarmMotor1OverCurrentSw_s225: any;
    AlarmMotor1OverCurrentSw_s226: any;
    AlarmMotor1OverCurrentSw_s227: any;
    AlarmMotor1OverCurrentSw_s228: any;
    AlarmMotor1OverCurrentSw_s229: any;
    AlarmMotor1OverCurrentSw_s230: any;
    AlarmMotor1OverCurrentSw_s231: any;
    AlarmMotor1OverCurrentSw_s232: any;
    AlarmMotor1OverCurrentSw_s233: any;
    AlarmMotor1OverCurrentSw_s234: any;
    AlarmMotor1OverCurrentSw_s235: any;
    AlarmMotor1OverCurrentSw_s236: any;
    AlarmMotor1OverCurrentSw_s237: any;
    AlarmMotor1OverCurrentSw_s238: any;
    AlarmMotor1OverCurrentSw_s239: any;
    AlarmMotor1OverCurrentSw_s240: any;
    AlarmMotor1OverCurrentSw_s241: any;
    AlarmMotor1OverCurrentSw_s242: any;
    AlarmMotor1OverCurrentSw_s243: any;
    AlarmMotor1OverCurrentSw_s244: any;
    AlarmMotor1OverCurrentSw_s245: any;
    AlarmMotor1OverCurrentSw_s246: any;
    AlarmMotor1OverCurrentSw_s247: any;
    AlarmMotor1OverCurrentSw_s248: any;
    AlarmMotor1OverCurrentSw_s249: any;
    AlarmMotor1OverCurrentSw_s250: any;
    AlarmMotor1OverCurrentSw_s251: any;
    AlarmMotor1OverCurrentSw_s252: any;
    AlarmMotor1OverCurrentSw_s253: any;
    AlarmMotor1OverCurrentSw_s254: any;
    AlarmMotor1OverCurrentSw_s255: any;
    AlarmMotor1OverCurrentSw_s256: any;
    AlarmAxis1Blocked_s1: any;
    AlarmAxis1Blocked_s2: any;
    AlarmAxis1Blocked_s3: any;
    AlarmAxis1Blocked_s4: any;
    AlarmAxis1Blocked_s5: any;
    AlarmAxis1Blocked_s6: any;
    AlarmAxis1Blocked_s7: any;
    AlarmAxis1Blocked_s8: any;
    AlarmAxis1Blocked_s9: any;
    AlarmAxis1Blocked_s10: any;
    AlarmAxis1Blocked_s11: any;
    AlarmAxis1Blocked_s12: any;
    AlarmAxis1Blocked_s13: any;
    AlarmAxis1Blocked_s14: any;
    AlarmAxis1Blocked_s15: any;
    AlarmAxis1Blocked_s16: any;
    AlarmAxis1Blocked_s17: any;
    AlarmAxis1Blocked_s18: any;
    AlarmAxis1Blocked_s19: any;
    AlarmAxis1Blocked_s20: any;
    AlarmAxis1Blocked_s21: any;
    AlarmAxis1Blocked_s22: any;
    AlarmAxis1Blocked_s23: any;
    AlarmAxis1Blocked_s24: any;
    AlarmAxis1Blocked_s25: any;
    AlarmAxis1Blocked_s26: any;
    AlarmAxis1Blocked_s27: any;
    AlarmAxis1Blocked_s28: any;
    AlarmAxis1Blocked_s29: any;
    AlarmAxis1Blocked_s30: any;
    AlarmAxis1Blocked_s31: any;
    AlarmAxis1Blocked_s32: any;
    AlarmAxis1Blocked_s33: any;
    AlarmAxis1Blocked_s34: any;
    AlarmAxis1Blocked_s35: any;
    AlarmAxis1Blocked_s36: any;
    AlarmAxis1Blocked_s37: any;
    AlarmAxis1Blocked_s38: any;
    AlarmAxis1Blocked_s39: any;
    AlarmAxis1Blocked_s40: any;
    AlarmAxis1Blocked_s41: any;
    AlarmAxis1Blocked_s42: any;
    AlarmAxis1Blocked_s43: any;
    AlarmAxis1Blocked_s44: any;
    AlarmAxis1Blocked_s45: any;
    AlarmAxis1Blocked_s46: any;
    AlarmAxis1Blocked_s47: any;
    AlarmAxis1Blocked_s48: any;
    AlarmAxis1Blocked_s49: any;
    AlarmAxis1Blocked_s50: any;
    AlarmAxis1Blocked_s51: any;
    AlarmAxis1Blocked_s52: any;
    AlarmAxis1Blocked_s53: any;
    AlarmAxis1Blocked_s54: any;
    AlarmAxis1Blocked_s55: any;
    AlarmAxis1Blocked_s56: any;
    AlarmAxis1Blocked_s57: any;
    AlarmAxis1Blocked_s58: any;
    AlarmAxis1Blocked_s59: any;
    AlarmAxis1Blocked_s60: any;
    AlarmAxis1Blocked_s61: any;
    AlarmAxis1Blocked_s62: any;
    AlarmAxis1Blocked_s63: any;
    AlarmAxis1Blocked_s64: any;
    AlarmAxis1Blocked_s65: any;
    AlarmAxis1Blocked_s66: any;
    AlarmAxis1Blocked_s67: any;
    AlarmAxis1Blocked_s68: any;
    AlarmAxis1Blocked_s69: any;
    AlarmAxis1Blocked_s70: any;
    AlarmAxis1Blocked_s71: any;
    AlarmAxis1Blocked_s72: any;
    AlarmAxis1Blocked_s73: any;
    AlarmAxis1Blocked_s74: any;
    AlarmAxis1Blocked_s75: any;
    AlarmAxis1Blocked_s76: any;
    AlarmAxis1Blocked_s77: any;
    AlarmAxis1Blocked_s78: any;
    AlarmAxis1Blocked_s79: any;
    AlarmAxis1Blocked_s80: any;
    AlarmAxis1Blocked_s81: any;
    AlarmAxis1Blocked_s82: any;
    AlarmAxis1Blocked_s83: any;
    AlarmAxis1Blocked_s84: any;
    AlarmAxis1Blocked_s85: any;
    AlarmAxis1Blocked_s86: any;
    AlarmAxis1Blocked_s87: any;
    AlarmAxis1Blocked_s88: any;
    AlarmAxis1Blocked_s89: any;
    AlarmAxis1Blocked_s90: any;
    AlarmAxis1Blocked_s91: any;
    AlarmAxis1Blocked_s92: any;
    AlarmAxis1Blocked_s93: any;
    AlarmAxis1Blocked_s94: any;
    AlarmAxis1Blocked_s95: any;
    AlarmAxis1Blocked_s96: any;
    AlarmAxis1Blocked_s97: any;
    AlarmAxis1Blocked_s98: any;
    AlarmAxis1Blocked_s99: any;
    AlarmAxis1Blocked_s100: any;
    AlarmAxis1Blocked_s101: any;
    AlarmAxis1Blocked_s102: any;
    AlarmAxis1Blocked_s103: any;
    AlarmAxis1Blocked_s104: any;
    AlarmAxis1Blocked_s105: any;
    AlarmAxis1Blocked_s106: any;
    AlarmAxis1Blocked_s107: any;
    AlarmAxis1Blocked_s108: any;
    AlarmAxis1Blocked_s109: any;
    AlarmAxis1Blocked_s110: any;
    AlarmAxis1Blocked_s111: any;
    AlarmAxis1Blocked_s112: any;
    AlarmAxis1Blocked_s113: any;
    AlarmAxis1Blocked_s114: any;
    AlarmAxis1Blocked_s115: any;
    AlarmAxis1Blocked_s116: any;
    AlarmAxis1Blocked_s117: any;
    AlarmAxis1Blocked_s118: any;
    AlarmAxis1Blocked_s119: any;
    AlarmAxis1Blocked_s120: any;
    AlarmAxis1Blocked_s121: any;
    AlarmAxis1Blocked_s122: any;
    AlarmAxis1Blocked_s123: any;
    AlarmAxis1Blocked_s124: any;
    AlarmAxis1Blocked_s125: any;
    AlarmAxis1Blocked_s126: any;
    AlarmAxis1Blocked_s127: any;
    AlarmAxis1Blocked_s128: any;
    AlarmAxis1Blocked_s129: any;
    AlarmAxis1Blocked_s130: any;
    AlarmAxis1Blocked_s131: any;
    AlarmAxis1Blocked_s132: any;
    AlarmAxis1Blocked_s133: any;
    AlarmAxis1Blocked_s134: any;
    AlarmAxis1Blocked_s135: any;
    AlarmAxis1Blocked_s136: any;
    AlarmAxis1Blocked_s137: any;
    AlarmAxis1Blocked_s138: any;
    AlarmAxis1Blocked_s139: any;
    AlarmAxis1Blocked_s140: any;
    AlarmAxis1Blocked_s141: any;
    AlarmAxis1Blocked_s142: any;
    AlarmAxis1Blocked_s143: any;
    AlarmAxis1Blocked_s144: any;
    AlarmAxis1Blocked_s145: any;
    AlarmAxis1Blocked_s146: any;
    AlarmAxis1Blocked_s147: any;
    AlarmAxis1Blocked_s148: any;
    AlarmAxis1Blocked_s149: any;
    AlarmAxis1Blocked_s150: any;
    AlarmAxis1Blocked_s151: any;
    AlarmAxis1Blocked_s152: any;
    AlarmAxis1Blocked_s153: any;
    AlarmAxis1Blocked_s154: any;
    AlarmAxis1Blocked_s155: any;
    AlarmAxis1Blocked_s156: any;
    AlarmAxis1Blocked_s157: any;
    AlarmAxis1Blocked_s158: any;
    AlarmAxis1Blocked_s159: any;
    AlarmAxis1Blocked_s160: any;
    AlarmAxis1Blocked_s161: any;
    AlarmAxis1Blocked_s162: any;
    AlarmAxis1Blocked_s163: any;
    AlarmAxis1Blocked_s164: any;
    AlarmAxis1Blocked_s165: any;
    AlarmAxis1Blocked_s166: any;
    AlarmAxis1Blocked_s167: any;
    AlarmAxis1Blocked_s168: any;
    AlarmAxis1Blocked_s169: any;
    AlarmAxis1Blocked_s170: any;
    AlarmAxis1Blocked_s171: any;
    AlarmAxis1Blocked_s172: any;
    AlarmAxis1Blocked_s173: any;
    AlarmAxis1Blocked_s174: any;
    AlarmAxis1Blocked_s175: any;
    AlarmAxis1Blocked_s176: any;
    AlarmAxis1Blocked_s177: any;
    AlarmAxis1Blocked_s178: any;
    AlarmAxis1Blocked_s179: any;
    AlarmAxis1Blocked_s180: any;
    AlarmAxis1Blocked_s181: any;
    AlarmAxis1Blocked_s182: any;
    AlarmAxis1Blocked_s183: any;
    AlarmAxis1Blocked_s184: any;
    AlarmAxis1Blocked_s185: any;
    AlarmAxis1Blocked_s186: any;
    AlarmAxis1Blocked_s187: any;
    AlarmAxis1Blocked_s188: any;
    AlarmAxis1Blocked_s189: any;
    AlarmAxis1Blocked_s190: any;
    AlarmAxis1Blocked_s191: any;
    AlarmAxis1Blocked_s192: any;
    AlarmAxis1Blocked_s193: any;
    AlarmAxis1Blocked_s194: any;
    AlarmAxis1Blocked_s195: any;
    AlarmAxis1Blocked_s196: any;
    AlarmAxis1Blocked_s197: any;
    AlarmAxis1Blocked_s198: any;
    AlarmAxis1Blocked_s199: any;
    AlarmAxis1Blocked_s200: any;
    AlarmAxis1Blocked_s201: any;
    AlarmAxis1Blocked_s202: any;
    AlarmAxis1Blocked_s203: any;
    AlarmAxis1Blocked_s204: any;
    AlarmAxis1Blocked_s205: any;
    AlarmAxis1Blocked_s206: any;
    AlarmAxis1Blocked_s207: any;
    AlarmAxis1Blocked_s208: any;
    AlarmAxis1Blocked_s209: any;
    AlarmAxis1Blocked_s210: any;
    AlarmAxis1Blocked_s211: any;
    AlarmAxis1Blocked_s212: any;
    AlarmAxis1Blocked_s213: any;
    AlarmAxis1Blocked_s214: any;
    AlarmAxis1Blocked_s215: any;
    AlarmAxis1Blocked_s216: any;
    AlarmAxis1Blocked_s217: any;
    AlarmAxis1Blocked_s218: any;
    AlarmAxis1Blocked_s219: any;
    AlarmAxis1Blocked_s220: any;
    AlarmAxis1Blocked_s221: any;
    AlarmAxis1Blocked_s222: any;
    AlarmAxis1Blocked_s223: any;
    AlarmAxis1Blocked_s224: any;
    AlarmAxis1Blocked_s225: any;
    AlarmAxis1Blocked_s226: any;
    AlarmAxis1Blocked_s227: any;
    AlarmAxis1Blocked_s228: any;
    AlarmAxis1Blocked_s229: any;
    AlarmAxis1Blocked_s230: any;
    AlarmAxis1Blocked_s231: any;
    AlarmAxis1Blocked_s232: any;
    AlarmAxis1Blocked_s233: any;
    AlarmAxis1Blocked_s234: any;
    AlarmAxis1Blocked_s235: any;
    AlarmAxis1Blocked_s236: any;
    AlarmAxis1Blocked_s237: any;
    AlarmAxis1Blocked_s238: any;
    AlarmAxis1Blocked_s239: any;
    AlarmAxis1Blocked_s240: any;
    AlarmAxis1Blocked_s241: any;
    AlarmAxis1Blocked_s242: any;
    AlarmAxis1Blocked_s243: any;
    AlarmAxis1Blocked_s244: any;
    AlarmAxis1Blocked_s245: any;
    AlarmAxis1Blocked_s246: any;
    AlarmAxis1Blocked_s247: any;
    AlarmAxis1Blocked_s248: any;
    AlarmAxis1Blocked_s249: any;
    AlarmAxis1Blocked_s250: any;
    AlarmAxis1Blocked_s251: any;
    AlarmAxis1Blocked_s252: any;
    AlarmAxis1Blocked_s253: any;
    AlarmAxis1Blocked_s254: any;
    AlarmAxis1Blocked_s255: any;
    AlarmAxis1Blocked_s256: any;
    AlarmMotor1OverCurrentHw_s1: any;
    AlarmMotor1OverCurrentHw_s2: any;
    AlarmMotor1OverCurrentHw_s3: any;
    AlarmMotor1OverCurrentHw_s4: any;
    AlarmMotor1OverCurrentHw_s5: any;
    AlarmMotor1OverCurrentHw_s6: any;
    AlarmMotor1OverCurrentHw_s7: any;
    AlarmMotor1OverCurrentHw_s8: any;
    AlarmMotor1OverCurrentHw_s9: any;
    AlarmMotor1OverCurrentHw_s10: any;
    AlarmMotor1OverCurrentHw_s11: any;
    AlarmMotor1OverCurrentHw_s12: any;
    AlarmMotor1OverCurrentHw_s13: any;
    AlarmMotor1OverCurrentHw_s14: any;
    AlarmMotor1OverCurrentHw_s15: any;
    AlarmMotor1OverCurrentHw_s16: any;
    AlarmMotor1OverCurrentHw_s17: any;
    AlarmMotor1OverCurrentHw_s18: any;
    AlarmMotor1OverCurrentHw_s19: any;
    AlarmMotor1OverCurrentHw_s20: any;
    AlarmMotor1OverCurrentHw_s21: any;
    AlarmMotor1OverCurrentHw_s22: any;
    AlarmMotor1OverCurrentHw_s23: any;
    AlarmMotor1OverCurrentHw_s24: any;
    AlarmMotor1OverCurrentHw_s25: any;
    AlarmMotor1OverCurrentHw_s26: any;
    AlarmMotor1OverCurrentHw_s27: any;
    AlarmMotor1OverCurrentHw_s28: any;
    AlarmMotor1OverCurrentHw_s29: any;
    AlarmMotor1OverCurrentHw_s30: any;
    AlarmMotor1OverCurrentHw_s31: any;
    AlarmMotor1OverCurrentHw_s32: any;
    AlarmMotor1OverCurrentHw_s33: any;
    AlarmMotor1OverCurrentHw_s34: any;
    AlarmMotor1OverCurrentHw_s35: any;
    AlarmMotor1OverCurrentHw_s36: any;
    AlarmMotor1OverCurrentHw_s37: any;
    AlarmMotor1OverCurrentHw_s38: any;
    AlarmMotor1OverCurrentHw_s39: any;
    AlarmMotor1OverCurrentHw_s40: any;
    AlarmMotor1OverCurrentHw_s41: any;
    AlarmMotor1OverCurrentHw_s42: any;
    AlarmMotor1OverCurrentHw_s43: any;
    AlarmMotor1OverCurrentHw_s44: any;
    AlarmMotor1OverCurrentHw_s45: any;
    AlarmMotor1OverCurrentHw_s46: any;
    AlarmMotor1OverCurrentHw_s47: any;
    AlarmMotor1OverCurrentHw_s48: any;
    AlarmMotor1OverCurrentHw_s49: any;
    AlarmMotor1OverCurrentHw_s50: any;
    AlarmMotor1OverCurrentHw_s51: any;
    AlarmMotor1OverCurrentHw_s52: any;
    AlarmMotor1OverCurrentHw_s53: any;
    AlarmMotor1OverCurrentHw_s54: any;
    AlarmMotor1OverCurrentHw_s55: any;
    AlarmMotor1OverCurrentHw_s56: any;
    AlarmMotor1OverCurrentHw_s57: any;
    AlarmMotor1OverCurrentHw_s58: any;
    AlarmMotor1OverCurrentHw_s59: any;
    AlarmMotor1OverCurrentHw_s60: any;
    AlarmMotor1OverCurrentHw_s61: any;
    AlarmMotor1OverCurrentHw_s62: any;
    AlarmMotor1OverCurrentHw_s63: any;
    AlarmMotor1OverCurrentHw_s64: any;
    AlarmMotor1OverCurrentHw_s65: any;
    AlarmMotor1OverCurrentHw_s66: any;
    AlarmMotor1OverCurrentHw_s67: any;
    AlarmMotor1OverCurrentHw_s68: any;
    AlarmMotor1OverCurrentHw_s69: any;
    AlarmMotor1OverCurrentHw_s70: any;
    AlarmMotor1OverCurrentHw_s71: any;
    AlarmMotor1OverCurrentHw_s72: any;
    AlarmMotor1OverCurrentHw_s73: any;
    AlarmMotor1OverCurrentHw_s74: any;
    AlarmMotor1OverCurrentHw_s75: any;
    AlarmMotor1OverCurrentHw_s76: any;
    AlarmMotor1OverCurrentHw_s77: any;
    AlarmMotor1OverCurrentHw_s78: any;
    AlarmMotor1OverCurrentHw_s79: any;
    AlarmMotor1OverCurrentHw_s80: any;
    AlarmMotor1OverCurrentHw_s81: any;
    AlarmMotor1OverCurrentHw_s82: any;
    AlarmMotor1OverCurrentHw_s83: any;
    AlarmMotor1OverCurrentHw_s84: any;
    AlarmMotor1OverCurrentHw_s85: any;
    AlarmMotor1OverCurrentHw_s86: any;
    AlarmMotor1OverCurrentHw_s87: any;
    AlarmMotor1OverCurrentHw_s88: any;
    AlarmMotor1OverCurrentHw_s89: any;
    AlarmMotor1OverCurrentHw_s90: any;
    AlarmMotor1OverCurrentHw_s91: any;
    AlarmMotor1OverCurrentHw_s92: any;
    AlarmMotor1OverCurrentHw_s93: any;
    AlarmMotor1OverCurrentHw_s94: any;
    AlarmMotor1OverCurrentHw_s95: any;
    AlarmMotor1OverCurrentHw_s96: any;
    AlarmMotor1OverCurrentHw_s97: any;
    AlarmMotor1OverCurrentHw_s98: any;
    AlarmMotor1OverCurrentHw_s99: any;
    AlarmMotor1OverCurrentHw_s100: any;
    AlarmMotor1OverCurrentHw_s101: any;
    AlarmMotor1OverCurrentHw_s102: any;
    AlarmMotor1OverCurrentHw_s103: any;
    AlarmMotor1OverCurrentHw_s104: any;
    AlarmMotor1OverCurrentHw_s105: any;
    AlarmMotor1OverCurrentHw_s106: any;
    AlarmMotor1OverCurrentHw_s107: any;
    AlarmMotor1OverCurrentHw_s108: any;
    AlarmMotor1OverCurrentHw_s109: any;
    AlarmMotor1OverCurrentHw_s110: any;
    AlarmMotor1OverCurrentHw_s111: any;
    AlarmMotor1OverCurrentHw_s112: any;
    AlarmMotor1OverCurrentHw_s113: any;
    AlarmMotor1OverCurrentHw_s114: any;
    AlarmMotor1OverCurrentHw_s115: any;
    AlarmMotor1OverCurrentHw_s116: any;
    AlarmMotor1OverCurrentHw_s117: any;
    AlarmMotor1OverCurrentHw_s118: any;
    AlarmMotor1OverCurrentHw_s119: any;
    AlarmMotor1OverCurrentHw_s120: any;
    AlarmMotor1OverCurrentHw_s121: any;
    AlarmMotor1OverCurrentHw_s122: any;
    AlarmMotor1OverCurrentHw_s123: any;
    AlarmMotor1OverCurrentHw_s124: any;
    AlarmMotor1OverCurrentHw_s125: any;
    AlarmMotor1OverCurrentHw_s126: any;
    AlarmMotor1OverCurrentHw_s127: any;
    AlarmMotor1OverCurrentHw_s128: any;
    AlarmMotor1OverCurrentHw_s129: any;
    AlarmMotor1OverCurrentHw_s130: any;
    AlarmMotor1OverCurrentHw_s131: any;
    AlarmMotor1OverCurrentHw_s132: any;
    AlarmMotor1OverCurrentHw_s133: any;
    AlarmMotor1OverCurrentHw_s134: any;
    AlarmMotor1OverCurrentHw_s135: any;
    AlarmMotor1OverCurrentHw_s136: any;
    AlarmMotor1OverCurrentHw_s137: any;
    AlarmMotor1OverCurrentHw_s138: any;
    AlarmMotor1OverCurrentHw_s139: any;
    AlarmMotor1OverCurrentHw_s140: any;
    AlarmMotor1OverCurrentHw_s141: any;
    AlarmMotor1OverCurrentHw_s142: any;
    AlarmMotor1OverCurrentHw_s143: any;
    AlarmMotor1OverCurrentHw_s144: any;
    AlarmMotor1OverCurrentHw_s145: any;
    AlarmMotor1OverCurrentHw_s146: any;
    AlarmMotor1OverCurrentHw_s147: any;
    AlarmMotor1OverCurrentHw_s148: any;
    AlarmMotor1OverCurrentHw_s149: any;
    AlarmMotor1OverCurrentHw_s150: any;
    AlarmMotor1OverCurrentHw_s151: any;
    AlarmMotor1OverCurrentHw_s152: any;
    AlarmMotor1OverCurrentHw_s153: any;
    AlarmMotor1OverCurrentHw_s154: any;
    AlarmMotor1OverCurrentHw_s155: any;
    AlarmMotor1OverCurrentHw_s156: any;
    AlarmMotor1OverCurrentHw_s157: any;
    AlarmMotor1OverCurrentHw_s158: any;
    AlarmMotor1OverCurrentHw_s159: any;
    AlarmMotor1OverCurrentHw_s160: any;
    AlarmMotor1OverCurrentHw_s161: any;
    AlarmMotor1OverCurrentHw_s162: any;
    AlarmMotor1OverCurrentHw_s163: any;
    AlarmMotor1OverCurrentHw_s164: any;
    AlarmMotor1OverCurrentHw_s165: any;
    AlarmMotor1OverCurrentHw_s166: any;
    AlarmMotor1OverCurrentHw_s167: any;
    AlarmMotor1OverCurrentHw_s168: any;
    AlarmMotor1OverCurrentHw_s169: any;
    AlarmMotor1OverCurrentHw_s170: any;
    AlarmMotor1OverCurrentHw_s171: any;
    AlarmMotor1OverCurrentHw_s172: any;
    AlarmMotor1OverCurrentHw_s173: any;
    AlarmMotor1OverCurrentHw_s174: any;
    AlarmMotor1OverCurrentHw_s175: any;
    AlarmMotor1OverCurrentHw_s176: any;
    AlarmMotor1OverCurrentHw_s177: any;
    AlarmMotor1OverCurrentHw_s178: any;
    AlarmMotor1OverCurrentHw_s179: any;
    AlarmMotor1OverCurrentHw_s180: any;
    AlarmMotor1OverCurrentHw_s181: any;
    AlarmMotor1OverCurrentHw_s182: any;
    AlarmMotor1OverCurrentHw_s183: any;
    AlarmMotor1OverCurrentHw_s184: any;
    AlarmMotor1OverCurrentHw_s185: any;
    AlarmMotor1OverCurrentHw_s186: any;
    AlarmMotor1OverCurrentHw_s187: any;
    AlarmMotor1OverCurrentHw_s188: any;
    AlarmMotor1OverCurrentHw_s189: any;
    AlarmMotor1OverCurrentHw_s190: any;
    AlarmMotor1OverCurrentHw_s191: any;
    AlarmMotor1OverCurrentHw_s192: any;
    AlarmMotor1OverCurrentHw_s193: any;
    AlarmMotor1OverCurrentHw_s194: any;
    AlarmMotor1OverCurrentHw_s195: any;
    AlarmMotor1OverCurrentHw_s196: any;
    AlarmMotor1OverCurrentHw_s197: any;
    AlarmMotor1OverCurrentHw_s198: any;
    AlarmMotor1OverCurrentHw_s199: any;
    AlarmMotor1OverCurrentHw_s200: any;
    AlarmMotor1OverCurrentHw_s201: any;
    AlarmMotor1OverCurrentHw_s202: any;
    AlarmMotor1OverCurrentHw_s203: any;
    AlarmMotor1OverCurrentHw_s204: any;
    AlarmMotor1OverCurrentHw_s205: any;
    AlarmMotor1OverCurrentHw_s206: any;
    AlarmMotor1OverCurrentHw_s207: any;
    AlarmMotor1OverCurrentHw_s208: any;
    AlarmMotor1OverCurrentHw_s209: any;
    AlarmMotor1OverCurrentHw_s210: any;
    AlarmMotor1OverCurrentHw_s211: any;
    AlarmMotor1OverCurrentHw_s212: any;
    AlarmMotor1OverCurrentHw_s213: any;
    AlarmMotor1OverCurrentHw_s214: any;
    AlarmMotor1OverCurrentHw_s215: any;
    AlarmMotor1OverCurrentHw_s216: any;
    AlarmMotor1OverCurrentHw_s217: any;
    AlarmMotor1OverCurrentHw_s218: any;
    AlarmMotor1OverCurrentHw_s219: any;
    AlarmMotor1OverCurrentHw_s220: any;
    AlarmMotor1OverCurrentHw_s221: any;
    AlarmMotor1OverCurrentHw_s222: any;
    AlarmMotor1OverCurrentHw_s223: any;
    AlarmMotor1OverCurrentHw_s224: any;
    AlarmMotor1OverCurrentHw_s225: any;
    AlarmMotor1OverCurrentHw_s226: any;
    AlarmMotor1OverCurrentHw_s227: any;
    AlarmMotor1OverCurrentHw_s228: any;
    AlarmMotor1OverCurrentHw_s229: any;
    AlarmMotor1OverCurrentHw_s230: any;
    AlarmMotor1OverCurrentHw_s231: any;
    AlarmMotor1OverCurrentHw_s232: any;
    AlarmMotor1OverCurrentHw_s233: any;
    AlarmMotor1OverCurrentHw_s234: any;
    AlarmMotor1OverCurrentHw_s235: any;
    AlarmMotor1OverCurrentHw_s236: any;
    AlarmMotor1OverCurrentHw_s237: any;
    AlarmMotor1OverCurrentHw_s238: any;
    AlarmMotor1OverCurrentHw_s239: any;
    AlarmMotor1OverCurrentHw_s240: any;
    AlarmMotor1OverCurrentHw_s241: any;
    AlarmMotor1OverCurrentHw_s242: any;
    AlarmMotor1OverCurrentHw_s243: any;
    AlarmMotor1OverCurrentHw_s244: any;
    AlarmMotor1OverCurrentHw_s245: any;
    AlarmMotor1OverCurrentHw_s246: any;
    AlarmMotor1OverCurrentHw_s247: any;
    AlarmMotor1OverCurrentHw_s248: any;
    AlarmMotor1OverCurrentHw_s249: any;
    AlarmMotor1OverCurrentHw_s250: any;
    AlarmMotor1OverCurrentHw_s251: any;
    AlarmMotor1OverCurrentHw_s252: any;
    AlarmMotor1OverCurrentHw_s253: any;
    AlarmMotor1OverCurrentHw_s254: any;
    AlarmMotor1OverCurrentHw_s255: any;
    AlarmMotor1OverCurrentHw_s256: any;
    AlarmCriticalBatt_s1: any;
    AlarmCriticalBatt_s2: any;
    AlarmCriticalBatt_s3: any;
    AlarmCriticalBatt_s4: any;
    AlarmCriticalBatt_s5: any;
    AlarmCriticalBatt_s6: any;
    AlarmCriticalBatt_s7: any;
    AlarmCriticalBatt_s8: any;
    AlarmCriticalBatt_s9: any;
    AlarmCriticalBatt_s10: any;
    AlarmCriticalBatt_s11: any;
    AlarmCriticalBatt_s12: any;
    AlarmCriticalBatt_s13: any;
    AlarmCriticalBatt_s14: any;
    AlarmCriticalBatt_s15: any;
    AlarmCriticalBatt_s16: any;
    AlarmCriticalBatt_s17: any;
    AlarmCriticalBatt_s18: any;
    AlarmCriticalBatt_s19: any;
    AlarmCriticalBatt_s20: any;
    AlarmCriticalBatt_s21: any;
    AlarmCriticalBatt_s22: any;
    AlarmCriticalBatt_s23: any;
    AlarmCriticalBatt_s24: any;
    AlarmCriticalBatt_s25: any;
    AlarmCriticalBatt_s26: any;
    AlarmCriticalBatt_s27: any;
    AlarmCriticalBatt_s28: any;
    AlarmCriticalBatt_s29: any;
    AlarmCriticalBatt_s30: any;
    AlarmCriticalBatt_s31: any;
    AlarmCriticalBatt_s32: any;
    AlarmCriticalBatt_s33: any;
    AlarmCriticalBatt_s34: any;
    AlarmCriticalBatt_s35: any;
    AlarmCriticalBatt_s36: any;
    AlarmCriticalBatt_s37: any;
    AlarmCriticalBatt_s38: any;
    AlarmCriticalBatt_s39: any;
    AlarmCriticalBatt_s40: any;
    AlarmCriticalBatt_s41: any;
    AlarmCriticalBatt_s42: any;
    AlarmCriticalBatt_s43: any;
    AlarmCriticalBatt_s44: any;
    AlarmCriticalBatt_s45: any;
    AlarmCriticalBatt_s46: any;
    AlarmCriticalBatt_s47: any;
    AlarmCriticalBatt_s48: any;
    AlarmCriticalBatt_s49: any;
    AlarmCriticalBatt_s50: any;
    AlarmCriticalBatt_s51: any;
    AlarmCriticalBatt_s52: any;
    AlarmCriticalBatt_s53: any;
    AlarmCriticalBatt_s54: any;
    AlarmCriticalBatt_s55: any;
    AlarmCriticalBatt_s56: any;
    AlarmCriticalBatt_s57: any;
    AlarmCriticalBatt_s58: any;
    AlarmCriticalBatt_s59: any;
    AlarmCriticalBatt_s60: any;
    AlarmCriticalBatt_s61: any;
    AlarmCriticalBatt_s62: any;
    AlarmCriticalBatt_s63: any;
    AlarmCriticalBatt_s64: any;
    AlarmCriticalBatt_s65: any;
    AlarmCriticalBatt_s66: any;
    AlarmCriticalBatt_s67: any;
    AlarmCriticalBatt_s68: any;
    AlarmCriticalBatt_s69: any;
    AlarmCriticalBatt_s70: any;
    AlarmCriticalBatt_s71: any;
    AlarmCriticalBatt_s72: any;
    AlarmCriticalBatt_s73: any;
    AlarmCriticalBatt_s74: any;
    AlarmCriticalBatt_s75: any;
    AlarmCriticalBatt_s76: any;
    AlarmCriticalBatt_s77: any;
    AlarmCriticalBatt_s78: any;
    AlarmCriticalBatt_s79: any;
    AlarmCriticalBatt_s80: any;
    AlarmCriticalBatt_s81: any;
    AlarmCriticalBatt_s82: any;
    AlarmCriticalBatt_s83: any;
    AlarmCriticalBatt_s84: any;
    AlarmCriticalBatt_s85: any;
    AlarmCriticalBatt_s86: any;
    AlarmCriticalBatt_s87: any;
    AlarmCriticalBatt_s88: any;
    AlarmCriticalBatt_s89: any;
    AlarmCriticalBatt_s90: any;
    AlarmCriticalBatt_s91: any;
    AlarmCriticalBatt_s92: any;
    AlarmCriticalBatt_s93: any;
    AlarmCriticalBatt_s94: any;
    AlarmCriticalBatt_s95: any;
    AlarmCriticalBatt_s96: any;
    AlarmCriticalBatt_s97: any;
    AlarmCriticalBatt_s98: any;
    AlarmCriticalBatt_s99: any;
    AlarmCriticalBatt_s100: any;
    AlarmCriticalBatt_s101: any;
    AlarmCriticalBatt_s102: any;
    AlarmCriticalBatt_s103: any;
    AlarmCriticalBatt_s104: any;
    AlarmCriticalBatt_s105: any;
    AlarmCriticalBatt_s106: any;
    AlarmCriticalBatt_s107: any;
    AlarmCriticalBatt_s108: any;
    AlarmCriticalBatt_s109: any;
    AlarmCriticalBatt_s110: any;
    AlarmCriticalBatt_s111: any;
    AlarmCriticalBatt_s112: any;
    AlarmCriticalBatt_s113: any;
    AlarmCriticalBatt_s114: any;
    AlarmCriticalBatt_s115: any;
    AlarmCriticalBatt_s116: any;
    AlarmCriticalBatt_s117: any;
    AlarmCriticalBatt_s118: any;
    AlarmCriticalBatt_s119: any;
    AlarmCriticalBatt_s120: any;
    AlarmCriticalBatt_s121: any;
    AlarmCriticalBatt_s122: any;
    AlarmCriticalBatt_s123: any;
    AlarmCriticalBatt_s124: any;
    AlarmCriticalBatt_s125: any;
    AlarmCriticalBatt_s126: any;
    AlarmCriticalBatt_s127: any;
    AlarmCriticalBatt_s128: any;
    AlarmCriticalBatt_s129: any;
    AlarmCriticalBatt_s130: any;
    AlarmCriticalBatt_s131: any;
    AlarmCriticalBatt_s132: any;
    AlarmCriticalBatt_s133: any;
    AlarmCriticalBatt_s134: any;
    AlarmCriticalBatt_s135: any;
    AlarmCriticalBatt_s136: any;
    AlarmCriticalBatt_s137: any;
    AlarmCriticalBatt_s138: any;
    AlarmCriticalBatt_s139: any;
    AlarmCriticalBatt_s140: any;
    AlarmCriticalBatt_s141: any;
    AlarmCriticalBatt_s142: any;
    AlarmCriticalBatt_s143: any;
    AlarmCriticalBatt_s144: any;
    AlarmCriticalBatt_s145: any;
    AlarmCriticalBatt_s146: any;
    AlarmCriticalBatt_s147: any;
    AlarmCriticalBatt_s148: any;
    AlarmCriticalBatt_s149: any;
    AlarmCriticalBatt_s150: any;
    AlarmCriticalBatt_s151: any;
    AlarmCriticalBatt_s152: any;
    AlarmCriticalBatt_s153: any;
    AlarmCriticalBatt_s154: any;
    AlarmCriticalBatt_s155: any;
    AlarmCriticalBatt_s156: any;
    AlarmCriticalBatt_s157: any;
    AlarmCriticalBatt_s158: any;
    AlarmCriticalBatt_s159: any;
    AlarmCriticalBatt_s160: any;
    AlarmCriticalBatt_s161: any;
    AlarmCriticalBatt_s162: any;
    AlarmCriticalBatt_s163: any;
    AlarmCriticalBatt_s164: any;
    AlarmCriticalBatt_s165: any;
    AlarmCriticalBatt_s166: any;
    AlarmCriticalBatt_s167: any;
    AlarmCriticalBatt_s168: any;
    AlarmCriticalBatt_s169: any;
    AlarmCriticalBatt_s170: any;
    AlarmCriticalBatt_s171: any;
    AlarmCriticalBatt_s172: any;
    AlarmCriticalBatt_s173: any;
    AlarmCriticalBatt_s174: any;
    AlarmCriticalBatt_s175: any;
    AlarmCriticalBatt_s176: any;
    AlarmCriticalBatt_s177: any;
    AlarmCriticalBatt_s178: any;
    AlarmCriticalBatt_s179: any;
    AlarmCriticalBatt_s180: any;
    AlarmCriticalBatt_s181: any;
    AlarmCriticalBatt_s182: any;
    AlarmCriticalBatt_s183: any;
    AlarmCriticalBatt_s184: any;
    AlarmCriticalBatt_s185: any;
    AlarmCriticalBatt_s186: any;
    AlarmCriticalBatt_s187: any;
    AlarmCriticalBatt_s188: any;
    AlarmCriticalBatt_s189: any;
    AlarmCriticalBatt_s190: any;
    AlarmCriticalBatt_s191: any;
    AlarmCriticalBatt_s192: any;
    AlarmCriticalBatt_s193: any;
    AlarmCriticalBatt_s194: any;
    AlarmCriticalBatt_s195: any;
    AlarmCriticalBatt_s196: any;
    AlarmCriticalBatt_s197: any;
    AlarmCriticalBatt_s198: any;
    AlarmCriticalBatt_s199: any;
    AlarmCriticalBatt_s200: any;
    AlarmCriticalBatt_s201: any;
    AlarmCriticalBatt_s202: any;
    AlarmCriticalBatt_s203: any;
    AlarmCriticalBatt_s204: any;
    AlarmCriticalBatt_s205: any;
    AlarmCriticalBatt_s206: any;
    AlarmCriticalBatt_s207: any;
    AlarmCriticalBatt_s208: any;
    AlarmCriticalBatt_s209: any;
    AlarmCriticalBatt_s210: any;
    AlarmCriticalBatt_s211: any;
    AlarmCriticalBatt_s212: any;
    AlarmCriticalBatt_s213: any;
    AlarmCriticalBatt_s214: any;
    AlarmCriticalBatt_s215: any;
    AlarmCriticalBatt_s216: any;
    AlarmCriticalBatt_s217: any;
    AlarmCriticalBatt_s218: any;
    AlarmCriticalBatt_s219: any;
    AlarmCriticalBatt_s220: any;
    AlarmCriticalBatt_s221: any;
    AlarmCriticalBatt_s222: any;
    AlarmCriticalBatt_s223: any;
    AlarmCriticalBatt_s224: any;
    AlarmCriticalBatt_s225: any;
    AlarmCriticalBatt_s226: any;
    AlarmCriticalBatt_s227: any;
    AlarmCriticalBatt_s228: any;
    AlarmCriticalBatt_s229: any;
    AlarmCriticalBatt_s230: any;
    AlarmCriticalBatt_s231: any;
    AlarmCriticalBatt_s232: any;
    AlarmCriticalBatt_s233: any;
    AlarmCriticalBatt_s234: any;
    AlarmCriticalBatt_s235: any;
    AlarmCriticalBatt_s236: any;
    AlarmCriticalBatt_s237: any;
    AlarmCriticalBatt_s238: any;
    AlarmCriticalBatt_s239: any;
    AlarmCriticalBatt_s240: any;
    AlarmCriticalBatt_s241: any;
    AlarmCriticalBatt_s242: any;
    AlarmCriticalBatt_s243: any;
    AlarmCriticalBatt_s244: any;
    AlarmCriticalBatt_s245: any;
    AlarmCriticalBatt_s246: any;
    AlarmCriticalBatt_s247: any;
    AlarmCriticalBatt_s248: any;
    AlarmCriticalBatt_s249: any;
    AlarmCriticalBatt_s250: any;
    AlarmCriticalBatt_s251: any;
    AlarmCriticalBatt_s252: any;
    AlarmCriticalBatt_s253: any;
    AlarmCriticalBatt_s254: any;
    AlarmCriticalBatt_s255: any;
    AlarmCriticalBatt_s256: any;
    AlarmLowBatt_s1: any;
    AlarmLowBatt_s2: any;
    AlarmLowBatt_s3: any;
    AlarmLowBatt_s4: any;
    AlarmLowBatt_s5: any;
    AlarmLowBatt_s6: any;
    AlarmLowBatt_s7: any;
    AlarmLowBatt_s8: any;
    AlarmLowBatt_s9: any;
    AlarmLowBatt_s10: any;
    AlarmLowBatt_s11: any;
    AlarmLowBatt_s12: any;
    AlarmLowBatt_s13: any;
    AlarmLowBatt_s14: any;
    AlarmLowBatt_s15: any;
    AlarmLowBatt_s16: any;
    AlarmLowBatt_s17: any;
    AlarmLowBatt_s18: any;
    AlarmLowBatt_s19: any;
    AlarmLowBatt_s20: any;
    AlarmLowBatt_s21: any;
    AlarmLowBatt_s22: any;
    AlarmLowBatt_s23: any;
    AlarmLowBatt_s24: any;
    AlarmLowBatt_s25: any;
    AlarmLowBatt_s26: any;
    AlarmLowBatt_s27: any;
    AlarmLowBatt_s28: any;
    AlarmLowBatt_s29: any;
    AlarmLowBatt_s30: any;
    AlarmLowBatt_s31: any;
    AlarmLowBatt_s32: any;
    AlarmLowBatt_s33: any;
    AlarmLowBatt_s34: any;
    AlarmLowBatt_s35: any;
    AlarmLowBatt_s36: any;
    AlarmLowBatt_s37: any;
    AlarmLowBatt_s38: any;
    AlarmLowBatt_s39: any;
    AlarmLowBatt_s40: any;
    AlarmLowBatt_s41: any;
    AlarmLowBatt_s42: any;
    AlarmLowBatt_s43: any;
    AlarmLowBatt_s44: any;
    AlarmLowBatt_s45: any;
    AlarmLowBatt_s46: any;
    AlarmLowBatt_s47: any;
    AlarmLowBatt_s48: any;
    AlarmLowBatt_s49: any;
    AlarmLowBatt_s50: any;
    AlarmLowBatt_s51: any;
    AlarmLowBatt_s52: any;
    AlarmLowBatt_s53: any;
    AlarmLowBatt_s54: any;
    AlarmLowBatt_s55: any;
    AlarmLowBatt_s56: any;
    AlarmLowBatt_s57: any;
    AlarmLowBatt_s58: any;
    AlarmLowBatt_s59: any;
    AlarmLowBatt_s60: any;
    AlarmLowBatt_s61: any;
    AlarmLowBatt_s62: any;
    AlarmLowBatt_s63: any;
    AlarmLowBatt_s64: any;
    AlarmLowBatt_s65: any;
    AlarmLowBatt_s66: any;
    AlarmLowBatt_s67: any;
    AlarmLowBatt_s68: any;
    AlarmLowBatt_s69: any;
    AlarmLowBatt_s70: any;
    AlarmLowBatt_s71: any;
    AlarmLowBatt_s72: any;
    AlarmLowBatt_s73: any;
    AlarmLowBatt_s74: any;
    AlarmLowBatt_s75: any;
    AlarmLowBatt_s76: any;
    AlarmLowBatt_s77: any;
    AlarmLowBatt_s78: any;
    AlarmLowBatt_s79: any;
    AlarmLowBatt_s80: any;
    AlarmLowBatt_s81: any;
    AlarmLowBatt_s82: any;
    AlarmLowBatt_s83: any;
    AlarmLowBatt_s84: any;
    AlarmLowBatt_s85: any;
    AlarmLowBatt_s86: any;
    AlarmLowBatt_s87: any;
    AlarmLowBatt_s88: any;
    AlarmLowBatt_s89: any;
    AlarmLowBatt_s90: any;
    AlarmLowBatt_s91: any;
    AlarmLowBatt_s92: any;
    AlarmLowBatt_s93: any;
    AlarmLowBatt_s94: any;
    AlarmLowBatt_s95: any;
    AlarmLowBatt_s96: any;
    AlarmLowBatt_s97: any;
    AlarmLowBatt_s98: any;
    AlarmLowBatt_s99: any;
    AlarmLowBatt_s100: any;
    AlarmLowBatt_s101: any;
    AlarmLowBatt_s102: any;
    AlarmLowBatt_s103: any;
    AlarmLowBatt_s104: any;
    AlarmLowBatt_s105: any;
    AlarmLowBatt_s106: any;
    AlarmLowBatt_s107: any;
    AlarmLowBatt_s108: any;
    AlarmLowBatt_s109: any;
    AlarmLowBatt_s110: any;
    AlarmLowBatt_s111: any;
    AlarmLowBatt_s112: any;
    AlarmLowBatt_s113: any;
    AlarmLowBatt_s114: any;
    AlarmLowBatt_s115: any;
    AlarmLowBatt_s116: any;
    AlarmLowBatt_s117: any;
    AlarmLowBatt_s118: any;
    AlarmLowBatt_s119: any;
    AlarmLowBatt_s120: any;
    AlarmLowBatt_s121: any;
    AlarmLowBatt_s122: any;
    AlarmLowBatt_s123: any;
    AlarmLowBatt_s124: any;
    AlarmLowBatt_s125: any;
    AlarmLowBatt_s126: any;
    AlarmLowBatt_s127: any;
    AlarmLowBatt_s128: any;
    AlarmLowBatt_s129: any;
    AlarmLowBatt_s130: any;
    AlarmLowBatt_s131: any;
    AlarmLowBatt_s132: any;
    AlarmLowBatt_s133: any;
    AlarmLowBatt_s134: any;
    AlarmLowBatt_s135: any;
    AlarmLowBatt_s136: any;
    AlarmLowBatt_s137: any;
    AlarmLowBatt_s138: any;
    AlarmLowBatt_s139: any;
    AlarmLowBatt_s140: any;
    AlarmLowBatt_s141: any;
    AlarmLowBatt_s142: any;
    AlarmLowBatt_s143: any;
    AlarmLowBatt_s144: any;
    AlarmLowBatt_s145: any;
    AlarmLowBatt_s146: any;
    AlarmLowBatt_s147: any;
    AlarmLowBatt_s148: any;
    AlarmLowBatt_s149: any;
    AlarmLowBatt_s150: any;
    AlarmLowBatt_s151: any;
    AlarmLowBatt_s152: any;
    AlarmLowBatt_s153: any;
    AlarmLowBatt_s154: any;
    AlarmLowBatt_s155: any;
    AlarmLowBatt_s156: any;
    AlarmLowBatt_s157: any;
    AlarmLowBatt_s158: any;
    AlarmLowBatt_s159: any;
    AlarmLowBatt_s160: any;
    AlarmLowBatt_s161: any;
    AlarmLowBatt_s162: any;
    AlarmLowBatt_s163: any;
    AlarmLowBatt_s164: any;
    AlarmLowBatt_s165: any;
    AlarmLowBatt_s166: any;
    AlarmLowBatt_s167: any;
    AlarmLowBatt_s168: any;
    AlarmLowBatt_s169: any;
    AlarmLowBatt_s170: any;
    AlarmLowBatt_s171: any;
    AlarmLowBatt_s172: any;
    AlarmLowBatt_s173: any;
    AlarmLowBatt_s174: any;
    AlarmLowBatt_s175: any;
    AlarmLowBatt_s176: any;
    AlarmLowBatt_s177: any;
    AlarmLowBatt_s178: any;
    AlarmLowBatt_s179: any;
    AlarmLowBatt_s180: any;
    AlarmLowBatt_s181: any;
    AlarmLowBatt_s182: any;
    AlarmLowBatt_s183: any;
    AlarmLowBatt_s184: any;
    AlarmLowBatt_s185: any;
    AlarmLowBatt_s186: any;
    AlarmLowBatt_s187: any;
    AlarmLowBatt_s188: any;
    AlarmLowBatt_s189: any;
    AlarmLowBatt_s190: any;
    AlarmLowBatt_s191: any;
    AlarmLowBatt_s192: any;
    AlarmLowBatt_s193: any;
    AlarmLowBatt_s194: any;
    AlarmLowBatt_s195: any;
    AlarmLowBatt_s196: any;
    AlarmLowBatt_s197: any;
    AlarmLowBatt_s198: any;
    AlarmLowBatt_s199: any;
    AlarmLowBatt_s200: any;
    AlarmLowBatt_s201: any;
    AlarmLowBatt_s202: any;
    AlarmLowBatt_s203: any;
    AlarmLowBatt_s204: any;
    AlarmLowBatt_s205: any;
    AlarmLowBatt_s206: any;
    AlarmLowBatt_s207: any;
    AlarmLowBatt_s208: any;
    AlarmLowBatt_s209: any;
    AlarmLowBatt_s210: any;
    AlarmLowBatt_s211: any;
    AlarmLowBatt_s212: any;
    AlarmLowBatt_s213: any;
    AlarmLowBatt_s214: any;
    AlarmLowBatt_s215: any;
    AlarmLowBatt_s216: any;
    AlarmLowBatt_s217: any;
    AlarmLowBatt_s218: any;
    AlarmLowBatt_s219: any;
    AlarmLowBatt_s220: any;
    AlarmLowBatt_s221: any;
    AlarmLowBatt_s222: any;
    AlarmLowBatt_s223: any;
    AlarmLowBatt_s224: any;
    AlarmLowBatt_s225: any;
    AlarmLowBatt_s226: any;
    AlarmLowBatt_s227: any;
    AlarmLowBatt_s228: any;
    AlarmLowBatt_s229: any;
    AlarmLowBatt_s230: any;
    AlarmLowBatt_s231: any;
    AlarmLowBatt_s232: any;
    AlarmLowBatt_s233: any;
    AlarmLowBatt_s234: any;
    AlarmLowBatt_s235: any;
    AlarmLowBatt_s236: any;
    AlarmLowBatt_s237: any;
    AlarmLowBatt_s238: any;
    AlarmLowBatt_s239: any;
    AlarmLowBatt_s240: any;
    AlarmLowBatt_s241: any;
    AlarmLowBatt_s242: any;
    AlarmLowBatt_s243: any;
    AlarmLowBatt_s244: any;
    AlarmLowBatt_s245: any;
    AlarmLowBatt_s246: any;
    AlarmLowBatt_s247: any;
    AlarmLowBatt_s248: any;
    AlarmLowBatt_s249: any;
    AlarmLowBatt_s250: any;
    AlarmLowBatt_s251: any;
    AlarmLowBatt_s252: any;
    AlarmLowBatt_s253: any;
    AlarmLowBatt_s254: any;
    AlarmLowBatt_s255: any;
    AlarmLowBatt_s256: any;
    AlarmNotEnoughBatt_s1: any;
    AlarmNotEnoughBatt_s2: any;
    AlarmNotEnoughBatt_s3: any;
    AlarmNotEnoughBatt_s4: any;
    AlarmNotEnoughBatt_s5: any;
    AlarmNotEnoughBatt_s6: any;
    AlarmNotEnoughBatt_s7: any;
    AlarmNotEnoughBatt_s8: any;
    AlarmNotEnoughBatt_s9: any;
    AlarmNotEnoughBatt_s10: any;
    AlarmNotEnoughBatt_s11: any;
    AlarmNotEnoughBatt_s12: any;
    AlarmNotEnoughBatt_s13: any;
    AlarmNotEnoughBatt_s14: any;
    AlarmNotEnoughBatt_s15: any;
    AlarmNotEnoughBatt_s16: any;
    AlarmNotEnoughBatt_s17: any;
    AlarmNotEnoughBatt_s18: any;
    AlarmNotEnoughBatt_s19: any;
    AlarmNotEnoughBatt_s20: any;
    AlarmNotEnoughBatt_s21: any;
    AlarmNotEnoughBatt_s22: any;
    AlarmNotEnoughBatt_s23: any;
    AlarmNotEnoughBatt_s24: any;
    AlarmNotEnoughBatt_s25: any;
    AlarmNotEnoughBatt_s26: any;
    AlarmNotEnoughBatt_s27: any;
    AlarmNotEnoughBatt_s28: any;
    AlarmNotEnoughBatt_s29: any;
    AlarmNotEnoughBatt_s30: any;
    AlarmNotEnoughBatt_s31: any;
    AlarmNotEnoughBatt_s32: any;
    AlarmNotEnoughBatt_s33: any;
    AlarmNotEnoughBatt_s34: any;
    AlarmNotEnoughBatt_s35: any;
    AlarmNotEnoughBatt_s36: any;
    AlarmNotEnoughBatt_s37: any;
    AlarmNotEnoughBatt_s38: any;
    AlarmNotEnoughBatt_s39: any;
    AlarmNotEnoughBatt_s40: any;
    AlarmNotEnoughBatt_s41: any;
    AlarmNotEnoughBatt_s42: any;
    AlarmNotEnoughBatt_s43: any;
    AlarmNotEnoughBatt_s44: any;
    AlarmNotEnoughBatt_s45: any;
    AlarmNotEnoughBatt_s46: any;
    AlarmNotEnoughBatt_s47: any;
    AlarmNotEnoughBatt_s48: any;
    AlarmNotEnoughBatt_s49: any;
    AlarmNotEnoughBatt_s50: any;
    AlarmNotEnoughBatt_s51: any;
    AlarmNotEnoughBatt_s52: any;
    AlarmNotEnoughBatt_s53: any;
    AlarmNotEnoughBatt_s54: any;
    AlarmNotEnoughBatt_s55: any;
    AlarmNotEnoughBatt_s56: any;
    AlarmNotEnoughBatt_s57: any;
    AlarmNotEnoughBatt_s58: any;
    AlarmNotEnoughBatt_s59: any;
    AlarmNotEnoughBatt_s60: any;
    AlarmNotEnoughBatt_s61: any;
    AlarmNotEnoughBatt_s62: any;
    AlarmNotEnoughBatt_s63: any;
    AlarmNotEnoughBatt_s64: any;
    AlarmNotEnoughBatt_s65: any;
    AlarmNotEnoughBatt_s66: any;
    AlarmNotEnoughBatt_s67: any;
    AlarmNotEnoughBatt_s68: any;
    AlarmNotEnoughBatt_s69: any;
    AlarmNotEnoughBatt_s70: any;
    AlarmNotEnoughBatt_s71: any;
    AlarmNotEnoughBatt_s72: any;
    AlarmNotEnoughBatt_s73: any;
    AlarmNotEnoughBatt_s74: any;
    AlarmNotEnoughBatt_s75: any;
    AlarmNotEnoughBatt_s76: any;
    AlarmNotEnoughBatt_s77: any;
    AlarmNotEnoughBatt_s78: any;
    AlarmNotEnoughBatt_s79: any;
    AlarmNotEnoughBatt_s80: any;
    AlarmNotEnoughBatt_s81: any;
    AlarmNotEnoughBatt_s82: any;
    AlarmNotEnoughBatt_s83: any;
    AlarmNotEnoughBatt_s84: any;
    AlarmNotEnoughBatt_s85: any;
    AlarmNotEnoughBatt_s86: any;
    AlarmNotEnoughBatt_s87: any;
    AlarmNotEnoughBatt_s88: any;
    AlarmNotEnoughBatt_s89: any;
    AlarmNotEnoughBatt_s90: any;
    AlarmNotEnoughBatt_s91: any;
    AlarmNotEnoughBatt_s92: any;
    AlarmNotEnoughBatt_s93: any;
    AlarmNotEnoughBatt_s94: any;
    AlarmNotEnoughBatt_s95: any;
    AlarmNotEnoughBatt_s96: any;
    AlarmNotEnoughBatt_s97: any;
    AlarmNotEnoughBatt_s98: any;
    AlarmNotEnoughBatt_s99: any;
    AlarmNotEnoughBatt_s100: any;
    AlarmNotEnoughBatt_s101: any;
    AlarmNotEnoughBatt_s102: any;
    AlarmNotEnoughBatt_s103: any;
    AlarmNotEnoughBatt_s104: any;
    AlarmNotEnoughBatt_s105: any;
    AlarmNotEnoughBatt_s106: any;
    AlarmNotEnoughBatt_s107: any;
    AlarmNotEnoughBatt_s108: any;
    AlarmNotEnoughBatt_s109: any;
    AlarmNotEnoughBatt_s110: any;
    AlarmNotEnoughBatt_s111: any;
    AlarmNotEnoughBatt_s112: any;
    AlarmNotEnoughBatt_s113: any;
    AlarmNotEnoughBatt_s114: any;
    AlarmNotEnoughBatt_s115: any;
    AlarmNotEnoughBatt_s116: any;
    AlarmNotEnoughBatt_s117: any;
    AlarmNotEnoughBatt_s118: any;
    AlarmNotEnoughBatt_s119: any;
    AlarmNotEnoughBatt_s120: any;
    AlarmNotEnoughBatt_s121: any;
    AlarmNotEnoughBatt_s122: any;
    AlarmNotEnoughBatt_s123: any;
    AlarmNotEnoughBatt_s124: any;
    AlarmNotEnoughBatt_s125: any;
    AlarmNotEnoughBatt_s126: any;
    AlarmNotEnoughBatt_s127: any;
    AlarmNotEnoughBatt_s128: any;
    AlarmNotEnoughBatt_s129: any;
    AlarmNotEnoughBatt_s130: any;
    AlarmNotEnoughBatt_s131: any;
    AlarmNotEnoughBatt_s132: any;
    AlarmNotEnoughBatt_s133: any;
    AlarmNotEnoughBatt_s134: any;
    AlarmNotEnoughBatt_s135: any;
    AlarmNotEnoughBatt_s136: any;
    AlarmNotEnoughBatt_s137: any;
    AlarmNotEnoughBatt_s138: any;
    AlarmNotEnoughBatt_s139: any;
    AlarmNotEnoughBatt_s140: any;
    AlarmNotEnoughBatt_s141: any;
    AlarmNotEnoughBatt_s142: any;
    AlarmNotEnoughBatt_s143: any;
    AlarmNotEnoughBatt_s144: any;
    AlarmNotEnoughBatt_s145: any;
    AlarmNotEnoughBatt_s146: any;
    AlarmNotEnoughBatt_s147: any;
    AlarmNotEnoughBatt_s148: any;
    AlarmNotEnoughBatt_s149: any;
    AlarmNotEnoughBatt_s150: any;
    AlarmNotEnoughBatt_s151: any;
    AlarmNotEnoughBatt_s152: any;
    AlarmNotEnoughBatt_s153: any;
    AlarmNotEnoughBatt_s154: any;
    AlarmNotEnoughBatt_s155: any;
    AlarmNotEnoughBatt_s156: any;
    AlarmNotEnoughBatt_s157: any;
    AlarmNotEnoughBatt_s158: any;
    AlarmNotEnoughBatt_s159: any;
    AlarmNotEnoughBatt_s160: any;
    AlarmNotEnoughBatt_s161: any;
    AlarmNotEnoughBatt_s162: any;
    AlarmNotEnoughBatt_s163: any;
    AlarmNotEnoughBatt_s164: any;
    AlarmNotEnoughBatt_s165: any;
    AlarmNotEnoughBatt_s166: any;
    AlarmNotEnoughBatt_s167: any;
    AlarmNotEnoughBatt_s168: any;
    AlarmNotEnoughBatt_s169: any;
    AlarmNotEnoughBatt_s170: any;
    AlarmNotEnoughBatt_s171: any;
    AlarmNotEnoughBatt_s172: any;
    AlarmNotEnoughBatt_s173: any;
    AlarmNotEnoughBatt_s174: any;
    AlarmNotEnoughBatt_s175: any;
    AlarmNotEnoughBatt_s176: any;
    AlarmNotEnoughBatt_s177: any;
    AlarmNotEnoughBatt_s178: any;
    AlarmNotEnoughBatt_s179: any;
    AlarmNotEnoughBatt_s180: any;
    AlarmNotEnoughBatt_s181: any;
    AlarmNotEnoughBatt_s182: any;
    AlarmNotEnoughBatt_s183: any;
    AlarmNotEnoughBatt_s184: any;
    AlarmNotEnoughBatt_s185: any;
    AlarmNotEnoughBatt_s186: any;
    AlarmNotEnoughBatt_s187: any;
    AlarmNotEnoughBatt_s188: any;
    AlarmNotEnoughBatt_s189: any;
    AlarmNotEnoughBatt_s190: any;
    AlarmNotEnoughBatt_s191: any;
    AlarmNotEnoughBatt_s192: any;
    AlarmNotEnoughBatt_s193: any;
    AlarmNotEnoughBatt_s194: any;
    AlarmNotEnoughBatt_s195: any;
    AlarmNotEnoughBatt_s196: any;
    AlarmNotEnoughBatt_s197: any;
    AlarmNotEnoughBatt_s198: any;
    AlarmNotEnoughBatt_s199: any;
    AlarmNotEnoughBatt_s200: any;
    AlarmNotEnoughBatt_s201: any;
    AlarmNotEnoughBatt_s202: any;
    AlarmNotEnoughBatt_s203: any;
    AlarmNotEnoughBatt_s204: any;
    AlarmNotEnoughBatt_s205: any;
    AlarmNotEnoughBatt_s206: any;
    AlarmNotEnoughBatt_s207: any;
    AlarmNotEnoughBatt_s208: any;
    AlarmNotEnoughBatt_s209: any;
    AlarmNotEnoughBatt_s210: any;
    AlarmNotEnoughBatt_s211: any;
    AlarmNotEnoughBatt_s212: any;
    AlarmNotEnoughBatt_s213: any;
    AlarmNotEnoughBatt_s214: any;
    AlarmNotEnoughBatt_s215: any;
    AlarmNotEnoughBatt_s216: any;
    AlarmNotEnoughBatt_s217: any;
    AlarmNotEnoughBatt_s218: any;
    AlarmNotEnoughBatt_s219: any;
    AlarmNotEnoughBatt_s220: any;
    AlarmNotEnoughBatt_s221: any;
    AlarmNotEnoughBatt_s222: any;
    AlarmNotEnoughBatt_s223: any;
    AlarmNotEnoughBatt_s224: any;
    AlarmNotEnoughBatt_s225: any;
    AlarmNotEnoughBatt_s226: any;
    AlarmNotEnoughBatt_s227: any;
    AlarmNotEnoughBatt_s228: any;
    AlarmNotEnoughBatt_s229: any;
    AlarmNotEnoughBatt_s230: any;
    AlarmNotEnoughBatt_s231: any;
    AlarmNotEnoughBatt_s232: any;
    AlarmNotEnoughBatt_s233: any;
    AlarmNotEnoughBatt_s234: any;
    AlarmNotEnoughBatt_s235: any;
    AlarmNotEnoughBatt_s236: any;
    AlarmNotEnoughBatt_s237: any;
    AlarmNotEnoughBatt_s238: any;
    AlarmNotEnoughBatt_s239: any;
    AlarmNotEnoughBatt_s240: any;
    AlarmNotEnoughBatt_s241: any;
    AlarmNotEnoughBatt_s242: any;
    AlarmNotEnoughBatt_s243: any;
    AlarmNotEnoughBatt_s244: any;
    AlarmNotEnoughBatt_s245: any;
    AlarmNotEnoughBatt_s246: any;
    AlarmNotEnoughBatt_s247: any;
    AlarmNotEnoughBatt_s248: any;
    AlarmNotEnoughBatt_s249: any;
    AlarmNotEnoughBatt_s250: any;
    AlarmNotEnoughBatt_s251: any;
    AlarmNotEnoughBatt_s252: any;
    AlarmNotEnoughBatt_s253: any;
    AlarmNotEnoughBatt_s254: any;
    AlarmNotEnoughBatt_s255: any;
    AlarmNotEnoughBatt_s256: any;
    alarmLimitedBatt_s1: any;
    alarmLimitedBatt_s2: any;
    alarmLimitedBatt_s3: any;
    alarmLimitedBatt_s4: any;
    alarmLimitedBatt_s5: any;
    alarmLimitedBatt_s6: any;
    alarmLimitedBatt_s7: any;
    alarmLimitedBatt_s8: any;
    alarmLimitedBatt_s9: any;
    alarmLimitedBatt_s10: any;
    alarmLimitedBatt_s11: any;
    alarmLimitedBatt_s12: any;
    alarmLimitedBatt_s13: any;
    alarmLimitedBatt_s14: any;
    alarmLimitedBatt_s15: any;
    alarmLimitedBatt_s16: any;
    alarmLimitedBatt_s17: any;
    alarmLimitedBatt_s18: any;
    alarmLimitedBatt_s19: any;
    alarmLimitedBatt_s20: any;
    alarmLimitedBatt_s21: any;
    alarmLimitedBatt_s22: any;
    alarmLimitedBatt_s23: any;
    alarmLimitedBatt_s24: any;
    alarmLimitedBatt_s25: any;
    alarmLimitedBatt_s26: any;
    alarmLimitedBatt_s27: any;
    alarmLimitedBatt_s28: any;
    alarmLimitedBatt_s29: any;
    alarmLimitedBatt_s30: any;
    alarmLimitedBatt_s31: any;
    alarmLimitedBatt_s32: any;
    alarmLimitedBatt_s33: any;
    alarmLimitedBatt_s34: any;
    alarmLimitedBatt_s35: any;
    alarmLimitedBatt_s36: any;
    alarmLimitedBatt_s37: any;
    alarmLimitedBatt_s38: any;
    alarmLimitedBatt_s39: any;
    alarmLimitedBatt_s40: any;
    alarmLimitedBatt_s41: any;
    alarmLimitedBatt_s42: any;
    alarmLimitedBatt_s43: any;
    alarmLimitedBatt_s44: any;
    alarmLimitedBatt_s45: any;
    alarmLimitedBatt_s46: any;
    alarmLimitedBatt_s47: any;
    alarmLimitedBatt_s48: any;
    alarmLimitedBatt_s49: any;
    alarmLimitedBatt_s50: any;
    alarmLimitedBatt_s51: any;
    alarmLimitedBatt_s52: any;
    alarmLimitedBatt_s53: any;
    alarmLimitedBatt_s54: any;
    alarmLimitedBatt_s55: any;
    alarmLimitedBatt_s56: any;
    alarmLimitedBatt_s57: any;
    alarmLimitedBatt_s58: any;
    alarmLimitedBatt_s59: any;
    alarmLimitedBatt_s60: any;
    alarmLimitedBatt_s61: any;
    alarmLimitedBatt_s62: any;
    alarmLimitedBatt_s63: any;
    alarmLimitedBatt_s64: any;
    alarmLimitedBatt_s65: any;
    alarmLimitedBatt_s66: any;
    alarmLimitedBatt_s67: any;
    alarmLimitedBatt_s68: any;
    alarmLimitedBatt_s69: any;
    alarmLimitedBatt_s70: any;
    alarmLimitedBatt_s71: any;
    alarmLimitedBatt_s72: any;
    alarmLimitedBatt_s73: any;
    alarmLimitedBatt_s74: any;
    alarmLimitedBatt_s75: any;
    alarmLimitedBatt_s76: any;
    alarmLimitedBatt_s77: any;
    alarmLimitedBatt_s78: any;
    alarmLimitedBatt_s79: any;
    alarmLimitedBatt_s80: any;
    alarmLimitedBatt_s81: any;
    alarmLimitedBatt_s82: any;
    alarmLimitedBatt_s83: any;
    alarmLimitedBatt_s84: any;
    alarmLimitedBatt_s85: any;
    alarmLimitedBatt_s86: any;
    alarmLimitedBatt_s87: any;
    alarmLimitedBatt_s88: any;
    alarmLimitedBatt_s89: any;
    alarmLimitedBatt_s90: any;
    alarmLimitedBatt_s91: any;
    alarmLimitedBatt_s92: any;
    alarmLimitedBatt_s93: any;
    alarmLimitedBatt_s94: any;
    alarmLimitedBatt_s95: any;
    alarmLimitedBatt_s96: any;
    alarmLimitedBatt_s97: any;
    alarmLimitedBatt_s98: any;
    alarmLimitedBatt_s99: any;
    alarmLimitedBatt_s100: any;
    alarmLimitedBatt_s101: any;
    alarmLimitedBatt_s102: any;
    alarmLimitedBatt_s103: any;
    alarmLimitedBatt_s104: any;
    alarmLimitedBatt_s105: any;
    alarmLimitedBatt_s106: any;
    alarmLimitedBatt_s107: any;
    alarmLimitedBatt_s108: any;
    alarmLimitedBatt_s109: any;
    alarmLimitedBatt_s110: any;
    alarmLimitedBatt_s111: any;
    alarmLimitedBatt_s112: any;
    alarmLimitedBatt_s113: any;
    alarmLimitedBatt_s114: any;
    alarmLimitedBatt_s115: any;
    alarmLimitedBatt_s116: any;
    alarmLimitedBatt_s117: any;
    alarmLimitedBatt_s118: any;
    alarmLimitedBatt_s119: any;
    alarmLimitedBatt_s120: any;
    alarmLimitedBatt_s121: any;
    alarmLimitedBatt_s122: any;
    alarmLimitedBatt_s123: any;
    alarmLimitedBatt_s124: any;
    alarmLimitedBatt_s125: any;
    alarmLimitedBatt_s126: any;
    alarmLimitedBatt_s127: any;
    alarmLimitedBatt_s128: any;
    alarmLimitedBatt_s129: any;
    alarmLimitedBatt_s130: any;
    alarmLimitedBatt_s131: any;
    alarmLimitedBatt_s132: any;
    alarmLimitedBatt_s133: any;
    alarmLimitedBatt_s134: any;
    alarmLimitedBatt_s135: any;
    alarmLimitedBatt_s136: any;
    alarmLimitedBatt_s137: any;
    alarmLimitedBatt_s138: any;
    alarmLimitedBatt_s139: any;
    alarmLimitedBatt_s140: any;
    alarmLimitedBatt_s141: any;
    alarmLimitedBatt_s142: any;
    alarmLimitedBatt_s143: any;
    alarmLimitedBatt_s144: any;
    alarmLimitedBatt_s145: any;
    alarmLimitedBatt_s146: any;
    alarmLimitedBatt_s147: any;
    alarmLimitedBatt_s148: any;
    alarmLimitedBatt_s149: any;
    alarmLimitedBatt_s150: any;
    alarmLimitedBatt_s151: any;
    alarmLimitedBatt_s152: any;
    alarmLimitedBatt_s153: any;
    alarmLimitedBatt_s154: any;
    alarmLimitedBatt_s155: any;
    alarmLimitedBatt_s156: any;
    alarmLimitedBatt_s157: any;
    alarmLimitedBatt_s158: any;
    alarmLimitedBatt_s159: any;
    alarmLimitedBatt_s160: any;
    alarmLimitedBatt_s161: any;
    alarmLimitedBatt_s162: any;
    alarmLimitedBatt_s163: any;
    alarmLimitedBatt_s164: any;
    alarmLimitedBatt_s165: any;
    alarmLimitedBatt_s166: any;
    alarmLimitedBatt_s167: any;
    alarmLimitedBatt_s168: any;
    alarmLimitedBatt_s169: any;
    alarmLimitedBatt_s170: any;
    alarmLimitedBatt_s171: any;
    alarmLimitedBatt_s172: any;
    alarmLimitedBatt_s173: any;
    alarmLimitedBatt_s174: any;
    alarmLimitedBatt_s175: any;
    alarmLimitedBatt_s176: any;
    alarmLimitedBatt_s177: any;
    alarmLimitedBatt_s178: any;
    alarmLimitedBatt_s179: any;
    alarmLimitedBatt_s180: any;
    alarmLimitedBatt_s181: any;
    alarmLimitedBatt_s182: any;
    alarmLimitedBatt_s183: any;
    alarmLimitedBatt_s184: any;
    alarmLimitedBatt_s185: any;
    alarmLimitedBatt_s186: any;
    alarmLimitedBatt_s187: any;
    alarmLimitedBatt_s188: any;
    alarmLimitedBatt_s189: any;
    alarmLimitedBatt_s190: any;
    alarmLimitedBatt_s191: any;
    alarmLimitedBatt_s192: any;
    alarmLimitedBatt_s193: any;
    alarmLimitedBatt_s194: any;
    alarmLimitedBatt_s195: any;
    alarmLimitedBatt_s196: any;
    alarmLimitedBatt_s197: any;
    alarmLimitedBatt_s198: any;
    alarmLimitedBatt_s199: any;
    alarmLimitedBatt_s200: any;
    alarmLimitedBatt_s201: any;
    alarmLimitedBatt_s202: any;
    alarmLimitedBatt_s203: any;
    alarmLimitedBatt_s204: any;
    alarmLimitedBatt_s205: any;
    alarmLimitedBatt_s206: any;
    alarmLimitedBatt_s207: any;
    alarmLimitedBatt_s208: any;
    alarmLimitedBatt_s209: any;
    alarmLimitedBatt_s210: any;
    alarmLimitedBatt_s211: any;
    alarmLimitedBatt_s212: any;
    alarmLimitedBatt_s213: any;
    alarmLimitedBatt_s214: any;
    alarmLimitedBatt_s215: any;
    alarmLimitedBatt_s216: any;
    alarmLimitedBatt_s217: any;
    alarmLimitedBatt_s218: any;
    alarmLimitedBatt_s219: any;
    alarmLimitedBatt_s220: any;
    alarmLimitedBatt_s221: any;
    alarmLimitedBatt_s222: any;
    alarmLimitedBatt_s223: any;
    alarmLimitedBatt_s224: any;
    alarmLimitedBatt_s225: any;
    alarmLimitedBatt_s226: any;
    alarmLimitedBatt_s227: any;
    alarmLimitedBatt_s228: any;
    alarmLimitedBatt_s229: any;
    alarmLimitedBatt_s230: any;
    alarmLimitedBatt_s231: any;
    alarmLimitedBatt_s232: any;
    alarmLimitedBatt_s233: any;
    alarmLimitedBatt_s234: any;
    alarmLimitedBatt_s235: any;
    alarmLimitedBatt_s236: any;
    alarmLimitedBatt_s237: any;
    alarmLimitedBatt_s238: any;
    alarmLimitedBatt_s239: any;
    alarmLimitedBatt_s240: any;
    alarmLimitedBatt_s241: any;
    alarmLimitedBatt_s242: any;
    alarmLimitedBatt_s243: any;
    alarmLimitedBatt_s244: any;
    alarmLimitedBatt_s245: any;
    alarmLimitedBatt_s246: any;
    alarmLimitedBatt_s247: any;
    alarmLimitedBatt_s248: any;
    alarmLimitedBatt_s249: any;
    alarmLimitedBatt_s250: any;
    alarmLimitedBatt_s251: any;
    alarmLimitedBatt_s252: any;
    alarmLimitedBatt_s253: any;
    alarmLimitedBatt_s254: any;
    alarmLimitedBatt_s255: any;
    alarmLimitedBatt_s256: any;

    NumMotorAlarm_s1: any;
    NumMotorAlarm_s2: any;
    NumMotorAlarm_s3: any;
    NumMotorAlarm_s4: any;
    NumMotorAlarm_s5: any;
    NumMotorAlarm_s6: any;
    NumMotorAlarm_s7: any;
    NumMotorAlarm_s8: any;
    NumMotorAlarm_s9: any;
    NumMotorAlarm_s10: any;
    NumMotorAlarm_s11: any;
    NumMotorAlarm_s12: any;
    NumMotorAlarm_s13: any;
    NumMotorAlarm_s14: any;
    NumMotorAlarm_s15: any;
    NumMotorAlarm_s16: any;
    NumMotorAlarm_s17: any;
    NumMotorAlarm_s18: any;
    NumMotorAlarm_s19: any;
    NumMotorAlarm_s20: any;
    NumMotorAlarm_s21: any;
    NumMotorAlarm_s22: any;
    NumMotorAlarm_s23: any;
    NumMotorAlarm_s24: any;
    NumMotorAlarm_s25: any;
    NumMotorAlarm_s26: any;
    NumMotorAlarm_s27: any;
    NumMotorAlarm_s28: any;
    NumMotorAlarm_s29: any;
    NumMotorAlarm_s30: any;
    NumMotorAlarm_s31: any;
    NumMotorAlarm_s32: any;
    NumMotorAlarm_s33: any;
    NumMotorAlarm_s34: any;
    NumMotorAlarm_s35: any;
    NumMotorAlarm_s36: any;
    NumMotorAlarm_s37: any;
    NumMotorAlarm_s38: any;
    NumMotorAlarm_s39: any;
    NumMotorAlarm_s40: any;
    NumMotorAlarm_s41: any;
    NumMotorAlarm_s42: any;
    NumMotorAlarm_s43: any;
    NumMotorAlarm_s44: any;
    NumMotorAlarm_s45: any;
    NumMotorAlarm_s46: any;
    NumMotorAlarm_s47: any;
    NumMotorAlarm_s48: any;
    NumMotorAlarm_s49: any;
    NumMotorAlarm_s50: any;
    NumMotorAlarm_s51: any;
    NumMotorAlarm_s52: any;
    NumMotorAlarm_s53: any;
    NumMotorAlarm_s54: any;
    NumMotorAlarm_s55: any;
    NumMotorAlarm_s56: any;
    NumMotorAlarm_s57: any;
    NumMotorAlarm_s58: any;
    NumMotorAlarm_s59: any;
    NumMotorAlarm_s60: any;
    NumMotorAlarm_s61: any;
    NumMotorAlarm_s62: any;
    NumMotorAlarm_s63: any;
    NumMotorAlarm_s64: any;
    NumMotorAlarm_s65: any;
    NumMotorAlarm_s66: any;
    NumMotorAlarm_s67: any;
    NumMotorAlarm_s68: any;
    NumMotorAlarm_s69: any;
    NumMotorAlarm_s70: any;
    NumMotorAlarm_s71: any;
    NumMotorAlarm_s72: any;
    NumMotorAlarm_s73: any;
    NumMotorAlarm_s74: any;
    NumMotorAlarm_s75: any;
    NumMotorAlarm_s76: any;
    NumMotorAlarm_s77: any;
    NumMotorAlarm_s78: any;
    NumMotorAlarm_s79: any;
    NumMotorAlarm_s80: any;
    NumMotorAlarm_s81: any;
    NumMotorAlarm_s82: any;
    NumMotorAlarm_s83: any;
    NumMotorAlarm_s84: any;
    NumMotorAlarm_s85: any;
    NumMotorAlarm_s86: any;
    NumMotorAlarm_s87: any;
    NumMotorAlarm_s88: any;
    NumMotorAlarm_s89: any;
    NumMotorAlarm_s90: any;
    NumMotorAlarm_s91: any;
    NumMotorAlarm_s92: any;
    NumMotorAlarm_s93: any;
    NumMotorAlarm_s94: any;
    NumMotorAlarm_s95: any;
    NumMotorAlarm_s96: any;
    NumMotorAlarm_s97: any;
    NumMotorAlarm_s98: any;
    NumMotorAlarm_s99: any;
    NumMotorAlarm_s100: any;
    NumMotorAlarm_s101: any;
    NumMotorAlarm_s102: any;
    NumMotorAlarm_s103: any;
    NumMotorAlarm_s104: any;
    NumMotorAlarm_s105: any;
    NumMotorAlarm_s106: any;
    NumMotorAlarm_s107: any;
    NumMotorAlarm_s108: any;
    NumMotorAlarm_s109: any;
    NumMotorAlarm_s110: any;
    NumMotorAlarm_s111: any;
    NumMotorAlarm_s112: any;
    NumMotorAlarm_s113: any;
    NumMotorAlarm_s114: any;
    NumMotorAlarm_s115: any;
    NumMotorAlarm_s116: any;
    NumMotorAlarm_s117: any;
    NumMotorAlarm_s118: any;
    NumMotorAlarm_s119: any;
    NumMotorAlarm_s120: any;
    NumMotorAlarm_s121: any;
    NumMotorAlarm_s122: any;
    NumMotorAlarm_s123: any;
    NumMotorAlarm_s124: any;
    NumMotorAlarm_s125: any;
    NumMotorAlarm_s126: any;
    NumMotorAlarm_s127: any;
    NumMotorAlarm_s128: any;
    NumMotorAlarm_s129: any;
    NumMotorAlarm_s130: any;
    NumMotorAlarm_s131: any;
    NumMotorAlarm_s132: any;
    NumMotorAlarm_s133: any;
    NumMotorAlarm_s134: any;
    NumMotorAlarm_s135: any;
    NumMotorAlarm_s136: any;
    NumMotorAlarm_s137: any;
    NumMotorAlarm_s138: any;
    NumMotorAlarm_s139: any;
    NumMotorAlarm_s140: any;
    NumMotorAlarm_s141: any;
    NumMotorAlarm_s142: any;
    NumMotorAlarm_s143: any;
    NumMotorAlarm_s144: any;
    NumMotorAlarm_s145: any;
    NumMotorAlarm_s146: any;
    NumMotorAlarm_s147: any;
    NumMotorAlarm_s148: any;
    NumMotorAlarm_s149: any;
    NumMotorAlarm_s150: any;
    NumMotorAlarm_s151: any;
    NumMotorAlarm_s152: any;
    NumMotorAlarm_s153: any;
    NumMotorAlarm_s154: any;
    NumMotorAlarm_s155: any;
    NumMotorAlarm_s156: any;
    NumMotorAlarm_s157: any;
    NumMotorAlarm_s158: any;
    NumMotorAlarm_s159: any;
    NumMotorAlarm_s160: any;
    NumMotorAlarm_s161: any;
    NumMotorAlarm_s162: any;
    NumMotorAlarm_s163: any;
    NumMotorAlarm_s164: any;
    NumMotorAlarm_s165: any;
    NumMotorAlarm_s166: any;
    NumMotorAlarm_s167: any;
    NumMotorAlarm_s168: any;
    NumMotorAlarm_s169: any;
    NumMotorAlarm_s170: any;
    NumMotorAlarm_s171: any;
    NumMotorAlarm_s172: any;
    NumMotorAlarm_s173: any;
    NumMotorAlarm_s174: any;
    NumMotorAlarm_s175: any;
    NumMotorAlarm_s176: any;
    NumMotorAlarm_s177: any;
    NumMotorAlarm_s178: any;
    NumMotorAlarm_s179: any;
    NumMotorAlarm_s180: any;
    NumMotorAlarm_s181: any;
    NumMotorAlarm_s182: any;
    NumMotorAlarm_s183: any;
    NumMotorAlarm_s184: any;
    NumMotorAlarm_s185: any;
    NumMotorAlarm_s186: any;
    NumMotorAlarm_s187: any;
    NumMotorAlarm_s188: any;
    NumMotorAlarm_s189: any;
    NumMotorAlarm_s190: any;
    NumMotorAlarm_s191: any;
    NumMotorAlarm_s192: any;
    NumMotorAlarm_s193: any;
    NumMotorAlarm_s194: any;
    NumMotorAlarm_s195: any;
    NumMotorAlarm_s196: any;
    NumMotorAlarm_s197: any;
    NumMotorAlarm_s198: any;
    NumMotorAlarm_s199: any;
    NumMotorAlarm_s200: any;
    NumMotorAlarm_s201: any;
    NumMotorAlarm_s202: any;
    NumMotorAlarm_s203: any;
    NumMotorAlarm_s204: any;
    NumMotorAlarm_s205: any;
    NumMotorAlarm_s206: any;
    NumMotorAlarm_s207: any;
    NumMotorAlarm_s208: any;
    NumMotorAlarm_s209: any;
    NumMotorAlarm_s210: any;
    NumMotorAlarm_s211: any;
    NumMotorAlarm_s212: any;
    NumMotorAlarm_s213: any;
    NumMotorAlarm_s214: any;
    NumMotorAlarm_s215: any;
    NumMotorAlarm_s216: any;
    NumMotorAlarm_s217: any;
    NumMotorAlarm_s218: any;
    NumMotorAlarm_s219: any;
    NumMotorAlarm_s220: any;
    NumMotorAlarm_s221: any;
    NumMotorAlarm_s222: any;
    NumMotorAlarm_s223: any;
    NumMotorAlarm_s224: any;
    NumMotorAlarm_s225: any;
    NumMotorAlarm_s226: any;
    NumMotorAlarm_s227: any;
    NumMotorAlarm_s228: any;
    NumMotorAlarm_s229: any;
    NumMotorAlarm_s230: any;
    NumMotorAlarm_s231: any;
    NumMotorAlarm_s232: any;
    NumMotorAlarm_s233: any;
    NumMotorAlarm_s234: any;
    NumMotorAlarm_s235: any;
    NumMotorAlarm_s236: any;
    NumMotorAlarm_s237: any;
    NumMotorAlarm_s238: any;
    NumMotorAlarm_s239: any;
    NumMotorAlarm_s240: any;
    NumMotorAlarm_s241: any;
    NumMotorAlarm_s242: any;
    NumMotorAlarm_s243: any;
    NumMotorAlarm_s244: any;
    NumMotorAlarm_s245: any;
    NumMotorAlarm_s246: any;
    NumMotorAlarm_s247: any;
    NumMotorAlarm_s248: any;
    NumMotorAlarm_s249: any;
    NumMotorAlarm_s250: any;
    NumMotorAlarm_s251: any;
    NumMotorAlarm_s252: any;
    NumMotorAlarm_s253: any;
    NumMotorAlarm_s254: any;
    NumMotorAlarm_s255: any;
    NumMotorAlarm_s256: any;
    NumAxisAlarm_s1: any;
    NumAxisAlarm_s2: any;
    NumAxisAlarm_s3: any;
    NumAxisAlarm_s4: any;
    NumAxisAlarm_s5: any;
    NumAxisAlarm_s6: any;
    NumAxisAlarm_s7: any;
    NumAxisAlarm_s8: any;
    NumAxisAlarm_s9: any;
    NumAxisAlarm_s10: any;
    NumAxisAlarm_s11: any;
    NumAxisAlarm_s12: any;
    NumAxisAlarm_s13: any;
    NumAxisAlarm_s14: any;
    NumAxisAlarm_s15: any;
    NumAxisAlarm_s16: any;
    NumAxisAlarm_s17: any;
    NumAxisAlarm_s18: any;
    NumAxisAlarm_s19: any;
    NumAxisAlarm_s20: any;
    NumAxisAlarm_s21: any;
    NumAxisAlarm_s22: any;
    NumAxisAlarm_s23: any;
    NumAxisAlarm_s24: any;
    NumAxisAlarm_s25: any;
    NumAxisAlarm_s26: any;
    NumAxisAlarm_s27: any;
    NumAxisAlarm_s28: any;
    NumAxisAlarm_s29: any;
    NumAxisAlarm_s30: any;
    NumAxisAlarm_s31: any;
    NumAxisAlarm_s32: any;
    NumAxisAlarm_s33: any;
    NumAxisAlarm_s34: any;
    NumAxisAlarm_s35: any;
    NumAxisAlarm_s36: any;
    NumAxisAlarm_s37: any;
    NumAxisAlarm_s38: any;
    NumAxisAlarm_s39: any;
    NumAxisAlarm_s40: any;
    NumAxisAlarm_s41: any;
    NumAxisAlarm_s42: any;
    NumAxisAlarm_s43: any;
    NumAxisAlarm_s44: any;
    NumAxisAlarm_s45: any;
    NumAxisAlarm_s46: any;
    NumAxisAlarm_s47: any;
    NumAxisAlarm_s48: any;
    NumAxisAlarm_s49: any;
    NumAxisAlarm_s50: any;
    NumAxisAlarm_s51: any;
    NumAxisAlarm_s52: any;
    NumAxisAlarm_s53: any;
    NumAxisAlarm_s54: any;
    NumAxisAlarm_s55: any;
    NumAxisAlarm_s56: any;
    NumAxisAlarm_s57: any;
    NumAxisAlarm_s58: any;
    NumAxisAlarm_s59: any;
    NumAxisAlarm_s60: any;
    NumAxisAlarm_s61: any;
    NumAxisAlarm_s62: any;
    NumAxisAlarm_s63: any;
    NumAxisAlarm_s64: any;
    NumAxisAlarm_s65: any;
    NumAxisAlarm_s66: any;
    NumAxisAlarm_s67: any;
    NumAxisAlarm_s68: any;
    NumAxisAlarm_s69: any;
    NumAxisAlarm_s70: any;
    NumAxisAlarm_s71: any;
    NumAxisAlarm_s72: any;
    NumAxisAlarm_s73: any;
    NumAxisAlarm_s74: any;
    NumAxisAlarm_s75: any;
    NumAxisAlarm_s76: any;
    NumAxisAlarm_s77: any;
    NumAxisAlarm_s78: any;
    NumAxisAlarm_s79: any;
    NumAxisAlarm_s80: any;
    NumAxisAlarm_s81: any;
    NumAxisAlarm_s82: any;
    NumAxisAlarm_s83: any;
    NumAxisAlarm_s84: any;
    NumAxisAlarm_s85: any;
    NumAxisAlarm_s86: any;
    NumAxisAlarm_s87: any;
    NumAxisAlarm_s88: any;
    NumAxisAlarm_s89: any;
    NumAxisAlarm_s90: any;
    NumAxisAlarm_s91: any;
    NumAxisAlarm_s92: any;
    NumAxisAlarm_s93: any;
    NumAxisAlarm_s94: any;
    NumAxisAlarm_s95: any;
    NumAxisAlarm_s96: any;
    NumAxisAlarm_s97: any;
    NumAxisAlarm_s98: any;
    NumAxisAlarm_s99: any;
    NumAxisAlarm_s100: any;
    NumAxisAlarm_s101: any;
    NumAxisAlarm_s102: any;
    NumAxisAlarm_s103: any;
    NumAxisAlarm_s104: any;
    NumAxisAlarm_s105: any;
    NumAxisAlarm_s106: any;
    NumAxisAlarm_s107: any;
    NumAxisAlarm_s108: any;
    NumAxisAlarm_s109: any;
    NumAxisAlarm_s110: any;
    NumAxisAlarm_s111: any;
    NumAxisAlarm_s112: any;
    NumAxisAlarm_s113: any;
    NumAxisAlarm_s114: any;
    NumAxisAlarm_s115: any;
    NumAxisAlarm_s116: any;
    NumAxisAlarm_s117: any;
    NumAxisAlarm_s118: any;
    NumAxisAlarm_s119: any;
    NumAxisAlarm_s120: any;
    NumAxisAlarm_s121: any;
    NumAxisAlarm_s122: any;
    NumAxisAlarm_s123: any;
    NumAxisAlarm_s124: any;
    NumAxisAlarm_s125: any;
    NumAxisAlarm_s126: any;
    NumAxisAlarm_s127: any;
    NumAxisAlarm_s128: any;
    NumAxisAlarm_s129: any;
    NumAxisAlarm_s130: any;
    NumAxisAlarm_s131: any;
    NumAxisAlarm_s132: any;
    NumAxisAlarm_s133: any;
    NumAxisAlarm_s134: any;
    NumAxisAlarm_s135: any;
    NumAxisAlarm_s136: any;
    NumAxisAlarm_s137: any;
    NumAxisAlarm_s138: any;
    NumAxisAlarm_s139: any;
    NumAxisAlarm_s140: any;
    NumAxisAlarm_s141: any;
    NumAxisAlarm_s142: any;
    NumAxisAlarm_s143: any;
    NumAxisAlarm_s144: any;
    NumAxisAlarm_s145: any;
    NumAxisAlarm_s146: any;
    NumAxisAlarm_s147: any;
    NumAxisAlarm_s148: any;
    NumAxisAlarm_s149: any;
    NumAxisAlarm_s150: any;
    NumAxisAlarm_s151: any;
    NumAxisAlarm_s152: any;
    NumAxisAlarm_s153: any;
    NumAxisAlarm_s154: any;
    NumAxisAlarm_s155: any;
    NumAxisAlarm_s156: any;
    NumAxisAlarm_s157: any;
    NumAxisAlarm_s158: any;
    NumAxisAlarm_s159: any;
    NumAxisAlarm_s160: any;
    NumAxisAlarm_s161: any;
    NumAxisAlarm_s162: any;
    NumAxisAlarm_s163: any;
    NumAxisAlarm_s164: any;
    NumAxisAlarm_s165: any;
    NumAxisAlarm_s166: any;
    NumAxisAlarm_s167: any;
    NumAxisAlarm_s168: any;
    NumAxisAlarm_s169: any;
    NumAxisAlarm_s170: any;
    NumAxisAlarm_s171: any;
    NumAxisAlarm_s172: any;
    NumAxisAlarm_s173: any;
    NumAxisAlarm_s174: any;
    NumAxisAlarm_s175: any;
    NumAxisAlarm_s176: any;
    NumAxisAlarm_s177: any;
    NumAxisAlarm_s178: any;
    NumAxisAlarm_s179: any;
    NumAxisAlarm_s180: any;
    NumAxisAlarm_s181: any;
    NumAxisAlarm_s182: any;
    NumAxisAlarm_s183: any;
    NumAxisAlarm_s184: any;
    NumAxisAlarm_s185: any;
    NumAxisAlarm_s186: any;
    NumAxisAlarm_s187: any;
    NumAxisAlarm_s188: any;
    NumAxisAlarm_s189: any;
    NumAxisAlarm_s190: any;
    NumAxisAlarm_s191: any;
    NumAxisAlarm_s192: any;
    NumAxisAlarm_s193: any;
    NumAxisAlarm_s194: any;
    NumAxisAlarm_s195: any;
    NumAxisAlarm_s196: any;
    NumAxisAlarm_s197: any;
    NumAxisAlarm_s198: any;
    NumAxisAlarm_s199: any;
    NumAxisAlarm_s200: any;
    NumAxisAlarm_s201: any;
    NumAxisAlarm_s202: any;
    NumAxisAlarm_s203: any;
    NumAxisAlarm_s204: any;
    NumAxisAlarm_s205: any;
    NumAxisAlarm_s206: any;
    NumAxisAlarm_s207: any;
    NumAxisAlarm_s208: any;
    NumAxisAlarm_s209: any;
    NumAxisAlarm_s210: any;
    NumAxisAlarm_s211: any;
    NumAxisAlarm_s212: any;
    NumAxisAlarm_s213: any;
    NumAxisAlarm_s214: any;
    NumAxisAlarm_s215: any;
    NumAxisAlarm_s216: any;
    NumAxisAlarm_s217: any;
    NumAxisAlarm_s218: any;
    NumAxisAlarm_s219: any;
    NumAxisAlarm_s220: any;
    NumAxisAlarm_s221: any;
    NumAxisAlarm_s222: any;
    NumAxisAlarm_s223: any;
    NumAxisAlarm_s224: any;
    NumAxisAlarm_s225: any;
    NumAxisAlarm_s226: any;
    NumAxisAlarm_s227: any;
    NumAxisAlarm_s228: any;
    NumAxisAlarm_s229: any;
    NumAxisAlarm_s230: any;
    NumAxisAlarm_s231: any;
    NumAxisAlarm_s232: any;
    NumAxisAlarm_s233: any;
    NumAxisAlarm_s234: any;
    NumAxisAlarm_s235: any;
    NumAxisAlarm_s236: any;
    NumAxisAlarm_s237: any;
    NumAxisAlarm_s238: any;
    NumAxisAlarm_s239: any;
    NumAxisAlarm_s240: any;
    NumAxisAlarm_s241: any;
    NumAxisAlarm_s242: any;
    NumAxisAlarm_s243: any;
    NumAxisAlarm_s244: any;
    NumAxisAlarm_s245: any;
    NumAxisAlarm_s246: any;
    NumAxisAlarm_s247: any;
    NumAxisAlarm_s248: any;
    NumAxisAlarm_s249: any;
    NumAxisAlarm_s250: any;
    NumAxisAlarm_s251: any;
    NumAxisAlarm_s252: any;
    NumAxisAlarm_s253: any;
    NumAxisAlarm_s254: any;
    NumAxisAlarm_s255: any;
    NumAxisAlarm_s256: any;
    NumBattAlarm_s1: any;
    NumBattAlarm_s2: any;
    NumBattAlarm_s3: any;
    NumBattAlarm_s4: any;
    NumBattAlarm_s5: any;
    NumBattAlarm_s6: any;
    NumBattAlarm_s7: any;
    NumBattAlarm_s8: any;
    NumBattAlarm_s9: any;
    NumBattAlarm_s10: any;
    NumBattAlarm_s11: any;
    NumBattAlarm_s12: any;
    NumBattAlarm_s13: any;
    NumBattAlarm_s14: any;
    NumBattAlarm_s15: any;
    NumBattAlarm_s16: any;
    NumBattAlarm_s17: any;
    NumBattAlarm_s18: any;
    NumBattAlarm_s19: any;
    NumBattAlarm_s20: any;
    NumBattAlarm_s21: any;
    NumBattAlarm_s22: any;
    NumBattAlarm_s23: any;
    NumBattAlarm_s24: any;
    NumBattAlarm_s25: any;
    NumBattAlarm_s26: any;
    NumBattAlarm_s27: any;
    NumBattAlarm_s28: any;
    NumBattAlarm_s29: any;
    NumBattAlarm_s30: any;
    NumBattAlarm_s31: any;
    NumBattAlarm_s32: any;
    NumBattAlarm_s33: any;
    NumBattAlarm_s34: any;
    NumBattAlarm_s35: any;
    NumBattAlarm_s36: any;
    NumBattAlarm_s37: any;
    NumBattAlarm_s38: any;
    NumBattAlarm_s39: any;
    NumBattAlarm_s40: any;
    NumBattAlarm_s41: any;
    NumBattAlarm_s42: any;
    NumBattAlarm_s43: any;
    NumBattAlarm_s44: any;
    NumBattAlarm_s45: any;
    NumBattAlarm_s46: any;
    NumBattAlarm_s47: any;
    NumBattAlarm_s48: any;
    NumBattAlarm_s49: any;
    NumBattAlarm_s50: any;
    NumBattAlarm_s51: any;
    NumBattAlarm_s52: any;
    NumBattAlarm_s53: any;
    NumBattAlarm_s54: any;
    NumBattAlarm_s55: any;
    NumBattAlarm_s56: any;
    NumBattAlarm_s57: any;
    NumBattAlarm_s58: any;
    NumBattAlarm_s59: any;
    NumBattAlarm_s60: any;
    NumBattAlarm_s61: any;
    NumBattAlarm_s62: any;
    NumBattAlarm_s63: any;
    NumBattAlarm_s64: any;
    NumBattAlarm_s65: any;
    NumBattAlarm_s66: any;
    NumBattAlarm_s67: any;
    NumBattAlarm_s68: any;
    NumBattAlarm_s69: any;
    NumBattAlarm_s70: any;
    NumBattAlarm_s71: any;
    NumBattAlarm_s72: any;
    NumBattAlarm_s73: any;
    NumBattAlarm_s74: any;
    NumBattAlarm_s75: any;
    NumBattAlarm_s76: any;
    NumBattAlarm_s77: any;
    NumBattAlarm_s78: any;
    NumBattAlarm_s79: any;
    NumBattAlarm_s80: any;
    NumBattAlarm_s81: any;
    NumBattAlarm_s82: any;
    NumBattAlarm_s83: any;
    NumBattAlarm_s84: any;
    NumBattAlarm_s85: any;
    NumBattAlarm_s86: any;
    NumBattAlarm_s87: any;
    NumBattAlarm_s88: any;
    NumBattAlarm_s89: any;
    NumBattAlarm_s90: any;
    NumBattAlarm_s91: any;
    NumBattAlarm_s92: any;
    NumBattAlarm_s93: any;
    NumBattAlarm_s94: any;
    NumBattAlarm_s95: any;
    NumBattAlarm_s96: any;
    NumBattAlarm_s97: any;
    NumBattAlarm_s98: any;
    NumBattAlarm_s99: any;
    NumBattAlarm_s100: any;
    NumBattAlarm_s101: any;
    NumBattAlarm_s102: any;
    NumBattAlarm_s103: any;
    NumBattAlarm_s104: any;
    NumBattAlarm_s105: any;
    NumBattAlarm_s106: any;
    NumBattAlarm_s107: any;
    NumBattAlarm_s108: any;
    NumBattAlarm_s109: any;
    NumBattAlarm_s110: any;
    NumBattAlarm_s111: any;
    NumBattAlarm_s112: any;
    NumBattAlarm_s113: any;
    NumBattAlarm_s114: any;
    NumBattAlarm_s115: any;
    NumBattAlarm_s116: any;
    NumBattAlarm_s117: any;
    NumBattAlarm_s118: any;
    NumBattAlarm_s119: any;
    NumBattAlarm_s120: any;
    NumBattAlarm_s121: any;
    NumBattAlarm_s122: any;
    NumBattAlarm_s123: any;
    NumBattAlarm_s124: any;
    NumBattAlarm_s125: any;
    NumBattAlarm_s126: any;
    NumBattAlarm_s127: any;
    NumBattAlarm_s128: any;
    NumBattAlarm_s129: any;
    NumBattAlarm_s130: any;
    NumBattAlarm_s131: any;
    NumBattAlarm_s132: any;
    NumBattAlarm_s133: any;
    NumBattAlarm_s134: any;
    NumBattAlarm_s135: any;
    NumBattAlarm_s136: any;
    NumBattAlarm_s137: any;
    NumBattAlarm_s138: any;
    NumBattAlarm_s139: any;
    NumBattAlarm_s140: any;
    NumBattAlarm_s141: any;
    NumBattAlarm_s142: any;
    NumBattAlarm_s143: any;
    NumBattAlarm_s144: any;
    NumBattAlarm_s145: any;
    NumBattAlarm_s146: any;
    NumBattAlarm_s147: any;
    NumBattAlarm_s148: any;
    NumBattAlarm_s149: any;
    NumBattAlarm_s150: any;
    NumBattAlarm_s151: any;
    NumBattAlarm_s152: any;
    NumBattAlarm_s153: any;
    NumBattAlarm_s154: any;
    NumBattAlarm_s155: any;
    NumBattAlarm_s156: any;
    NumBattAlarm_s157: any;
    NumBattAlarm_s158: any;
    NumBattAlarm_s159: any;
    NumBattAlarm_s160: any;
    NumBattAlarm_s161: any;
    NumBattAlarm_s162: any;
    NumBattAlarm_s163: any;
    NumBattAlarm_s164: any;
    NumBattAlarm_s165: any;
    NumBattAlarm_s166: any;
    NumBattAlarm_s167: any;
    NumBattAlarm_s168: any;
    NumBattAlarm_s169: any;
    NumBattAlarm_s170: any;
    NumBattAlarm_s171: any;
    NumBattAlarm_s172: any;
    NumBattAlarm_s173: any;
    NumBattAlarm_s174: any;
    NumBattAlarm_s175: any;
    NumBattAlarm_s176: any;
    NumBattAlarm_s177: any;
    NumBattAlarm_s178: any;
    NumBattAlarm_s179: any;
    NumBattAlarm_s180: any;
    NumBattAlarm_s181: any;
    NumBattAlarm_s182: any;
    NumBattAlarm_s183: any;
    NumBattAlarm_s184: any;
    NumBattAlarm_s185: any;
    NumBattAlarm_s186: any;
    NumBattAlarm_s187: any;
    NumBattAlarm_s188: any;
    NumBattAlarm_s189: any;
    NumBattAlarm_s190: any;
    NumBattAlarm_s191: any;
    NumBattAlarm_s192: any;
    NumBattAlarm_s193: any;
    NumBattAlarm_s194: any;
    NumBattAlarm_s195: any;
    NumBattAlarm_s196: any;
    NumBattAlarm_s197: any;
    NumBattAlarm_s198: any;
    NumBattAlarm_s199: any;
    NumBattAlarm_s200: any;
    NumBattAlarm_s201: any;
    NumBattAlarm_s202: any;
    NumBattAlarm_s203: any;
    NumBattAlarm_s204: any;
    NumBattAlarm_s205: any;
    NumBattAlarm_s206: any;
    NumBattAlarm_s207: any;
    NumBattAlarm_s208: any;
    NumBattAlarm_s209: any;
    NumBattAlarm_s210: any;
    NumBattAlarm_s211: any;
    NumBattAlarm_s212: any;
    NumBattAlarm_s213: any;
    NumBattAlarm_s214: any;
    NumBattAlarm_s215: any;
    NumBattAlarm_s216: any;
    NumBattAlarm_s217: any;
    NumBattAlarm_s218: any;
    NumBattAlarm_s219: any;
    NumBattAlarm_s220: any;
    NumBattAlarm_s221: any;
    NumBattAlarm_s222: any;
    NumBattAlarm_s223: any;
    NumBattAlarm_s224: any;
    NumBattAlarm_s225: any;
    NumBattAlarm_s226: any;
    NumBattAlarm_s227: any;
    NumBattAlarm_s228: any;
    NumBattAlarm_s229: any;
    NumBattAlarm_s230: any;
    NumBattAlarm_s231: any;
    NumBattAlarm_s232: any;
    NumBattAlarm_s233: any;
    NumBattAlarm_s234: any;
    NumBattAlarm_s235: any;
    NumBattAlarm_s236: any;
    NumBattAlarm_s237: any;
    NumBattAlarm_s238: any;
    NumBattAlarm_s239: any;
    NumBattAlarm_s240: any;
    NumBattAlarm_s241: any;
    NumBattAlarm_s242: any;
    NumBattAlarm_s243: any;
    NumBattAlarm_s244: any;
    NumBattAlarm_s245: any;
    NumBattAlarm_s246: any;
    NumBattAlarm_s247: any;
    NumBattAlarm_s248: any;
    NumBattAlarm_s249: any;
    NumBattAlarm_s250: any;
    NumBattAlarm_s251: any;
    NumBattAlarm_s252: any;
    NumBattAlarm_s253: any;
    NumBattAlarm_s254: any;
    NumBattAlarm_s255: any;
    NumBattAlarm_s256: any;
    NumSwAlarm_s1: any;
    NumSwAlarm_s2: any;
    NumSwAlarm_s3: any;
    NumSwAlarm_s4: any;
    NumSwAlarm_s5: any;
    NumSwAlarm_s6: any;
    NumSwAlarm_s7: any;
    NumSwAlarm_s8: any;
    NumSwAlarm_s9: any;
    NumSwAlarm_s10: any;
    NumSwAlarm_s11: any;
    NumSwAlarm_s12: any;
    NumSwAlarm_s13: any;
    NumSwAlarm_s14: any;
    NumSwAlarm_s15: any;
    NumSwAlarm_s16: any;
    NumSwAlarm_s17: any;
    NumSwAlarm_s18: any;
    NumSwAlarm_s19: any;
    NumSwAlarm_s20: any;
    NumSwAlarm_s21: any;
    NumSwAlarm_s22: any;
    NumSwAlarm_s23: any;
    NumSwAlarm_s24: any;
    NumSwAlarm_s25: any;
    NumSwAlarm_s26: any;
    NumSwAlarm_s27: any;
    NumSwAlarm_s28: any;
    NumSwAlarm_s29: any;
    NumSwAlarm_s30: any;
    NumSwAlarm_s31: any;
    NumSwAlarm_s32: any;
    NumSwAlarm_s33: any;
    NumSwAlarm_s34: any;
    NumSwAlarm_s35: any;
    NumSwAlarm_s36: any;
    NumSwAlarm_s37: any;
    NumSwAlarm_s38: any;
    NumSwAlarm_s39: any;
    NumSwAlarm_s40: any;
    NumSwAlarm_s41: any;
    NumSwAlarm_s42: any;
    NumSwAlarm_s43: any;
    NumSwAlarm_s44: any;
    NumSwAlarm_s45: any;
    NumSwAlarm_s46: any;
    NumSwAlarm_s47: any;
    NumSwAlarm_s48: any;
    NumSwAlarm_s49: any;
    NumSwAlarm_s50: any;
    NumSwAlarm_s51: any;
    NumSwAlarm_s52: any;
    NumSwAlarm_s53: any;
    NumSwAlarm_s54: any;
    NumSwAlarm_s55: any;
    NumSwAlarm_s56: any;
    NumSwAlarm_s57: any;
    NumSwAlarm_s58: any;
    NumSwAlarm_s59: any;
    NumSwAlarm_s60: any;
    NumSwAlarm_s61: any;
    NumSwAlarm_s62: any;
    NumSwAlarm_s63: any;
    NumSwAlarm_s64: any;
    NumSwAlarm_s65: any;
    NumSwAlarm_s66: any;
    NumSwAlarm_s67: any;
    NumSwAlarm_s68: any;
    NumSwAlarm_s69: any;
    NumSwAlarm_s70: any;
    NumSwAlarm_s71: any;
    NumSwAlarm_s72: any;
    NumSwAlarm_s73: any;
    NumSwAlarm_s74: any;
    NumSwAlarm_s75: any;
    NumSwAlarm_s76: any;
    NumSwAlarm_s77: any;
    NumSwAlarm_s78: any;
    NumSwAlarm_s79: any;
    NumSwAlarm_s80: any;
    NumSwAlarm_s81: any;
    NumSwAlarm_s82: any;
    NumSwAlarm_s83: any;
    NumSwAlarm_s84: any;
    NumSwAlarm_s85: any;
    NumSwAlarm_s86: any;
    NumSwAlarm_s87: any;
    NumSwAlarm_s88: any;
    NumSwAlarm_s89: any;
    NumSwAlarm_s90: any;
    NumSwAlarm_s91: any;
    NumSwAlarm_s92: any;
    NumSwAlarm_s93: any;
    NumSwAlarm_s94: any;
    NumSwAlarm_s95: any;
    NumSwAlarm_s96: any;
    NumSwAlarm_s97: any;
    NumSwAlarm_s98: any;
    NumSwAlarm_s99: any;
    NumSwAlarm_s100: any;
    NumSwAlarm_s101: any;
    NumSwAlarm_s102: any;
    NumSwAlarm_s103: any;
    NumSwAlarm_s104: any;
    NumSwAlarm_s105: any;
    NumSwAlarm_s106: any;
    NumSwAlarm_s107: any;
    NumSwAlarm_s108: any;
    NumSwAlarm_s109: any;
    NumSwAlarm_s110: any;
    NumSwAlarm_s111: any;
    NumSwAlarm_s112: any;
    NumSwAlarm_s113: any;
    NumSwAlarm_s114: any;
    NumSwAlarm_s115: any;
    NumSwAlarm_s116: any;
    NumSwAlarm_s117: any;
    NumSwAlarm_s118: any;
    NumSwAlarm_s119: any;
    NumSwAlarm_s120: any;
    NumSwAlarm_s121: any;
    NumSwAlarm_s122: any;
    NumSwAlarm_s123: any;
    NumSwAlarm_s124: any;
    NumSwAlarm_s125: any;
    NumSwAlarm_s126: any;
    NumSwAlarm_s127: any;
    NumSwAlarm_s128: any;
    NumSwAlarm_s129: any;
    NumSwAlarm_s130: any;
    NumSwAlarm_s131: any;
    NumSwAlarm_s132: any;
    NumSwAlarm_s133: any;
    NumSwAlarm_s134: any;
    NumSwAlarm_s135: any;
    NumSwAlarm_s136: any;
    NumSwAlarm_s137: any;
    NumSwAlarm_s138: any;
    NumSwAlarm_s139: any;
    NumSwAlarm_s140: any;
    NumSwAlarm_s141: any;
    NumSwAlarm_s142: any;
    NumSwAlarm_s143: any;
    NumSwAlarm_s144: any;
    NumSwAlarm_s145: any;
    NumSwAlarm_s146: any;
    NumSwAlarm_s147: any;
    NumSwAlarm_s148: any;
    NumSwAlarm_s149: any;
    NumSwAlarm_s150: any;
    NumSwAlarm_s151: any;
    NumSwAlarm_s152: any;
    NumSwAlarm_s153: any;
    NumSwAlarm_s154: any;
    NumSwAlarm_s155: any;
    NumSwAlarm_s156: any;
    NumSwAlarm_s157: any;
    NumSwAlarm_s158: any;
    NumSwAlarm_s159: any;
    NumSwAlarm_s160: any;
    NumSwAlarm_s161: any;
    NumSwAlarm_s162: any;
    NumSwAlarm_s163: any;
    NumSwAlarm_s164: any;
    NumSwAlarm_s165: any;
    NumSwAlarm_s166: any;
    NumSwAlarm_s167: any;
    NumSwAlarm_s168: any;
    NumSwAlarm_s169: any;
    NumSwAlarm_s170: any;
    NumSwAlarm_s171: any;
    NumSwAlarm_s172: any;
    NumSwAlarm_s173: any;
    NumSwAlarm_s174: any;
    NumSwAlarm_s175: any;
    NumSwAlarm_s176: any;
    NumSwAlarm_s177: any;
    NumSwAlarm_s178: any;
    NumSwAlarm_s179: any;
    NumSwAlarm_s180: any;
    NumSwAlarm_s181: any;
    NumSwAlarm_s182: any;
    NumSwAlarm_s183: any;
    NumSwAlarm_s184: any;
    NumSwAlarm_s185: any;
    NumSwAlarm_s186: any;
    NumSwAlarm_s187: any;
    NumSwAlarm_s188: any;
    NumSwAlarm_s189: any;
    NumSwAlarm_s190: any;
    NumSwAlarm_s191: any;
    NumSwAlarm_s192: any;
    NumSwAlarm_s193: any;
    NumSwAlarm_s194: any;
    NumSwAlarm_s195: any;
    NumSwAlarm_s196: any;
    NumSwAlarm_s197: any;
    NumSwAlarm_s198: any;
    NumSwAlarm_s199: any;
    NumSwAlarm_s200: any;
    NumSwAlarm_s201: any;
    NumSwAlarm_s202: any;
    NumSwAlarm_s203: any;
    NumSwAlarm_s204: any;
    NumSwAlarm_s205: any;
    NumSwAlarm_s206: any;
    NumSwAlarm_s207: any;
    NumSwAlarm_s208: any;
    NumSwAlarm_s209: any;
    NumSwAlarm_s210: any;
    NumSwAlarm_s211: any;
    NumSwAlarm_s212: any;
    NumSwAlarm_s213: any;
    NumSwAlarm_s214: any;
    NumSwAlarm_s215: any;
    NumSwAlarm_s216: any;
    NumSwAlarm_s217: any;
    NumSwAlarm_s218: any;
    NumSwAlarm_s219: any;
    NumSwAlarm_s220: any;
    NumSwAlarm_s221: any;
    NumSwAlarm_s222: any;
    NumSwAlarm_s223: any;
    NumSwAlarm_s224: any;
    NumSwAlarm_s225: any;
    NumSwAlarm_s226: any;
    NumSwAlarm_s227: any;
    NumSwAlarm_s228: any;
    NumSwAlarm_s229: any;
    NumSwAlarm_s230: any;
    NumSwAlarm_s231: any;
    NumSwAlarm_s232: any;
    NumSwAlarm_s233: any;
    NumSwAlarm_s234: any;
    NumSwAlarm_s235: any;
    NumSwAlarm_s236: any;
    NumSwAlarm_s237: any;
    NumSwAlarm_s238: any;
    NumSwAlarm_s239: any;
    NumSwAlarm_s240: any;
    NumSwAlarm_s241: any;
    NumSwAlarm_s242: any;
    NumSwAlarm_s243: any;
    NumSwAlarm_s244: any;
    NumSwAlarm_s245: any;
    NumSwAlarm_s246: any;
    NumSwAlarm_s247: any;
    NumSwAlarm_s248: any;
    NumSwAlarm_s249: any;
    NumSwAlarm_s250: any;
    NumSwAlarm_s251: any;
    NumSwAlarm_s252: any;
    NumSwAlarm_s253: any;
    NumSwAlarm_s254: any;
    NumSwAlarm_s255: any;
    NumSwAlarm_s256: any;
    NumCommAlarm_s1: any;
    NumCommAlarm_s2: any;
    NumCommAlarm_s3: any;
    NumCommAlarm_s4: any;
    NumCommAlarm_s5: any;
    NumCommAlarm_s6: any;
    NumCommAlarm_s7: any;
    NumCommAlarm_s8: any;
    NumCommAlarm_s9: any;
    NumCommAlarm_s10: any;
    NumCommAlarm_s11: any;
    NumCommAlarm_s12: any;
    NumCommAlarm_s13: any;
    NumCommAlarm_s14: any;
    NumCommAlarm_s15: any;
    NumCommAlarm_s16: any;
    NumCommAlarm_s17: any;
    NumCommAlarm_s18: any;
    NumCommAlarm_s19: any;
    NumCommAlarm_s20: any;
    NumCommAlarm_s21: any;
    NumCommAlarm_s22: any;
    NumCommAlarm_s23: any;
    NumCommAlarm_s24: any;
    NumCommAlarm_s25: any;
    NumCommAlarm_s26: any;
    NumCommAlarm_s27: any;
    NumCommAlarm_s28: any;
    NumCommAlarm_s29: any;
    NumCommAlarm_s30: any;
    NumCommAlarm_s31: any;
    NumCommAlarm_s32: any;
    NumCommAlarm_s33: any;
    NumCommAlarm_s34: any;
    NumCommAlarm_s35: any;
    NumCommAlarm_s36: any;
    NumCommAlarm_s37: any;
    NumCommAlarm_s38: any;
    NumCommAlarm_s39: any;
    NumCommAlarm_s40: any;
    NumCommAlarm_s41: any;
    NumCommAlarm_s42: any;
    NumCommAlarm_s43: any;
    NumCommAlarm_s44: any;
    NumCommAlarm_s45: any;
    NumCommAlarm_s46: any;
    NumCommAlarm_s47: any;
    NumCommAlarm_s48: any;
    NumCommAlarm_s49: any;
    NumCommAlarm_s50: any;
    NumCommAlarm_s51: any;
    NumCommAlarm_s52: any;
    NumCommAlarm_s53: any;
    NumCommAlarm_s54: any;
    NumCommAlarm_s55: any;
    NumCommAlarm_s56: any;
    NumCommAlarm_s57: any;
    NumCommAlarm_s58: any;
    NumCommAlarm_s59: any;
    NumCommAlarm_s60: any;
    NumCommAlarm_s61: any;
    NumCommAlarm_s62: any;
    NumCommAlarm_s63: any;
    NumCommAlarm_s64: any;
    NumCommAlarm_s65: any;
    NumCommAlarm_s66: any;
    NumCommAlarm_s67: any;
    NumCommAlarm_s68: any;
    NumCommAlarm_s69: any;
    NumCommAlarm_s70: any;
    NumCommAlarm_s71: any;
    NumCommAlarm_s72: any;
    NumCommAlarm_s73: any;
    NumCommAlarm_s74: any;
    NumCommAlarm_s75: any;
    NumCommAlarm_s76: any;
    NumCommAlarm_s77: any;
    NumCommAlarm_s78: any;
    NumCommAlarm_s79: any;
    NumCommAlarm_s80: any;
    NumCommAlarm_s81: any;
    NumCommAlarm_s82: any;
    NumCommAlarm_s83: any;
    NumCommAlarm_s84: any;
    NumCommAlarm_s85: any;
    NumCommAlarm_s86: any;
    NumCommAlarm_s87: any;
    NumCommAlarm_s88: any;
    NumCommAlarm_s89: any;
    NumCommAlarm_s90: any;
    NumCommAlarm_s91: any;
    NumCommAlarm_s92: any;
    NumCommAlarm_s93: any;
    NumCommAlarm_s94: any;
    NumCommAlarm_s95: any;
    NumCommAlarm_s96: any;
    NumCommAlarm_s97: any;
    NumCommAlarm_s98: any;
    NumCommAlarm_s99: any;
    NumCommAlarm_s100: any;
    NumCommAlarm_s101: any;
    NumCommAlarm_s102: any;
    NumCommAlarm_s103: any;
    NumCommAlarm_s104: any;
    NumCommAlarm_s105: any;
    NumCommAlarm_s106: any;
    NumCommAlarm_s107: any;
    NumCommAlarm_s108: any;
    NumCommAlarm_s109: any;
    NumCommAlarm_s110: any;
    NumCommAlarm_s111: any;
    NumCommAlarm_s112: any;
    NumCommAlarm_s113: any;
    NumCommAlarm_s114: any;
    NumCommAlarm_s115: any;
    NumCommAlarm_s116: any;
    NumCommAlarm_s117: any;
    NumCommAlarm_s118: any;
    NumCommAlarm_s119: any;
    NumCommAlarm_s120: any;
    NumCommAlarm_s121: any;
    NumCommAlarm_s122: any;
    NumCommAlarm_s123: any;
    NumCommAlarm_s124: any;
    NumCommAlarm_s125: any;
    NumCommAlarm_s126: any;
    NumCommAlarm_s127: any;
    NumCommAlarm_s128: any;
    NumCommAlarm_s129: any;
    NumCommAlarm_s130: any;
    NumCommAlarm_s131: any;
    NumCommAlarm_s132: any;
    NumCommAlarm_s133: any;
    NumCommAlarm_s134: any;
    NumCommAlarm_s135: any;
    NumCommAlarm_s136: any;
    NumCommAlarm_s137: any;
    NumCommAlarm_s138: any;
    NumCommAlarm_s139: any;
    NumCommAlarm_s140: any;
    NumCommAlarm_s141: any;
    NumCommAlarm_s142: any;
    NumCommAlarm_s143: any;
    NumCommAlarm_s144: any;
    NumCommAlarm_s145: any;
    NumCommAlarm_s146: any;
    NumCommAlarm_s147: any;
    NumCommAlarm_s148: any;
    NumCommAlarm_s149: any;
    NumCommAlarm_s150: any;
    NumCommAlarm_s151: any;
    NumCommAlarm_s152: any;
    NumCommAlarm_s153: any;
    NumCommAlarm_s154: any;
    NumCommAlarm_s155: any;
    NumCommAlarm_s156: any;
    NumCommAlarm_s157: any;
    NumCommAlarm_s158: any;
    NumCommAlarm_s159: any;
    NumCommAlarm_s160: any;
    NumCommAlarm_s161: any;
    NumCommAlarm_s162: any;
    NumCommAlarm_s163: any;
    NumCommAlarm_s164: any;
    NumCommAlarm_s165: any;
    NumCommAlarm_s166: any;
    NumCommAlarm_s167: any;
    NumCommAlarm_s168: any;
    NumCommAlarm_s169: any;
    NumCommAlarm_s170: any;
    NumCommAlarm_s171: any;
    NumCommAlarm_s172: any;
    NumCommAlarm_s173: any;
    NumCommAlarm_s174: any;
    NumCommAlarm_s175: any;
    NumCommAlarm_s176: any;
    NumCommAlarm_s177: any;
    NumCommAlarm_s178: any;
    NumCommAlarm_s179: any;
    NumCommAlarm_s180: any;
    NumCommAlarm_s181: any;
    NumCommAlarm_s182: any;
    NumCommAlarm_s183: any;
    NumCommAlarm_s184: any;
    NumCommAlarm_s185: any;
    NumCommAlarm_s186: any;
    NumCommAlarm_s187: any;
    NumCommAlarm_s188: any;
    NumCommAlarm_s189: any;
    NumCommAlarm_s190: any;
    NumCommAlarm_s191: any;
    NumCommAlarm_s192: any;
    NumCommAlarm_s193: any;
    NumCommAlarm_s194: any;
    NumCommAlarm_s195: any;
    NumCommAlarm_s196: any;
    NumCommAlarm_s197: any;
    NumCommAlarm_s198: any;
    NumCommAlarm_s199: any;
    NumCommAlarm_s200: any;
    NumCommAlarm_s201: any;
    NumCommAlarm_s202: any;
    NumCommAlarm_s203: any;
    NumCommAlarm_s204: any;
    NumCommAlarm_s205: any;
    NumCommAlarm_s206: any;
    NumCommAlarm_s207: any;
    NumCommAlarm_s208: any;
    NumCommAlarm_s209: any;
    NumCommAlarm_s210: any;
    NumCommAlarm_s211: any;
    NumCommAlarm_s212: any;
    NumCommAlarm_s213: any;
    NumCommAlarm_s214: any;
    NumCommAlarm_s215: any;
    NumCommAlarm_s216: any;
    NumCommAlarm_s217: any;
    NumCommAlarm_s218: any;
    NumCommAlarm_s219: any;
    NumCommAlarm_s220: any;
    NumCommAlarm_s221: any;
    NumCommAlarm_s222: any;
    NumCommAlarm_s223: any;
    NumCommAlarm_s224: any;
    NumCommAlarm_s225: any;
    NumCommAlarm_s226: any;
    NumCommAlarm_s227: any;
    NumCommAlarm_s228: any;
    NumCommAlarm_s229: any;
    NumCommAlarm_s230: any;
    NumCommAlarm_s231: any;
    NumCommAlarm_s232: any;
    NumCommAlarm_s233: any;
    NumCommAlarm_s234: any;
    NumCommAlarm_s235: any;
    NumCommAlarm_s236: any;
    NumCommAlarm_s237: any;
    NumCommAlarm_s238: any;
    NumCommAlarm_s239: any;
    NumCommAlarm_s240: any;
    NumCommAlarm_s241: any;
    NumCommAlarm_s242: any;
    NumCommAlarm_s243: any;
    NumCommAlarm_s244: any;
    NumCommAlarm_s245: any;
    NumCommAlarm_s246: any;
    NumCommAlarm_s247: any;
    NumCommAlarm_s248: any;
    NumCommAlarm_s249: any;
    NumCommAlarm_s250: any;
    NumCommAlarm_s251: any;
    NumCommAlarm_s252: any;
    NumCommAlarm_s253: any;
    NumCommAlarm_s254: any;
    NumCommAlarm_s255: any;
    NumCommAlarm_s256: any;
    BT_Active_s1: any;
    BT_Active_s2: any;
    BT_Active_s3: any;
    BT_Active_s4: any;
    BT_Active_s5: any;
    BT_Active_s6: any;
    BT_Active_s7: any;
    BT_Active_s8: any;
    BT_Active_s9: any;
    BT_Active_s10: any;
    BT_Active_s11: any;
    BT_Active_s12: any;
    BT_Active_s13: any;
    BT_Active_s14: any;
    BT_Active_s15: any;
    BT_Active_s16: any;
    BT_Active_s17: any;
    BT_Active_s18: any;
    BT_Active_s19: any;
    BT_Active_s20: any;
    BT_Active_s21: any;
    BT_Active_s22: any;
    BT_Active_s23: any;
    BT_Active_s24: any;
    BT_Active_s25: any;
    BT_Active_s26: any;
    BT_Active_s27: any;
    BT_Active_s28: any;
    BT_Active_s29: any;
    BT_Active_s30: any;
    BT_Active_s31: any;
    BT_Active_s32: any;
    BT_Active_s33: any;
    BT_Active_s34: any;
    BT_Active_s35: any;
    BT_Active_s36: any;
    BT_Active_s37: any;
    BT_Active_s38: any;
    BT_Active_s39: any;
    BT_Active_s40: any;
    BT_Active_s41: any;
    BT_Active_s42: any;
    BT_Active_s43: any;
    BT_Active_s44: any;
    BT_Active_s45: any;
    BT_Active_s46: any;
    BT_Active_s47: any;
    BT_Active_s48: any;
    BT_Active_s49: any;
    BT_Active_s50: any;
    BT_Active_s51: any;
    BT_Active_s52: any;
    BT_Active_s53: any;
    BT_Active_s54: any;
    BT_Active_s55: any;
    BT_Active_s56: any;
    BT_Active_s57: any;
    BT_Active_s58: any;
    BT_Active_s59: any;
    BT_Active_s60: any;
    BT_Active_s61: any;
    BT_Active_s62: any;
    BT_Active_s63: any;
    BT_Active_s64: any;
    BT_Active_s65: any;
    BT_Active_s66: any;
    BT_Active_s67: any;
    BT_Active_s68: any;
    BT_Active_s69: any;
    BT_Active_s70: any;
    BT_Active_s71: any;
    BT_Active_s72: any;
    BT_Active_s73: any;
    BT_Active_s74: any;
    BT_Active_s75: any;
    BT_Active_s76: any;
    BT_Active_s77: any;
    BT_Active_s78: any;
    BT_Active_s79: any;
    BT_Active_s80: any;
    BT_Active_s81: any;
    BT_Active_s82: any;
    BT_Active_s83: any;
    BT_Active_s84: any;
    BT_Active_s85: any;
    BT_Active_s86: any;
    BT_Active_s87: any;
    BT_Active_s88: any;
    BT_Active_s89: any;
    BT_Active_s90: any;
    BT_Active_s91: any;
    BT_Active_s92: any;
    BT_Active_s93: any;
    BT_Active_s94: any;
    BT_Active_s95: any;
    BT_Active_s96: any;
    BT_Active_s97: any;
    BT_Active_s98: any;
    BT_Active_s99: any;
    BT_Active_s100: any;
    BT_Active_s101: any;
    BT_Active_s102: any;
    BT_Active_s103: any;
    BT_Active_s104: any;
    BT_Active_s105: any;
    BT_Active_s106: any;
    BT_Active_s107: any;
    BT_Active_s108: any;
    BT_Active_s109: any;
    BT_Active_s110: any;
    BT_Active_s111: any;
    BT_Active_s112: any;
    BT_Active_s113: any;
    BT_Active_s114: any;
    BT_Active_s115: any;
    BT_Active_s116: any;
    BT_Active_s117: any;
    BT_Active_s118: any;
    BT_Active_s119: any;
    BT_Active_s120: any;
    BT_Active_s121: any;
    BT_Active_s122: any;
    BT_Active_s123: any;
    BT_Active_s124: any;
    BT_Active_s125: any;
    BT_Active_s126: any;
    BT_Active_s127: any;
    BT_Active_s128: any;
    BT_Active_s129: any;
    BT_Active_s130: any;
    BT_Active_s131: any;
    BT_Active_s132: any;
    BT_Active_s133: any;
    BT_Active_s134: any;
    BT_Active_s135: any;
    BT_Active_s136: any;
    BT_Active_s137: any;
    BT_Active_s138: any;
    BT_Active_s139: any;
    BT_Active_s140: any;
    BT_Active_s141: any;
    BT_Active_s142: any;
    BT_Active_s143: any;
    BT_Active_s144: any;
    BT_Active_s145: any;
    BT_Active_s146: any;
    BT_Active_s147: any;
    BT_Active_s148: any;
    BT_Active_s149: any;
    BT_Active_s150: any;
    BT_Active_s151: any;
    BT_Active_s152: any;
    BT_Active_s153: any;
    BT_Active_s154: any;
    BT_Active_s155: any;
    BT_Active_s156: any;
    BT_Active_s157: any;
    BT_Active_s158: any;
    BT_Active_s159: any;
    BT_Active_s160: any;
    BT_Active_s161: any;
    BT_Active_s162: any;
    BT_Active_s163: any;
    BT_Active_s164: any;
    BT_Active_s165: any;
    BT_Active_s166: any;
    BT_Active_s167: any;
    BT_Active_s168: any;
    BT_Active_s169: any;
    BT_Active_s170: any;
    BT_Active_s171: any;
    BT_Active_s172: any;
    BT_Active_s173: any;
    BT_Active_s174: any;
    BT_Active_s175: any;
    BT_Active_s176: any;
    BT_Active_s177: any;
    BT_Active_s178: any;
    BT_Active_s179: any;
    BT_Active_s180: any;
    BT_Active_s181: any;
    BT_Active_s182: any;
    BT_Active_s183: any;
    BT_Active_s184: any;
    BT_Active_s185: any;
    BT_Active_s186: any;
    BT_Active_s187: any;
    BT_Active_s188: any;
    BT_Active_s189: any;
    BT_Active_s190: any;
    BT_Active_s191: any;
    BT_Active_s192: any;
    BT_Active_s193: any;
    BT_Active_s194: any;
    BT_Active_s195: any;
    BT_Active_s196: any;
    BT_Active_s197: any;
    BT_Active_s198: any;
    BT_Active_s199: any;
    BT_Active_s200: any;
    BT_Active_s201: any;
    BT_Active_s202: any;
    BT_Active_s203: any;
    BT_Active_s204: any;
    BT_Active_s205: any;
    BT_Active_s206: any;
    BT_Active_s207: any;
    BT_Active_s208: any;
    BT_Active_s209: any;
    BT_Active_s210: any;
    BT_Active_s211: any;
    BT_Active_s212: any;
    BT_Active_s213: any;
    BT_Active_s214: any;
    BT_Active_s215: any;
    BT_Active_s216: any;
    BT_Active_s217: any;
    BT_Active_s218: any;
    BT_Active_s219: any;
    BT_Active_s220: any;
    BT_Active_s221: any;
    BT_Active_s222: any;
    BT_Active_s223: any;
    BT_Active_s224: any;
    BT_Active_s225: any;
    BT_Active_s226: any;
    BT_Active_s227: any;
    BT_Active_s228: any;
    BT_Active_s229: any;
    BT_Active_s230: any;
    BT_Active_s231: any;
    BT_Active_s232: any;
    BT_Active_s233: any;
    BT_Active_s234: any;
    BT_Active_s235: any;
    BT_Active_s236: any;
    BT_Active_s237: any;
    BT_Active_s238: any;
    BT_Active_s239: any;
    BT_Active_s240: any;
    BT_Active_s241: any;
    BT_Active_s242: any;
    BT_Active_s243: any;
    BT_Active_s244: any;
    BT_Active_s245: any;
    BT_Active_s246: any;
    BT_Active_s247: any;
    BT_Active_s248: any;
    BT_Active_s249: any;
    BT_Active_s250: any;
    BT_Active_s251: any;
    BT_Active_s252: any;
    BT_Active_s253: any;
    BT_Active_s254: any;
    BT_Active_s255: any;
    BT_Active_s256: any;
    SafePositionState_s1: any;
    SafePositionState_s2: any;
    SafePositionState_s3: any;
    SafePositionState_s4: any;
    SafePositionState_s5: any;
    SafePositionState_s6: any;
    SafePositionState_s7: any;
    SafePositionState_s8: any;
    SafePositionState_s9: any;
    SafePositionState_s10: any;
    SafePositionState_s11: any;
    SafePositionState_s12: any;
    SafePositionState_s13: any;
    SafePositionState_s14: any;
    SafePositionState_s15: any;
    SafePositionState_s16: any;
    SafePositionState_s17: any;
    SafePositionState_s18: any;
    SafePositionState_s19: any;
    SafePositionState_s20: any;
    SafePositionState_s21: any;
    SafePositionState_s22: any;
    SafePositionState_s23: any;
    SafePositionState_s24: any;
    SafePositionState_s25: any;
    SafePositionState_s26: any;
    SafePositionState_s27: any;
    SafePositionState_s28: any;
    SafePositionState_s29: any;
    SafePositionState_s30: any;
    SafePositionState_s31: any;
    SafePositionState_s32: any;
    SafePositionState_s33: any;
    SafePositionState_s34: any;
    SafePositionState_s35: any;
    SafePositionState_s36: any;
    SafePositionState_s37: any;
    SafePositionState_s38: any;
    SafePositionState_s39: any;
    SafePositionState_s40: any;
    SafePositionState_s41: any;
    SafePositionState_s42: any;
    SafePositionState_s43: any;
    SafePositionState_s44: any;
    SafePositionState_s45: any;
    SafePositionState_s46: any;
    SafePositionState_s47: any;
    SafePositionState_s48: any;
    SafePositionState_s49: any;
    SafePositionState_s50: any;
    SafePositionState_s51: any;
    SafePositionState_s52: any;
    SafePositionState_s53: any;
    SafePositionState_s54: any;
    SafePositionState_s55: any;
    SafePositionState_s56: any;
    SafePositionState_s57: any;
    SafePositionState_s58: any;
    SafePositionState_s59: any;
    SafePositionState_s60: any;
    SafePositionState_s61: any;
    SafePositionState_s62: any;
    SafePositionState_s63: any;
    SafePositionState_s64: any;
    SafePositionState_s65: any;
    SafePositionState_s66: any;
    SafePositionState_s67: any;
    SafePositionState_s68: any;
    SafePositionState_s69: any;
    SafePositionState_s70: any;
    SafePositionState_s71: any;
    SafePositionState_s72: any;
    SafePositionState_s73: any;
    SafePositionState_s74: any;
    SafePositionState_s75: any;
    SafePositionState_s76: any;
    SafePositionState_s77: any;
    SafePositionState_s78: any;
    SafePositionState_s79: any;
    SafePositionState_s80: any;
    SafePositionState_s81: any;
    SafePositionState_s82: any;
    SafePositionState_s83: any;
    SafePositionState_s84: any;
    SafePositionState_s85: any;
    SafePositionState_s86: any;
    SafePositionState_s87: any;
    SafePositionState_s88: any;
    SafePositionState_s89: any;
    SafePositionState_s90: any;
    SafePositionState_s91: any;
    SafePositionState_s92: any;
    SafePositionState_s93: any;
    SafePositionState_s94: any;
    SafePositionState_s95: any;
    SafePositionState_s96: any;
    SafePositionState_s97: any;
    SafePositionState_s98: any;
    SafePositionState_s99: any;
    SafePositionState_s100: any;
    SafePositionState_s101: any;
    SafePositionState_s102: any;
    SafePositionState_s103: any;
    SafePositionState_s104: any;
    SafePositionState_s105: any;
    SafePositionState_s106: any;
    SafePositionState_s107: any;
    SafePositionState_s108: any;
    SafePositionState_s109: any;
    SafePositionState_s110: any;
    SafePositionState_s111: any;
    SafePositionState_s112: any;
    SafePositionState_s113: any;
    SafePositionState_s114: any;
    SafePositionState_s115: any;
    SafePositionState_s116: any;
    SafePositionState_s117: any;
    SafePositionState_s118: any;
    SafePositionState_s119: any;
    SafePositionState_s120: any;
    SafePositionState_s121: any;
    SafePositionState_s122: any;
    SafePositionState_s123: any;
    SafePositionState_s124: any;
    SafePositionState_s125: any;
    SafePositionState_s126: any;
    SafePositionState_s127: any;
    SafePositionState_s128: any;
    SafePositionState_s129: any;
    SafePositionState_s130: any;
    SafePositionState_s131: any;
    SafePositionState_s132: any;
    SafePositionState_s133: any;
    SafePositionState_s134: any;
    SafePositionState_s135: any;
    SafePositionState_s136: any;
    SafePositionState_s137: any;
    SafePositionState_s138: any;
    SafePositionState_s139: any;
    SafePositionState_s140: any;
    SafePositionState_s141: any;
    SafePositionState_s142: any;
    SafePositionState_s143: any;
    SafePositionState_s144: any;
    SafePositionState_s145: any;
    SafePositionState_s146: any;
    SafePositionState_s147: any;
    SafePositionState_s148: any;
    SafePositionState_s149: any;
    SafePositionState_s150: any;
    SafePositionState_s151: any;
    SafePositionState_s152: any;
    SafePositionState_s153: any;
    SafePositionState_s154: any;
    SafePositionState_s155: any;
    SafePositionState_s156: any;
    SafePositionState_s157: any;
    SafePositionState_s158: any;
    SafePositionState_s159: any;
    SafePositionState_s160: any;
    SafePositionState_s161: any;
    SafePositionState_s162: any;
    SafePositionState_s163: any;
    SafePositionState_s164: any;
    SafePositionState_s165: any;
    SafePositionState_s166: any;
    SafePositionState_s167: any;
    SafePositionState_s168: any;
    SafePositionState_s169: any;
    SafePositionState_s170: any;
    SafePositionState_s171: any;
    SafePositionState_s172: any;
    SafePositionState_s173: any;
    SafePositionState_s174: any;
    SafePositionState_s175: any;
    SafePositionState_s176: any;
    SafePositionState_s177: any;
    SafePositionState_s178: any;
    SafePositionState_s179: any;
    SafePositionState_s180: any;
    SafePositionState_s181: any;
    SafePositionState_s182: any;
    SafePositionState_s183: any;
    SafePositionState_s184: any;
    SafePositionState_s185: any;
    SafePositionState_s186: any;
    SafePositionState_s187: any;
    SafePositionState_s188: any;
    SafePositionState_s189: any;
    SafePositionState_s190: any;
    SafePositionState_s191: any;
    SafePositionState_s192: any;
    SafePositionState_s193: any;
    SafePositionState_s194: any;
    SafePositionState_s195: any;
    SafePositionState_s196: any;
    SafePositionState_s197: any;
    SafePositionState_s198: any;
    SafePositionState_s199: any;
    SafePositionState_s200: any;
    SafePositionState_s201: any;
    SafePositionState_s202: any;
    SafePositionState_s203: any;
    SafePositionState_s204: any;
    SafePositionState_s205: any;
    SafePositionState_s206: any;
    SafePositionState_s207: any;
    SafePositionState_s208: any;
    SafePositionState_s209: any;
    SafePositionState_s210: any;
    SafePositionState_s211: any;
    SafePositionState_s212: any;
    SafePositionState_s213: any;
    SafePositionState_s214: any;
    SafePositionState_s215: any;
    SafePositionState_s216: any;
    SafePositionState_s217: any;
    SafePositionState_s218: any;
    SafePositionState_s219: any;
    SafePositionState_s220: any;
    SafePositionState_s221: any;
    SafePositionState_s222: any;
    SafePositionState_s223: any;
    SafePositionState_s224: any;
    SafePositionState_s225: any;
    SafePositionState_s226: any;
    SafePositionState_s227: any;
    SafePositionState_s228: any;
    SafePositionState_s229: any;
    SafePositionState_s230: any;
    SafePositionState_s231: any;
    SafePositionState_s232: any;
    SafePositionState_s233: any;
    SafePositionState_s234: any;
    SafePositionState_s235: any;
    SafePositionState_s236: any;
    SafePositionState_s237: any;
    SafePositionState_s238: any;
    SafePositionState_s239: any;
    SafePositionState_s240: any;
    SafePositionState_s241: any;
    SafePositionState_s242: any;
    SafePositionState_s243: any;
    SafePositionState_s244: any;
    SafePositionState_s245: any;
    SafePositionState_s246: any;
    SafePositionState_s247: any;
    SafePositionState_s248: any;
    SafePositionState_s249: any;
    SafePositionState_s250: any;
    SafePositionState_s251: any;
    SafePositionState_s252: any;
    SafePositionState_s253: any;
    SafePositionState_s254: any;
    SafePositionState_s255: any;
    SafePositionState_s256: any;
    WindSpeed_today: any;
    WindDirection_today: any;


    ncualarm: any;
    ncutotal: any;
    rsualarm: any;
    rsutotal: any;
    tcualarm: any;
    tcutotal: any;

    forEach(param: any): void;
}

