import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { TelemetryControllerService, DeferredResultResponseEntity } from '../../rest';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-migas',
  templateUrl: './migas.component.html',
  styleUrls: ['./migas.component.scss'],
})
export class MigasComponent implements OnInit {
  @Input() vista: any;
  @Input() plantaid: any;
  @Input() deviceid: any;
  fechactual: any;
  name: any;
  nombreplanta: any;
  namedevice: any;
  arrayatributos: DeferredResultResponseEntity;

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    public http: HttpClient,
    public translate: TranslateService,
    // tslint:disable-next-line:no-shadowed-variable
    private TelemetryControllerService: TelemetryControllerService) {
    this.activeRoute.queryParams.subscribe(params => { this.plantaid = params.dataid; });
  }

  ngOnInit() {
    this.fechactual = formatDate(new Date(), 'yyyy/MM/dd HH:mm', 'en');
    this.TelemetryControllerService.getAttributesUsingGET('ASSET', this.plantaid)
      .pipe(switchMap(res => {
        return of(res);
      })
      )
      .subscribe(res => {
        this.arrayatributos = res;
        this.arrayatributos.forEach(function (obj, index) {
          switch (obj.key) {
            case 'device_ShortName': {
              this.name = obj.value;
              break;
            }
            case 'Display_Name': {
              this.nombreplanta = obj.value;
              break;
            }
          }
        }.bind(this));
      }, error => {
        console.log('error' + error);
      });
    if (this.vista === 3 || this.vista === 2 || this.vista === 1) {
      // NCU-TCU-RSU
      this.namedevice = this.deviceid;
      this.TelemetryControllerService.getAttributesUsingGET('DEVICE', this.deviceid)
        .pipe(switchMap(res => {
          return of(res);
        })
        )
        .subscribe(res => {
          this.arrayatributos = res;
          this.arrayatributos.forEach(function (obj, index) {
            switch (obj.key) {
              case 'device_ShortName': {
                this.namedevice = obj.value;
                break;
              }
            }
          }.bind(this));
        }, error => {
          console.log('error');
        });
    }
  }

  routingDeviceAngular(id: any, tipo: number, vista: number) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        dataid: id,
      },
      replaceUrl: true
    };

    if (vista === 0) {
      this.router.navigate(['/db-tabla'], navigationExtras);
    } else if (vista === 1) {
      if (tipo === 1) {
        this.router.navigate(['/planta-base'], navigationExtras);
      } else {
        this.router.navigate(['/db-tabla'], navigationExtras);
      }
    } else if (vista === 2) {
      if (tipo === 1) {
        this.router.navigate(['/planta-base'], navigationExtras);
      } else {
        this.router.navigate(['/db-tabla'], navigationExtras);
      }
    } else if (vista === 3) {
      if (tipo === 1) {
        this.router.navigate(['/planta-base'], navigationExtras);
      } else {
        this.router.navigate(['/db-tabla'], navigationExtras);
      }
    }
  }

}
