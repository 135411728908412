/**
 * ThingsBoard REST API
 * For instructions how to authorize requests please visit <a href=\'http://thingsboard.io/docs/reference/rest-api/\'>REST API documentation page</a>.
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@thingsboard.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EntityId } from './entityId';


export interface EntityRelation { 
    additionalInfo?: string;
    from?: EntityId;
    to?: EntityId;
    type?: string;
    typeGroup?: EntityRelation.TypeGroupEnum;
}
export namespace EntityRelation {
    export type TypeGroupEnum = 'COMMON' | 'ALARM' | 'DASHBOARD' | 'TO_ENTITY_GROUP' | 'FROM_ENTITY_GROUP' | 'RULE_CHAIN' | 'RULE_NODE';
    export const TypeGroupEnum = {
        COMMON: 'COMMON' as TypeGroupEnum,
        ALARM: 'ALARM' as TypeGroupEnum,
        DASHBOARD: 'DASHBOARD' as TypeGroupEnum,
        TOENTITYGROUP: 'TO_ENTITY_GROUP' as TypeGroupEnum,
        FROMENTITYGROUP: 'FROM_ENTITY_GROUP' as TypeGroupEnum,
        RULECHAIN: 'RULE_CHAIN' as TypeGroupEnum,
        RULENODE: 'RULE_NODE' as TypeGroupEnum
    };
}


