/**
 * ThingsBoard REST API
 * For instructions how to authorize requests please visit <a href=\'http://thingsboard.io/docs/reference/rest-api/\'>REST API documentation page</a>.
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@thingsboard.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ComponentDescriptorId } from './componentDescriptorId';


export interface ComponentDescriptor { 
    actions?: string;
    clazz?: string;
    configurationDescriptor?: string;
    createdTime?: number;
    id?: ComponentDescriptorId;
    name?: string;
    scope?: ComponentDescriptor.ScopeEnum;
    type?: ComponentDescriptor.TypeEnum;
}
export namespace ComponentDescriptor {
    export type ScopeEnum = 'SYSTEM' | 'TENANT';
    export const ScopeEnum = {
        SYSTEM: 'SYSTEM' as ScopeEnum,
        TENANT: 'TENANT' as ScopeEnum
    };
    export type TypeEnum = 'ENRICHMENT' | 'FILTER' | 'TRANSFORMATION' | 'ACTION' | 'ANALYTICS' | 'EXTERNAL';
    export const TypeEnum = {
        ENRICHMENT: 'ENRICHMENT' as TypeEnum,
        FILTER: 'FILTER' as TypeEnum,
        TRANSFORMATION: 'TRANSFORMATION' as TypeEnum,
        ACTION: 'ACTION' as TypeEnum,
        ANALYTICS: 'ANALYTICS' as TypeEnum,
        EXTERNAL: 'EXTERNAL' as TypeEnum
    };
}


