/**
 * ThingsBoard REST API
 * For instructions how to authorize requests please visit
 * <a href=\'http://thingsboard.io/docs/reference/rest-api/\'>REST API documentation page</a>.
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@thingsboard.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec } from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { EntityGroup } from '../model/models';
import { EntityGroupId } from '../model/models';
import { EntityGroupInfo } from '../model/models';
import { PageDataContactBasedobject } from '../model/models';
import { PageDataShortEntityView } from '../model/models';
import { ShortEntityView } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EntityGroupControllerService {

    // protected basePath = 'https://iot.stinorland.com:443';
    protected basePath = environment.apiThingsboardUrl;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;
    private textSearchsortPropertysortOrderpageSizepage: any;

    // tslint:disable-next-line:max-line-length
    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === 'object' && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === 'object') {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error('key may not be null if value is Date');
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error('key may not be null if value is not object or array');
        }
        return httpParams;
    }

    /**
     * addEntitiesToEntityGroup
     * @param entityGroupId entityGroupId
     * @param strEntityIds strEntityIds
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public addEntitiesToEntityGroupUsingPOST(entityGroupId: string, strEntityIds: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    // tslint:disable-next-line:max-line-length
    public addEntitiesToEntityGroupUsingPOST(entityGroupId: string, strEntityIds: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    // tslint:disable-next-line:max-line-length
    public addEntitiesToEntityGroupUsingPOST(entityGroupId: string, strEntityIds: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    // tslint:disable-next-line:max-line-length
    public addEntitiesToEntityGroupUsingPOST(entityGroupId: string, strEntityIds: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (entityGroupId === null || entityGroupId === undefined) {
            throw new Error('Required parameter entityGroupId was null or undefined when calling addEntitiesToEntityGroupUsingPOST.');
        }
        if (strEntityIds === null || strEntityIds === undefined) {
            throw new Error('Required parameter strEntityIds was null or undefined when calling addEntitiesToEntityGroupUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/entityGroup/${encodeURIComponent(String(entityGroupId))}/addEntities`,
            strEntityIds,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * deleteEntityGroup
     * @param entityGroupId entityGroupId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public deleteEntityGroupUsingDELETE(entityGroupId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    // tslint:disable-next-line:max-line-length
    public deleteEntityGroupUsingDELETE(entityGroupId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    // tslint:disable-next-line:max-line-length
    public deleteEntityGroupUsingDELETE(entityGroupId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    // tslint:disable-next-line:max-line-length
    public deleteEntityGroupUsingDELETE(entityGroupId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (entityGroupId === null || entityGroupId === undefined) {
            throw new Error('Required parameter entityGroupId was null or undefined when calling deleteEntityGroupUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/api/entityGroup/${encodeURIComponent(String(entityGroupId))}`,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getEnitityGroupByOwnerAndNameAndType
     * @param ownerType ownerType
     * @param ownerId ownerId
     * @param groupType EntityGroup type
     * @param groupName groupName
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEnitityGroupByOwnerAndNameAndTypeUsingGET(ownerType: string, ownerId: string, groupType: 'CUSTOMER' | 'ASSET' | 'DEVICE' | 'USER' | 'ENTITY_VIEW' | 'DASHBOARD', groupName: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<EntityGroupInfo>;
    public getEnitityGroupByOwnerAndNameAndTypeUsingGET(ownerType: string, ownerId: string, groupType: 'CUSTOMER' | 'ASSET' | 'DEVICE' | 'USER' | 'ENTITY_VIEW' | 'DASHBOARD', groupName: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<EntityGroupInfo>>;
    public getEnitityGroupByOwnerAndNameAndTypeUsingGET(ownerType: string, ownerId: string, groupType: 'CUSTOMER' | 'ASSET' | 'DEVICE' | 'USER' | 'ENTITY_VIEW' | 'DASHBOARD', groupName: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<EntityGroupInfo>>;
    public getEnitityGroupByOwnerAndNameAndTypeUsingGET(ownerType: string, ownerId: string, groupType: 'CUSTOMER' | 'ASSET' | 'DEVICE' | 'USER' | 'ENTITY_VIEW' | 'DASHBOARD', groupName: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (ownerType === null || ownerType === undefined) {
            // tslint:disable-next-line:max-line-length
            throw new Error('Required parameter ownerType was null or undefined when calling getEnitityGroupByOwnerAndNameAndTypeUsingGET.');
        }
        if (ownerId === null || ownerId === undefined) {
            throw new Error('Required parameter ownerId was null or undefined when calling getEnitityGroupByOwnerAndNameAndTypeUsingGET.');
        }
        if (groupType === null || groupType === undefined) {
            // tslint:disable-next-line:max-line-length
            throw new Error('Required parameter groupType was null or undefined when calling getEnitityGroupByOwnerAndNameAndTypeUsingGET.');
        }
        if (groupName === null || groupName === undefined) {
            // tslint:disable-next-line:max-line-length
            throw new Error('Required parameter groupName was null or undefined when calling getEnitityGroupByOwnerAndNameAndTypeUsingGET.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<EntityGroupInfo>(`${this.configuration.basePath}/api/entityGroup/${encodeURIComponent(String(ownerType))}/${encodeURIComponent(String(ownerId))}/${encodeURIComponent(String(groupType))}/${encodeURIComponent(String(groupName))}`,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getEntities
     * @param entityGroupId entityGroupId
     * @param pageSize Page size
     * @param page Page
     * @param textSearch textSearch
     * @param sortProperty sortProperty
     * @param sortOrder sortOrder
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public getEntitiesUsingGET(entityGroupId: string, pageSize: string, page: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<PageDataShortEntityView>;
    // tslint:disable-next-line:max-line-length
    public getEntitiesUsingGET(entityGroupId: string, pageSize: string, page: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<PageDataShortEntityView>>;
    // tslint:disable-next-line:max-line-length
    public getEntitiesUsingGET(entityGroupId: string, pageSize: string, page: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<PageDataShortEntityView>>;
    // tslint:disable-next-line:max-line-length
    public getEntitiesUsingGET(entityGroupId: string, pageSize: string, page: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (entityGroupId === null || entityGroupId === undefined) {
            throw new Error('Required parameter entityGroupId was null or undefined when calling getEntitiesUsingGET.');
        }
        if (pageSize === null || pageSize === undefined) {
            throw new Error('Required parameter pageSize was null or undefined when calling getEntitiesUsingGET.');
        }
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling getEntitiesUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (textSearch !== undefined && textSearch !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            textSearch as any, 'textSearch');
        }
        if (sortProperty !== undefined && sortProperty !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            sortProperty as any, 'sortProperty');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            sortOrder as any, 'sortOrder');
        }
        if (pageSize !== undefined && pageSize !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            pageSize as any, 'pageSize');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            page as any, 'page');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<PageDataShortEntityView>(`${this.configuration.basePath}/api/entityGroup/${encodeURIComponent(String(entityGroupId))}/entities${encodeURIComponent(String(this.textSearchsortPropertysortOrderpageSizepage))}`,
            {
                params: queryParameters,
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getEntityGroupAllByOwnerAndType
     * @param ownerType ownerType
     * @param ownerId ownerId
     * @param groupType EntityGroup type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEntityGroupAllByOwnerAndTypeUsingGET(ownerType: string, ownerId: string, groupType: 'CUSTOMER' | 'ASSET' | 'DEVICE' | 'USER' | 'ENTITY_VIEW' | 'DASHBOARD', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<EntityGroupInfo>;
    public getEntityGroupAllByOwnerAndTypeUsingGET(ownerType: string, ownerId: string, groupType: 'CUSTOMER' | 'ASSET' | 'DEVICE' | 'USER' | 'ENTITY_VIEW' | 'DASHBOARD', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<EntityGroupInfo>>;
    public getEntityGroupAllByOwnerAndTypeUsingGET(ownerType: string, ownerId: string, groupType: 'CUSTOMER' | 'ASSET' | 'DEVICE' | 'USER' | 'ENTITY_VIEW' | 'DASHBOARD', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<EntityGroupInfo>>;
    public getEntityGroupAllByOwnerAndTypeUsingGET(ownerType: string, ownerId: string, groupType: 'CUSTOMER' | 'ASSET' | 'DEVICE' | 'USER' | 'ENTITY_VIEW' | 'DASHBOARD', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (ownerType === null || ownerType === undefined) {
            throw new Error('Required parameter ownerType was null or undefined when calling getEntityGroupAllByOwnerAndTypeUsingGET.');
        }
        if (ownerId === null || ownerId === undefined) {
            throw new Error('Required parameter ownerId was null or undefined when calling getEntityGroupAllByOwnerAndTypeUsingGET.');
        }
        if (groupType === null || groupType === undefined) {
            throw new Error('Required parameter groupType was null or undefined when calling getEntityGroupAllByOwnerAndTypeUsingGET.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<EntityGroupInfo>(`${this.configuration.basePath}/api/entityGroup/all/${encodeURIComponent(String(ownerType))}/${encodeURIComponent(String(ownerId))}/${encodeURIComponent(String(groupType))}`,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getEntityGroupById
     * @param entityGroupId entityGroupId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public getEntityGroupByIdUsingGET(entityGroupId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<EntityGroupInfo>;
    public getEntityGroupByIdUsingGET(entityGroupId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<EntityGroupInfo>>;
    public getEntityGroupByIdUsingGET(entityGroupId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<EntityGroupInfo>>;
    // tslint:disable-next-line:max-line-length
    public getEntityGroupByIdUsingGET(entityGroupId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (entityGroupId === null || entityGroupId === undefined) {
            throw new Error('Required parameter entityGroupId was null or undefined when calling getEntityGroupByIdUsingGET.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<EntityGroupInfo>(`${this.configuration.basePath}/api/entityGroup/${encodeURIComponent(String(entityGroupId))}`,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getEntityGroupsByIds
     * @param entityGroupIds entityGroupIds
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEntityGroupsByIdsUsingGET(entityGroupIds: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<EntityGroup>>;
    // tslint:disable-next-line:max-line-length
    public getEntityGroupsByIdsUsingGET(entityGroupIds: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<EntityGroup>>>;
    public getEntityGroupsByIdsUsingGET(entityGroupIds: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<EntityGroup>>>;
    // tslint:disable-next-line:max-line-length
    public getEntityGroupsByIdsUsingGET(entityGroupIds: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (entityGroupIds === null || entityGroupIds === undefined) {
            throw new Error('Required parameter entityGroupIds was null or undefined when calling getEntityGroupsByIdsUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (entityGroupIds !== undefined && entityGroupIds !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            entityGroupIds as any, 'entityGroupIds');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<EntityGroup>>(`${this.configuration.basePath}/api/entityGroups${encodeURIComponent(String(entityGroupIds))}`,
            {
                params: queryParameters,
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getEntityGroupsByOwnerAndType
     * @param ownerType ownerType
     * @param ownerId ownerId
     * @param groupType EntityGroup type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEntityGroupsByOwnerAndTypeUsingGET(ownerType: string, ownerId: string, groupType: 'CUSTOMER' | 'ASSET' | 'DEVICE' | 'USER' | 'ENTITY_VIEW' | 'DASHBOARD', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<EntityGroupInfo>>;
    public getEntityGroupsByOwnerAndTypeUsingGET(ownerType: string, ownerId: string, groupType: 'CUSTOMER' | 'ASSET' | 'DEVICE' | 'USER' | 'ENTITY_VIEW' | 'DASHBOARD', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<EntityGroupInfo>>>;
    public getEntityGroupsByOwnerAndTypeUsingGET(ownerType: string, ownerId: string, groupType: 'CUSTOMER' | 'ASSET' | 'DEVICE' | 'USER' | 'ENTITY_VIEW' | 'DASHBOARD', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<EntityGroupInfo>>>;
    public getEntityGroupsByOwnerAndTypeUsingGET(ownerType: string, ownerId: string, groupType: 'CUSTOMER' | 'ASSET' | 'DEVICE' | 'USER' | 'ENTITY_VIEW' | 'DASHBOARD', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (ownerType === null || ownerType === undefined) {
            throw new Error('Required parameter ownerType was null or undefined when calling getEntityGroupsByOwnerAndTypeUsingGET.');
        }
        if (ownerId === null || ownerId === undefined) {
            throw new Error('Required parameter ownerId was null or undefined when calling getEntityGroupsByOwnerAndTypeUsingGET.');
        }
        if (groupType === null || groupType === undefined) {
            throw new Error('Required parameter groupType was null or undefined when calling getEntityGroupsByOwnerAndTypeUsingGET.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<EntityGroupInfo>>(`${this.configuration.basePath}/api/entityGroups/${encodeURIComponent(String(ownerType))}/${encodeURIComponent(String(ownerId))}/${encodeURIComponent(String(groupType))}`,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getEntityGroupsByType
     * @param groupType EntityGroup type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public getEntityGroupsByTypeUsingGET(groupType: 'CUSTOMER' | 'ASSET' | 'DEVICE' | 'USER' | 'ENTITY_VIEW' | 'DASHBOARD', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<EntityGroupInfo>>;
    public getEntityGroupsByTypeUsingGET(groupType: 'CUSTOMER' | 'ASSET' | 'DEVICE' | 'USER' | 'ENTITY_VIEW' | 'DASHBOARD', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<EntityGroupInfo>>>;
    public getEntityGroupsByTypeUsingGET(groupType: 'CUSTOMER' | 'ASSET' | 'DEVICE' | 'USER' | 'ENTITY_VIEW' | 'DASHBOARD', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<EntityGroupInfo>>>;
    public getEntityGroupsByTypeUsingGET(groupType: 'CUSTOMER' | 'ASSET' | 'DEVICE' | 'USER' | 'ENTITY_VIEW' | 'DASHBOARD', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (groupType === null || groupType === undefined) {
            throw new Error('Required parameter groupType was null or undefined when calling getEntityGroupsByTypeUsingGET.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<EntityGroupInfo>>(`${this.configuration.basePath}/api/entityGroups/${encodeURIComponent(String(groupType))}`,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getEntityGroupsForEntity
     * @param entityType Entity type
     * @param entityId entityId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public getEntityGroupsForEntityUsingGET(entityType: 'CUSTOMER' | 'ASSET' | 'DEVICE' | 'USER' | 'ENTITY_VIEW' | 'DASHBOARD', entityId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<EntityGroupId>>;
    // tslint:disable-next-line:max-line-length
    public getEntityGroupsForEntityUsingGET(entityType: 'CUSTOMER' | 'ASSET' | 'DEVICE' | 'USER' | 'ENTITY_VIEW' | 'DASHBOARD', entityId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<EntityGroupId>>>;
    // tslint:disable-next-line:max-line-length
    public getEntityGroupsForEntityUsingGET(entityType: 'CUSTOMER' | 'ASSET' | 'DEVICE' | 'USER' | 'ENTITY_VIEW' | 'DASHBOARD', entityId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<EntityGroupId>>>;
    // tslint:disable-next-line:max-line-length
    public getEntityGroupsForEntityUsingGET(entityType: 'CUSTOMER' | 'ASSET' | 'DEVICE' | 'USER' | 'ENTITY_VIEW' | 'DASHBOARD', entityId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (entityType === null || entityType === undefined) {
            throw new Error('Required parameter entityType was null or undefined when calling getEntityGroupsForEntityUsingGET.');
        }
        if (entityId === null || entityId === undefined) {
            throw new Error('Required parameter entityId was null or undefined when calling getEntityGroupsForEntityUsingGET.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<EntityGroupId>>(`${this.configuration.basePath}/api/entityGroups/${encodeURIComponent(String(entityType))}/${encodeURIComponent(String(entityId))}`,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getGroupEntity
     * @param entityGroupId entityGroupId
     * @param entityId entityId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public getGroupEntityUsingGET(entityGroupId: string, entityId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<ShortEntityView>;
    // tslint:disable-next-line:max-line-length
    public getGroupEntityUsingGET(entityGroupId: string, entityId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<ShortEntityView>>;
    // tslint:disable-next-line:max-line-length
    public getGroupEntityUsingGET(entityGroupId: string, entityId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<ShortEntityView>>;
    // tslint:disable-next-line:max-line-length
    public getGroupEntityUsingGET(entityGroupId: string, entityId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (entityGroupId === null || entityGroupId === undefined) {
            throw new Error('Required parameter entityGroupId was null or undefined when calling getGroupEntityUsingGET.');
        }
        if (entityId === null || entityId === undefined) {
            throw new Error('Required parameter entityId was null or undefined when calling getGroupEntityUsingGET.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ShortEntityView>(`${this.configuration.basePath}/api/entityGroup/${encodeURIComponent(String(entityGroupId))}/${encodeURIComponent(String(entityId))}`,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getOwners
     * @param pageSize pageSize
     * @param page page
     * @param textSearch textSearch
     * @param sortProperty sortProperty
     * @param sortOrder sortOrder
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOwnersUsingGET(pageSize: string, page: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<PageDataContactBasedobject>;
    public getOwnersUsingGET(pageSize: string, page: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<PageDataContactBasedobject>>;
    public getOwnersUsingGET(pageSize: string, page: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<PageDataContactBasedobject>>;
    public getOwnersUsingGET(pageSize: string, page: string, textSearch?: string, sortProperty?: string, sortOrder?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (pageSize === null || pageSize === undefined) {
            throw new Error('Required parameter pageSize was null or undefined when calling getOwnersUsingGET.');
        }
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling getOwnersUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (textSearch !== undefined && textSearch !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            textSearch as any, 'textSearch');
        }
        if (sortProperty !== undefined && sortProperty !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            sortProperty as any, 'sortProperty');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            sortOrder as any, 'sortOrder');
        }
        if (pageSize !== undefined && pageSize !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            pageSize as any, 'pageSize');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            page as any, 'page');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<PageDataContactBasedobject>(`${this.configuration.basePath}/api/owners${encodeURIComponent(String(this.textSearchsortPropertysortOrderpageSizepage))}`,
            {
                params: queryParameters,
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * makeEntityGroupPrivate
     * @param entityGroupId entityGroupId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public makeEntityGroupPrivateUsingPOST(entityGroupId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    // tslint:disable-next-line:max-line-length
    public makeEntityGroupPrivateUsingPOST(entityGroupId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    // tslint:disable-next-line:max-line-length
    public makeEntityGroupPrivateUsingPOST(entityGroupId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    // tslint:disable-next-line:max-line-length
    public makeEntityGroupPrivateUsingPOST(entityGroupId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (entityGroupId === null || entityGroupId === undefined) {
            throw new Error('Required parameter entityGroupId was null or undefined when calling makeEntityGroupPrivateUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/entityGroup/${encodeURIComponent(String(entityGroupId))}/makePrivate`,
            null,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * makeEntityGroupPublic
     * @param entityGroupId entityGroupId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public makeEntityGroupPublicUsingPOST(entityGroupId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    // tslint:disable-next-line:max-line-length
    public makeEntityGroupPublicUsingPOST(entityGroupId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    // tslint:disable-next-line:max-line-length
    public makeEntityGroupPublicUsingPOST(entityGroupId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    // tslint:disable-next-line:max-line-length
    public makeEntityGroupPublicUsingPOST(entityGroupId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (entityGroupId === null || entityGroupId === undefined) {
            throw new Error('Required parameter entityGroupId was null or undefined when calling makeEntityGroupPublicUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/entityGroup/${encodeURIComponent(String(entityGroupId))}/makePublic`,
            null,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * removeEntitiesFromEntityGroup
     * @param entityGroupId entityGroupId
     * @param strEntityIds strEntityIds
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public removeEntitiesFromEntityGroupUsingPOST(entityGroupId: string, strEntityIds: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    // tslint:disable-next-line:max-line-length
    public removeEntitiesFromEntityGroupUsingPOST(entityGroupId: string, strEntityIds: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    // tslint:disable-next-line:max-line-length
    public removeEntitiesFromEntityGroupUsingPOST(entityGroupId: string, strEntityIds: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    // tslint:disable-next-line:max-line-length
    public removeEntitiesFromEntityGroupUsingPOST(entityGroupId: string, strEntityIds: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (entityGroupId === null || entityGroupId === undefined) {
            throw new Error('Required parameter entityGroupId was null or undefined when calling removeEntitiesFromEntityGroupUsingPOST.');
        }
        if (strEntityIds === null || strEntityIds === undefined) {
            throw new Error('Required parameter strEntityIds was null or undefined when calling removeEntitiesFromEntityGroupUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/entityGroup/${encodeURIComponent(String(entityGroupId))}/deleteEntities`,
            strEntityIds,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * saveEntityGroup
     * @param entityGroup entityGroup
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveEntityGroupUsingPOST(entityGroup: EntityGroup, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<EntityGroupInfo>;
    public saveEntityGroupUsingPOST(entityGroup: EntityGroup, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<EntityGroupInfo>>;
    public saveEntityGroupUsingPOST(entityGroup: EntityGroup, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<EntityGroupInfo>>;
    // tslint:disable-next-line:max-line-length
    public saveEntityGroupUsingPOST(entityGroup: EntityGroup, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (entityGroup === null || entityGroup === undefined) {
            throw new Error('Required parameter entityGroup was null or undefined when calling saveEntityGroupUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<EntityGroupInfo>(`${this.configuration.basePath}/api/entityGroup`,
            entityGroup,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * shareEntityGroupToChildOwnerUserGroup
     * @param entityGroupId entityGroupId
     * @param userGroupId userGroupId
     * @param roleId roleId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public shareEntityGroupToChildOwnerUserGroupUsingPOST(entityGroupId: string, userGroupId: string, roleId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    // tslint:disable-next-line:max-line-length
    public shareEntityGroupToChildOwnerUserGroupUsingPOST(entityGroupId: string, userGroupId: string, roleId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    // tslint:disable-next-line:max-line-length
    public shareEntityGroupToChildOwnerUserGroupUsingPOST(entityGroupId: string, userGroupId: string, roleId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    // tslint:disable-next-line:max-line-length
    public shareEntityGroupToChildOwnerUserGroupUsingPOST(entityGroupId: string, userGroupId: string, roleId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (entityGroupId === null || entityGroupId === undefined) {
            throw new Error('Required parameter entityGroupId was null or undefined when calling shareEntityGroupToChildOwnerUserGroupUsingPOST.');
        }
        if (userGroupId === null || userGroupId === undefined) {
            throw new Error('Required parameter userGroupId was null or undefined when calling shareEntityGroupToChildOwnerUserGroupUsingPOST.');
        }
        if (roleId === null || roleId === undefined) {
            throw new Error('Required parameter roleId was null or undefined when calling shareEntityGroupToChildOwnerUserGroupUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/entityGroup/${encodeURIComponent(String(entityGroupId))}/${encodeURIComponent(String(userGroupId))}/${encodeURIComponent(String(roleId))}/share`,
            null,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

}
