/**
 * ThingsBoard REST API
 * For instructions how to authorize requests please visit <a href=\'http://thingsboard.io/docs/reference/rest-api/\'>REST API documentation page</a>.
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@thingsboard.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EntityId } from './entityId';
import {DeferredResultResponseEntity} from './deferredResultResponseEntity';


export interface EntityRelationInfo {
    AlarmNoWindInfo: any;
    AlarmNoSnowInfo: any;
    AlarmNoFloodInfo: any;
    BadConfiguration: any;
    AlarmWind: any;
    AlarmAnyWind: any;
    AlarmAnySnow: any;
    AlarmAnyGustWind: any;
    AlarmAnyFlood: any;
    Gw1Timeout: any;
    Gw2Timeout: any;
    AlarmUps: any;
    AlarmAnemoComm: any;
    AlarmAnyRsuComm: any;
    AlarmAnyWS: any;
    AlarmAnySS: any;
    AlarmAnyFS: any;
    AlarmMotor1OverCurrentSw: any;
    AlarmMotor1OverCurrentHw: any;
    AlarmTimeNeverSet: any;
    AlarmLowTemp: any;
    AlarmAxis1LowSpeed: any;
    AlarmAxis1Blocked: any;
    AlarmOutOfRange: any;
    AlarmLsHwBot1: any;
    AlarmLsHwTop1: any;
    AlarmCommLost: any;
    AlarmStopButton: any;
    AlarmWindHigh: any;
    AlarmWindMid: any;
    AlarmWindLow: any;
    AlarmRain: any;
    AlarmSnow: any;
    AlarmTestBuild: any;
    AlarmZigbee: any;
    AlarmSystemMonitor: any;
    AlarmSnowSensor: any;
    AlarmWindSensor: any;
    DifferenceAbs: any;
    Target_Angle_a1_deg: any;
    Charge: any;
    MainStateRequest: any;
    Position_a1_deg: any;
    alarmgreen: number;
    alarmred: number;
    alarmmorado: number;
    alarmorange: number;
    alarmblue: number;
    alarmyellow: number;
    alarm: any;
    additionalInfo?: string;
    from?: EntityId;
    fromName?: string;
    to?: EntityId;
    toName?: string;
    type?: string;
    typeGroup?: EntityRelationInfo.TypeGroupEnum;
    arrayatributos?: DeferredResultResponseEntity;
    tipo?: string;
    tiponumber?: number;
    hasAlarm?: boolean;
    hasStatus?: boolean;
    lastcommunication: any;
    pasafiltroncu: any;
    ncuLat: number;
    ncuLong: number;
    devicename(devicename: any);
    deviceid(deviceid: any);
    devicelat(devicelat: any);
    devicelong(devicelong: any);
    devicenamersu(devicename: any);
    deviceidrsu(deviceid: any);
    deviceactiversu(deviceactiversu: any);
    devicelatrsu(devicelat: any);
    devicelongrsu(devicelong: any);
    devicepositiona1deg(devicepositiona1deg: any);
    devicepositiondifferenceabs(devicepositiondifferenceabs: any);
    devicepositiontargetanglea1deg(devicepositiontargetanglea1deg: any);
    devicepositioncharge(devicepositioncharge: any);
    devicepositionmainstate(devicepositionmainstate: any);
    devicepositionnumcommalarm(devicepositionnumcommalarm: any);
    devicepositionnumswalarm(devicepositionnumswalarm: any);
    devicepositionnumbattalarm(devicepositionnumbattalarm: any);
    devicepositionnumaxisalarm(devicepositionnumaxisalarm: any);
    devicepositionnummotoralarm(devicepositionnummotoralarm: any);
    devicepositionalarmmotorhw(devicepositionalarmmotorhw: any);
    devicepositionalarmaxisblock(devicepositionalarmaxisblock: any);
    devicepositionalarmmotorsw(devicepositionalarmmotorsw: any);
    devicepositionalarmsystemmon(devicepositionalarmsystemmon: any);
    devicepositionalarmtestbuild(devicepositionalarmtestbuild: any);
    devicepositionalarmtimeneverset(devicepositionalarmtimeneverset: any);
    devicepositionalarmaxislowspeed(devicepositionalarmaxislowspeed: any);
    devicepositionalarmlshwbot(devicepositionalarmlshwbot: any);
    devicepositionalarmlshwtop(devicepositionalarmlshwtop: any);
    devicepositionalarmcomm(devicepositionalarmcomm: any);
    devicepositionalarmzigbee(devicepositionalarmzigbee: any);
    devicepositionalarmstopb(devicepositionalarmstopb: any);
    devicepositionalarmcriticalbat(devicepositionalarmcriticalbat: any);
    devicepositionalarmlowbat(devicepositionalarmlowbat: any);
    devicepositionalarmnotbat(devicepositionalarmnotbat: any);
    devicepositionalarmlimbat(devicepositionalarmlimbat: any);
    devicepositionalarmcommrsu(devicepositionalarmcommrsu: any);
    devicepositionalarmwsrsu(devicepositionalarmwsrsu: any);
    devicepositionalarmwindrsu(devicepositionalarmwindrsu: any);
    devicepositionalarmgustrsu(devicepositionalarmgustrsu: any);
    devicepositionalarmsnowrsu(devicepositionalarmsnowrsu: any);
    devicepositionalarmfloodrsu(devicepositionalarmfloodrsu: any);
    devicepositionnumcommrsu(devicepositionnumcommrsu: any);
    devicepositionnummetorsu(devicepositionnummetorsu: any);
    devicepositionpasafiltrotcu(devicepositionpasafiltrotcu: any);
    devicepositionpasafiltrorsu(devicepositionpasafiltrorsu: any);
}
// tslint:disable-next-line:no-namespace
export namespace EntityRelationInfo {
    export type TypeGroupEnum = 'COMMON' | 'ALARM' | 'DASHBOARD' | 'TO_ENTITY_GROUP' | 'FROM_ENTITY_GROUP' | 'RULE_CHAIN' | 'RULE_NODE';
    export const TypeGroupEnum = {
        COMMON: 'COMMON' as TypeGroupEnum,
        ALARM: 'ALARM' as TypeGroupEnum,
        DASHBOARD: 'DASHBOARD' as TypeGroupEnum,
        TOENTITYGROUP: 'TO_ENTITY_GROUP' as TypeGroupEnum,
        FROMENTITYGROUP: 'FROM_ENTITY_GROUP' as TypeGroupEnum,
        RULECHAIN: 'RULE_CHAIN' as TypeGroupEnum,
        RULENODE: 'RULE_NODE' as TypeGroupEnum
    };
}


