import {Component, Input, OnInit} from '@angular/core';
import { ModalController } from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-colour-legend',
  templateUrl: './colour-legend.component.html',
  styleUrls: ['./colour-legend.component.scss'],
})
export class ColourLegendComponent implements OnInit {

  @Input() totales: any;
  @Input() vista: any;
  constructor(public http: HttpClient, public translate: TranslateService, public modalCtrl: ModalController) { }

  ngOnInit() {}

  close() {
    this.modalCtrl.dismiss();
  }

}

