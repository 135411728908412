import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { ModalController } from '@ionic/angular';
import { ColourLegendComponent } from 'src/app/components/colour-legend/colour-legend.component';
import { DeviceInfoComponent } from 'src/app/components/device-info/device-info.component';

@Component({
  selector: 'app-footer-alarmas',
  templateUrl: './footer-alarmas.component.html',
  styleUrls: ['./footer-alarmas.component.scss'],
})
export class FooterAlarmasComponent implements OnInit {

  @Input() totales: any;
  @Input() vista: any;
  constructor(public http: HttpClient, public translate: TranslateService, public modalCtrl: ModalController) {}

  ngOnInit() {
  }

  async colourLegend() {

    const modalColourLegend = await this.modalCtrl.create({
        component: ColourLegendComponent,
        cssClass: 'colourLegendModal',
        componentProps: {
            'totales': this.totales,
            'vista': this.vista
        }
    });
    await modalColourLegend.present();
  }

  async deviceInfo() {

    const modalDeviceInfo = await this.modalCtrl.create({
        component: DeviceInfoComponent,
        cssClass: 'deviceInfoModal',
        componentProps: {
          'totales': this.totales,
          'vista': this.vista
        }
    });
    await modalDeviceInfo.present();

    modalDeviceInfo.onDidDismiss()
        .then((data) => {
        });
  }

}


