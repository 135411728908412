/**
 * ThingsBoard REST API
 * For instructions how to authorize requests please visit <a href=\'http://thingsboard.io/docs/reference/rest-api/\'>REST API documentation page</a>.
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@thingsboard.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AlarmId } from './alarmId';
import { EntityId } from './entityId';
import { TenantId } from './tenantId';


export interface Alarm { 
    ackTs?: number;
    clearTs?: number;
    createdTime?: number;
    details?: string;
    endTs?: number;
    id?: AlarmId;
    name?: string;
    originator?: EntityId;
    propagate?: boolean;
    propagateRelationTypes?: Array<string>;
    severity?: Alarm.SeverityEnum;
    startTs?: number;
    status?: Alarm.StatusEnum;
    tenantId?: TenantId;
    type?: string;
}
export namespace Alarm {
    export type SeverityEnum = 'CRITICAL' | 'MAJOR' | 'MINOR' | 'WARNING' | 'INDETERMINATE';
    export const SeverityEnum = {
        CRITICAL: 'CRITICAL' as SeverityEnum,
        MAJOR: 'MAJOR' as SeverityEnum,
        MINOR: 'MINOR' as SeverityEnum,
        WARNING: 'WARNING' as SeverityEnum,
        INDETERMINATE: 'INDETERMINATE' as SeverityEnum
    };
    export type StatusEnum = 'ACTIVE_UNACK' | 'ACTIVE_ACK' | 'CLEARED_UNACK' | 'CLEARED_ACK';
    export const StatusEnum = {
        ACTIVEUNACK: 'ACTIVE_UNACK' as StatusEnum,
        ACTIVEACK: 'ACTIVE_ACK' as StatusEnum,
        CLEAREDUNACK: 'CLEARED_UNACK' as StatusEnum,
        CLEAREDACK: 'CLEARED_ACK' as StatusEnum
    };
}


