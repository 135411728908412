/**
 * ThingsBoard REST API
 * For instructions how to authorize requests please visit <a href=\'http://thingsboard.io/docs/reference/rest-api/\'>REST API documentation page</a>.
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@thingsboard.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EntityId } from './entityId';
import { TenantId } from './tenantId';
import { UserId } from './userId';
import { CustomerId } from './customerId';


export interface User { 
    additionalInfo?: string;
    authority?: User.AuthorityEnum;
    createdTime?: number;
    customerId?: CustomerId;
    email?: string;
    firstName?: string;
    id?: UserId;
    lastName?: string;
    name?: string;
    ownerId?: EntityId;
    tenantId?: TenantId;
}
export namespace User {
    export type AuthorityEnum = 'SYS_ADMIN' | 'TENANT_ADMIN' | 'CUSTOMER_USER' | 'REFRESH_TOKEN';
    export const AuthorityEnum = {
        SYSADMIN: 'SYS_ADMIN' as AuthorityEnum,
        TENANTADMIN: 'TENANT_ADMIN' as AuthorityEnum,
        CUSTOMERUSER: 'CUSTOMER_USER' as AuthorityEnum,
        REFRESHTOKEN: 'REFRESH_TOKEN' as AuthorityEnum
    };
}


