/**
 * ThingsBoard REST API
 * For instructions how to authorize requests please visit
 * <a href=\'https://thingsboard.io/docs/reference/rest-api/\'>REST API documentation page</a>.
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@thingsboard.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec } from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { DeferredResultResponseEntity } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TelemetryControllerService {

    // protected basePath = 'https://iot.stinorland.com:443';
    protected basePath = environment.apiThingsboardUrl;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;
    private keysdeleteAllDataForKeysstartTsendTsrewriteLatestIfDeleted: any;
    private keysuseStrictDataTypes: any;
    private intervallimitagguseStrictDataTypeskeysstartTsendTs: any;

    // tslint:disable-next-line:max-line-length
    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === 'object' && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === 'object') {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error('key may not be null if value is Date');
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error('key may not be null if value is not object or array');
        }
        return httpParams;
    }

    /**
     * deleteEntityTimeseries
     * @param entityType entityType
     * @param entityId entityId
     * @param keys keys
     * @param deleteAllDataForKeys deleteAllDataForKeys
     * @param startTs startTs
     * @param endTs endTs
     * @param rewriteLatestIfDeleted rewriteLatestIfDeleted
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public deleteEntityTimeseriesUsingDELETE(entityType: string, entityId: string, keys: string, deleteAllDataForKeys?: boolean, startTs?: number, endTs?: number, rewriteLatestIfDeleted?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<DeferredResultResponseEntity>;
    // tslint:disable-next-line:max-line-length
    public deleteEntityTimeseriesUsingDELETE(entityType: string, entityId: string, keys: string, deleteAllDataForKeys?: boolean, startTs?: number, endTs?: number, rewriteLatestIfDeleted?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<DeferredResultResponseEntity>>;
    // tslint:disable-next-line:max-line-length
    public deleteEntityTimeseriesUsingDELETE(entityType: string, entityId: string, keys: string, deleteAllDataForKeys?: boolean, startTs?: number, endTs?: number, rewriteLatestIfDeleted?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<DeferredResultResponseEntity>>;
    // tslint:disable-next-line:max-line-length
    public deleteEntityTimeseriesUsingDELETE(entityType: string, entityId: string, keys: string, deleteAllDataForKeys?: boolean, startTs?: number, endTs?: number, rewriteLatestIfDeleted?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (entityType === null || entityType === undefined) {
            throw new Error('Required parameter entityType was null or undefined when calling deleteEntityTimeseriesUsingDELETE.');
        }
        if (entityId === null || entityId === undefined) {
            throw new Error('Required parameter entityId was null or undefined when calling deleteEntityTimeseriesUsingDELETE.');
        }
        if (keys === null || keys === undefined) {
            throw new Error('Required parameter keys was null or undefined when calling deleteEntityTimeseriesUsingDELETE.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (keys !== undefined && keys !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            keys as any, 'keys');
        }
        if (deleteAllDataForKeys !== undefined && deleteAllDataForKeys !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            deleteAllDataForKeys as any, 'deleteAllDataForKeys');
        }
        if (startTs !== undefined && startTs !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            startTs as any, 'startTs');
        }
        if (endTs !== undefined && endTs !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            endTs as any, 'endTs');
        }
        if (rewriteLatestIfDeleted !== undefined && rewriteLatestIfDeleted !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            rewriteLatestIfDeleted as any, 'rewriteLatestIfDeleted');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<DeferredResultResponseEntity>(`${this.configuration.basePath}/api/plugins/telemetry/${encodeURIComponent(String(entityType))}/${encodeURIComponent(String(entityId))}/timeseries/delete${encodeURIComponent(String(this.keysdeleteAllDataForKeysstartTsendTsrewriteLatestIfDeleted))}`,
            {
                params: queryParameters,
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getAttributeKeysByScope
     * @param entityType entityType
     * @param entityId entityId
     * @param scope scope
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public getAttributeKeysByScopeUsingGET(entityType: string, entityId: string, scope: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<DeferredResultResponseEntity>;
    // tslint:disable-next-line:max-line-length
    public getAttributeKeysByScopeUsingGET(entityType: string, entityId: string, scope: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<DeferredResultResponseEntity>>;
    // tslint:disable-next-line:max-line-length
    public getAttributeKeysByScopeUsingGET(entityType: string, entityId: string, scope: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<DeferredResultResponseEntity>>;
    // tslint:disable-next-line:max-line-length
    public getAttributeKeysByScopeUsingGET(entityType: string, entityId: string, scope: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (entityType === null || entityType === undefined) {
            throw new Error('Required parameter entityType was null or undefined when calling getAttributeKeysByScopeUsingGET.');
        }
        if (entityId === null || entityId === undefined) {
            throw new Error('Required parameter entityId was null or undefined when calling getAttributeKeysByScopeUsingGET.');
        }
        if (scope === null || scope === undefined) {
            throw new Error('Required parameter scope was null or undefined when calling getAttributeKeysByScopeUsingGET.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<DeferredResultResponseEntity>(`${this.configuration.basePath}/api/plugins/telemetry/${encodeURIComponent(String(entityType))}/${encodeURIComponent(String(entityId))}/keys/attributes/${encodeURIComponent(String(scope))}`,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getAttributeKeys
     * @param entityType entityType
     * @param entityId entityId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public getAttributeKeysUsingGET(entityType: string, entityId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<DeferredResultResponseEntity>;
    // tslint:disable-next-line:max-line-length
    public getAttributeKeysUsingGET(entityType: string, entityId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<DeferredResultResponseEntity>>;
    // tslint:disable-next-line:max-line-length
    public getAttributeKeysUsingGET(entityType: string, entityId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<DeferredResultResponseEntity>>;
    // tslint:disable-next-line:max-line-length
    public getAttributeKeysUsingGET(entityType: string, entityId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (entityType === null || entityType === undefined) {
            throw new Error('Required parameter entityType was null or undefined when calling getAttributeKeysUsingGET.');
        }
        if (entityId === null || entityId === undefined) {
            throw new Error('Required parameter entityId was null or undefined when calling getAttributeKeysUsingGET.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<DeferredResultResponseEntity>(`${this.configuration.basePath}/api/plugins/telemetry/${encodeURIComponent(String(entityType))}/${encodeURIComponent(String(entityId))}/keys/attributes`,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getAttributes
     * @param entityType entityType
     * @param entityId entityId
     * @param keys keys
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public getAttributesUsingGET(entityType: string, entityId: string, keys?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<DeferredResultResponseEntity>;
    // tslint:disable-next-line:max-line-length
    public getAttributesUsingGET(entityType: string, entityId: string, keys?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<DeferredResultResponseEntity>>;
    // tslint:disable-next-line:max-line-length
    public getAttributesUsingGET(entityType: string, entityId: string, keys?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<DeferredResultResponseEntity>>;
    // tslint:disable-next-line:max-line-length
    public getAttributesUsingGET(entityType: string, entityId: string, keys?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (entityType === null || entityType === undefined) {
            throw new Error('Required parameter entityType was null or undefined when calling getAttributesUsingGET.');
        }
        if (entityId === null || entityId === undefined) {
            throw new Error('Required parameter entityId was null or undefined when calling getAttributesUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (keys !== undefined && keys !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            keys as any, 'keys');
        }else{
            keys = '';
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<DeferredResultResponseEntity>(`${this.configuration.basePath}/api/plugins/telemetry/${encodeURIComponent(String(entityType))}/${encodeURIComponent(String(entityId))}/values/attributes${encodeURIComponent(String(keys))}`,
            {
                params: queryParameters,
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getLatestTimeseries
     * @param entityType entityType
     * @param entityId entityId
     * @param keys keys
     * @param useStrictDataTypes useStrictDataTypes
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public getLatestTimeseriesUsingGET(entityType: string, entityId: string, keys?: string, useStrictDataTypes?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<DeferredResultResponseEntity>;
    public getLatestTimeseriesUsingGET(entityType: string, entityId: string, keys?: string, useStrictDataTypes?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<DeferredResultResponseEntity>>;
    // tslint:disable-next-line:max-line-length
    public getLatestTimeseriesUsingGET(entityType: string, entityId: string, keys?: string, useStrictDataTypes?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<DeferredResultResponseEntity>>;
    public getLatestTimeseriesUsingGET(entityType: string, entityId: string, keys?: string, useStrictDataTypes?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (entityType === null || entityType === undefined) {
            throw new Error('Required parameter entityType was null or undefined when calling getLatestTimeseriesUsingGET.');
        }
        if (entityId === null || entityId === undefined) {
            throw new Error('Required parameter entityId was null or undefined when calling getLatestTimeseriesUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (keys !== undefined && keys !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            keys as any, 'keys');
        }
        if (useStrictDataTypes !== undefined && useStrictDataTypes !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            useStrictDataTypes as any, 'useStrictDataTypes');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        // tslint:disable-next-line:max-line-length
        // return this.httpClient.get<DeferredResultResponseEntity>(`${this.configuration.basePath}/api/plugins/telemetry/${encodeURIComponent(String(entityType))}/${encodeURIComponent(String(entityId))}/values/timeseries${encodeURIComponent(String(this.keysuseStrictDataTypes))}`,
        return this.httpClient.get<DeferredResultResponseEntity>(`${this.configuration.basePath}/api/plugins/telemetry/${encodeURIComponent(String(entityType))}/${encodeURIComponent(String(entityId))}/values/timeseries`,
            {
                params: queryParameters,
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getTimeseriesKeys
     * @param entityType entityType
     * @param entityId entityId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public getTimeseriesKeysUsingGET(entityType: string, entityId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<DeferredResultResponseEntity>;
    // tslint:disable-next-line:max-line-length
    public getTimeseriesKeysUsingGET(entityType: string, entityId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<DeferredResultResponseEntity>>;
    // tslint:disable-next-line:max-line-length
    public getTimeseriesKeysUsingGET(entityType: string, entityId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<DeferredResultResponseEntity>>;
    // tslint:disable-next-line:max-line-length
    public getTimeseriesKeysUsingGET(entityType: string, entityId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (entityType === null || entityType === undefined) {
            throw new Error('Required parameter entityType was null or undefined when calling getTimeseriesKeysUsingGET.');
        }
        if (entityId === null || entityId === undefined) {
            throw new Error('Required parameter entityId was null or undefined when calling getTimeseriesKeysUsingGET.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<DeferredResultResponseEntity>(`${this.configuration.basePath}/api/plugins/telemetry/${encodeURIComponent(String(entityType))}/${encodeURIComponent(String(entityId))}/keys/timeseries`,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * getTimeseries
     * @param entityType entityType
     * @param entityId entityId
     * @param keys keys
     * @param startTs startTs
     * @param endTs endTs
     * @param interval interval
     * @param limit limit
     * @param agg agg
     * @param useStrictDataTypes useStrictDataTypes
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public getTimeseriesUsingGET(entityType: string, entityId: string, keys: string, startTs: string, endTs: string, interval?: number, limit?: number, agg?: string, useStrictDataTypes?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<DeferredResultResponseEntity>;
    // tslint:disable-next-line:max-line-length
    public getTimeseriesUsingGET(entityType: string, entityId: string, keys: string, startTs: string, endTs: string, interval?: number, limit?: number, agg?: string, useStrictDataTypes?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<DeferredResultResponseEntity>>;
    // tslint:disable-next-line:max-line-length
    public getTimeseriesUsingGET(entityType: string, entityId: string, keys: string, startTs: string, endTs: string, interval?: number, limit?: number, agg?: string, useStrictDataTypes?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<DeferredResultResponseEntity>>;
    // tslint:disable-next-line:max-line-length
    public getTimeseriesUsingGET(entityType: string, entityId: string, keys: string, startTs: string, endTs: string, interval?: number, limit?: number, agg?: string, useStrictDataTypes?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (entityType === null || entityType === undefined) {
            throw new Error('Required parameter entityType was null or undefined when calling getTimeseriesUsingGET.');
        }
        if (entityId === null || entityId === undefined) {
            throw new Error('Required parameter entityId was null or undefined when calling getTimeseriesUsingGET.');
        }
        if (keys === null || keys === undefined) {
            throw new Error('Required parameter keys was null or undefined when calling getTimeseriesUsingGET.');
        }
        if (startTs === null || startTs === undefined) {
            throw new Error('Required parameter startTs was null or undefined when calling getTimeseriesUsingGET.');
        }
        if (endTs === null || endTs === undefined) {
            throw new Error('Required parameter endTs was null or undefined when calling getTimeseriesUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (interval !== undefined && interval !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            interval as any, 'interval');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            limit as any, 'limit');
        }
        if (agg !== undefined && agg !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            agg as any, 'agg');
        }
        if (useStrictDataTypes !== undefined && useStrictDataTypes !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            useStrictDataTypes as any, 'useStrictDataTypes');
        }
        if (keys !== undefined && keys !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            keys as any, 'keys');
        }
        if (startTs !== undefined && startTs !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            startTs as any, 'startTs');
        }
        if (endTs !== undefined && endTs !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            endTs as any, 'endTs');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        // tslint:disable-next-line:max-line-length
        // return this.httpClient.get<DeferredResultResponseEntity>(`${this.configuration.basePath}/api/plugins/telemetry/${encodeURIComponent(String(entityType))}/${encodeURIComponent(String(entityId))}/values/timeseries${encodeURIComponent(String(this.intervallimitagguseStrictDataTypeskeysstartTsendTs))}`,
        return this.httpClient.get<DeferredResultResponseEntity>(`${this.configuration.basePath}/api/plugins/telemetry/${encodeURIComponent(String(entityType))}/${encodeURIComponent(String(entityId))}/values/timeseries`,
            {
                params: queryParameters,
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * saveDeviceAttributes
     * @param deviceId deviceId
     * @param scope scope
     * @param request request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public saveDeviceAttributesUsingPOST(deviceId: string, scope: string, request: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<DeferredResultResponseEntity>;
    // tslint:disable-next-line:max-line-length
    public saveDeviceAttributesUsingPOST(deviceId: string, scope: string, request: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<DeferredResultResponseEntity>>;
    // tslint:disable-next-line:max-line-length
    public saveDeviceAttributesUsingPOST(deviceId: string, scope: string, request: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<DeferredResultResponseEntity>>;
    // tslint:disable-next-line:max-line-length
    public saveDeviceAttributesUsingPOST(deviceId: string, scope: string, request: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling saveDeviceAttributesUsingPOST.');
        }
        if (scope === null || scope === undefined) {
            throw new Error('Required parameter scope was null or undefined when calling saveDeviceAttributesUsingPOST.');
        }
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling saveDeviceAttributesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<DeferredResultResponseEntity>(`${this.configuration.basePath}/api/plugins/telemetry/${encodeURIComponent(String(deviceId))}/${encodeURIComponent(String(scope))}`,
            request,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * saveEntityAttributesV1
     * @param entityType entityType
     * @param entityId entityId
     * @param scope scope
     * @param request request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public saveEntityAttributesV1UsingPOST(entityType: string, entityId: string, scope: string, request: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<DeferredResultResponseEntity>;
    // tslint:disable-next-line:max-line-length
    public saveEntityAttributesV1UsingPOST(entityType: string, entityId: string, scope: string, request: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<DeferredResultResponseEntity>>;
    // tslint:disable-next-line:max-line-length
    public saveEntityAttributesV1UsingPOST(entityType: string, entityId: string, scope: string, request: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<DeferredResultResponseEntity>>;
    // tslint:disable-next-line:max-line-length
    public saveEntityAttributesV1UsingPOST(entityType: string, entityId: string, scope: string, request: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (entityType === null || entityType === undefined) {
            throw new Error('Required parameter entityType was null or undefined when calling saveEntityAttributesV1UsingPOST.');
        }
        if (entityId === null || entityId === undefined) {
            throw new Error('Required parameter entityId was null or undefined when calling saveEntityAttributesV1UsingPOST.');
        }
        if (scope === null || scope === undefined) {
            throw new Error('Required parameter scope was null or undefined when calling saveEntityAttributesV1UsingPOST.');
        }
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling saveEntityAttributesV1UsingPOST.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<DeferredResultResponseEntity>(`${this.configuration.basePath}/api/plugins/telemetry/${encodeURIComponent(String(entityType))}/${encodeURIComponent(String(entityId))}/${encodeURIComponent(String(scope))}`,
            request,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * saveEntityAttributesV2
     * @param entityType entityType
     * @param entityId entityId
     * @param scope scope
     * @param request request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public saveEntityAttributesV2UsingPOST(entityType: string, entityId: string, scope: string, request: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<DeferredResultResponseEntity>;
    // tslint:disable-next-line:max-line-length
    public saveEntityAttributesV2UsingPOST(entityType: string, entityId: string, scope: string, request: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<DeferredResultResponseEntity>>;
    // tslint:disable-next-line:max-line-length
    public saveEntityAttributesV2UsingPOST(entityType: string, entityId: string, scope: string, request: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<DeferredResultResponseEntity>>;
    // tslint:disable-next-line:max-line-length
    public saveEntityAttributesV2UsingPOST(entityType: string, entityId: string, scope: string, request: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (entityType === null || entityType === undefined) {
            throw new Error('Required parameter entityType was null or undefined when calling saveEntityAttributesV2UsingPOST.');
        }
        if (entityId === null || entityId === undefined) {
            throw new Error('Required parameter entityId was null or undefined when calling saveEntityAttributesV2UsingPOST.');
        }
        if (scope === null || scope === undefined) {
            throw new Error('Required parameter scope was null or undefined when calling saveEntityAttributesV2UsingPOST.');
        }
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling saveEntityAttributesV2UsingPOST.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<DeferredResultResponseEntity>(`${this.configuration.basePath}/api/plugins/telemetry/${encodeURIComponent(String(entityType))}/${encodeURIComponent(String(entityId))}/attributes/${encodeURIComponent(String(scope))}`,
            request,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * saveEntityTelemetry
     * @param entityType entityType
     * @param entityId entityId
     * @param scope scope
     * @param requestBody requestBody
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public saveEntityTelemetryUsingPOST(entityType: string, entityId: string, scope: string, requestBody: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<DeferredResultResponseEntity>;
    // tslint:disable-next-line:max-line-length
    public saveEntityTelemetryUsingPOST(entityType: string, entityId: string, scope: string, requestBody: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<DeferredResultResponseEntity>>;
    // tslint:disable-next-line:max-line-length
    public saveEntityTelemetryUsingPOST(entityType: string, entityId: string, scope: string, requestBody: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<DeferredResultResponseEntity>>;
    // tslint:disable-next-line:max-line-length
    public saveEntityTelemetryUsingPOST(entityType: string, entityId: string, scope: string, requestBody: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (entityType === null || entityType === undefined) {
            throw new Error('Required parameter entityType was null or undefined when calling saveEntityTelemetryUsingPOST.');
        }
        if (entityId === null || entityId === undefined) {
            throw new Error('Required parameter entityId was null or undefined when calling saveEntityTelemetryUsingPOST.');
        }
        if (scope === null || scope === undefined) {
            throw new Error('Required parameter scope was null or undefined when calling saveEntityTelemetryUsingPOST.');
        }
        if (requestBody === null || requestBody === undefined) {
            throw new Error('Required parameter requestBody was null or undefined when calling saveEntityTelemetryUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<DeferredResultResponseEntity>(`${this.configuration.basePath}/api/plugins/telemetry/${encodeURIComponent(String(entityType))}/${encodeURIComponent(String(entityId))}/timeseries/${encodeURIComponent(String(scope))}`,
            requestBody,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * saveEntityTelemetryWithTTL
     * @param entityType entityType
     * @param entityId entityId
     * @param scope scope
     * @param ttl ttl
     * @param requestBody requestBody
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // tslint:disable-next-line:max-line-length
    public saveEntityTelemetryWithTTLUsingPOST(entityType: string, entityId: string, scope: string, ttl: number, requestBody: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<DeferredResultResponseEntity>;
    // tslint:disable-next-line:max-line-length
    public saveEntityTelemetryWithTTLUsingPOST(entityType: string, entityId: string, scope: string, ttl: number, requestBody: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<DeferredResultResponseEntity>>;
    // tslint:disable-next-line:max-line-length
    public saveEntityTelemetryWithTTLUsingPOST(entityType: string, entityId: string, scope: string, ttl: number, requestBody: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<DeferredResultResponseEntity>>;
    // tslint:disable-next-line:max-line-length
    public saveEntityTelemetryWithTTLUsingPOST(entityType: string, entityId: string, scope: string, ttl: number, requestBody: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (entityType === null || entityType === undefined) {
            throw new Error('Required parameter entityType was null or undefined when calling saveEntityTelemetryWithTTLUsingPOST.');
        }
        if (entityId === null || entityId === undefined) {
            throw new Error('Required parameter entityId was null or undefined when calling saveEntityTelemetryWithTTLUsingPOST.');
        }
        if (scope === null || scope === undefined) {
            throw new Error('Required parameter scope was null or undefined when calling saveEntityTelemetryWithTTLUsingPOST.');
        }
        if (ttl === null || ttl === undefined) {
            throw new Error('Required parameter ttl was null or undefined when calling saveEntityTelemetryWithTTLUsingPOST.');
        }
        if (requestBody === null || requestBody === undefined) {
            throw new Error('Required parameter requestBody was null or undefined when calling saveEntityTelemetryWithTTLUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (X-Authorization) required
        credential = this.configuration.lookupCredential('X-Authorization');
        if (credential) {
            headers = headers.set('X-Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<DeferredResultResponseEntity>(`${this.configuration.basePath}/api/plugins/telemetry/${encodeURIComponent(String(entityType))}/${encodeURIComponent(String(entityId))}/timeseries/${encodeURIComponent(String(scope))}/${encodeURIComponent(String(ttl))}`,
            requestBody,
            {
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

}
