import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { IPublicClientApplication, PublicClientApplication, BrowserCacheLocation } from '@azure/msal-browser';
import { MsalModule, MsalService, MSAL_INSTANCE } from '@azure/msal-angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import {NgxPaginationModule} from 'ngx-pagination';

import { MarkerService } from './marker.service';
import { PopupService } from './popup.service';
import { HelperFunctionsService } from './helper-functions.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import {ComponentsModule} from './components/components.module';
import { environment } from './../environments/environment';

let msalInstance: IPublicClientApplication | undefined = undefined;
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function MSALInstanceFactory(): IPublicClientApplication {
  msalInstance =
      msalInstance ??
      new PublicClientApplication({
        auth: {
          clientId: '8f8ba5fc-88bf-4614-97fe-8de0d3002381', // Application (client) ID from the app registration
          // authority: 'Enter_the_Cloud_Instance_Id_Here/Enter_the_Tenant_Info_Here', // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
          authority: 'https://login.microsoftonline.com/0875fa84-3dbe-42a2-9cbc-0c9680ec3708', // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
          // redirectUri: 'http://localhost:8100', // This is your redirect URI LOCAL
          // postLogoutRedirectUri: 'http://localhost:8100',
          // redirectUri: 'https://stinorland.merkatu.info', // This is your redirect URI MERKATU.INFO
          // postLogoutRedirectUri: 'https://stinorland.merkatu.info',
          // redirectUri: 'https://iot.stinorland.com:3004', // This is your redirect URI PRODUCCION
          // postLogoutRedirectUri: 'https://iot.stinorland.com:3004',
          // redirectUri: 'http://localhost', // This is your redirect APK
          // postLogoutRedirectUri: 'http://localhost',
          redirectUri: environment.azureUrl, // // VARIABLE ENTORNO
          postLogoutRedirectUri: environment.azureUrl, // VARIABLE ENTORNO
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
        },
      });

  return msalInstance;
}


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    NgxPaginationModule,
    IonicModule.forRoot(),
    HttpClientModule,
    ComponentsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    AppRoutingModule],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    MarkerService,
    InAppBrowser,
    PopupService,
    HelperFunctionsService,
    { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
    MsalService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
