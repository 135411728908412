import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CabeceraComponent } from './cabecera/cabecera.component';
import { FooterAlarmasComponent } from './footer-alarmas/footer-alarmas.component';
import { ColourLegendComponent } from './colour-legend/colour-legend.component';
import { DeviceInfoComponent } from './device-info/device-info.component';
import { SidebarDispositivoModalComponent } from './sidebar-dispositivo-modal/sidebar-dispositivo-modal.component';
import { ProgressComponent } from './progress/progress.component';
import { MigasComponent } from './migas/migas.component';
import { SidebarDispositivoComponent } from './sidebar-dispositivo/sidebar-dispositivo.component';
import { TranslateModule } from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';


@NgModule({
  declarations: [
    CabeceraComponent,
    FooterAlarmasComponent,
    DeviceInfoComponent,
    SidebarDispositivoModalComponent,
    ProgressComponent,
    ColourLegendComponent,
    MigasComponent,
    SidebarDispositivoComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    IonicModule
  ],
  exports: [CabeceraComponent, FooterAlarmasComponent, DeviceInfoComponent, SidebarDispositivoModalComponent, ProgressComponent, ColourLegendComponent,MigasComponent, SidebarDispositivoComponent]
})
export class ComponentsModule { }
